import { observer } from 'mobx-react';
import { ICON_BUTTON_ICONS } from '../../buttons/IconButton';

const DetailsContactTab = observer(({ contact }) => {
  return (
    <div className="s-dct-info-wrap">
      <div className="s-dct-user-name">{contact.userName}</div>
      <div className="s-dct-company-name">{contact.companyName}</div>
      <div className="s-dct-separator" />
      <div className="s-dct-rows-wrap">
        <div className="s-dct-row">
          <img className="noselect s-dct-row-icon" src={ICON_BUTTON_ICONS.GEO_ALT} />
          <div className="s-dct-row-text">{contact.location}</div>
        </div>
        <div className="s-dct-row">
          <img className="noselect s-dct-row-icon" src={ICON_BUTTON_ICONS.TELEPHONE} />
          <div className="s-dct-row-text">{contact.phone}</div>
        </div>
        <div className="s-dct-row">
          <img className="noselect s-dct-row-icon" src={ICON_BUTTON_ICONS.ENVELOPE} />
          <div className="s-dct-row-text">{contact.email}</div>
        </div>
      </div>
    </div>
  );
});

export default DetailsContactTab;
