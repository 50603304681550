import { observer, useLocalObservable } from 'mobx-react';
import { PORTFOLIO_FIELDS } from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { formatNumberRange } from '../../../../utils/utils';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../../components/buttons/IconButton';
import { useEffect } from 'react';
import ExistingOutreachPopup from '../../../../components/shared/ExistingOutreachPopup';

const PortfolioResult = observer(
  ({ entry, displayDetailsPopup, displayMessagePopup, projectId, searchId, excludeResult }) => {
    const { utilsStore, outreachStore, projectStore } = useStore();
    const state = useLocalObservable(() => ({
      existingOutreachPopupOpen: false,
      toggleExistingOutreachPopup: () => {
        state.existingOutreachPopupOpen = !state.existingOutreachPopupOpen;
        utilsStore.lockScroll(state.existingOutreachPopupOpen);
      }
    }));

    useEffect(() => {
      return () => {
        if (state.existingOutreachPopupOpen) {
          state.toggleExistingOutreachPopup();
        }
      };
    }, [state]);

    let badgeClass = 'success';
    if (entry.match === 'Average match') {
      badgeClass = 'info';
    }
    if (entry.match === 'Good match') {
      badgeClass = 'warning';
    }

    const canOutreach =
      !!projectId &&
      !projectStore.isLoadingProjects &&
      !outreachStore.isSendingOutreach &&
      outreachStore.canSendOutreachToEntryId(projectId, entry.id);

    const disabledOutreach =
      !projectId || projectStore.isLoadingProjects || outreachStore.isSendingOutreach;

    const outreach = outreachStore.outreaches.find(
      (o) => o.projectId === projectId && o.objectData.id === entry.id
    );

    return (
      <div key={entry.id} className={`item${entry.isExcluded ? ' no-shadow' : ''}`}>
        {state.existingOutreachPopupOpen && (
          <ExistingOutreachPopup onClose={state.toggleExistingOutreachPopup} outreach={outreach} />
        )}
        <div className="cols">
          {/* <div className="col no-desktop mobile-badge no-border">
          <div className={`badge ${badgeClass}`}>{entry.match}</div>
        </div> */}
          <div className="col no-mobile">
            <div className="label">Portfolio name</div>
            <div className="value">{entry[PORTFOLIO_FIELDS.NAME.NAME]}</div>
          </div>
          <div className="col">
            <div className="label">Industry</div>
            <div className="value">
              {utilsStore
                .shortenList(utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.INDUSTRIES), 3)
                .join(', ')}
            </div>
          </div>
          <div className="col">
            <div className="label">Geographical focus</div>
            <div className="value">
              {utilsStore
                .shortenList(utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.GEOGRAPHIES), 3)
                .join(', ')}
            </div>
          </div>
          <div className="col no-mobile">
            <div className="label">Enterprise value (€M)</div>
            <div className="value">
              {formatNumberRange(
                entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
              )}
            </div>
          </div>
          <div className="col no-mobile">
            <div className="label">Equity value (€M)</div>
            <div className="value">
              {formatNumberRange(
                entry[PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME],
                entry[PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]
              )}
            </div>
          </div>
          <div className="col no-desktop">
            <div className="label">Stake</div>
            <div className="value">
              {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.EQUITY).join(', ')}
            </div>
          </div>
          <div className="col no-desktop no-border">
            <div className="label">Revenue (€M)</div>
            <div className="value">
              {formatNumberRange(
                entry[PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME],
                entry[PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]
              )}
            </div>
          </div>
        </div>
        {/* <div className="col no-mobile">
        <div className="label">Match</div>
        <div className={`value badge ${badgeClass}`}>{entry.match}</div>
      </div> */}
        <div className="actions default-search-actions">
          {entry.isExcluded ? (
            <div className="col no-border restore-btn">
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
                innerText="Restore"
                onClick={() => excludeResult(entry, false)}
                iconOnRight
                filled
              />
            </div>
          ) : (
            <>
              {' '}
              <div className="col no-border view-details-btn">
                <IconButton
                  filled
                  type={ICON_BUTTON_TYPES.BLUE}
                  onClick={() => displayDetailsPopup(entry)}
                  innerText="View details"
                />
              </div>
              <div className="col no-border">
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  icon={
                    outreachStore.canSendOutreachToEntryId(projectId, entry.id)
                      ? ICON_BUTTON_ICONS.CHAT_RIGHT_TEXT
                      : ICON_BUTTON_ICONS.ENVELOPE_CHECK
                  }
                  onClick={() => {
                    if (canOutreach) {
                      displayMessagePopup(entry);
                    } else {
                      if (outreach) {
                        state.toggleExistingOutreachPopup();
                      }
                    }
                  }}
                  disabled={disabledOutreach}
                  tooltipText={
                    !projectId
                      ? 'Save search results to initiate outreach'
                      : outreachStore.canSendOutreachToEntryId(projectId, entry.id)
                      ? 'Outreach'
                      : 'Outreach initiated'
                  }
                  filled={!outreachStore.canSendOutreachToEntryId(projectId, entry.id)}
                />
              </div>
              <div className="col no-border">
                <IconButton
                  type={ICON_BUTTON_TYPES.RED}
                  icon={ICON_BUTTON_ICONS.X_LG}
                  tooltipText="Exclude result"
                  onClick={() => excludeResult(entry, true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default PortfolioResult;
