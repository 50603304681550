import { observer } from 'mobx-react';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import { useStore } from '../../../store/store';
import useHistory from '../../../hooks/useHistory';
import { encodeOutreachParams } from './constants';
import { paths } from '../../../utils/constants/routes';

const TeamFeedbackButton = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { navigate } = useHistory();

  const isSelected = params.selected === 'teamFeedback';

  const onSelect = () => {
    if (isSelected) {
      return;
    }

    navigate(
      paths.OUTREACH +
        '?params=' +
        encodeOutreachParams({ selected: 'teamFeedback', type: null, outreachId: null })
    );
  };

  return (
    <div
      className={`noselect team-feedback-button${isSelected ? ' selected' : ''}`}
      onClick={onSelect}>
      <img src={ICON_BUTTON_ICONS.CHAT_LEFT_QUOTE} className="tfb-left-icon" />
      <div className="tbf-content-wrap">
        <div className="tfb-title">Team feedback</div>
        {outreachStore.outreachesList.teamFeedbackNewMessagesCount > 0 && (
          <div className="tfb-count">
            {outreachStore.outreachesList.teamFeedbackNewMessagesCount}
          </div>
        )}
        {!outreachStore.outreachesList.teamFeedbackNewMessagesCount &&
          outreachStore.outreachesList.teamFeedbackHasMarkedUnread && <div className="tfb-count" />}
        <img src={ICON_BUTTON_ICONS.CHEVRON_RIGHT} className="tfb-right-icon" />
      </div>
    </div>
  );
});

export default TeamFeedbackButton;
