export const CURRENCIES = {
  EUR: '€'
};

export const NUMBERS = {
  MILLION: 'M'
};

export const PUNCTUATION = {
  SPACE: ' ',
  COMMA: ',',
  SEMICOLON: ';',
  COLON: ':',
  QUOTATION: '"',
  APOSTROPHE: '’',
  PERCENT: '%',
  EN_DASH: '–', // ranges and with the meaning “to”
  EM_DASH: '—', // separate extra information or mark a break in a sentence
  HYPHEN: '-', // link words (or parts of words)
  EMPTY_VALUE: '– – –' // EN_DASH spaces with SPACE
};
