export const invTypePageSize = 8;
export const INV_TYPE_TABS = {
  FUNDS: 'funds',
  PORTFOLIOS: 'portfolios',
  INVEST_PROFILES: 'investprofiles'
};

export const encodeAllInvestmentsParams = (params = {}) =>
  encodeURIComponent(JSON.stringify(params));
export const decodeAllInvestmentsParams = (paramsString = '') =>
  JSON.parse(decodeURIComponent(paramsString));
