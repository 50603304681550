import { observer } from 'mobx-react';
import { INVEST_PROFILE_FIELDS } from '../../../../utils/constants/fields';
import { formatNumberRange, formatPercent } from '../../../../utils/utils';

const InvestProfileInformation = observer(({ entry }) => {
  return (
    <div className="content">
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{INVEST_PROFILE_FIELDS.REVENUE_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[INVEST_PROFILE_FIELDS.REVENUE_RANGE_MIN.NAME],
            entry[INVEST_PROFILE_FIELDS.REVENUE_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{INVEST_PROFILE_FIELDS.EBITDA_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[INVEST_PROFILE_FIELDS.EBITDA_RANGE_MIN.NAME],
            entry[INVEST_PROFILE_FIELDS.EBITDA_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{INVEST_PROFILE_FIELDS.EBITDA_MARGIN.LABEL}</div>
        <div className="value">
          {formatPercent(entry[INVEST_PROFILE_FIELDS.EBITDA_MARGIN.NAME])}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
            entry[INVEST_PROFILE_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{INVEST_PROFILE_FIELDS.EQUITY_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[INVEST_PROFILE_FIELDS.EQUITY_RANGE_MIN.NAME],
            entry[INVEST_PROFILE_FIELDS.EQUITY_RANGE_MAX.NAME]
          )}
        </div>
      </div>
    </div>
  );
});

export default InvestProfileInformation;
