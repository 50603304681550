export const encodeOutreachParams = (params = {}) => encodeURIComponent(JSON.stringify(params));
export const decodeOutreachParams = (paramsString = '') =>
  JSON.parse(decodeURIComponent(paramsString));

export const getOutreachMessageDisplayTime = (dateInMs, currentTimeInMs) => {
  let displayTime = '';
  if (dateInMs) {
    const diffInMinutes = Math.floor((currentTimeInMs - dateInMs) / 60000);
    if (diffInMinutes < 1) {
      displayTime = 'now';
    } else {
      if (diffInMinutes < 60) {
        displayTime = diffInMinutes + ' min';
      } else {
        const hours = Math.floor(diffInMinutes / 60);
        if (hours < 24) {
          displayTime = hours + ' h';
        } else {
          const days = Math.floor(hours / 24);
          if (days < 8) {
            displayTime = days + ' d';
          } else {
            const date = new Date(dateInMs);
            displayTime = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
          }
        }
      }
    }
  }

  return displayTime;
};

export const getCompanyNewsDisplayTime = (dateInMs, currentTimeInMs) => {
  let displayTime = '';
  if (dateInMs) {
    const diffInMinutes = Math.floor((currentTimeInMs - dateInMs) / 60000);
    if (diffInMinutes < 1) {
      displayTime = 'now';
    } else {
      if (diffInMinutes < 60) {
        displayTime = diffInMinutes + (diffInMinutes > 1 ? ' minutes' : ' minute') + ' ago';
      } else {
        const hours = Math.floor(diffInMinutes / 60);
        if (hours < 24) {
          displayTime = hours + (hours > 1 ? ' hours' : ' hour') + ' ago';
        } else {
          const days = Math.floor(hours / 24);
          if (days < 8) {
            displayTime = days + (days > 1 ? ' days' : ' day') + ' ago';
          } else {
            const date = new Date(dateInMs);
            displayTime = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
          }
        }
      }
    }
  }

  return displayTime;
};
