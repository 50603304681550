import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import AgentProjectButton from './AgentProjectButton';

const ArchivedProjectsSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isArchivedProjectsExpanded, toggle_d_ArchivedProjects, projectsList } = outreachStore;

  return (
    <>
      <CollapsableSectionHead
        title="Archived projects"
        icon={ICON_BUTTON_ICONS.ARCHIVE}
        isCollapsed={!d_isArchivedProjectsExpanded}
        toggleCollapse={toggle_d_ArchivedProjects}
        count={projectsList.archivedNewMessagesCount}
        showEmptyDot={
          !projectsList.archivedNewMessagesCount &&
          Object.values(projectsList.archived).findIndex((p) => p.hasMarkedUnread) !== -1
        }
      />
      {d_isArchivedProjectsExpanded &&
        Object.entries(projectsList.archived)
          .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
          .map(([projectId, project]) => {
            return (
              <AgentProjectButton
                key={projectId}
                projectId={projectId}
                project={project}
                params={params}
                list={projectsList.archived}
                type="archived"
              />
            );
          })}
      {d_isArchivedProjectsExpanded && <div className="separator" />}
    </>
  );
});

export default ArchivedProjectsSection;
