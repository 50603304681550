export const ACCOUNT_VERIF_TYPES = {
  // Expected from BE
  CREATED: 'Created',
  VERIFIED: 'Verified',
  APPROVED: 'Approved',
  // UI logic
  OBTAINING: 'OBTAINING',
  NOT_OBTAINED: 'NOT_OBTAINED'
};

export const ACCOUNT_TYPES = {
  // Expected from BE
  INVESTOR: 'Investor',
  AGENT: 'Agent',
  // UI logic
  OBTAINING: 'OBTAINING',
  NOT_OBTAINED: 'NOT_OBTAINED'
};
