import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

export const getUserPool = () => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  };

  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
};

export const getCognitoUser = (username) => {
  const userPool = getUserPool();

  const userData = {
    Username: username,
    Pool: userPool
  };

  return new AmazonCognitoIdentity.CognitoUser(userData);
};

export const exchangeCodeForTokens = async (code) => {
  console.log('exchangeCodeForTokens');
  // exchanging tokens is done via POSTing x-www-form-urlencoded data to the token endpoint
  const url = process.env.REACT_APP_COGNITO_DOMAIN + '/oauth2/token';
  const data = {
    grant_type: 'authorization_code',
    client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    redirect_uri: window.location.origin + '?authenticate',
    code: code
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams(data)
  };
  const result = await fetch(url, options);
  const json = await result.json();

  // create CognitoUserSession from tokens
  const idToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: json.id_token
  });
  const accessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: json.access_token
  });
  const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: json.refresh_token
  });
  const sessionData = {
    IdToken: idToken,
    AccessToken: accessToken,
    RefreshToken: refreshToken
  };
  const userSession = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

  const cognitoUser = getCognitoUser(idToken.payload.email);
  // refresh session
  cognitoUser.refreshSession(refreshToken, (err /*, session*/) => {
    if (err) {
      console.log('refreshSession err: ', err);
      return;
    }
  });
  return userSession;
};
