import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../store/store';
import MobileAppRefresher from '../loaders/MobileAppRefresher';

const SignLayout = observer(({ children }) => {
  const { utilsStore, isWaitlistActivated } = useStore();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (isWaitlistActivated) {
      utilsStore.setIsMobileFooterHidden(true);
      return () => {
        utilsStore.setIsMobileFooterHidden(false);
      };
    }
  }, []);

  return (
    <div
      className={`app-body signup-layout-initial-form ${
        utilsStore.isMobileHamburgerOpen ? 'hidden' : ''
      }`}>
      <MobileAppRefresher />
      <div className="left-item">
        <div className="title-container">
          <h1 className="signup">
            Empowering our clients to <span className="action-color">win</span>.
          </h1>
          {isWaitlistActivated ? (
            <h2 className="signup">Find out more and join our waiting list.</h2>
          ) : (
            <h2 className="signup">Sign up now to find out more.</h2>
          )}
        </div>
      </div>
      <div className="middle-item">
        <div className="divider"></div>
      </div>
      <div className="right-item">{children}</div>
    </div>
  );
});

export default SignLayout;
