import { observer } from 'mobx-react';
import { ThreeDots } from 'react-loader-spinner';

const GeneralLoader = observer(({ text = '' }) => {
  const showText = !!text;
  return (
    <div className="loader-wrapper">
      <div className="content">
        <ThreeDots width={48} height={12} color="#348CF9" />
        {showText && <div>{text}</div>}
      </div>
    </div>
  );
});

export default GeneralLoader;
