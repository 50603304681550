import { observer } from 'mobx-react';
import * as Portal from '@radix-ui/react-portal';
import IconButton, { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import { useEffect } from 'react';
import { useStore } from '../../../store/store';
import { stringToColour } from '../../../utils/utils';

const ChatMembersPopup = observer(({ onClose = () => {}, participants = [] }) => {
  const { authStore } = useStore();
  useEffect(() => {
    authStore.loadCompanyUsers();
  }, [authStore]);

  return (
    <Portal.Root>
      <div className="desktop-chat-members-popup-closer" onClick={onClose}></div>
      <div className="desktop-chat-members-popup">
        <div className="dcmp-head">
          <div className="dcmp-head-text">Members</div>
          <IconButton
            innerText="Close"
            icon={ICON_BUTTON_ICONS.X_LG}
            iconOnRight
            withBorder={false}
            withFill={false}
            onClick={onClose}
          />
        </div>
        <div className="dcmp-members-count">{participants.length} members</div>
        <div className="dcmp-separator" />
        <div className="fade-top" />
        <div className="fade-bottom" />
        <div className="dcmp-members-scroll-body">
          {participants.map((u) => {
            const jobPosition =
              authStore.companyUsers.find((cu) => cu.id === u.id)?.jobPosition || 'unknown';

            return (
              <div key={u.id} className="member-wrap">
                <div
                  className="noselect member-icon"
                  style={{ backgroundColor: stringToColour(u.firstName + u.lastName) }}>
                  {u.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="member-info-wrap">
                  <div className="member-name">
                    {u.firstName} {u.lastName}
                  </div>
                  <div className="member-job-position">{jobPosition}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Portal.Root>
  );
});

export default ChatMembersPopup;
