import { observer, useLocalObservable } from 'mobx-react';
import {
  MASS_OUTREACH_FIELDS,
  getErrorFields,
  trimStateFields,
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  INVEST_PROFILE_FIELDS
} from '../../../../utils/constants/fields';
import Input from '../../../../components/inputs/Input';
import TextArea from '../../../../components/inputs/TextArea';
import { mapFieldsToState } from '../../../../utils/utils';
import { useEffect } from 'react';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';
import { useStore } from '../../../../store/store';
import { eventOutreachSearch } from '../../../../ga4/ga4';
import { HEADER_MESSAGE_TYPES } from '../../../../utils/constants/header';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../../components/buttons/IconButton';
import { API_ENDPOINTS } from '../../../../api/endpoints';
import { REQ_METHODS } from '../../../../api/methods';
import { runInAction } from 'mobx';
import { CONTENT_TYPES } from '../../../../api/contentTypes';
import PDFList from '../../../../components/shared/PDFList';
import MultiSelect from '../../../../components/inputs/MultiSelect';

const MassOutreachPopup = observer(
  ({ toggleMassOutreachPopup, submitMessage, projectId, entries }) => {
    const { utilsStore, makeRequest, outreachStore, projectStore } = useStore();
    const state = useLocalObservable(() => ({
      isRendered: false,
      setIsRendered: (value = false) => (state.isRendered = value),
      fields: mapFieldsToState(MASS_OUTREACH_FIELDS),
      setFieldValue: (field = {}, value) => {
        state.fields[field.NAME] = value;
      },
      documents: [],
      onSubmitErrorState: false,
      setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
      get validationFields() {
        return getErrorFields(Object.values(MASS_OUTREACH_FIELDS), state.fields);
      },
      get isSendDisabled() {
        return (
          !!state.documents.find((d) => d.isUploading) ||
          state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
          (state.onSubmitErrorState && state.validationFields.invalidFields.length)
        );
      },
      isMessageSent: false,
      messageError: null,
      sendMessage: () => {
        trimStateFields(state.fields);
        if (state.validationFields.invalidFields.length) {
          if (!state.onSubmitErrorState) {
            state.setOnSubmitErrorState(true);
          }
          return;
        }

        state.messageError = null;
        submitMessage(
          state.fields[MASS_OUTREACH_FIELDS.ENRIES_IDS.NAME],
          state.fields[MASS_OUTREACH_FIELDS.SUBJECT.NAME],
          state.fields[MASS_OUTREACH_FIELDS.MESSAGE.NAME],
          state.documents.map((d) => d.key),
          () => {
            if (!state.isRendered) {
              utilsStore.setHeaderMessage(`Outreach successfully initiated.`);
              return;
            }
            state.isMessageSent = true;
            eventOutreachSearch();
          },
          (errorMessage) => {
            if (!state.isRendered) {
              utilsStore.setHeaderMessage(
                `Failed to send mass outreach!`,
                HEADER_MESSAGE_TYPES.ERROR
              );
              return;
            }
            state.messageError = errorMessage || 'Failed to send message to investor';
          }
        );
      }
    }));

    useEffect(() => {
      state.setIsRendered(true);
      return () => {
        state.setIsRendered(false);
      };
    }, [state]);

    const isLoading = outreachStore.isSendingOutreach;

    const types = {
      funds: 'Fund',
      portfolios: 'Portfolio company',
      investprofiles: 'Invest profile',
      undefined: 'Unknown'
    };
    const type =
      types[
        projectStore.projects.concat(projectStore.archivedProjects).find((p) => p.id === projectId)
          ?.searchType
      ];

    return (
      <div className="overlay">
        <div className="container popup-message">
          {isLoading ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => toggleMassOutreachPopup(false)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <GeneralLoader />
                </div>
              </div>
            </>
          ) : state.isMessageSent ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => toggleMassOutreachPopup(false)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <img src="/icons/check-circle-blue-40.svg" alt="Success" />
                  <div className="text">Outreach successfully initiated.</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="header message">
                <div className="text">
                  <label>Send message to multiple investors</label>
                </div>

                <div className="close" onClick={() => toggleMassOutreachPopup(false)}>
                  Close
                </div>
              </div>
              <div className="separator"></div>
              <div className="content">
                <MultiSelect
                  field={MASS_OUTREACH_FIELDS.ENRIES_IDS}
                  value={state.fields[MASS_OUTREACH_FIELDS.ENRIES_IDS.NAME]}
                  values={entries
                    .filter((e) => outreachStore.canSendOutreachToEntryId(projectId, e.id))
                    .map((e) => {
                      let entryName = e.name;
                      if (type === types.funds) {
                        entryName = e[FUND_FIELDS.NAME.NAME];
                      } else if (type === types.portfolios) {
                        entryName = e[PORTFOLIO_FIELDS.NAME.NAME];
                      } else if (type === types.investprofiles) {
                        entryName =
                          (e.companyName ? `${e.companyName} ` : '') +
                          e[INVEST_PROFILE_FIELDS.NAME.NAME];
                      }
                      return { name: entryName + ' • ' + type, value: e.id };
                    })}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  inputWrap={{ enable: true, className: 'field' }}
                />
                <Input
                  field={MASS_OUTREACH_FIELDS.SUBJECT}
                  value={state.fields[MASS_OUTREACH_FIELDS.SUBJECT.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field' }}
                />
                <TextArea
                  field={MASS_OUTREACH_FIELDS.MESSAGE}
                  value={state.fields[MASS_OUTREACH_FIELDS.MESSAGE.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field no-margin-bottom' }}
                />
                <PDFList documents={state.documents} />
                <div className="info">Outreach implies adherence to transact.digital terms.</div>
                <div className="actions outreach">
                  <div className="pdf-action">
                    <IconButton
                      type={ICON_BUTTON_TYPES.BLUE}
                      icon={ICON_BUTTON_ICONS.PAPERCLIP}
                      isPDF
                      onPDF={(file) => {
                        const size = file.size;
                        const sizeInMB = '(' + (size / (1024 * 1024)).toFixed(2) + 'mb)';
                        let key = (Math.random() + 1).toString(36).substring(7);
                        runInAction(() => {
                          state.documents.push({
                            key,
                            isUploading: true,
                            name: file.name,
                            size: sizeInMB
                          });
                        });

                        makeRequest({
                          endpoint: API_ENDPOINTS.DOCUMENT_PRESIGN,
                          body: { filename: file.name, size },
                          onSuccess: (response) => {
                            if (!state.isRendered) {
                              return;
                            }

                            const foundIndex = state.documents.findIndex((d) => d.key === key);
                            if (foundIndex !== -1) {
                              state.documents.splice(foundIndex, 1);
                            }

                            const { presignedUrl } = response;
                            key = response.key;

                            state.documents.splice(foundIndex, 0, {
                              key,
                              isUploading: true,
                              name: file.name,
                              size: sizeInMB
                            });

                            (async () => {
                              try {
                                await fetch(presignedUrl, {
                                  method: REQ_METHODS.PUT,
                                  headers: {
                                    'Content-Type': CONTENT_TYPES.TEXT_PLAIN
                                  },
                                  body: file
                                });

                                if (!state.isRendered) {
                                  return;
                                }

                                const foundIndex = state.documents.findIndex((d) => d.key === key);
                                if (foundIndex !== -1) {
                                  runInAction(() => {
                                    state.documents[foundIndex].isUploading = false;
                                  });
                                }
                              } catch (error) {
                                if (!state.isRendered) {
                                  return;
                                }

                                const foundIndex = state.documents.findIndex((d) => d.key === key);
                                if (foundIndex !== -1) {
                                  runInAction(() => {
                                    state.documents.splice(foundIndex, 1);
                                  });
                                }

                                console.log('Error from ', presignedUrl);
                                console.log('error', error);
                              }
                            })();
                          },
                          onError: (errorMessage) => {
                            const foundIndex = state.documents.findIndex((d) => d.key === key);
                            if (foundIndex !== -1) {
                              state.documents.splice(foundIndex, 1);
                            }
                            console.log('Error from /documents-presign-url');
                            console.log('errorMessage', errorMessage);
                          }
                        });
                      }}
                    />
                    <div className="pdf-action-info">pdf format only</div>
                  </div>
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    innerText="Send multiple messages"
                    disabled={state.isSendDisabled}
                    onClick={state.sendMessage}
                    filled
                  />
                </div>
                {!!state.messageError && <div className="errors">{state.messageError}</div>}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default MassOutreachPopup;
