import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { GA_CONFIG, GA_EVENTS, GA_DIMENSIONS, isInDevelopment } from './constants';
import { logError, gaEventGuard } from './utils';
import { FUND_FIELDS, isEmptyValue, PORTFOLIO_FIELDS } from '../utils/constants/fields';

let isInit = false;

// const dimsToClear = Object.values(GA_DIMENSIONS).filter(
//   (dim) => ![GA_DIMENSIONS.traffic_type].includes(dim)
// );

const dimsToClear = [GA_DIMENSIONS.testMode, GA_DIMENSIONS.debug_mode, GA_DIMENSIONS.debug_event];

const dimsClearObj = Object.fromEntries(dimsToClear.map((dim) => [dim, undefined]));

/**
 * Initializes GTM and GA4
 */
export const initGA = () => {
  if (!GA_CONFIG.gaMeasurementId && !GA_CONFIG.gtmContainerId) {
    return;
  }

  const isInternalTraffic = isInDevelopment;

  const initOptions = {
    ...(isInDevelopment ? { [GA_DIMENSIONS.debug_mode]: true } : {}),
    ...(isInternalTraffic ? { [GA_DIMENSIONS.traffic_type]: 'internal' } : {})
  };

  const gaOptions = {
    anonymizeIp: true,
    siteSpeedSampleRate: 100,
    ...initOptions
  };

  if (GA_CONFIG.gaMeasurementId) {
    ReactGA.initialize(GA_CONFIG.gaMeasurementId, {
      [GA_DIMENSIONS.testMode]: isInDevelopment,
      gaOptions
      // legacyDimensionMetric: false, // uncomment if needed to be false
    });
  }

  if (GA_CONFIG.gtmContainerId) {
    TagManager.initialize({
      gtmId: GA_CONFIG.gtmContainerId,
      dataLayer: {
        anonymizeIp: true,
        ...initOptions
      }
    });

    isInit = true;
  }
};

/**
 * Pushes event objects to GTM data layer.
 * @param {Object} mainEventData - The event object.
 */
const pushEvent = (eventName = '', mainEventData = {}) => {
  if (!isInit) {
    return;
  }

  if (!Object.values(GA_EVENTS).includes(eventName)) {
    logError(`[pushEvent] 'eventName' param (${eventName}) is wrong!`);
  }

  const dataLayerVariables = {};
  if (isInDevelopment) {
    Object.assign(
      dataLayerVariables,
      Object.fromEntries(
        [GA_DIMENSIONS.testMode, GA_DIMENSIONS.debug_mode, GA_DIMENSIONS.debug_event].map((dim) => [
          dim,
          true
        ])
      )
    );
  }

  // if traffic type is internal dataLayerVariable.traffic_type = 'internal'

  const eventData = { ...dataLayerVariables, ...mainEventData, event: eventName };
  if (isInDevelopment) {
    console.log('eventData', eventData);
  }
  window.dataLayer.push({ ...dimsClearObj });
  window.dataLayer.push(eventData);
};

export const eventWaitlistFormStep1 = (companyType = '') => {
  gaEventGuard(() => {
    const waitlistFormStep1EventData = { [GA_DIMENSIONS.cType]: companyType };
    pushEvent(GA_EVENTS.Waitlist_form_step_1, waitlistFormStep1EventData);
  });
};

export const eventWaitlistFormStep2 = (companyType = '') => {
  gaEventGuard(() => {
    const waitlistFormStep2EventData = { [GA_DIMENSIONS.cType]: companyType };
    pushEvent(GA_EVENTS.Waitlist_form_step_2, waitlistFormStep2EventData);
  });
};

export const eventSignUpStep1 = (companyType = '') => {
  gaEventGuard(() => {
    const signUpStep1EventData = { [GA_DIMENSIONS.cType]: companyType };
    pushEvent(GA_EVENTS.sign_up_step_1, signUpStep1EventData);
  });
};

export const eventSignUpStep2 = (companyType = '') => {
  gaEventGuard(() => {
    const signUpStep2EventData = { [GA_DIMENSIONS.cType]: companyType };
    pushEvent(GA_EVENTS.sign_up_step_2, signUpStep2EventData);
  });
};

export const eventLogin = (userId = '', method = 'email') => {
  gaEventGuard(() => {
    const loginEventData = { [GA_DIMENSIONS.method]: method, [GA_DIMENSIONS.user_id]: userId };
    pushEvent(GA_EVENTS.login, loginEventData);
  });
};

export const eventPageviewStartPage = (fundsCount = 0, portfoliosCount = 0) => {
  gaEventGuard(() => {
    if (typeof fundsCount !== 'number') {
      logError(
        `[eventPageviewStartPage] 'fundsCount' param (${fundsCount}) must be of type number (${typeof fundsCount})!`
      );
    }

    if (typeof portfoliosCount !== 'number') {
      logError(
        `[eventPageviewStartPage] 'portfoliosCount' param (${portfoliosCount}) must be of type number (${typeof portfoliosCount})!`
      );
    }

    const pageviewStartPageEventData = {
      [GA_DIMENSIONS.funds]: fundsCount.toString(),
      [GA_DIMENSIONS.portfolio_companies]: portfoliosCount.toString()
    };

    pushEvent(GA_EVENTS.pageview_start_page, pageviewStartPageEventData);
  });
};

export const eventPageviewFundCreate = () => {
  gaEventGuard(() => {
    pushEvent(GA_EVENTS.pageview_fund_create);
  });
};

export const eventFundCreationStep = (event = '', fundData = {}) => {
  gaEventGuard(() => {
    if (
      ![
        GA_EVENTS.fund_create_Information_complete,
        GA_EVENTS.fund_create_GeoIndustries_complete,
        GA_EVENTS.fund_create_financials_complete,
        GA_EVENTS.fund_create_transaction_complete,
        GA_EVENTS.fund_create_valuation_complete,
        GA_EVENTS.fund_create_complete
      ].includes(event)
    ) {
      logError(`[eventFundCreationStep] 'event' param (${event}) is wrong!`);
    }

    const fundCreationStepEventData = Object.fromEntries(
      Object.values(FUND_FIELDS)
        .filter((fieldConfig) => !!fieldConfig.GA4?.[event])
        .map((fieldConfig) => {
          const gaFieldName = Object.entries(fieldConfig.GA4).filter(
            ([eventName]) => eventName === event
          )[0][1];

          const fieldValue = fundData[fieldConfig.NAME];

          const gaFieldValue = isEmptyValue(fieldValue)
            ? ''
            : Array.isArray(fieldValue)
            ? fieldValue.join(' - ')
            : fieldValue.toString();

          return [gaFieldName, gaFieldValue];
        })
    );

    pushEvent(event, fundCreationStepEventData);
  });
};

export const eventCreateCompanyProfilePageView = () => {
  gaEventGuard(() => {
    pushEvent(GA_EVENTS.company_create_page_view);
  });
};

export const eventCompleteCompanyProfile = (companyType = '', country = '', tagline = '') => {
  gaEventGuard(() => {
    const completeCompanyProfileEventData = {
      [GA_DIMENSIONS.company_type]: (companyType || '').toString(),
      [GA_DIMENSIONS.Country]: (country || '').toString(),
      [GA_DIMENSIONS.Tagline]: (tagline || '').toString()
    };

    pushEvent(GA_EVENTS.complete_company_profile, completeCompanyProfileEventData);
  });
};

export const eventPageviewPortfolioCoCreate = () => {
  gaEventGuard(() => {
    pushEvent(GA_EVENTS.pageview_portfolio_co_create);
  });
};

export const eventPortfolioCoCreationStep = (event = '', fundData = {}) => {
  gaEventGuard(() => {
    if (
      ![
        GA_EVENTS.portfolio_co_information_complete,
        GA_EVENTS.portfolio_co_geoindustries_complete,
        GA_EVENTS.portfolio_co_financials_complete,
        GA_EVENTS.portfolio_co_transaction_complete,
        GA_EVENTS.portfolio_co_valuation_complete,
        GA_EVENTS.portfolio_co_create_complete
      ].includes(event)
    ) {
      logError(`[eventFundCreationStep] 'event' param (${event}) is wrong!`);
    }

    const portfolioCoCreationStepEventData = Object.fromEntries(
      Object.values(PORTFOLIO_FIELDS)
        .filter((fieldConfig) => !!fieldConfig.GA4?.[event])
        .map((fieldConfig) => {
          const gaFieldName = Object.entries(fieldConfig.GA4).filter(
            ([eventName]) => eventName === event
          )[0][1];

          const fieldValue = fundData[fieldConfig.NAME];

          const gaFieldValue = isEmptyValue(fieldValue)
            ? ''
            : Array.isArray(fieldValue)
            ? fieldValue.join(' - ')
            : fieldValue.toString();

          return [gaFieldName, gaFieldValue];
        })
    );

    pushEvent(event, portfolioCoCreationStepEventData);
  });
};

export const eventPageviewInvestProfileCreate = () => {
  gaEventGuard(() => {
    pushEvent(GA_EVENTS.pageview_invest_profile_create);
  });
};

export const eventDownloadSearch = () => {
  gaEventGuard(() => {
    pushEvent(GA_EVENTS.download_search);
  });
};

export const eventOutreachSearch = () => {
  gaEventGuard(() => {
    pushEvent(GA_EVENTS.outreach_search);
  });
};
