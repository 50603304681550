import { useLocation } from 'react-router';
import { useEffect } from 'react';
import history from '../utils/history';
import { runInAction } from 'mobx';

const useAppLocationHistory = () => {
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('popstate', history._pop);
    return () => {
      window.removeEventListener('popstate', history._pop);
    };
  }, []);

  useEffect(() => {
    if (window.location.href !== history.lastHref) {
      runInAction(() => {
        history._push(location, window.location.href);
      });
    }
  }, [location]);
};

export default useAppLocationHistory;
