import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import GeneralError from '../../components/errors/GeneralError';
import GeneralLoader from '../../components/loaders/GeneralLoader';

const ProfilerTeam = observer(() => {
  const { profilerProjectStore } = useStore();

  const showError =
    profilerProjectStore.loadProjectsError && !profilerProjectStore.allProjects.length;

  if (showError) {
    return (
      <StaticPageLayout page="profiler-team">
        {showError && (
          <GeneralError
            errorMessage={profilerProjectStore.loadProjectsError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={profilerProjectStore.loadProjects}
            withLogoutButton
          />
        )}
      </StaticPageLayout>
    );
  }

  if (
    profilerProjectStore.initialLoadProjects ||
    (profilerProjectStore.isLoadingProjects && !profilerProjectStore.allProjects.length)
  ) {
    return (
      <StaticPageLayout page="profiler-team">
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  return <StaticPageLayout page="profiler-team"></StaticPageLayout>;
});

export default ProfilerTeam;
