import { observer } from 'mobx-react';
import { useMemo } from 'react';
import Input from './Input';

const RangeInput = observer(
  ({
    fieldMin,
    fieldMax,
    valueMin = '',
    valueMax = '',
    setFieldValue,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    hideErrorMessages = false,
    hideRequiredLabelSymbol = false,
    wrapClass = [],
    errorMsgClassName = ''
  }) => {
    const { validationState, onSubmitErrorMessages } = useMemo(() => {
      let validationState = '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => [fieldMin.NAME, fieldMax.NAME].includes(m.field) && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        validationState = 'error';
      }

      return { validationState, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    return (
      <div className={'range-field' + (wrapClass ? ` ${wrapClass}` : '')}>
        <Input
          field={fieldMin}
          value={valueMin}
          setFieldValue={setFieldValue}
          disabled={disabled}
          showOnSubmitErrorState={showOnSubmitErrorState}
          messages={onSubmitErrorMessages}
          hideErrorMessages
          hideRequiredLabelSymbol={hideRequiredLabelSymbol}
        />
        <div className="separator" />
        <Input
          field={fieldMax}
          value={valueMax}
          setFieldValue={setFieldValue}
          disabled={disabled}
          showOnSubmitErrorState={showOnSubmitErrorState}
          messages={onSubmitErrorMessages}
          hideErrorMessages
          hideRequiredLabelSymbol={hideRequiredLabelSymbol}
        />
        {!hideErrorMessages &&
          validationState === 'error' &&
          onSubmitErrorMessages
            .filter(({ msg }) => msg.length)
            .map(({ msg }, idx) => (
              <div key={idx} className={errorMsgClassName || 'range-error-msg-input'}>
                {msg}
              </div>
            ))}
      </div>
    );
  }
);

export default RangeInput;
