import { useEffect, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import { useStore } from '../../../store/store';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import useHistory from '../../../hooks/useHistory';
import { ACCOUNT_TYPES } from '../../../utils/constants/auth';
import { decodeOutreachParams, encodeOutreachParams } from './constants';
import { paths } from '../../../utils/constants/routes';
import SavedProjectsSection from './SavedProjectsSection';
import ArchivedProjectsSection from './ArchivedProjectsSection';
import RecentOutreachesSection from './RecentOutreachesSection';
import MyOutreachesSection from './MyOutreachesSection';
import ForwardedOutreachesSection from './ForwardedOutreachesSection';
import ArchivedOutreachesSection from './ArchivedOutreachesSection';
import TeamFeedbackButton from './TeamFeedbackButton';
import OutreachBox from './OutreachBox';
import ProvideFeedbackSection from './ProvideFeedbackSection';
import ReceiveFeedbackSection from './ReceiveFeedbackSection';
import Chat from './Chat';
import HeaderTab from '../mobile/HeaderTab';
import HeaderSubtab from '../mobile/HeaderSubtab';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import { runInAction } from 'mobx';

const OutreachDesktop = observer(() => {
  const { location, navigate } = useHistory();
  const { outreachStore, authStore, utilsStore } = useStore();

  const state = useLocalObservable(() => ({
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    mobileScrollY: window.scrollY,
    setMobileScrollY: (value = 0) => (state.mobileScrollY = value),
    hideMobileOutreachBoxes: false
  }));

  useEffect(() => {
    if (outreachStore.isLoadingOutreaches || outreachStore.initialLoadOutreaches) {
      return;
    }

    // params:
    // selected - null, 'teamFeedback', 'recent', objectId || projectId
    // type - null, 'active', 'archived', 'forwarded'
    // outreachId - null, outreachId
    // selectedFeedbackType - null, 'receive', 'provide'

    let [urlParams, params, shouldNavigate] = [null, {}, false];
    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeOutreachParams(urlParams.get('params'));
    } catch (ex) {
      params = { selected: null, type: null, outreachId: null };
      shouldNavigate = true;
    }

    if (typeof params !== 'object' || params === null || params === undefined) {
      params = { selected: null, type: null, outreachId: null };
      shouldNavigate = true;
    }

    if (
      params.selected === 'teamFeedback' &&
      authStore.userAccountType !== ACCOUNT_TYPES.INVESTOR
    ) {
      params = { selected: null, type: null, outreachId: null };
      shouldNavigate = true;
    }

    if (params.type === 'forwarded' && authStore.userAccountType !== ACCOUNT_TYPES.INVESTOR) {
      params = { selected: null, type: null, outreachId: null };
      shouldNavigate = true;
    }

    if (
      params.outreachId &&
      outreachStore.outreaches.findIndex((o) => o.id === params.outreachId) === -1
    ) {
      params.outreachId = null;
      shouldNavigate = true;
    }

    if (authStore.userAccountType === ACCOUNT_TYPES.INVESTOR) {
      if (params.selected && !['teamFeedback', 'recent'].includes(params.selected)) {
        if (['active', 'archived', 'forwarded'].includes(params.type)) {
          const list = {
            active: outreachStore.outreachesList.active,
            archived: outreachStore.outreachesList.archived,
            forwarded: outreachStore.outreachesList.forwarded
          }[params.type];
          if (!list[params.selected]) {
            params.selected = null;
            params.type = null;
            shouldNavigate = true;
          }
        }
      }
    } else if (authStore.userAccountType === ACCOUNT_TYPES.AGENT) {
      if (params.selected && params.selected !== 'recent') {
        if (['active', 'archived'].includes(params.type)) {
          const list = {
            active: outreachStore.projectsList.saved,
            archived: outreachStore.projectsList.archived
          }[params.type];
          if (!list[params.selected]) {
            params.selected = null;
            params.type = null;
            shouldNavigate = true;
          }
        }
      }
    }

    if (shouldNavigate) {
      if (!params.selected && !params.type && !params.outreachId) {
        const mostRecentOutreach = outreachStore.recentOutreaches[0];
        if (mostRecentOutreach) {
          navigate(
            paths.OUTREACH +
              '?params=' +
              encodeOutreachParams({
                selected: 'recent',
                type: null,
                outreachId: state.layout === 'mobile' ? null : mostRecentOutreach.id
              })
          );
        } else {
          navigate(paths.OUTREACH + '?params=' + encodeOutreachParams(params));
        }
      } else {
        navigate(paths.OUTREACH + '?params=' + encodeOutreachParams(params));
      }
    }

    state.setParams(params);
  }, [
    state,
    outreachStore,
    outreachStore.isLoadingOutreaches,
    outreachStore.outreaches,
    outreachStore.projectsList,
    outreachStore.recentOutreaches,
    outreachStore.outreachesList,
    location.search
  ]);

  useEffect(() => {
    const onScroll = () => {
      if (state.layout !== 'mobile' || state.params.outreachId) {
        return;
      }
      state.setMobileScrollY(window.scrollY);
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [state]);

  useEffect(() => {
    if (state.layout === 'mobile') {
      if (state.params.outreachId) {
        runInAction(() => {
          state.hideMobileOutreachBoxes = true;
        });
      } else {
        const mobileScrollY = state.mobileScrollY;
        runInAction(() => {
          state.hideMobileOutreachBoxes = false;
        });
        setTimeout(() => {
          window.scrollTo({ top: mobileScrollY });
        }, 10);
      }
    }
  }, [state, state.params.outreachId]);

  const hideMobileFooter = state.layout === 'mobile';

  if (
    outreachStore.initialLoadOutreaches ||
    (outreachStore.isLoadingOutreaches && !outreachStore.outreaches.length)
  ) {
    return (
      <StaticPageLayout
        page="outreach"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  if (outreachStore.loadOutreachesError && !outreachStore.outreaches.length) {
    return (
      <StaticPageLayout
        page="outreach"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        <GeneralError
          errorMessage={outreachStore.loadOutreachesError}
          actionMessage="You may want to:"
          actionButtontext="Try again"
          onActionButtonClick={outreachStore.loadOutreaches}
        />
      </StaticPageLayout>
    );
  }

  const isAgent = authStore.userAccountType === ACCOUNT_TYPES.AGENT;

  const mobileOutreachesList =
    (isAgent && state.params.selected && state.params.type === 'active'
      ? outreachStore.projectsList.saved[state.params.selected]?.outreaches
      : isAgent && state.params.selected && state.params.type === 'archived'
      ? outreachStore.projectsList.archived[state.params.selected]?.outreaches
      : !isAgent && state.params.selected && state.params.type === 'active'
      ? outreachStore.outreachesList.active[state.params.selected]?.outreaches
      : !isAgent && state.params.selected && state.params.type === 'archived'
      ? outreachStore.outreachesList.archived[state.params.selected]?.outreaches
      : !isAgent && state.params.selected && state.params.type === 'forwarded'
      ? outreachStore.outreachesList.forwarded[state.params.selected]?.outreaches
      : state.params.selected === 'recent'
      ? outreachStore.recentOutreaches
      : !isAgent &&
        state.params.selected === 'teamFeedback' &&
        state.params.selectedFeedbackType === 'provide'
      ? outreachStore.outreachesList.giveFeedback
      : !isAgent &&
        state.params.selected === 'teamFeedback' &&
        state.params.selectedFeedbackType === 'receive'
      ? outreachStore.outreachesList.getFeedback
      : []) || [];

  const mainRenderKey = `${authStore.userAccountType}-${
    state.params.type || (state.params.selected === 'teamFeedback' ? 'teamFeedback' : 'recent')
  }`;

  if (state.layout === 'mobile') {
    return (
      <StaticPageLayout
        page="outreach"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        <div className="mobile-header">
          <div className={`tabs ${isAgent ? 'agent' : 'investor'}`}>
            {isAgent && (
              <HeaderTab
                icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                text="Saved projects"
                active={state.params.type === 'active'}
                count={outreachStore.projectsList.savedNewMessagesCount}
                showEmptyDot={
                  Object.values(outreachStore.projectsList.saved).findIndex(
                    (p) => p.hasMarkedUnread
                  ) !== -1
                }
                onClick={() => {
                  const firstProjectId = Object.entries(outreachStore.projectsList.saved).sort(
                    (a, b) => b[1].lastMessageDate - a[1].lastMessageDate
                  )[0]?.[0];

                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({
                        type: 'active',
                        outreachId: null,
                        selected: firstProjectId || null
                      })
                  );
                }}
              />
            )}
            {!isAgent && (
              <HeaderTab
                icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                text="My outreaches"
                active={state.params.type === 'active'}
                count={outreachStore.outreachesList.activeNewMessagesCount}
                showEmptyDot={
                  Object.values(outreachStore.outreachesList.active).findIndex(
                    (obj) => obj.hasMarkedUnread
                  ) !== -1
                }
                onClick={() => {
                  const objectId = Object.entries(outreachStore.outreachesList.active).sort(
                    (a, b) => b[1].lastMessageDate - a[1].lastMessageDate
                  )[0]?.[0];

                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({
                        type: 'active',
                        outreachId: null,
                        selected: objectId || null
                      })
                  );
                }}
              />
            )}
            <HeaderTab
              icon={ICON_BUTTON_ICONS.CHAT_SQUARE_TEXT}
              text="Recent outreaches"
              active={state.params.selected === 'recent'}
              count={outreachStore.recentOutreaches.reduce((c, o) => c + o.newMessagesCount, 0)}
              showEmptyDot={
                outreachStore.recentOutreaches.findIndex((o) => o.hasMarkedUnread) !== -1
              }
              onClick={() => {
                navigate(
                  paths.OUTREACH +
                    '?params=' +
                    encodeOutreachParams({
                      selected: 'recent',
                      type: null,
                      outreachId: null
                    })
                );
              }}
            />
            {isAgent && (
              <HeaderTab
                icon={ICON_BUTTON_ICONS.ARCHIVE}
                text="Archived projects"
                active={state.params.type === 'archived'}
                count={outreachStore.projectsList.archivedNewMessagesCount}
                showEmptyDot={
                  Object.values(outreachStore.projectsList.archived).findIndex(
                    (p) => p.hasMarkedUnread
                  ) !== -1
                }
                onClick={() => {
                  const firstProjectId = Object.entries(outreachStore.projectsList.archived).sort(
                    (a, b) => b[1].lastMessageDate - a[1].lastMessageDate
                  )[0]?.[0];

                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({
                        type: 'archived',
                        outreachId: null,
                        selected: firstProjectId || null
                      })
                  );
                }}
              />
            )}
            {!isAgent && (
              <HeaderTab
                icon={ICON_BUTTON_ICONS.ARROW_RIGHT_SQUARE}
                text="Forwarded outreaches"
                active={state.params.type === 'forwarded'}
                count={outreachStore.outreachesList.forwardedNewMessagesCount}
                showEmptyDot={
                  Object.values(outreachStore.outreachesList.forwarded).findIndex(
                    (obj) => obj.hasMarkedUnread
                  ) !== -1
                }
                onClick={() => {
                  const objectId = Object.entries(outreachStore.outreachesList.forwarded).sort(
                    (a, b) => b[1].lastMessageDate - a[1].lastMessageDate
                  )[0]?.[0];

                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({
                        type: 'forwarded',
                        outreachId: null,
                        selected: objectId || null
                      })
                  );
                }}
              />
            )}
            {!isAgent && (
              <HeaderTab
                icon={ICON_BUTTON_ICONS.ARCHIVE}
                text="Archived outreaches"
                active={state.params.type === 'archived'}
                count={outreachStore.outreachesList.archivedNewMessagesCount}
                showEmptyDot={
                  Object.values(outreachStore.outreachesList.archived).findIndex(
                    (obj) => obj.hasMarkedUnread
                  ) !== -1
                }
                onClick={() => {
                  const objectId = Object.entries(outreachStore.outreachesList.archived).sort(
                    (a, b) => b[1].lastMessageDate - a[1].lastMessageDate
                  )[0]?.[0];

                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({
                        type: 'archived',
                        outreachId: null,
                        selected: objectId || null
                      })
                  );
                }}
              />
            )}
            {!isAgent && (
              <HeaderTab
                wrapClassName="team-feedback"
                icon={ICON_BUTTON_ICONS.CHAT_LEFT_QUOTE}
                text="Team feedback"
                active={state.params.selected === 'teamFeedback'}
                count={outreachStore.outreachesList.teamFeedbackNewMessagesCount}
                showEmptyDot={outreachStore.outreachesList.teamFeedbackHasMarkedUnread}
                onClick={() => {
                  const give = outreachStore.outreachesList.giveFeedback;
                  const get = outreachStore.outreachesList.getFeedback;
                  const firstOutreach = give
                    .concat(get)
                    .sort(
                      (a, b) =>
                        (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]
                          ?.date || 0) -
                        (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]
                          ?.date || 0)
                    );
                  let selectedFeedbackType = null;
                  if (firstOutreach) {
                    if (give.findIndex((o) => o.id === firstOutreach.id) !== -1) {
                      selectedFeedbackType = 'provide';
                    } else {
                      selectedFeedbackType = 'receive';
                    }
                  }

                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({
                        type: null,
                        outreachId: null,
                        selected: 'teamFeedback',
                        selectedFeedbackType
                      })
                  );
                }}
              />
            )}
          </div>
          {/* Saved projects */}
          {isAgent && state.params.type === 'active' && (
            <div className="subtabs" key={mainRenderKey}>
              {Object.entries(outreachStore.projectsList.saved)
                .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
                .map(([projectId, project]) => {
                  return (
                    <HeaderSubtab
                      key={`agent-active-${projectId}`}
                      type={project.searchType}
                      text={project.projectName}
                      active={state.params.selected === projectId}
                      count={project.newMessagesCount}
                      showEmptyDot={project.hasMarkedUnread}
                      onClick={() =>
                        navigate(
                          paths.OUTREACH +
                            '?params=' +
                            encodeOutreachParams({ ...state.params, selected: projectId })
                        )
                      }
                    />
                  );
                })}
            </div>
          )}
          {/* My outreaches */}
          {!isAgent && state.params.type === 'active' && (
            <div className="subtabs" key={mainRenderKey}>
              {Object.entries(outreachStore.outreachesList.active)
                .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
                .map(([objectId, object]) => {
                  return (
                    <HeaderSubtab
                      key={`investor-active-${objectId}`}
                      type={object.objectType}
                      text={object.name}
                      active={state.params.selected === objectId}
                      count={object.newMessagesCount}
                      showEmptyDot={object.hasMarkedUnread}
                      onClick={() =>
                        navigate(
                          paths.OUTREACH +
                            '?params=' +
                            encodeOutreachParams({ ...state.params, selected: objectId })
                        )
                      }
                    />
                  );
                })}
            </div>
          )}
          {/* Recent outreaches (show nothing) */}
          {/* Archived projects */}
          {!isAgent && state.params.type === 'archived' && (
            <div className="subtabs" key={mainRenderKey}>
              {Object.entries(outreachStore.outreachesList.archived)
                .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
                .map(([objectId, object]) => {
                  return (
                    <HeaderSubtab
                      key={`investor-archived-${objectId}`}
                      type={object.objectType}
                      text={object.name}
                      active={state.params.selected === objectId}
                      count={object.newMessagesCount}
                      showEmptyDot={object.hasMarkedUnread}
                      onClick={() =>
                        navigate(
                          paths.OUTREACH +
                            '?params=' +
                            encodeOutreachParams({ ...state.params, selected: objectId })
                        )
                      }
                    />
                  );
                })}
            </div>
          )}
          {/* Forwarded outreaches */}
          {!isAgent && state.params.type === 'forwarded' && (
            <div className="subtabs" key={mainRenderKey}>
              {Object.entries(outreachStore.outreachesList.forwarded)
                .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
                .map(([objectId, object]) => {
                  return (
                    <HeaderSubtab
                      key={`investor-forwarded-${objectId}`}
                      type={object.objectType}
                      text={object.name}
                      active={state.params.selected === objectId}
                      count={object.newMessagesCount}
                      showEmptyDot={object.hasMarkedUnread}
                      onClick={() =>
                        navigate(
                          paths.OUTREACH +
                            '?params=' +
                            encodeOutreachParams({ ...state.params, selected: objectId })
                        )
                      }
                    />
                  );
                })}
            </div>
          )}
          {/* Archived outreaches */}
          {isAgent && state.params.type === 'archived' && (
            <div className="subtabs" key={mainRenderKey}>
              {Object.entries(outreachStore.projectsList.archived)
                .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
                .map(([projectId, project]) => {
                  return (
                    <HeaderSubtab
                      key={`agent-archived-${projectId}`}
                      type={project.searchType}
                      text={project.projectName}
                      active={state.params.selected === projectId}
                      count={project.newMessagesCount}
                      showEmptyDot={project.hasMarkedUnread}
                      onClick={() =>
                        navigate(
                          paths.OUTREACH +
                            '?params=' +
                            encodeOutreachParams({ ...state.params, selected: projectId })
                        )
                      }
                    />
                  );
                })}
            </div>
          )}
          {/* Team feedback */}
          {!isAgent && state.params.selected === 'teamFeedback' && (
            <div className="subtabs" key={mainRenderKey}>
              <HeaderSubtab
                key={`investor-provide-feedback`}
                type={'teamfeedback'}
                text={'Provide feedback'}
                active={state.params.selectedFeedbackType === 'provide'}
                count={outreachStore.outreachesList.giveFeedbackNewMessagesCount}
                showEmptyDot={outreachStore.outreachesList.giveFeedbackHasMarkedUnread}
                onClick={() =>
                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({ ...state.params, selectedFeedbackType: 'provide' })
                  )
                }
              />
              <HeaderSubtab
                key={`investor-receive-feedback`}
                type={'teamfeedback'}
                text={'Receive feedback'}
                active={state.params.selectedFeedbackType === 'receive'}
                count={outreachStore.outreachesList.getFeedbackNewMessagesCount}
                showEmptyDot={outreachStore.outreachesList.getFeedbackHasMarkedUnread}
                onClick={() =>
                  navigate(
                    paths.OUTREACH +
                      '?params=' +
                      encodeOutreachParams({ ...state.params, selectedFeedbackType: 'receive' })
                  )
                }
              />
            </div>
          )}
          {state.params.selected !== 'recent' && (
            <>
              <div className="fade-right" />
              <div className="fade-left" />
            </>
          )}
        </div>
        {!state.hideMobileOutreachBoxes && (
          <>
            {mobileOutreachesList
              .slice()
              .sort(
                (a, b) =>
                  (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]?.date ||
                    0) -
                  (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]?.date || 0)
              )
              .map((o) => {
                return (
                  <OutreachBox key={mainRenderKey + o.id} outreach={o} params={state.params} />
                );
              })}
          </>
        )}
        {!!state.params.outreachId && (
          <Chat
            key={state.params.outreachId}
            outreachId={state.params.outreachId}
            params={state.params}
          />
        )}
      </StaticPageLayout>
    );
  }

  return (
    <StaticPageLayout page="outreach">
      <div className="layout-wrap">
        <div className="sections-wrap">
          <div className="fade-top" />
          <div className="fade-bottom" style={!isAgent ? { bottom: '50px' } : {}} />
          <div className="title">{isAgent ? 'Outreach' : 'Project outreach'}</div>
          <div className="scrollable-body">
            {isAgent && <SavedProjectsSection params={state.params} />}
            {!isAgent && <MyOutreachesSection params={state.params} />}
            <RecentOutreachesSection params={state.params} />
            {isAgent && <ArchivedProjectsSection params={state.params} />}
            {!isAgent && <ForwardedOutreachesSection params={state.params} />}
            {!isAgent && <ArchivedOutreachesSection params={state.params} />}
          </div>
          {!isAgent && <TeamFeedbackButton params={state.params} />}
        </div>
        <div className="right-container">
          <div className="outreaches-container-wrap">
            {!isAgent && state.params.selected === 'teamFeedback' && (
              <div className="tfb-header">
                <img className="tfb-header-icon" src={ICON_BUTTON_ICONS.CHAT_LEFT_QUOTE} />
                <div className="tfb-header-text">Team feedback</div>
              </div>
            )}
            <div
              className="fade-top"
              style={!isAgent && state.params.selected === 'teamFeedback' ? { top: '50px' } : {}}
            />
            <div className="fade-bottom" />
            <div className="outreaches-container">
              {isAgent &&
                state.params.selected &&
                state.params.type === 'active' &&
                (outreachStore.projectsList.saved[state.params.selected]?.outreaches || [])
                  .slice()
                  .sort(
                    (a, b) =>
                      (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]
                        ?.date || 0) -
                      (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]
                        ?.date || 0)
                  )
                  .map((o) => {
                    return <OutreachBox key={'a-u' + o.id} outreach={o} params={state.params} />;
                  })}
              {isAgent &&
                state.params.selected &&
                state.params.type === 'archived' &&
                (outreachStore.projectsList.archived[state.params.selected]?.outreaches || [])
                  .slice()
                  .sort(
                    (a, b) =>
                      (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]
                        ?.date || 0) -
                      (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]
                        ?.date || 0)
                  )
                  .map((o) => {
                    return <OutreachBox key={'a-a' + o.id} outreach={o} params={state.params} />;
                  })}
              {!isAgent &&
                state.params.selected &&
                state.params.type === 'active' &&
                (outreachStore.outreachesList.active[state.params.selected]?.outreaches || [])
                  .slice()
                  .sort(
                    (a, b) =>
                      (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]
                        ?.date || 0) -
                      (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]
                        ?.date || 0)
                  )
                  .map((o) => {
                    return <OutreachBox key={'i-u' + o.id} outreach={o} params={state.params} />;
                  })}
              {!isAgent &&
                state.params.selected &&
                state.params.type === 'archived' &&
                (outreachStore.outreachesList.archived[state.params.selected]?.outreaches || [])
                  .slice()
                  .sort(
                    (a, b) =>
                      (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]
                        ?.date || 0) -
                      (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]
                        ?.date || 0)
                  )
                  .map((o) => {
                    return <OutreachBox key={'i-a' + o.id} outreach={o} params={state.params} />;
                  })}
              {!isAgent &&
                state.params.selected &&
                state.params.type === 'forwarded' &&
                (outreachStore.outreachesList.forwarded[state.params.selected]?.outreaches || [])
                  .slice()
                  .sort(
                    (a, b) =>
                      (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]
                        ?.date || 0) -
                      (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]
                        ?.date || 0)
                  )
                  .map((o) => {
                    return <OutreachBox key={'i-f' + o.id} outreach={o} params={state.params} />;
                  })}
              {!isAgent && state.params.selected === 'teamFeedback' && (
                <>
                  <ProvideFeedbackSection params={state.params} />
                  <ReceiveFeedbackSection params={state.params} />
                </>
              )}
              {state.params.selected === 'recent' && state.params.outreachId && (
                <OutreachBox
                  key={'recent' + state.params.outreachId}
                  outreach={outreachStore.outreaches.find((o) => o.id === state.params.outreachId)}
                  params={state.params}
                />
              )}
            </div>
          </div>
          {!!state.params.outreachId && (
            <Chat
              key={state.params.outreachId}
              outreachId={state.params.outreachId}
              params={state.params}
            />
          )}
        </div>
      </div>
    </StaticPageLayout>
  );
});

export default OutreachDesktop;
