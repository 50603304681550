import { observer } from 'mobx-react';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { encodeOutreachParams } from './constants';
import { useStore } from '../../../store/store';

const icons = {
  funds: ICON_BUTTON_ICONS.CASH_STACK,
  portfolios: ICON_BUTTON_ICONS.PORTFOLIO_COMPANY,
  investprofiles: ICON_BUTTON_ICONS.INVEST_PROFILE
};

const AgentProjectButton = observer(
  ({ projectId = '', project = {}, params = {}, list = {}, type = '' }) => {
    const { outreachStore } = useStore();
    const { navigate } = useHistory();
    const isSelected = params.selected === projectId && !!list[projectId] && params.type === type;

    const onSelect = () => {
      if (isSelected) {
        return;
      }

      const firstOutreach = list[projectId].outreaches
        .slice()
        .sort(
          (a, b) =>
            (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]?.date || 0) -
            (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]?.date || 0)
        )[0];

      navigate(
        paths.OUTREACH +
          '?params=' +
          encodeOutreachParams({ selected: projectId, type, outreachId: firstOutreach?.id || null })
      );
    };

    return (
      <div
        className={`noselect agent-project-button${isSelected ? ' selected' : ''}`}
        onClick={onSelect}>
        <img className="icon-title" src={icons[project.searchType]} />
        <div className="head-title">{project.projectName}</div>
        {project.newMessagesCount > 0 && (
          <div className="head-count">{project.newMessagesCount}</div>
        )}
        {!project.newMessagesCount && list[projectId].hasMarkedUnread && (
          <div className="head-count" />
        )}
      </div>
    );
  }
);

export default AgentProjectButton;
