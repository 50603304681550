import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { formatDateDDMMYYYY } from '../../../utils/utils';

const CommentsTabEntry = observer(({ comment = {} }) => {
  const { authStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    get layout() {
      return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
    }
  }));

  const user = authStore.companyUsers.find((u) => u.id === comment.userId);

  if (state.layout === 'mobile') {
    return (
      <div className="row-mobile-comment">
        <div className="name-date">
          <div>{user ? `${user.firstName} ${user.lastName}` : 'unknown'}</div>
          <div>{formatDateDDMMYYYY(comment.date)}</div>
        </div>
        <div className="comment">{comment.commentText}</div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="item name-item">
        <div className="label">Name</div>
        <div className="value">{user ? `${user.firstName} ${user.lastName}` : 'unknown'}</div>
      </div>
      <div className="item">
        <div className="label">Comment Date</div>
        <div className="value">{formatDateDDMMYYYY(comment.date)}</div>
      </div>
      <div className="item comment-item">
        <div className="label">Feedback comment</div>
        <div className="value">{comment.commentText}</div>
      </div>
    </div>
  );
});

export default CommentsTabEntry;
