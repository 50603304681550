import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { useStore } from '../../store/store';
import { API_ENDPOINTS } from '../../api/endpoints';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import { useEffect } from 'react';
import Input from '../../components/inputs/Input';
import {
  COMPANY_PROFILE_FIELDS,
  getErrorFields,
  compareFields,
  trimStateFields
} from '../../utils/constants/fields';
import { mapFieldsToState } from '../../utils/utils';
import MultiSelect from '../../components/inputs/MultiSelect';
import { mapData } from '../../api/dataMappers';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';

const CompanyProfile = observer(() => {
  const { authStore, utilsStore, makeRequest } = useStore();

  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(COMPANY_PROFILE_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    restoreFields: (e) => {
      e?.preventDefault?.();
      const initial = mapFieldsToState(COMPANY_PROFILE_FIELDS);
      Object.entries(state.fields).forEach(([fieldName]) => {
        state.fields[fieldName] = authStore.userProfileCompany?.[fieldName] || initial[fieldName];
      });
      state.setOnSubmitErrorState(false);
    },
    get validationFields() {
      return getErrorFields(Object.values(COMPANY_PROFILE_FIELDS), state.fields, [
        COMPANY_PROFILE_FIELDS.CONTACT_EMAIL,
        COMPANY_PROFILE_FIELDS.PHONE_NUMBER
      ]);
    },
    get isRestoreDisabled() {
      const hasDiff = compareFields(state.fields, authStore.userProfileCompany);
      return !hasDiff || state.isSaving;
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    isSaving: false,
    editCompanyProfile: (e) => {
      e?.preventDefault?.();

      trimStateFields(state.fields);
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      state.isSaving = true;
      makeRequest({
        endpoint: API_ENDPOINTS.EDIT_PROFILE_COMPANY,
        body: mapData(state.fields, COMPANY_PROFILE_FIELDS, true),
        onSuccess: (response) => {
          authStore.userProfileCompany = mapData(response, COMPANY_PROFILE_FIELDS);
          utilsStore.setHeaderMessage('Successfully edited company profile.');
        },
        onError: (errorMessage) => {
          utilsStore.setHeaderMessage(
            errorMessage || 'Failed to edit company profile.',
            HEADER_MESSAGE_TYPES.ERROR
          );
        },
        onFinally: () => {
          state.isSaving = false;
        }
      });
    }
  }));

  useEffect(() => {
    if (authStore.user && authStore.userProfileCompany) {
      runInAction(() => {
        Object.entries(authStore.userProfileCompany).forEach(
          ([key, value]) => (state.fields[key] = value)
        );
      });
    }
  }, [state, authStore.user, authStore.userProfileCompany]);

  return (
    <StaticPageLayout page="company-profile" hideMobileFooter={true}>
      <form>
        <div className="form-header">
          <div className="container">
            <div className="info">
              <div className="title">My company</div>
              <div className="subtitle">Manage your company data</div>
            </div>
            <div className="actions">
              <button
                className="btn btn-short btn-transparent disable-fade-transparent"
                disabled={state.isRestoreDisabled}
                onClick={state.restoreFields}
                type="reset">
                Restore
              </button>
              <button
                className="btn btn-short btn-primary disable-fade-primary"
                disabled={state.isSaveDisabled}
                onClick={state.editCompanyProfile}
                type="submit">
                {state.isSaving ? 'Loading...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
        <div className="form-body form-row-layout">
          <div className="row">
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.COMPANY_NAME}
                value={state.fields[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
              />
            </div>
            <div className="col">
              <MultiSelect
                field={COMPANY_PROFILE_FIELDS.COMPANY_TYPE}
                value={state.fields[COMPANY_PROFILE_FIELDS.COMPANY_TYPE.NAME]}
                setFieldValue={state.setFieldValue}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MultiSelect
                field={COMPANY_PROFILE_FIELDS.COUNTRY}
                value={state.fields[COMPANY_PROFILE_FIELDS.COUNTRY.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
              />
            </div>
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.CITY}
                value={state.fields[COMPANY_PROFILE_FIELDS.CITY.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.PHONE_NUMBER}
                value={state.fields[COMPANY_PROFILE_FIELDS.PHONE_NUMBER.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
                showOnSubmitErrorState={state.onSubmitErrorState}
                messages={state.validationFields.messages}
              />
            </div>
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.CONTACT_EMAIL}
                value={state.fields[COMPANY_PROFILE_FIELDS.CONTACT_EMAIL.NAME]}
                setFieldValue={state.setFieldValue}
                showOnSubmitErrorState={state.onSubmitErrorState}
                messages={state.validationFields.messages}
                disabled={state.isSaving}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.TAGLINE}
                value={state.fields[COMPANY_PROFILE_FIELDS.TAGLINE.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
              />
            </div>
            <div className="col" />
          </div>
        </div>
        <div className="form-footer">
          <button
            className="btn btn-col-2 btn-transparent disable-fade-transparent"
            disabled={state.isRestoreDisabled}
            onClick={state.restoreFields}
            type="reset">
            Restore
          </button>
          <button
            className="btn btn-col-2 btn-primary disable-fade-primary"
            disabled={state.isSaveDisabled}
            onClick={state.editCompanyProfile}
            type="submit">
            {state.isSaving ? 'Loading...' : 'Save'}
          </button>
        </div>
      </form>
    </StaticPageLayout>
  );
});

export default CompanyProfile;
