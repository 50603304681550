import { observer, useLocalObservable } from 'mobx-react';
import { useMemo, useRef, useEffect } from 'react';
import InputWrapper from './components/InputWrapper';

const TextArea = observer(
  ({
    field,
    value = '',
    setFieldValue,
    formId = null,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    hideErrorMessages = false,
    hideRequiredLabelSymbol = false,
    inputWrap = { enable: false, className: '' },
    wrapClassname = '',
    adjustHeight = { enable: false, minRows: 0, maxRows: 0, lineHeight: 24 },
    onClick = () => {},
    onKeyDown = () => {},
    textAreaClassName = ''
  }) => {
    const state = useLocalObservable(() => ({
      height: (adjustHeight.lineHeight || 24) * (adjustHeight.minRows || 1) + 7 + 7 + 2,
      setHeight: (height) => {
        if (height !== state.height) {
          state.height = height;
        }
      },
      updateHeight: (ref, minRows, maxRows, newValue) => {
        const minHeight = (adjustHeight.lineHeight || 24) * (minRows || 1) + 7 + 7 + 2;
        const maxHeight = (maxRows || minRows || 1) * (adjustHeight.lineHeight || 24) + 7 + 7 + 2;
        let height = 0;
        var div = document.createElement('div');
        div.innerHTML = newValue;
        div.style.padding = '7px 16px';
        div.style.border = '1px solid black';
        div.style.width = `${ref.current.offsetWidth}px`;
        const fontSize = window.getComputedStyle(ref.current, null).getPropertyValue('font-size');
        const lineHeight = window
          .getComputedStyle(ref.current, null)
          .getPropertyValue('line-height');
        div.style.fontSize = fontSize || '16px';
        div.style.lineHeight = lineHeight || '24px';
        div.style.position = 'fixed';
        div.style.top = '-10000px';
        div.style.left = '-10000px';
        div.style.whiteSpace = 'normal !important';
        // div.style.wordBreak = 'break-all';
        div.style.overflowWrap = 'break-word';
        document.body.appendChild(div);
        height = div.offsetHeight;
        document.body.removeChild(div);
        if (height < minHeight) {
          height = minHeight;
        } else if (height > maxHeight) {
          height = maxHeight;
        }

        state.setHeight(height);
      }
    }));
    const ref = useRef(null);
    const props = useMemo(() => {
      const [inputProps, labelProps] = [{}, {}];

      inputProps.className = field.INPUT_CLASSNAME || 'form-control';

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      if (typeof field.ROWS === 'number') {
        inputProps.rows = `${field.ROWS}`;
      }

      if (typeof field.COLS === 'number') {
        inputProps.cols = `${field.COLS}`;
      }

      if (typeof field.MAXLENGTH === 'number') {
        inputProps.maxLength = `${field.MAXLENGTH}`;
      }

      if (formId || field.FORM_ID) {
        inputProps.form = formId || field.FORM_ID;
      }

      if (field.READ_ONLY) {
        inputProps.readOnly = true;
      }

      if (field.WRAP) {
        inputProps.wrap = field.WRAP;
      }

      return { inputProps, labelProps };
    }, [field, formId]);

    const { validationState, onSubmitErrorMessages } = useMemo(() => {
      let validationState = '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => m.field === field.NAME && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        validationState = 'error';
      }

      return { validationState, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    const withWrap = inputWrap.enable;
    const withAdjustHeight = adjustHeight.enable;

    useEffect(() => {
      const onResize = () => {
        if (adjustHeight.enable && ref.current) {
          state.updateHeight(ref, adjustHeight.minRows, adjustHeight.maxRows, value);
        }
      };
      window.addEventListener('resize', onResize);
      onResize();
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [
      state,
      value,
      ref,
      ref.current,
      adjustHeight,
      adjustHeight.enable,
      adjustHeight.minRows,
      adjustHeight.maxRows
    ]);

    const view = (
      <div
        className={wrapClassname || field.WRAP_CLASSNAME || 'form-group'}
        {...{ 'validation-state': validationState }}>
        {!!field.LABEL && (
          <label htmlFor={field.ID} {...props.labelProps}>
            {field.LABEL}
            {field.REQUIRED && !hideRequiredLabelSymbol && !field.HIDE_REQUIRED_LABEL_SYMBOL && '*'}
            {!!field.LABEL_DESCRIPTION && <span>{field.LABEL_DESCRIPTION}</span>}
          </label>
        )}
        <div className="input-error-group">
          <div className="input-container">
            <textarea
              ref={ref}
              id={field.ID}
              placeholder={field.PLACEHOLDER}
              required={field.REQUIRED}
              disabled={disabled}
              value={value}
              onChange={(e) => {
                setFieldValue(
                  field,
                  typeof field.FORMAT === 'function' ? field.FORMAT(e.target.value) : e.target.value
                );
              }}
              {...props.inputProps}
              style={withAdjustHeight ? { height: state.height + 'px' } : {}}
              onClick={onClick}
              onKeyDown={(e) => {
                onKeyDown(e);
              }}
              className={textAreaClassName}
            />
          </div>
          {!hideErrorMessages &&
            onSubmitErrorMessages.map(({ msg }, idx) => (
              <div
                key={idx}
                className={
                  field.ON_SUBMIT_ERROR_STATE_ERROR_MESSAGE_CLASSNAME || 'error-msg-input'
                }>
                {msg}
              </div>
            ))}
        </div>
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default TextArea;
