export const projectTypePageSize = 8;
export const selectedProjectTabPageSize = 8;
export const PROJECT_TYPE_TABS = { SAVED: 'saved', ARCHIVED: 'archived' };
export const PROJECT_LIST_SORT = {
  DATE_DESC: 'DATE_DESC',
  DATE_ASC: 'DATE_ASC',
  TYPE_DESC: 'TYPE_DESC',
  TYPE_ASC: 'TYPE_ASC'
};
export const PROJECT_LIST_SORT_OPTIONS = [
  { name: 'Sort by date (desc) ↓', value: PROJECT_LIST_SORT.DATE_DESC },
  { name: 'Sort by date (asc)   ↑', value: PROJECT_LIST_SORT.DATE_ASC },
  { name: 'Sort by type (a → z)', value: PROJECT_LIST_SORT.TYPE_ASC },
  { name: 'Sort by type (z ← a)', value: PROJECT_LIST_SORT.TYPE_DESC }
];
export const SELECTED_PROJECT_TABS = {
  RESULTS: 'results',
  EXCLUDED: 'excluded',
  COMMENTS: 'comments'
};

export const encodeProjectsParams = (params = {}) => encodeURIComponent(JSON.stringify(params));
export const decodeProjectsParams = (paramsString = '') =>
  JSON.parse(decodeURIComponent(paramsString));
