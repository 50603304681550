import { REQ_METHODS } from './methods';
import { ACCOUNT_VERIF_TYPES, ACCOUNT_TYPES } from '../utils/constants/auth';
import {
  FUND_ENTRIES,
  COMPANY_PROFILE,
  setCompanyProfile,
  PORTFOLIO_COMPANIES_ENTRIES,
  OPTIONS,
  USER_PROFILE,
  setUserProfile,
  SAVED_SEARCH_RESULTS
} from './mockData';
import {
  SEARCH_FIELDS,
  SAVED_SEARCH_VIEW_FIELDS,
  FUND_FIELDS,
  PORTFOLIO_FIELDS
} from '../utils/constants/fields';
import { UI_OPTIONS } from '../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../utils/constants/uiOptionKeys';

export const API_ENDPOINTS = {
  GET_WEBSOCKET_TOKEN: {
    path: '/get-websocket-token',
    method: REQ_METHODS.GET,
    withCognitoToken: true
  },
  GET_PUBLIC_OPTIONS: {
    path: '/public/nomenclature',
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return JSON.parse(JSON.stringify(OPTIONS));
    // }
  },
  GET_ACCOUNT_VERIFICATION_PROGRESS: {
    path: '/account-verification-progress',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return ACCOUNT_VERIF_TYPES.APPROVED;
    // }
  },
  GET_ACCOUNT_TYPE: {
    path: '/account-type',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return ACCOUNT_TYPES.AGENT;
    // }
  },
  GET_USER_INFO: {
    path: '/get-user-info',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return JSON.parse(JSON.stringify(USER_PROFILE));
    // }
  },
  EDIT_USER_INFO: {
    path: '/update-user-info',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   setUserProfile({ ...USER_PROFILE, ...body });
    //   return JSON.parse(JSON.stringify(USER_PROFILE));
    // }
  },
  DEACTIVATE_USER_ACCOUNT: {
    path: '/deactivate-user',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: () => {
    //   return true;
    // }
  },
  GET_PROFILE_COMPANY: {
    path: '/get-profile-company',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return JSON.parse(JSON.stringify(COMPANY_PROFILE));
    // }
  },
  EDIT_PROFILE_COMPANY: {
    path: '/update-profile-company',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   setCompanyProfile({ ...COMPANY_PROFILE, ...body });
    //   return JSON.parse(JSON.stringify(COMPANY_PROFILE));
    // }
  },
  GET_SEARCH_RESULTS: {
    path: '/get-search-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: (body = {}) => {
    //   const isFund =
    //     body[SEARCH_FIELDS.SEARCH_TYPE.NAME] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
    //   const list = [];
    //   for (let i = 0; i < 20; i++) {
    //     list.push(
    //       ...JSON.parse(
    //         JSON.stringify(
    //           body[SEARCH_FIELDS.SEARCH_TYPE.NAME] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
    //             ? FUND_ENTRIES
    //             : PORTFOLIO_COMPANIES_ENTRIES
    //         )
    //       )
    //     );
    //   }

    //   const allData = list.map((r, i) => {
    //     r[isFund ? FUND_FIELDS.NAME.NAME : PORTFOLIO_FIELDS.NAME.NAME] = `${
    //       isFund ? 'Fund' : 'Portfolio'
    //     } ${i + 1}`;
    //     r.id = i + 3;
    //     return r;
    //   });

    //   const asc = body.asc;
    //   if (!asc) {
    //     allData.reverse();
    //   }

    //   let index = allData.findIndex((r) => r.id === body.from) + 1;
    //   const results = allData.slice(index, index + body.pageSize);
    //   const hasNext = allData[allData.length - 1].id !== results[results.length - 1].id;
    //   results.forEach((r) => (r.contactInfo = { firstName: 'John', lastName: 'Doe' }));
    //   return {
    //     total: allData.length,
    //     hasNext,
    //     data: results
    //   };
    // }
  },
  GET_PROJECTS: {
    path: '/list-projects',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_PROJECT_RESULTS: {
    path: '/get-project-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  SAVE_PROJECT: {
    path: '/create-project',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROJECT_NAME: {
    path: '/change-project-name',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  EXCLUDE_PROJECT_RESULT: {
    path: '/exclude-project-result',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  REQUEST_PROJECT_FEEDBACK: {
    path: '/request-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  RESOLVE_PROJECT_FEEDBACK: {
    path: '/resolve-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROJECT_LAST_CHECKED_DATE: {
    path: '/update-project-owner-last-checked-date',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ARCHIVE_PROJECT: {
    path: '/archive-project',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  DELETE_PROJECTS: {
    path: '/delete-projects',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  GET_SELF_COMPANY_FUNDS: {
    path: '/get-self-company-funds',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_SELF_COMPANY_PORTFOLIOS: {
    path: '/get-self-company-portfolios',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_SELF_COMPANY_INVEST_PROFILES: {
    path: '/get-self-company-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_SELF_COMPANY_USERS: {
    path: '/get-self-company-users',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_FUNDS: {
    path: '/get-funds',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   const entries = JSON.parse(JSON.stringify(FUND_ENTRIES));
    //   return entries;
    // }
  },
  EDIT_FUND: {
    path: '/update-funds',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   const foundEntry = FUND_ENTRIES.find((f) => f.id === body.id);
    //   if (foundEntry) {
    //     Object.entries(body).forEach(([key, value]) => {
    //       foundEntry[key] = value;
    //     });
    //   }
    //   return JSON.parse(JSON.stringify(foundEntry));
    // }
  },
  CREATE_FUND: {
    path: '/create-funds',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: (body = {}) => {
    //   const id = 'abc' + Math.random();
    //   FUND_ENTRIES.push(JSON.parse(JSON.stringify({ id, ...body })));
    //   return JSON.parse(JSON.stringify(FUND_ENTRIES.find((f) => f.id === id)));
    // }
  },
  DELETE_FUND: {
    path: '/delete-funds',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
    // mockResponseData: (body = {}) => {
    //   const foundIndex = FUND_ENTRIES.findIndex((f) => f.id === body.id);
    //   if (foundIndex !== -1) {
    //     FUND_ENTRIES.splice(foundIndex, 1);
    //   }
    //   return true;
    // }
  },
  GET_PORTFOLIO_COMPANIES: {
    path: '/get-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   const entries = JSON.parse(JSON.stringify(PORTFOLIO_COMPANIES_ENTRIES));
    //   return entries;
    // }
  },
  EDIT_PORTFOLIO_COMPANY: {
    path: '/update-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   const foundEntry = PORTFOLIO_COMPANIES_ENTRIES.find((f) => f.id === body.id);
    //   if (foundEntry) {
    //     Object.entries(body).forEach(([key, value]) => {
    //       foundEntry[key] = value;
    //     });
    //   }
    //   return JSON.parse(JSON.stringify(foundEntry));
    // }
  },
  CREATE_PORTFOLIO_COMPANY: {
    path: '/create-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: (body = {}) => {
    //   const id = 'asd' + Math.random();
    //   PORTFOLIO_COMPANIES_ENTRIES.push(JSON.parse(JSON.stringify({ id, ...body })));
    //   return JSON.parse(JSON.stringify(PORTFOLIO_COMPANIES_ENTRIES.find((f) => f.id === id)));
    // }
  },
  DELETE_PORTFOLIO_COMPANY: {
    path: '/delete-portfolio-companies',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
    // mockResponseData: (body = {}) => {
    //   const foundIndex = PORTFOLIO_COMPANIES_ENTRIES.findIndex((f) => f.id === body.id);
    //   if (foundIndex !== -1) {
    //     PORTFOLIO_COMPANIES_ENTRIES.splice(foundIndex, 1);
    //   }
    //   return true;
    // }
  },
  GET_INVEST_PROFILES: {
    path: '/get-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  EDIT_INVEST_PROFILE: {
    path: '/update-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  CREATE_INVEST_PROFILE: {
    path: '/create-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_INVEST_PROFILE: {
    path: '/delete-invest-profiles',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  DOCUMENT_PRESIGN: {
    path: '/documents-presign-url',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  POST_OUTREACH_MESSAGE: {
    path: '/send-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_OUTREACHES: {
    path: '/get-outreaches',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_OUTREACH_MESSAGES: {
    path: '/get-outreaches-messages',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  SEND_OUTREACH_MESSAGE: {
    path: '/send-outreach-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_OUTREACH_LAST_CHECKED_MESSAGE: {
    path: '/update-outreach-user-last-checked-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ARCHIVE_OUTREACH: {
    path: '/archive-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  FORWARD_OUTREACH: {
    path: '/forward-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  REQUEST_OUTREACH_FEEDBACK: {
    path: '/request-outreach-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  EDIT_OUTREACH_MESSAGE: {
    path: '/edit-outreach-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  DELETE_OUTREACH_MESSAGE: {
    path: '/delete-outreach-message',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_DOCUMENT_DOWNLOAD_URL: {
    path: '/document-download',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  RATE_OUTREACH: {
    path: '/rate-outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  MARK_OUTREACH_UNREAD: {
    path: '/mark-outreach-unread',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ADMIN_LIST_USERS: {
    path: '/admin-list-users',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ADMIN_UPDATE_USER: {
    path: '/admin-update-user',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  ADMIN_LIST_FPI: {
    path: '/admin-list-fpi',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_PROFILER_SEARCH_RESULTS: {
    path: '/get-profiler-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_PROFILER_PROJECTS: {
    path: '/list-profiler-projects',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  GET_PROFILER_PROJECT_RESULTS: {
    path: '/get-profiler-project-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  SAVE_PROFILER_PROJECT: {
    path: '/create-profiler-project',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROFILER_PROJECT_NAME: {
    path: '/change-profiler-project-name',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  EXCLUDE_PROFILER_PROJECT_RESULT: {
    path: '/exclude-profiler-project-result',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  REQUEST_PROFILER_PROJECT_FEEDBACK: {
    path: '/request-profiler-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  RESOLVE_PROFILER_PROJECT_FEEDBACK: {
    path: '/resolve-profiler-project-feedback',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROFILER_PROJECT_LAST_CHECKED_DATE: {
    path: '/update-profiler-project-owner-last-checked-date',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  ARCHIVE_PROFILER_PROJECT: {
    path: '/archive-profiler-project',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
  },
  DELETE_PROFILER_PROJECTS: {
    path: '/delete-profiler-projects',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
  },
  GET_PROFILER_WATCHLIST: {
    path: '/get-profiler-watch-list',
    withCognitoToken: true,
    method: REQ_METHODS.GET
  },
  ADD_TO_PROFILER_WATCHLIST: {
    path: '/add-to-profiler-watch-list',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  UPDATE_PROFILER_WATCHLIST_NEWS_CHECKED: {
    path: '/set-profiler-news-checked',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  },
  GET_COMPANY_LOGO: {
    path: '/get-company-logo',
    withCognitoToken: true,
    method: REQ_METHODS.POST
  }
};
