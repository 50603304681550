export const HEADER_MESSAGE_TYPES = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  INFO: 'INFO'
};

export const HEADER_MESSAGE_IDS = {
  PORTFOLIO_NEEDS_FUNDS: 'PORTFOLIO_NEEDS_FUNDS'
};
