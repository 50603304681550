import { observer } from 'mobx-react';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';

const CollapsableSectionHead = observer(
  ({
    title = '',
    icon = '',
    isCollapsed = true,
    toggleCollapse = () => {},
    count = 0,
    showEmptyDot = false
  }) => {
    return (
      <div className="noselect collapsable-section-head" onClick={toggleCollapse}>
        {icon && <img className="icon-title" src={icon} />}
        <div className="head-title">{title}</div>

        {isCollapsed && count > 0 && <div className="head-count">{count}</div>}
        {isCollapsed && showEmptyDot && <div className="head-count" />}
        <img
          className="icon-collapse"
          src={isCollapsed ? ICON_BUTTON_ICONS.CHEVRON_DOWN : ICON_BUTTON_ICONS.CHEVRON_UP}
        />
      </div>
    );
  }
);

export default CollapsableSectionHead;
