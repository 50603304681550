import { useStore } from '../store/store';
import { useLocation } from 'react-router';
import { paths, routes } from '../utils/constants/routes';
import { ACCOUNT_VERIF_TYPES, ACCOUNT_TYPES } from '../utils/constants/auth';
import Reroute from '../components/navigation/Reroute';
import AppLoader from '../components/loaders/AppLoader';
import AppError from '../components/errors/AppError';
import { matchPath } from '../utils/utils';
import { HEADER_MESSAGE_TYPES } from '../utils/constants/header';

const staticPaths = [
  paths.TERMS_N_CONDITIONS,
  paths.COOKIE_POLICY,
  paths.PRIVACY_POLICY,
  paths.IMPRINT,
  paths.GDPR
];

const whitelistPaths = [...staticPaths, paths.HOME];

const freeAuthPaths = [
  ...staticPaths,
  paths.HOME,
  paths.HOME_FULL,
  paths.FORGOTTEN_PASSWORD,
  paths.SIGNUP,
  paths.LOGIN
];

const nonApprovalPaths = [...staticPaths, paths.USER_VERIF_PROGRESS];

const nonCompletedCompanyPaths = [
  ...staticPaths,
  paths.USER_VERIF_PROGRESS,
  paths.CREATE_COMPANY_PROFILE
];

const blockAuthPaths = [paths.FORGOTTEN_PASSWORD, paths.SIGNUP, paths.LOGIN];

const agentOnlyPaths = [paths.SEARCH_RESULTS, paths.PROJECTS, paths.TEAM];

const investorOnlyPaths = [
  routes.FUNDS_PATH,
  routes.PORTFOLIO_COMPANIES_PATH,
  routes.INVEST_PROFILES_PATH,
  paths.ALL_INVESTMENTS,
  paths.PROFILER,
  paths.PROFILER_SEARCH_RESULTS,
  paths.PROFILER_PROJECTS,
  paths.PROFILER_TEAM,
  paths.PROFILER_WATCH_LIST
];

const masterOnlyPaths = [paths.ALL_INVESTMENTS];

const adminOnlyPaths = [paths.ADMIN_DASHBOARD];

const profilerOnlyPaths = [
  paths.PROFILER,
  paths.PROFILER_SEARCH_RESULTS,
  paths.PROFILER_PROJECTS,
  paths.PROFILER_TEAM,
  paths.PROFILER_WATCH_LIST
];

const restoreAfterLoginPaths = [
  paths.OUTREACH,
  ...agentOnlyPaths,
  ...investorOnlyPaths,
  ...masterOnlyPaths,
  ...adminOnlyPaths
];

const useAppRouteGuard = () => {
  const { authStore, isWaitlistActivated, utilsStore } = useStore();
  const location = useLocation();
  const { isLoadingAccountDetails, accountInfoError, userVerificationStatus } = authStore;
  const { user, userAccountType, hasUserProfileCompany } = authStore;
  const { isMasterUser, isCognitoAdmin, isCognitoProfiler } = authStore.userInfo || {};

  if (isLoadingAccountDetails) {
    return <AppLoader />;
  }

  if (accountInfoError) {
    return <AppError />;
  }

  if (isWaitlistActivated && !matchPath(whitelistPaths, location)) {
    return <Reroute path={user ? paths.HOME_FULL : paths.HOME} />;
  }

  if (
    user &&
    userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED &&
    !matchPath(nonApprovalPaths, location)
  ) {
    return <Reroute path={paths.USER_VERIF_PROGRESS} />;
  }

  if (
    user &&
    isMasterUser &&
    !isLoadingAccountDetails &&
    !hasUserProfileCompany &&
    userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED &&
    !matchPath(nonCompletedCompanyPaths, location)
  ) {
    return <Reroute path={paths.CREATE_COMPANY_PROFILE} />;
  }

  if (user && Object.keys(authStore.userInfo || {}).length) {
    if (window.initialPage) {
      if (
        window.initialPage.pathname === location.pathname &&
        window.initialPage.search === location.search
      ) {
        window.initialPage = null;
      } else {
        if (matchPath(restoreAfterLoginPaths, window.initialPage)) {
          let redirectPath = window.initialPage.pathname;
          if (window.initialPage.search) {
            redirectPath += window.initialPage.search;
          }
          window.initialPage = null;
          return <Reroute path={redirectPath} />;
        } else {
          window.initialPage = null;
        }
      }
    }
  }

  if (!user && !matchPath(freeAuthPaths, location)) {
    return <Reroute path={paths.HOME} />;
  }

  if (user && matchPath(blockAuthPaths, location)) {
    return <Reroute path={paths.HOME_FULL} />;
  }

  if (userAccountType !== ACCOUNT_TYPES.INVESTOR && matchPath(investorOnlyPaths, location)) {
    return <Reroute path={user ? paths.HOME_FULL : paths.HOME} />;
  }

  if (userAccountType !== ACCOUNT_TYPES.AGENT && matchPath(agentOnlyPaths, location)) {
    return <Reroute path={user ? paths.HOME_FULL : paths.HOME} />;
  }

  if (!isMasterUser && matchPath(masterOnlyPaths, location)) {
    return <Reroute path={user ? paths.HOME_FULL : paths.HOME} />;
  }

  if (!isCognitoAdmin && matchPath(adminOnlyPaths, location)) {
    return <Reroute path={user ? paths.HOME_FULL : paths.HOME} />;
  }

  if (!isCognitoProfiler && matchPath(profilerOnlyPaths, location)) {
    utilsStore.setHeaderMessage(
      `Page ${location.pathname} cannot be accessed.`,
      HEADER_MESSAGE_TYPES.WARNING
    );
    return <Reroute path={user ? paths.HOME_FULL : paths.HOME} />;
  }

  return null;
};

export default useAppRouteGuard;
