import { observer } from 'mobx-react';
import IconButton, { ICON_BUTTON_ICONS } from '../buttons/IconButton';
import { runInAction } from 'mobx';

const PDFList = observer(({ documents = [], wrapStyle = {} }) => {
  if (!documents.length) {
    return null;
  }

  return (
    <div className="pdf-list">
      {documents.map((d, i) => {
        return (
          <div key={i} className="doc-wrap" style={wrapStyle}>
            <img className="pdf-icon" src={ICON_BUTTON_ICONS.FILE_EARNMARK_PDF} />
            <div className="doc-info-wrap">
              <div className="doc-info-top">
                <div className="doc-name">{d.name}</div>
                <div className="doc-size">{d.size}</div>
              </div>
              <div className="doc-info-bottom">
                <div className="doc-status">{d.isUploading ? 'Uploading...' : 'Uploaded'}</div>
                <IconButton
                  icon={ICON_BUTTON_ICONS.X_LG}
                  withBorder={false}
                  onClick={() => {
                    runInAction(() => documents.splice(i, 1));
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default PDFList;
