import { observer } from 'mobx-react';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';
import { Fragment } from 'react';

const MobileFilters = observer(({ onToggle, fields, isSearchDisabled, onSearch }) => {
  const fieldsSections = Object.entries(fields)
    .filter(([id]) => id !== SEARCH_FIELDS.SEARCH_TYPE.ID)
    .reduce((arr, curr) => {
      arr.push(...Object.values(curr[1]));
      return arr;
    }, []);

  return (
    <div className="mobile-filters-container">
      <button onClick={onToggle} className="backlink">
        Search results
      </button>

      <div className="fields">
        {fields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
        {fieldsSections.map((field, idx) => (
          <Fragment key={idx}>{field}</Fragment>
        ))}
      </div>

      <div className="form-footer">
        <button
          className="btn btn-primary disable-fade-primary"
          onClick={onSearch}
          disabled={isSearchDisabled}>
          Show results
        </button>
      </div>
    </div>
  );
});

export default MobileFilters;
