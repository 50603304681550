import { observer, useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

const HeaderTab = observer(
  ({
    icon = '',
    text = '',
    active = false,
    count = 0,
    showEmptyDot = false,
    onClick = () => {},
    wrapClassName = ''
  }) => {
    const state = useLocalObservable(() => ({
      clicked: false,
      setClicked: () => (state.clicked = true)
    }));

    useEffect(() => {
      if (active && !state.clicked) {
        state.setClicked();
      }
    }, [state, active]);

    const tabClassNames = ['noselect', 'tab'];
    if (wrapClassName) {
      tabClassNames.push(wrapClassName);
    }
    if (state.clicked) {
      tabClassNames.push('clicked');
    }
    if (active) {
      tabClassNames.push('active');
    }

    return (
      <div
        className={tabClassNames.join(' ')}
        onClick={() => {
          if (!active) {
            onClick();
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 10);
          }
        }}>
        <div className="img-wrap">
          <img src={icon} />
          {!active && !!count && <div className="count">{count}</div>}
          {!active && !count && showEmptyDot && <div className="count" />}
        </div>
        <div className="text">{text}</div>
      </div>
    );
  }
);

export default HeaderTab;
