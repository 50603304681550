import StaticPageLayout from '../layouts/StaticPageLayout';
import GeneralLoader from './GeneralLoader';

const AppLoader = () => (
  <div className="app-wrapper">
    <StaticPageLayout>
      <div className="loader-container">
        <GeneralLoader />
      </div>
    </StaticPageLayout>
  </div>
);

export default AppLoader;
