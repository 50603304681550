import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { formatDateDDMMYYYY, mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import {
  PROFILER_SEARCH_FIELDS,
  PROFILER_PROJECT_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../utils/constants/fields';
import { encodeProjectsParams } from '../constants';
import ProfilerArchivePopup from './ProfilerArchivePopup';
import Input from '../../../components/inputs/Input';
import { useEffect } from 'react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';

const ProfilerProjectListEntry = observer(({ project = {}, params = {} }) => {
  const { profilerProjectStore, utilsStore } = useStore();
  const { isArchivingProject, isChangingProjectName } = profilerProjectStore;
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isArchivePopupOpen: false,
    toggleArchivePopup: (isOpen = false) => {
      state.isArchivePopupOpen = isOpen;
      utilsStore.lockScroll(isOpen);
    },
    isChangeNameToggled: false,
    toggleChangeName: () =>
      ([state.isChangeNameToggled, state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME]] = [
        !state.isChangeNameToggled,
        project.name
      ]),
    fields: mapFieldsToState(PROFILER_PROJECT_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields([PROFILER_PROJECT_FIELDS.NAME], state.fields);
    },
    get isSaveNameDisabled() {
      return (
        profilerProjectStore.isChangingProjectName ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
        state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME] ===
          project[PROFILER_PROJECT_FIELDS.NAME.NAME]
      );
    }
  }));

  useEffect(() => {
    return () => {
      if (state.isArchivePopupOpen) {
        state.toggleArchivePopup(false);
      }
    };
  }, [state]);

  const onViewDetailsClick = () => {
    navigate(
      paths.PROFILER_PROJECTS +
        '?params=' +
        encodeProjectsParams({ ...params, selectedProjectId: project.id })
    );
  };

  const onReExecuteSearchClick = () => {
    const initial = mapFieldsToState(PROFILER_SEARCH_FIELDS);
    const searchFields = {};
    Object.entries(project.fields).forEach(([fieldName, fieldValue]) => {
      searchFields[fieldName] = fieldValue || initial[fieldName];
    });
    const fieldsParams = Object.entries(searchFields)
      .filter((field) => field[1].length)
      .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
      .join('&');

    navigate(paths.PROFILER_SEARCH_RESULTS + `?page=1&${fieldsParams}`);
  };

  const onArchiveClick = () => {
    state.toggleArchivePopup(true);
  };

  const onSaveNameClick = () => {
    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    profilerProjectStore.changeProjectName(
      project.id,
      state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME],
      state.toggleChangeName
    );
  };

  const hasNewFeedback = profilerProjectStore.hasProjectNewFeedback(project);

  return (
    <div className="row profiler-row">
      {state.isArchivePopupOpen && (
        <ProfilerArchivePopup project={project} toggle={() => state.toggleArchivePopup(false)} />
      )}
      {!state.isChangeNameToggled ? (
        <div className="item project-name">
          <div className="label">Project name</div>
          <div className="value">
            {project[PROFILER_PROJECT_FIELDS.NAME.NAME]}
            <IconButton
              key="edit-profiler-project-name"
              icon={ICON_BUTTON_ICONS.PEN}
              disabled={isChangingProjectName}
              onClick={state.toggleChangeName}
              withBorder={false}
            />
          </div>
        </div>
      ) : (
        <div className="item project-change-name">
          <Input
            key="save-profiler-project-name"
            field={PROFILER_PROJECT_FIELDS.NAME}
            value={state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={isChangingProjectName}
            messages={state.validationFields.messages}
            showOnSubmitErrorState
            hideLabel
          />
          <div className="icons">
            <IconButton
              key="cancel-edit-profiler-project-name"
              icon={ICON_BUTTON_ICONS.CHECK_LG}
              type={ICON_BUTTON_TYPES.GREEN}
              onClick={onSaveNameClick}
              disabled={state.isSaveNameDisabled}
              withBorder={false}
            />
            <IconButton
              icon={ICON_BUTTON_ICONS.X_LG}
              type={ICON_BUTTON_TYPES.RED}
              onClick={state.toggleChangeName}
              disabled={profilerProjectStore.isChangingProjectName}
              withBorder={false}
            />
          </div>
        </div>
      )}
      <div className="item">
        <div className="label">Date saved</div>
        <div className="value">{formatDateDDMMYYYY(project.dateSaved)}</div>
      </div>
      <div className="item profiler-num-results">
        <div className="label">Number of results</div>
        <div className="value text-align-right-desktop">{project.resultsCount}</div>
      </div>
      <div className="item">
        <div className="label">Industries</div>
        <div className="value">{project.fields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}</div>
      </div>
      <div className="item profiler-product">
        <div className="label">Product portfolio</div>
        <div className="value">{project.fields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}</div>
      </div>
      <div className="actions profiler-project desktop-only">
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          innerText="View details"
          onClick={onViewDetailsClick}
          filled
        />
        <IconButton onClick={onReExecuteSearchClick} innerText="Re-execute" />
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={
            project.status === 'ACTIVE' ? ICON_BUTTON_ICONS.ARCHIVE : ICON_BUTTON_ICONS.ARROW_REPEAT
          }
          disabled={isArchivingProject}
          tooltipText={
            isArchivingProject
              ? ''
              : project.status === 'ACTIVE'
              ? `Archive ${project.name}`
              : `Unarchive ${project.name}`
          }
          onClick={onArchiveClick}
        />
      </div>
      <div className="actions-mobile mobile-only">
        <div className="actions-stretch">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            innerText="View details"
            onClick={onViewDetailsClick}
            filled
          />
          <IconButton onClick={onReExecuteSearchClick} innerText="Re-execute" />
        </div>
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={
            project.status === 'ACTIVE' ? ICON_BUTTON_ICONS.ARCHIVE : ICON_BUTTON_ICONS.ARROW_REPEAT
          }
          disabled={isArchivingProject}
          tooltipText={
            isArchivingProject
              ? ''
              : project.status === 'ACTIVE'
              ? `Archive ${project.name}`
              : `Unarchive ${project.name}`
          }
          onClick={onArchiveClick}
        />
      </div>
      {hasNewFeedback && <div className="project-notif-dot" />}
    </div>
  );
});

export default ProfilerProjectListEntry;
