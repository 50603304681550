import Input from '../../../../components/inputs/Input';
import { USER_CHANGE_PASSWORD_FIELDS, getErrorFields } from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { useLocalObservable } from 'mobx-react';
import { generateMessage, mapFieldsToState } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
import { paths } from '../../../../utils/constants/routes';
import { Fragment } from 'react';

const useChangePasswordTab = () => {
  const { authStore } = useStore();
  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(USER_CHANGE_PASSWORD_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    get isRestoreDisabled() {
      return !Object.values(state.fields).find((v) => !!v) || authStore.isAuthenticating;
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    onRestore: () => {
      state.fields = mapFieldsToState(USER_CHANGE_PASSWORD_FIELDS);
    },
    onSave: () => {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }

      if (state.validationFields.invalidFields.length) {
        return;
      }

      authStore.changePassword(
        state.fields[USER_CHANGE_PASSWORD_FIELDS.OLD_PASSWORD.NAME],
        state.fields[USER_CHANGE_PASSWORD_FIELDS.NEW_PASSWORD.NAME]
      );
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      const validations = getErrorFields(Object.values(USER_CHANGE_PASSWORD_FIELDS), state.fields);

      const [pwd, repwd] = [
        state.fields[USER_CHANGE_PASSWORD_FIELDS.NEW_PASSWORD.NAME],
        state.fields[USER_CHANGE_PASSWORD_FIELDS.RE_NEW_PASSWORD.NAME]
      ];

      if (pwd.length && repwd.length && pwd !== repwd) {
        validations.messages.push(
          generateMessage(
            "Passwords don't match",
            false,
            USER_CHANGE_PASSWORD_FIELDS.RE_NEW_PASSWORD.NAME
          )
        );
        validations.invalidFields.push({ name: USER_CHANGE_PASSWORD_FIELDS.RE_NEW_PASSWORD.NAME });
      }

      Object.values(USER_CHANGE_PASSWORD_FIELDS).forEach((fieldConfig) => {
        if (authStore.authErrors.invalidFields.includes(fieldConfig.NAME)) {
          const msgs = authStore.authErrors.messages.filter(
            (msg) => msg.field === fieldConfig.NAME
          );
          if (msgs.length) {
            validations.messages.push(...msgs);
          }
        }
      });

      return validations;
    }
  }));

  const { isRestoreDisabled, isSaveDisabled, onRestore, onSave } = state;

  const view = (
    <Fragment key="tab-change-password">
      <div className="mobile-tab-header">
        <Link to={paths.USER_PROFILE_SETTINGS} className="backlink">
          Back to settings
        </Link>
        <div className="title">Change password</div>
        <p>Easily change and set a new password</p>
      </div>
      <div className="form-body form-row-layout compact-rows" key="tab-change-pwd">
        <div className="row">
          <div className="col">
            <Input
              field={USER_CHANGE_PASSWORD_FIELDS.OLD_PASSWORD}
              value={state.fields[USER_CHANGE_PASSWORD_FIELDS.OLD_PASSWORD.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={authStore.isAuthenticating}
              showOnSubmitErrorState={state.onSubmitErrorState}
              messages={state.validationFields.messages}
            />
            <Input
              field={USER_CHANGE_PASSWORD_FIELDS.NEW_PASSWORD}
              value={state.fields[USER_CHANGE_PASSWORD_FIELDS.NEW_PASSWORD.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={authStore.isAuthenticating}
              showOnSubmitErrorState={true}
              messages={state.validationFields.messages}
            />
            <Input
              field={USER_CHANGE_PASSWORD_FIELDS.RE_NEW_PASSWORD}
              value={state.fields[USER_CHANGE_PASSWORD_FIELDS.RE_NEW_PASSWORD.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={authStore.isAuthenticating}
              showOnSubmitErrorState={true}
              messages={state.validationFields.messages}
            />
          </div>
        </div>
      </div>
      {authStore.authErrors.messages
        .filter((msg) => !msg.field)
        .map((m, idx) => (
          <div className={m.isValid ? 'success' : 'error'} key={idx}>
            {m.msg}
          </div>
        ))}
    </Fragment>
  );

  return { view, onSave, onRestore, isSaveDisabled, isRestoreDisabled };
};

export default useChangePasswordTab;
