import { observer } from 'mobx-react';
import { PROFILER_SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { Fragment } from 'react';

const DesktopFilters = observer(
  ({
    fields,
    fieldsCount,
    searchSectionsToggle,
    toggleSearchSection,
    onSearch,
    isSearchDisabled
  }) => {
    return (
      <div className="desktop-filters">
        {Object.values(PROFILER_SEARCH_CATEGORIES).map((section, idx) => {
          const { filled, total } = fieldsCount[section];
          return (
            <div className={`filter-category${idx === 0 ? ' no-border' : ''}`} key={section}>
              <button onClick={() => toggleSearchSection(section)}>
                <span>
                  {section} {filled}/{total}
                </span>
                <img
                  src="/icons/chevron-down.svg"
                  className={`${searchSectionsToggle[section] ? 'expanded' : 'collapsed'}`}
                />
              </button>
              {searchSectionsToggle[section] && (
                <div className="col-wrapper">
                  {Object.values(fields[section]).map((field, idx) => (
                    <Fragment key={idx}>{field}</Fragment>
                  ))}
                </div>
              )}
            </div>
          );
        })}
        <button
          className="btn btn-primary disable-fade-primary"
          onClick={(event) => onSearch(event) & window.scrollTo({ top: 0 })}
          disabled={isSearchDisabled}>
          Search
        </button>
      </div>
    );
  }
);

export default DesktopFilters;
