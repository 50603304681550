import { observable } from 'mobx';
import { matchPath } from './utils';

const history = observable({
  _locationStack: [],
  _hrefStack: [],
  _push: (location, href) => {
    history._locationStack.push(JSON.parse(JSON.stringify(location)));
    history._hrefStack.push(href);
  },
  _pop: () => {
    history._locationStack.pop();
    history._hrefStack.pop();
  },
  matchesPrevPath: (path = '') => {
    if (history._locationStack[history._locationStack.length - 2]) {
      return matchPath([path], history._locationStack[history._locationStack.length - 2]);
    }

    return false;
  },
  get lastHref() {
    return history._hrefStack[history._hrefStack.length - 1];
  },
  get lastLocation() {
    return history._locationStack[history._locationStack.length - 1];
  },
  get prevHref() {
    return history._hrefStack[history._hrefStack.length - 2];
  },
  get prevLocation() {
    return history._locationStack[history._locationStack.length - 2];
  }
});

export default history;
