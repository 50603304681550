import { observer, useLocalObservable } from 'mobx-react';
import * as Portal from '@radix-ui/react-portal';
import IconButton, { ICON_BUTTON_TYPES } from '../../../components/buttons/IconButton';
import { useStore } from '../../../store/store';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { ADMIN_DASHBOARD_USER_GROUPS_ARRAY_SELECTABLE } from '../constants';
import { FIELDS_TYPES } from '../../../utils/constants/fields';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';

const SelectUserGroupsPopup = observer(({ user = {}, onClose = () => {} }) => {
  const { adminDashboardStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    isRendered: true,
    cognitoGroups: [],
    get currentUserGroups() {
      return user.cognitoGroups;
    },
    get hasGroupChanges() {
      const changeCheck1 = state.cognitoGroups.length !== state.currentUserGroups.length;
      const changeCheck2 = !!state.cognitoGroups.find((g) => !state.currentUserGroups.includes(g));
      const changeCheck3 = !!state.currentUserGroups.find((g) => !state.cognitoGroups.includes(g));
      return changeCheck1 || changeCheck2 || changeCheck3;
    },
    get disableCancel() {
      return adminDashboardStore.isUpdatingUser;
    },
    get disableSave() {
      return adminDashboardStore.isUpdatingUser || !state.hasGroupChanges;
    },
    get disableInputs() {
      return adminDashboardStore.isUpdatingUser;
    },
    onSave: () => {
      const userDisplayName = user.firstName + ' ' + user.lastName;
      adminDashboardStore.updateUserGroups(
        user,
        state.cognitoGroups,
        () => {
          if (state.isRendered) {
            onClose();
          }

          utilsStore.setHeaderMessage(
            `Changes to group were successfully saved for ${userDisplayName}.`
          );
        },
        (errorMessage) => {
          if (state.isRendered) {
            onClose();
          }

          utilsStore.setHeaderMessage(
            errorMessage || `Failed to save group changes for ${userDisplayName}.`,
            HEADER_MESSAGE_TYPES.ERROR
          );
        }
      );
    }
  }));

  useEffect(() => {
    return () => {
      runInAction(() => {
        state.isRendered = false;
      });
    };
  }, [state]);

  useEffect(() => {
    runInAction(() => {
      state.cognitoGroups = user.cognitoGroups.slice();
    });
  }, [user.cognitoGroups]);

  return (
    <Portal.Root>
      <div className="admin-dashboard-select-user-groups-popup-closer" onClick={onClose} />
      <div className="admin-dashboard-select-user-groups-popup">
        <div>
          <div className="title">
            Select user group(s) for {user.firstName} {user.lastName}
          </div>
          <div className="subtitle">
            Manage group membership. Groups can be used to add permissions to the access token for
            multiple users.
          </div>
        </div>
        <div className="selections-title">
          <div className="title">Group name</div>
          <div className="title long">Description</div>
        </div>
        <div className="selections">
          {ADMIN_DASHBOARD_USER_GROUPS_ARRAY_SELECTABLE.filter(
            ({ value }) => !(state.currentUserGroups.includes('agent') && value === 'profiler')
          ).map((group) => {
            const checked = state.cognitoGroups.includes(group.value);
            return (
              <div key={group.value} className="row">
                <div className="name">
                  <div className="noselect options-layout">
                    <label>
                      <input
                        type={FIELDS_TYPES.TYPE_CHECKBOX}
                        checked={checked}
                        onChange={() => {
                          runInAction(() => {
                            if (checked) {
                              state.cognitoGroups = state.cognitoGroups.filter(
                                (g) => g !== group.value
                              );
                            } else {
                              state.cognitoGroups.push(group.value);
                            }
                          });
                        }}
                        disabled={state.disableInputs}
                      />
                      {group.name}
                    </label>
                  </div>
                </div>
                <div className="description">{group.description}</div>
              </div>
            );
          })}
        </div>
        <div className="actions">
          <IconButton innerText="Cancel" onClick={onClose} disabled={state.disableCancel} />
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            filled
            innerText="Save"
            disabled={state.disableSave}
            onClick={state.onSave}
          />
        </div>
      </div>
    </Portal.Root>
  );
});

export default SelectUserGroupsPopup;
