import { observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { runInAction } from 'mobx';

const SearchFormMobile = observer(
  ({
    onShowSection = null,
    fields,
    fieldsCount,
    /* isTypeSelected,*/ isSearchDisabled,
    onSearch,
    erroredSections = {}
  }) => {
    const { utilsStore } = useStore();
    const onSetSection = (section) => {
      if (onShowSection) {
        onShowSection(section);
      }
    };

    return (
      <form className="mobile" onSubmit={onSearch}>
        {fields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]}
        {Object.values(SEARCH_CATEGORIES).map((section) => {
          const { filled, total } = fieldsCount[section];
          const btnClassNames = ['btn', 'btn-next'];
          if (erroredSections[section]) {
            btnClassNames.push('error');
          }
          return (
            <button
              className={btnClassNames.join(' ')}
              key={section}
              onClick={() => {
                utilsStore.lockScroll(true, true);
                onSetSection(section);
                runInAction(() => {
                  utilsStore.isLockScroll = null;
                });
                window.scrollTo({ top: 0 });
              }}
              // disabled={!isTypeSelected}
            >
              {section} {filled}/{total}
            </button>
          );
        })}
        <button type="submit" className="btn btn-primary" disabled={isSearchDisabled}>
          Search
        </button>
      </form>
    );
  }
);

export default SearchFormMobile;
