import { observer, useLocalObservable } from 'mobx-react';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { encodeOutreachParams, getOutreachMessageDisplayTime } from './constants';
import { useStore } from '../../../store/store';
import { stringToColour } from '../../../utils/utils';
import { useEffect } from 'react';
import { ACCOUNT_TYPES } from '../../../utils/constants/auth';

const OutreachBox = observer(({ outreach = {}, params = {} }) => {
  const { authStore, outreachStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    now: Date.now(),
    setDateNow: (ms) => (state.now = ms)
  }));

  useEffect(() => {
    const interval = setInterval(() => state.setDateNow(Date.now()), 60_000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);

  const isAgent = authStore.userAccountType === ACCOUNT_TYPES.AGENT;

  const isSelected = params.outreachId === outreach.id;

  const onSelect = () => {
    if (isSelected) {
      return;
    }

    navigate(
      paths.OUTREACH + '?params=' + encodeOutreachParams({ ...params, outreachId: outreach.id })
    );
  };

  const participants = outreach.participants;

  const lastMessage =
    outreachStore.messages[outreach.id][outreachStore.messages[outreach.id].length - 1];

  let displayMessage = 'No messages';
  if (lastMessage) {
    if (lastMessage.messageType === 'PDF') {
      displayMessage = lastMessage.data.filename;
    } else {
      displayMessage = lastMessage.data;
    }

    if (lastMessage.userId === authStore.currentUserId) {
      displayMessage = 'You: ' + displayMessage;
    } else {
      if (
        participants.filter((u) => u.isActive).length > 2 &&
        !['system'].includes(lastMessage.messageType)
      ) {
        const participant = participants.find((u) => u.id === lastMessage.userId);
        if (participant) {
          displayMessage = `${participant.firstName}: ${displayMessage}`;
        }
      }
    }
  }

  const displayTime = getOutreachMessageDisplayTime(lastMessage?.date, state.now);

  const lastCheckedMessage = participants.find(
    (u) => u.id === authStore.currentUserId
  ).lastCheckedMessage;

  const unreadMessagesCount = outreachStore.messages[outreach.id].filter(
    (m) => m.date > lastCheckedMessage && m.userId !== authStore.currentUserId
  ).length;

  const activeParticipants = participants.filter(
    (u) => u.id !== authStore.currentUserId && u.isActive
  );

  const currentUserInfo = participants.find((u) => u.id === authStore.currentUserId);
  const isMarkedAsUnread = currentUserInfo.isMarkedAsUnread;

  const activeParticipant = activeParticipants[0];
  const displayName = activeParticipant.firstName + ' ' + activeParticipant.lastName;

  const showGroupChatFeedback = outreach.isProjectFeedback && activeParticipants.length > 1;

  return (
    <div className={`noselect outreach-box${isSelected ? ' selected' : ''}`} onClick={onSelect}>
      {showGroupChatFeedback ? (
        <div className="type" style={{ backgroundColor: '#F2D2D6' }}>
          G
        </div>
      ) : (
        <div
          className="type"
          style={{
            backgroundColor: stringToColour(
              activeParticipant.firstName + activeParticipant.lastName
            )
          }}>
          {displayName.charAt(0).toUpperCase()}
        </div>
      )}

      <div className="outreach-info-box">
        <div className="top-info">
          <div className="title">{showGroupChatFeedback ? 'Group chat feedback' : displayName}</div>
          <div className="date">{displayTime}</div>
        </div>
        <div className="bottom-info">
          <div
            className={`text-wrap ${
              unreadMessagesCount > 9 && unreadMessagesCount < 100 ? 'double-digit' : ''
            } ${unreadMessagesCount > 100 && unreadMessagesCount < 1000 ? 'triple-digit' : ''} ${
              unreadMessagesCount > 1000 ? 'quadruple-digit' : ''
            }`}>
            <div className="text-top">{isAgent ? outreach.objectData.name : outreach.subject}</div>
            <div className="text-bottom" style={unreadMessagesCount > 0 ? { fontWeight: 600 } : {}}>
              {displayMessage}
            </div>
          </div>
          {unreadMessagesCount > 0 && <div className="unread-count">{unreadMessagesCount}</div>}
          {isMarkedAsUnread && !unreadMessagesCount && <div className="unread-count" />}
        </div>
      </div>
    </div>
  );
});

export default OutreachBox;
