import { observer } from 'mobx-react';

export const STATUS_ICON_TYPES = {
  GREEN: 'green',
  RED: 'red'
};

const STATUS_ICONS = {
  [STATUS_ICON_TYPES.GREEN]: '/icons/clean/check-lg.svg',
  [STATUS_ICON_TYPES.RED]: '/icons/clean/x-lg.svg'
};

const StatusIcon = observer(({ type = STATUS_ICON_TYPES.GREEN }) => {
  const statusIconType = Object.values(STATUS_ICON_TYPES).includes(type)
    ? type
    : STATUS_ICON_TYPES.GREEN;
  const icon = STATUS_ICONS[statusIconType];

  return (
    <div className={`status-icon ${statusIconType}`}>
      <div className="img-wrap">
        <img src={icon} />
      </div>
    </div>
  );
});

export default StatusIcon;
