import { getPublicDomainList } from './constants/freeEmailProviders';
import { SEARCH_CATEGORIES, PROFILER_SEARCH_CATEGORIES } from './constants/searchCategories';
import { SEARCH_FIELDS, FIELDS_TYPES, PROFILER_SEARCH_FIELDS } from './constants/fields';
import { PUNCTUATION, CURRENCIES, NUMBERS } from './constants/notation';
import { matchRoutes } from 'react-router';

export const matchPath = (paths = [], location) => {
  const matches = matchRoutes(
    paths.map((path) => ({ path })),
    location
  );

  return !!matches;
};

export const mapFieldsToState = (fields = {}) => {
  return Object.fromEntries(
    Object.values(fields).map((fieldConfig) => [
      fieldConfig.NAME,
      fieldConfig.TYPE === FIELDS_TYPES.TYPE_CHECKBOX
        ? false
        : [
            FIELDS_TYPES.TYPE_MULTI_SELECT,
            FIELDS_TYPES.TYPE_SELECT,
            FIELDS_TYPES.TYPE_RADIO
          ].includes(fieldConfig.TYPE)
        ? []
        : ''
    ])
  );
};

export const generateMessage = (
  message = '',
  isValid = false,
  field = null,
  isOnSubmit = false
) => {
  return { msg: message, isValid, field, isOnSubmit };
};

export const isUsingCompanyEmail = (email = '') => {
  if (!email) {
    return false;
  }

  if (typeof email !== 'string') {
    return false;
  }

  if (!email.includes('@')) {
    return false;
  }

  const parts = email.split('@');
  const domain = parts[parts.length - 1];
  if (getPublicDomainList().includes(domain)) {
    return false;
  }

  return true;
};

export const validatePasswordComplexity = (password = '') => {
  const messages = [
    [password.length >= 8, 'Password must contain at least 8 characters'],
    [/\d/.test(password), 'Password must contain at least 1 number'],
    [/[^a-zA-Z0-9\s]/.test(password), 'Password must contain at least 1 special character'],
    [/[A-Z]/.test(password), 'Password must contain at least 1 uppercase letter'],
    [/[a-z]/.test(password), 'Password must contain at least 1 lowercase letter']
  ].map(([isValid, msg]) => generateMessage(msg, isValid));

  return messages;
};

export const formatNumber = (number = 0, spacer = PUNCTUATION.COMMA) => {
  try {
    const testNum = +number;
    if (isNaN(testNum) || !['string', 'number'].includes(typeof number) || number === '') {
      return PUNCTUATION.EMPTY_VALUE;
    }
  } catch (error) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  const formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacer);

  if (formattedNumber.endsWith('.00')) {
    return formattedNumber.slice(0, formattedNumber.length - 3);
  } else if (
    formattedNumber.endsWith('0') &&
    formattedNumber.charAt(formattedNumber.length - 3) === '.'
  ) {
    return formattedNumber.slice(0, formattedNumber.length - 1);
  }

  return formattedNumber;
};

export const formatPercent = (number = 0, spacer = PUNCTUATION.COMMA) => {
  const formattedNumber = formatNumber(number, spacer);
  if (formattedNumber === PUNCTUATION.EMPTY_VALUE) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${formattedNumber}${PUNCTUATION.PERCENT}`;
};

export const formatCurrency = (
  number = 0,
  currencyNotation = CURRENCIES.EUR,
  numberNotation = NUMBERS.MILLION
) => {
  const formattedNumber = formatNumber(number);
  if (formattedNumber === PUNCTUATION.EMPTY_VALUE) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${currencyNotation}${formattedNumber}${numberNotation}`;
};

export const formatCurrencyRange = (
  number1 = 0,
  number2 = 0,
  rangeNotation = PUNCTUATION.EN_DASH,
  currencyNotation = CURRENCIES.EUR,
  numberNotation = NUMBERS.MILLION
) => {
  const first = formatCurrency(number1, currencyNotation, numberNotation);
  const second = formatCurrency(number2, currencyNotation, numberNotation);

  if ([first, second].includes(PUNCTUATION.EMPTY_VALUE)) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${first}${rangeNotation}${second}`;
};

export const formatNumberRange = (
  number1 = 0,
  number2 = 0,
  rangeNotation = PUNCTUATION.EN_DASH
) => {
  const first = formatNumber(number1);
  const second = formatNumber(number2);

  if ([first, second].includes(PUNCTUATION.EMPTY_VALUE)) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${first}${rangeNotation}${second}`;
};

export const getFilledSearchCategoriesCount = (
  stateFields = {},
  categories = SEARCH_CATEGORIES,
  searchFields = SEARCH_FIELDS
) => {
  return Object.fromEntries(
    Object.values(categories).map((section) => {
      const values = Object.entries(stateFields)
        .filter(
          ([fieldName]) =>
            Object.values(searchFields).find((f) => f.NAME === fieldName)?.SECTION === section
        )
        .map((pair) => pair[1]);
      const filled = values.filter((v) =>
        Array.isArray(v) ? !!v.length : typeof v === 'boolean' ? v : !!v.length
      ).length;
      const total = values.length;
      return [section, { filled, total }];
    })
  );
};

export const getProfilerFilledSearchCategories = (stateFields = {}) => {
  return getFilledSearchCategoriesCount(
    stateFields,
    PROFILER_SEARCH_CATEGORIES,
    PROFILER_SEARCH_FIELDS
  );
};

export const formatDateDDMMYYYY = (date = new Date()) => {
  let d = date;
  if (!(date instanceof Date)) {
    try {
      d = new Date(date);
    } catch (error) {
      console.error(error);
      return '??/??/????';
    }
  }

  let [day, month, year] = [d.getDate(), d.getMonth() + 1, d.getFullYear()];

  if (day < 10) {
    day = '0' + day;
  }

  if (month < 10) {
    month = '0' + month;
  }

  return `${day}/${month}/${year}`;
};

export const canScrollElement = (node) => {
  if (node == null) {
    return false;
  }

  if (node.scrollHeight > node.clientHeight) {
    return true;
  }

  return false;
};

let pSBCr;
const pSBC = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    i = parseInt,
    m = Math.round,
    a = typeof c1 == 'string';
  if (
    typeof p != 'number' ||
    p < -1 ||
    p > 1 ||
    typeof c0 != 'string' ||
    (c0[0] != 'r' && c0[0] != '#') ||
    (c1 && !a)
  )
    return null;
  if (!pSBCr)
    pSBCr = (d) => {
      let n = d.length,
        x = {};
      if (n > 9) {
        ([r, g, b, a] = d = d.split(',')), (n = d.length);
        if (n < 3 || n > 4) return null;
        (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
          (x.g = i(g)),
          (x.b = i(b)),
          (x.a = a ? parseFloat(a) : -1);
      } else {
        if (n == 8 || n == 6 || n < 4) return null;
        if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
        d = i(d.slice(1), 16);
        if (n == 9 || n == 5)
          (x.r = (d >> 24) & 255),
            (x.g = (d >> 16) & 255),
            (x.b = (d >> 8) & 255),
            (x.a = m((d & 255) / 0.255) / 1000);
        else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
      }
      return x;
    };
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
    (f = pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 != 'c'
        ? pSBCr(c1)
        : P
        ? { r: 0, g: 0, b: 0, a: -1 }
        : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
  (a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h)
    return (
      'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')'
    );
  else
    return (
      '#' +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
};

export const stringToColour = (str = '') => {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return pSBC(0.42, colour, false, true);
};

export const checkBrowser = () => {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;

  // Detect Internet Explorer
  let IExplorerAgent = userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  let operaAgent = userAgentString.indexOf('OP') > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  return { safariAgent, chromeAgent, IExplorerAgent, operaAgent, firefoxAgent };
};
