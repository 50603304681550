import { useStore } from '../store/store';
import useHistory from './useHistory';
import { useEffect } from 'react';
import { paths } from '../utils/constants/routes';
import { runInAction } from 'mobx';
import { HEADER_MESSAGE_TYPES } from '../utils/constants/header';

const useAppAuthGuard = () => {
  const { authStore, utilsStore, isWaitlistActivated } = useStore();
  const { navigate } = useHistory();

  useEffect(() => {
    if (authStore.alreadyVerifiedEmail) {
      const email = authStore.alreadyVerifiedEmail;
      navigate(isWaitlistActivated ? paths.LOGIN : authStore.user ? paths.HOME_FULL : paths.HOME);

      runInAction(() => {
        authStore.alreadyVerifiedEmail = null;
        utilsStore.setHeaderMessage(
          `User with email ${email} is already registered and verified.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
      });
    }
  }, [authStore.alreadyVerifiedEmail]);
};

export default useAppAuthGuard;
