import { Link } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import { paths, routes } from '../../utils/constants/routes';
import { useStore } from '../../store/store';
import { useEffect, useRef } from 'react';
import { ACCOUNT_TYPES, ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import * as Portal from '@radix-ui/react-portal';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';
import useHistory from '../../hooks/useHistory';
import IconButton, { ICON_BUTTON_TYPES, ICON_BUTTON_ICONS } from '../buttons/IconButton';
import { matchPath } from '../../utils/utils';
import { COMPANY_PROFILE_FIELDS } from '../../utils/constants/fields';

const Header = observer(() => {
  const { authStore, utilsStore, outreachStore, projectStore, profilerProjectStore } = useStore();
  const { location, navigate } = useHistory();
  const menuContainerRef = useRef(null);
  const logoutContainerRef = useRef(null);
  const state = useLocalObservable(() => ({
    isLogoutConfirmationOpen: false,
    setIsLogoutConfirmationOpen: (value = false) => (state.isLogoutConfirmationOpen = value)
  }));

  const toggleHamburger = () => {
    utilsStore.setIsMobileHamburgerOpen(!utilsStore.isMobileHamburgerOpen);
  };

  const toggleDesktopMenu = () => {
    utilsStore.setIsDesktopMenuOpen(!utilsStore.isDesktopMenuOpen);
  };

  useEffect(() => {
    if (state.isLogoutConfirmationOpen) {
      utilsStore.lockScroll(true);
    } else {
      utilsStore.lockScroll(false);
    }
  }, [utilsStore, state.isLogoutConfirmationOpen]);

  const {
    user,
    userAccountType,
    hasUserProfileCompany,
    isCoraporatesInvestor,
    userVerificationStatus,
    logout,
    userInfo
  } = authStore;

  let pinnedMsgIcon = '';
  const pinnedNotificationClassess = ['notification'];
  if (utilsStore.headerPinnedMessage.type === HEADER_MESSAGE_TYPES.SUCCESS) {
    pinnedNotificationClassess.push('status-success');
    pinnedMsgIcon = '/icons/check-circle-msg-status.svg';
  } else if (utilsStore.headerPinnedMessage.type === HEADER_MESSAGE_TYPES.ERROR) {
    pinnedNotificationClassess.push('status-error');
    pinnedMsgIcon = '/icons/exclamation-octagon-msg-status.svg';
  } else if (utilsStore.headerPinnedMessage.type === HEADER_MESSAGE_TYPES.WARNING) {
    pinnedNotificationClassess.push('status-warning');
    pinnedMsgIcon = '/icons/exclamation-triangle-msg-status.svg';
  } else {
    pinnedNotificationClassess.push('status-info');
    pinnedMsgIcon = '/icons/info-circle-msg-status.svg';
  }

  let notifMsgIcon = '';
  const notificationClassess = ['notification'];
  if (utilsStore.headerMessage.type === HEADER_MESSAGE_TYPES.SUCCESS) {
    notificationClassess.push('status-success');
    notifMsgIcon = '/icons/check-circle-msg-status.svg';
  } else if (utilsStore.headerMessage.type === HEADER_MESSAGE_TYPES.ERROR) {
    notificationClassess.push('status-error');
    notifMsgIcon = '/icons/exclamation-octagon-msg-status.svg';
  } else if (utilsStore.headerMessage.type === HEADER_MESSAGE_TYPES.WARNING) {
    notificationClassess.push('status-warning');
    notifMsgIcon = '/icons/exclamation-triangle-msg-status.svg';
  } else {
    notificationClassess.push('status-info');
    notifMsgIcon = '/icons/info-circle-msg-status.svg';
  }

  if (utilsStore.headerMessage.isFading) {
    notificationClassess.push('fadeout');
  }

  const desktopMenuBtnClassess = ['desktop-menu-btn'];
  if (user) {
    desktopMenuBtnClassess.push('logged-in');
  } else {
    desktopMenuBtnClassess.push('logged-out');
  }
  if (utilsStore.isDesktopMenuOpen) {
    desktopMenuBtnClassess.push('opened');
  }

  const showMenu =
    userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED &&
    (userInfo?.isMasterUser ? hasUserProfileCompany : true);

  const showButtonMenu =
    userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED && hasUserProfileCompany;

  const onLogoClick = () => {
    if (utilsStore.isMobileHamburgerOpen) {
      toggleHamburger();
    }
    navigate(authStore.user ? paths.HOME_FULL : paths.HOME);
  };

  return (
    <>
      <header>
        <div className="container">
          {process.env.REACT_APP_ENVIRONMENT === 'dev' && authStore.userInfo && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 10000,
                fontSize: '8px',
                lineHeight: '12px',
                fontWeight: 600,
                color: 'black'
              }}>
              [{authStore.userAccountType}] (
              {authStore.userProfileCompany?.[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]}){' '}
              {authStore.userInfo?.firstName} {authStore.userInfo?.lastName} -{' '}
              {authStore.userInfo?.jobPosition || 'unknown'} | {authStore?.userInfo?.id}
            </div>
          )}
          <div className="logo" onClick={onLogoClick}>
            {/* <img className="only-desktop" src="/images/header-logo-desktop.svg" alt="logo" /> */}
            <img className="only-desktop" src="/images/header-logo-desktop-new.svg" alt="logo" />
            {/* <img className="only-mobile" src="/images/header-logo-mobile.svg" alt="logo" /> */}
            <img className="only-mobile" src="/images/header-logo-desktop-new.svg" alt="logo" />
          </div>
          {!!utilsStore.headerMessage.message && (
            <div className={notificationClassess.join(' ')}>
              <img src={notifMsgIcon} className="msg-icon" />
              <div>{utilsStore.headerMessage.message}</div>
            </div>
          )}
          {!!utilsStore.headerPinnedMessage.message && (
            <div className={pinnedNotificationClassess.join(' ')}>
              {/* <div className="msg-icon" /> */}
              <img src={pinnedMsgIcon} className="msg-icon" />
              <div>{utilsStore.headerPinnedMessage.message}</div>
            </div>
          )}
          {/* just keep an empty div replacing profile menu to keep flex layout */}
          {!user && <div className="empty"></div>}
          {!!user && (
            <div className="menu-flex-container">
              {showButtonMenu && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.HOME}
                    onClick={() => navigate(paths.HOME_FULL)}
                    outerText="Home"
                    filled={matchPath([paths.HOME_FULL], location)}
                  />
                </div>
              )}
              {showButtonMenu && userAccountType === ACCOUNT_TYPES.INVESTOR && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.SEARCH}
                    onClick={() => navigate(paths.PROFILER)}
                    outerText="Profiler"
                    filled={matchPath([paths.PROFILER], location)}
                    disabled={!authStore?.userInfo?.isCognitoProfiler}
                    tooltipText={
                      !authStore?.userInfo?.isCognitoProfiler
                        ? 'Please contact us for further information: info@transact.digital'
                        : ''
                    }
                  />
                </div>
              )}
              {showButtonMenu && userAccountType === ACCOUNT_TYPES.INVESTOR && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                    onClick={() => navigate(paths.PROFILER_PROJECTS)}
                    outerText="Projects"
                    filled={matchPath([paths.PROFILER_PROJECTS], location)}
                    disabled={!authStore?.userInfo?.isCognitoProfiler}
                    tooltipText={
                      !authStore?.userInfo?.isCognitoProfiler
                        ? 'Please contact us for further information: info@transact.digital'
                        : ''
                    }
                    notificationDot={
                      !matchPath([paths.PROFILER_PROJECTS], location) &&
                      profilerProjectStore.hasNewProjectFeedback
                    }
                    errorNotificationDot={
                      !matchPath([paths.PROFILER_PROJECTS], location) &&
                      !profilerProjectStore.allProjects.length &&
                      !!profilerProjectStore.loadProjectsError
                    }
                  />
                </div>
              )}
              {showButtonMenu && userAccountType === ACCOUNT_TYPES.INVESTOR && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.WATCHLIST}
                    onClick={() => navigate(paths.PROFILER_WATCH_LIST)}
                    outerText="Watchlist"
                    filled={matchPath([paths.PROFILER_WATCH_LIST], location)}
                    disabled={!authStore?.userInfo?.isCognitoProfiler}
                    tooltipText={
                      !authStore?.userInfo?.isCognitoProfiler
                        ? 'Please contact us for further information: info@transact.digital'
                        : ''
                    }
                    errorNotificationDot={
                      !matchPath([paths.PROFILER_WATCH_LIST], location) &&
                      !profilerProjectStore.watchlist.length &&
                      !!profilerProjectStore.loadWatchlistError
                    }
                  />
                </div>
              )}
              {showButtonMenu && userAccountType === ACCOUNT_TYPES.INVESTOR && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.SHARE}
                    onClick={() => navigate(paths.PROFILER_TEAM)}
                    outerText="Team"
                    filled={matchPath([paths.PROFILER_TEAM], location)}
                    disabled={!authStore?.userInfo?.isCognitoProfiler}
                    tooltipText={
                      !authStore?.userInfo?.isCognitoProfiler
                        ? 'Please contact us for further information: info@transact.digital'
                        : ''
                    }
                    notificationDot={
                      !matchPath([paths.PROFILER_TEAM], location) &&
                      profilerProjectStore.pendingSharedProjects.length > 1
                    }
                    errorNotificationDot={
                      !matchPath([paths.PROFILER_TEAM], location) &&
                      !profilerProjectStore.allProjects.length &&
                      !!profilerProjectStore.loadProjectsError
                    }
                  />
                </div>
              )}
              {showButtonMenu && userAccountType === ACCOUNT_TYPES.AGENT && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                    onClick={() => navigate(paths.PROJECTS)}
                    outerText="Projects"
                    filled={matchPath([paths.PROJECTS], location)}
                    notificationDot={
                      !matchPath([paths.PROJECTS], location) && projectStore.hasNewProjectFeedback
                    }
                    errorNotificationDot={
                      !matchPath([paths.PROJECTS], location) &&
                      !projectStore.allProjects.length &&
                      !!projectStore.loadProjectsError
                    }
                  />
                </div>
              )}
              {showButtonMenu && userAccountType === ACCOUNT_TYPES.AGENT && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.SHARE}
                    onClick={() => navigate(paths.TEAM)}
                    outerText="Team"
                    filled={matchPath([paths.TEAM], location)}
                    notificationDot={
                      !matchPath([paths.TEAM], location) &&
                      projectStore.pendingSharedProjects.length > 0
                    }
                    errorNotificationDot={
                      !matchPath([paths.TEAM], location) &&
                      !projectStore.allProjects.length &&
                      !!projectStore.loadProjectsError
                    }
                  />
                </div>
              )}
              {showButtonMenu && (
                <div className="header-menu-button-wrap">
                  <IconButton
                    type={ICON_BUTTON_TYPES.DEFAULT}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.CHAT_SQUARE_TEXT}
                    onClick={() => navigate(paths.OUTREACH)}
                    outerText="Outreach"
                    filled={matchPath([paths.OUTREACH], location)}
                    notificationDot={
                      !matchPath([paths.OUTREACH], location) &&
                      outreachStore.totalOutreachMessagesCount > 0
                    }
                    errorNotificationDot={
                      !matchPath([paths.OUTREACH], location) &&
                      !outreachStore.outreaches.length &&
                      !!outreachStore.loadOutreachesError
                    }
                  />
                </div>
              )}
              <div id="desktop-menu-button" className={desktopMenuBtnClassess.join(' ')}>
                <div className="desktop-menu-closer" onClick={toggleDesktopMenu} />
                <div className="header-menu-button-wrap">
                  <IconButton
                    id="desktop-menu-icon-button"
                    type={
                      matchPath(
                        [
                          routes.USER_PROFILE_PATH,
                          paths.COMPANY_PROFILE,
                          routes.INVEST_PROFILES_PATH,
                          routes.FUNDS_PATH,
                          routes.PORTFOLIO_COMPANIES_PATH,
                          paths.ALL_INVESTMENTS,
                          paths.ADMIN_DASHBOARD
                        ],
                        location
                      )
                        ? ICON_BUTTON_TYPES.BLUE
                        : ICON_BUTTON_TYPES.DEFAULT
                    }
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.PERSON}
                    onClick={toggleDesktopMenu}
                    outerText="Account"
                    filled={utilsStore.isDesktopMenuOpen}
                  />
                </div>
                <div className="menu-container" ref={menuContainerRef}>
                  <div className="links">
                    {showMenu && (
                      <>
                        <Link
                          to={paths.USER_PROFILE}
                          title="My account"
                          onClick={toggleDesktopMenu}
                          className={
                            matchPath([routes.USER_PROFILE_PATH], location) ? 'selected' : ''
                          }>
                          My account
                        </Link>
                        <Link
                          to={paths.COMPANY_PROFILE}
                          title="My company"
                          onClick={toggleDesktopMenu}
                          className={
                            matchPath([paths.COMPANY_PROFILE], location) ? 'selected' : ''
                          }>
                          My company
                        </Link>
                        {userAccountType === ACCOUNT_TYPES.INVESTOR && isCoraporatesInvestor && (
                          <Link
                            to={paths.INVEST_PROFILES}
                            title="My invest profiles"
                            onClick={toggleDesktopMenu}
                            className={
                              matchPath([routes.INVEST_PROFILES_PATH], location) ? 'selected' : ''
                            }>
                            My invest profiles
                          </Link>
                        )}
                        {userAccountType === ACCOUNT_TYPES.INVESTOR && !isCoraporatesInvestor && (
                          <Link
                            to={paths.FUNDS}
                            title="My funds & portfolio"
                            onClick={toggleDesktopMenu}
                            className={
                              matchPath(
                                [routes.FUNDS_PATH, routes.PORTFOLIO_COMPANIES_PATH],
                                location
                              )
                                ? 'selected'
                                : ''
                            }>
                            My funds & portfolio
                          </Link>
                        )}
                        {userAccountType === ACCOUNT_TYPES.INVESTOR && userInfo?.isMasterUser && (
                          <Link
                            to={paths.ALL_INVESTMENTS}
                            title="View all investments"
                            onClick={toggleDesktopMenu}
                            className={
                              matchPath([paths.ALL_INVESTMENTS], location) ? 'selected' : ''
                            }>
                            View all investments
                          </Link>
                        )}
                        {userInfo?.isCognitoAdmin && (
                          <Link
                            to={paths.ADMIN_DASHBOARD}
                            title="Admin dashboard"
                            onClick={toggleDesktopMenu}
                            className={
                              matchPath([paths.ADMIN_DASHBOARD], location) ? 'selected' : ''
                            }>
                            Admin dashboard
                          </Link>
                        )}
                      </>
                    )}
                    <Link
                      to="#"
                      onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen(true)}
                      id="logout-menu-button">
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={`mobile-menu ${utilsStore.isMobileHamburgerOpen ? 'opened' : ''}`}
            onClick={toggleHamburger}></div>
        </div>
      </header>
      {state.isLogoutConfirmationOpen && (
        <Portal.Root>
          <div className="transparent-overlay">
            <div className="container" ref={logoutContainerRef}>
              <div className="title">Do you really want to log out?</div>
              <div className="actions">
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen()}>
                  No, stay logged in
                </Link>
                <a
                  className="btn btn-outline"
                  onClick={() =>
                    state.setIsLogoutConfirmationOpen() & toggleDesktopMenu() & logout()
                  }>
                  Yes, log me out
                </a>
              </div>
            </div>
          </div>
        </Portal.Root>
      )}
    </>
  );
});

export default Header;
