import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import GeneralError from '../../components/errors/GeneralError';
import GeneralLoader from '../../components/loaders/GeneralLoader';

const ProfilerWatchList = observer(() => {
  const { profilerProjectStore } = useStore();
  const showError =
    profilerProjectStore.loadWatchlistError && !profilerProjectStore.watchlist.length;

  if (showError) {
    return (
      <StaticPageLayout page="profiler-watch-list">
        {showError && (
          <GeneralError
            errorMessage={profilerProjectStore.loadWatchlistError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={profilerProjectStore.loadWatchlist}
            withLogoutButton
          />
        )}
      </StaticPageLayout>
    );
  }

  if (
    profilerProjectStore.initialLoadWatchlist ||
    (profilerProjectStore.isLoadingWatchlist && !profilerProjectStore.watchlist.length)
  ) {
    return (
      <StaticPageLayout page="profiler-watch-list">
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  return <StaticPageLayout page="profiler-watch-list"></StaticPageLayout>;
});

export default ProfilerWatchList;
