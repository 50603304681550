import PropTypes from 'prop-types';

const SplashScreen = ({ onHide }) => (
  <div className="overlay">
    <div className="container">
      <div className="header icon-text">
        <img src="/icons/hand.svg" alt="hand" />
        <div className="text">Getting started: setup your company profile</div>
      </div>
      <div className="body">
        <p>
          Please take 2 minutes to complete your company profile. Upon completion you will
          automatically receive access to <strong>transact</strong>.digital.
        </p>
        <p>The bar below will indicate the progress of your company profile creation.</p>
        <div className="demo">
          <div className="progress"></div>
          <div className="icon-text">
            <img src="/icons/check-circle-blue.svg" alt="complete" />
            <div className="text">Company profile complete</div>
          </div>
        </div>
      </div>
      <div className="footer">
        <button className="btn btn-primary" onClick={onHide}>
          Start now
        </button>
      </div>
    </div>
  </div>
);

SplashScreen.propTypes = {
  onHide: PropTypes.func.isRequired
};

export default SplashScreen;
