export const isInDevelopment =
  process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'stg';

export const GA_CONFIG = {
  gaMeasurementId: '',
  gtmContainerId: 'GTM-T8RBCTTX'
};

export const GA_EVENTS = {
  // 1. Waitlist Events
  Waitlist_form_step_1: 'Waitlist_form_step_1',
  Waitlist_form_step_2: 'Waitlist_form_step_2',
  // 6.1 Signup form
  sign_up_step_1: 'sign_up_step_1',
  sign_up_step_2: 'sign_up_step_2',
  // 2.1 Login
  login: 'login',
  // 2.2 Start Page pageview
  pageview_start_page: 'pageview_start_page',
  // 3. Fund Creation
  pageview_fund_create: 'pageview_fund_create',
  fund_create_Information_complete: 'fund_create_Information_complete',
  fund_create_GeoIndustries_complete: 'fund_create_GeoIndustries_complete',
  fund_create_financials_complete: 'fund_create_financials_complete',
  fund_create_transaction_complete: 'fund_create_transaction_complete',
  fund_create_valuation_complete: 'fund_create_valuation_complete',
  fund_create_complete: 'fund_create_complete',
  // 4. Portfolio Company Form
  pageview_portfolio_co_create: 'pageview_portfolio_co_create',
  portfolio_co_information_complete: 'portfolio_co_information_complete',
  portfolio_co_geoindustries_complete: 'portfolio_co_geoindustries_complete',
  portfolio_co_financials_complete: 'portfolio_co_financials_complete',
  portfolio_co_transaction_complete: 'portfolio_co_transaction_complete',
  portfolio_co_valuation_complete: 'portfolio_co_valuation_complete',
  portfolio_co_create_complete: 'portfolio_co_create_complete',
  // company profile creation
  company_create_page_view: 'Getting Started',
  complete_company_profile: 'complete_company_profile',
  // 5.1 Search download
  download_search: 'download_search',
  // 5.2 Outreach Company/Fund
  outreach_search: 'outreach_search',
  // 6.1 Signup form
  //
  //
  pageview_invest_profile_create: 'pageview_invest_profile_create'
};

export const GA_DIMENSIONS = {
  testMode: 'testMode',
  debug_mode: 'debug_mode',
  debug_event: 'debug_event',
  traffic_type: 'traffic_type',
  ecommerce: 'ecommerce',
  method: 'method',
  user_id: 'user_id',
  cType: 'cType',
  funds: 'funds',
  portfolio_companies: 'portfolio_companies',
  fund_name: 'fund_name',
  Fund_vintage: 'Fund_vintage',
  Fund_Volume: 'Fund_Volume',
  Geographies: 'Geographies',
  Industries: 'Industries',
  Revenue_Range_min: 'Revenue_Range_min',
  Revenue_Range_max: 'Revenue_Range_max',
  EBITDA_min: 'EBITDA_min',
  EBITDA_max: 'EBITDA_max',
  EBITDA_margin: 'EBITDA_margin',
  EBIT_min: 'EBIT_min',
  EBIT_max: 'EBIT_max',
  EBIT_margin: 'EBIT_margin',
  Situation: 'Situation',
  Equity_Stake: 'Equity_Stake',
  Enterprise_VR_min: 'Enterprise_VR_min',
  Enterprise_VR_max: 'Enterprise_VR_max',
  Equity_VR_min: 'Equity_VR_min',
  Equity_VR_max: 'Equity_VR_max',
  portfolio_co_name: 'portfolio_co_name',
  portfolio_company_revenue: 'portfolio_company_revenue',
  portfolio_company_EBITDA: 'portfolio_company_EBITDA',
  portfolio_company_description: 'portfolio_company_description',
  geographies: 'geographies',
  industries: 'industries',
  revenue_range_min: 'revenue_range_min',
  revenue_range_max: 'revenue_range_max',
  EBITDA_range_min: 'EBITDA_range_min',
  EBITDA_range_max: 'EBITDA_range_max',
  EBIT_range_min: 'EBIT_range_min',
  EBIT_range_max: 'EBIT_range_max',
  situation: 'situation',
  equity_stake: 'equity_stake',
  enterprise_value_range_min: 'enterprise_value_range_min',
  enterprise_value_range_max: 'enterprise_value_range_max',
  equity_value_range_min: 'equity_value_range_min',
  equity_value_range_max: 'equity_value_range_max',
  company_type: 'company_type',
  Country: 'Country',
  Tagline: 'Tagline'
};
