import { useEffect } from 'react';
import { useStore } from '../store/store';
import { eventLogin } from '../ga4/ga4';

const useAppAnalytics = () => {
  const {
    authStore: { user }
  } = useStore();
  useEffect(() => {
    if (user) {
      eventLogin(user?.idToken?.payload?.['cognito:username']);
    }
  }, [user]);
};

export default useAppAnalytics;
