import { observer, useLocalObservable } from 'mobx-react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
// import Faq from './agent/Faq';
// import ReviewForm from './agent/ReviewForm';
import OnboardingSteps from './agent/OnboardingSteps';
import SearchFormDesktop from './agent/SearchFormDesktop';
import SearchFormMobile from './agent/SearchFormMobile';
import SearchFormMobileSection from './agent/SearchFormMobileSection';
import { SEARCH_FIELDS, getErrorFields, trimStateFields } from '../../../utils/constants/fields';
import { getFilledSearchCategoriesCount, mapFieldsToState } from '../../../utils/utils';
import { paths } from '../../../utils/constants/routes';
import { getAgentSearchFields } from './agent/constants/agentSearchFields';
import useHistory from '../../../hooks/useHistory';
import { useStore } from '../../../store/store';
import { SEARCH_CATEGORIES } from '../../../utils/constants/searchCategories';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';

const AgentView = observer(() => {
  const { utilsStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isMobileFiltersDisplayed: false,
    toggleMobileFilters: () => {
      state.isMobileFiltersDisplayed = !state.isMobileFiltersDisplayed;
    },
    mobileFilterSection: null,
    setMobileFilterSection: (value) => {
      state.mobileFilterSection = value;
      state.isMobileFiltersDisplayed = true;
    },
    fields: {
      ...mapFieldsToState(SEARCH_FIELDS),
      [SEARCH_FIELDS.SEARCH_TYPE.NAME]: [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds]
    },
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    get fieldsCount() {
      return getFilledSearchCategoriesCount(state.fields);
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(SEARCH_FIELDS), state.fields);
    },
    get isSearchDisabled() {
      return (
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    }
  }));

  const onSearch = (e) => {
    e?.preventDefault?.();

    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    const fields = Object.entries(state.fields)
      .filter((field) => field[1].length)
      .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
      .join('&');

    navigate(paths.SEARCH_RESULTS + `?page=1&${fields}`);
  };

  const isTypeSelected = !!state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0];
  const fields = getAgentSearchFields(state, isTypeSelected);
  let erroredSections = Object.fromEntries(
    Object.values(SEARCH_CATEGORIES).map((categoryName) => [categoryName, false])
  );
  if (state.onSubmitErrorState) {
    erroredSections = Object.fromEntries(
      Object.values(SEARCH_CATEGORIES).map((categoryName) => {
        const sectionFieldNames = Object.values(SEARCH_FIELDS)
          .filter((fieldConfig) => fieldConfig.SECTION === categoryName)
          .map((fieldConfig) => fieldConfig.NAME);
        const errored = state.validationFields.invalidFields.some(({ name }) =>
          sectionFieldNames.includes(name)
        );
        return [categoryName, errored];
      })
    );
  }

  return (
    <StaticPageLayout
      page="search-form"
      component={state.isMobileFiltersDisplayed ? 'mobile-filter' : 'general'}>
      {state.isMobileFiltersDisplayed ? (
        <SearchFormMobileSection
          section={state.mobileFilterSection}
          onBack={() => state.toggleMobileFilters() & utilsStore.lockScroll(false)}
          fields={fields}
        />
      ) : (
        <div className="search-container">
          <h1>Create your list of potential buyers</h1>
          <div className="search-fields-container">
            <SearchFormDesktop
              fields={fields}
              isSearchDisabled={state.isSearchDisabled}
              onSearch={onSearch}
            />
            <SearchFormMobile
              onShowSection={(section) => state.setMobileFilterSection(section)}
              isTypeSelected={isTypeSelected}
              fieldsCount={state.fieldsCount}
              isSearchDisabled={state.isSearchDisabled}
              onSearch={onSearch}
              fields={fields}
              erroredSections={erroredSections}
            />
          </div>
          <div className="onboarding-container">
            <OnboardingSteps />
          </div>
          {/* Coming soon
      <div className="review-container">
        <ReviewForm />
      </div> */}
          {/* Coming soon
      <div className="faq-container">
        <Faq />
      </div> */}
        </div>
      )}
    </StaticPageLayout>
  );
});

export default AgentView;
