import { observer } from 'mobx-react';
import { PORTFOLIO_FIELDS } from '../../../../utils/constants/fields';
import { formatNumberRange, formatPercent } from '../../../../utils/utils';

const PortfolioInformation = observer(({ entry }) => {
  return (
    <div className="content">
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME],
            entry[PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.NAME],
            entry[PORTFOLIO_FIELDS.EBITDA_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.EBITDA_MARGIN.LABEL}</div>
        <div className="value">{formatPercent(entry[PORTFOLIO_FIELDS.EBITDA_MARGIN.NAME])}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
            entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME],
            entry[PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]
          )}
        </div>
      </div>
    </div>
  );
});

export default PortfolioInformation;
