import { observer, useLocalObservable } from 'mobx-react';
import {
  ADMIN_DASHBOARD_USER_INFO_TABS,
  ADMIN_DASHBOARD_USER_GROUPS_ARRAY_ALL
} from '../constants';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from '../../../store/store';
import { OPTION_KEYS } from '../../../utils/constants/optionKeys';

const UserInfo = observer(({ user = {}, onClose = () => {} }) => {
  const { utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    tab: ADMIN_DASHBOARD_USER_INFO_TABS.USER_INFORMATION,
    setTab: (tab = '') => {
      if (state.tab !== tab) {
        state.tab = tab;
      }

      const tabDOM = document.getElementById('admin_dashboard_user_info_tab_' + tab);
      if (tabDOM) {
        setTimeout(() => {
          tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }, 0);
      }
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    }
  }));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const tabs = [
    {
      id: 'admin_dashboard_user_info_tab_' + ADMIN_DASHBOARD_USER_INFO_TABS.USER_INFORMATION,
      name: 'User information',
      isActive: state.tab === ADMIN_DASHBOARD_USER_INFO_TABS.USER_INFORMATION,
      onClick: () => state.setTab(ADMIN_DASHBOARD_USER_INFO_TABS.USER_INFORMATION)
    },
    {
      id: 'admin_dashboard_user_info_tab_' + ADMIN_DASHBOARD_USER_INFO_TABS.USER_ATTRIBUTES,
      name: 'User attributes',
      isActive: state.tab === ADMIN_DASHBOARD_USER_INFO_TABS.USER_ATTRIBUTES,
      onClick: () => state.setTab(ADMIN_DASHBOARD_USER_INFO_TABS.USER_ATTRIBUTES)
    },
    {
      id: 'admin_dashboard_user_info_tab_' + ADMIN_DASHBOARD_USER_INFO_TABS.USER_GROUP_MEMBERSHIP,
      name: `Group membership (${user.cognitoGroups.length})`,
      isActive: state.tab === ADMIN_DASHBOARD_USER_INFO_TABS.USER_GROUP_MEMBERSHIP,
      onClick: () => state.setTab(ADMIN_DASHBOARD_USER_INFO_TABS.USER_GROUP_MEMBERSHIP)
    }
  ];

  return (
    <div className="user-info">
      <Link
        className="backlink"
        onClick={(e) => {
          e?.preventDefault?.();
          onClose();
        }}>
        Back to users
      </Link>
      <div className="tabs-scrollable-wrap">
        <div className="tabs-wrap">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.id}
                id={tab.id}
                className={`noselect tab ${tab.isActive ? 'active' : ''}`}
                onClick={tab.onClick}>
                {tab.name}
              </div>
            );
          })}
        </div>
      </div>
      {state.tab === ADMIN_DASHBOARD_USER_INFO_TABS.USER_INFORMATION && (
        <div className="user-info-tab-content">
          <div className="section-one">
            <div className="field-wrap">
              <div className="field-name">Name</div>
              <div className="field-value text">
                {user.firstName} {user.lastName}
              </div>
            </div>
            <div className="field-wrap">
              <div className="field-name">User ID</div>
              <div className="field-value text">{user.id}</div>
            </div>
            <div className="field-wrap">
              <div className="field-name">Email</div>
              <div className="field-value text">{user.email}</div>
            </div>
          </div>
          <div className="section-two">
            <div className="field-wrap">
              <div className="field-name">Confirmation status</div>
              <div className="field-value with-chipp">
                {user.isCognitoVerified ? (
                  <div className="chipp green">Confirmed</div>
                ) : (
                  <div className="chipp red">Unconfirmed</div>
                )}
              </div>
            </div>
            <div className="field-wrap">
              <div className="field-name">Created time</div>
              <div className="field-value text">{new Date(user.createdAt).toLocaleString()}</div>
            </div>
            <div className="field-wrap">
              <div className="field-name">Last update time</div>
              <div className="field-value text">
                {user.updatedAt ? new Date(user.updatedAt).toLocaleString() : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      )}
      {state.tab === ADMIN_DASHBOARD_USER_INFO_TABS.USER_ATTRIBUTES && (
        <div className="user-attributes-tab-content">
          {state.layout === 'mobile' && (
            <>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">custom:company_name</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">{user.companyProfileName}</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Optional</div>
                </div>
              </div>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">ustom:company_type</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">
                    {user.companyType
                      ? utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES]?.find?.(
                          (c) => c.value === user.companyType
                        )?.name || user.companyType
                      : 'N/A'}
                  </div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Optional</div>
                </div>
              </div>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">email</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">{user.email}</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Required</div>
                </div>
              </div>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">family_name</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">{user.lastName}</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Optional</div>
                </div>
              </div>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">given_name</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">{user.firstName}</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Optional</div>
                </div>
              </div>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">name</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">
                    {user.firstName} {user.lastName}
                  </div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Required</div>
                </div>
              </div>
              <div className="attribute-item-mobile">
                <div className="row">
                  <div className="attribute">Attribute name</div>
                  <div className="attribute value">sub</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Value</div>
                  <div className="attribute value">{user.id}</div>
                </div>
                <div className="row">
                  <div className="attribute bold">Type</div>
                  <div className="attribute value">Required</div>
                </div>
              </div>
            </>
          )}
          {state.layout === 'desktop' && (
            <>
              <div className="attributes-table-heading-wrap-desktop">
                <div className="title">Attribute name</div>
                <div className="title">Value</div>
                <div className="title">Type</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">custom:company_name</div>
                <div className="text">{user.companyProfileName}</div>
                <div className="text">Optional</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">custom:company_type</div>
                <div className="text">
                  {user.companyType
                    ? utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES]?.find?.(
                        (c) => c.value === user.companyType
                      )?.name || user.companyType
                    : 'N/A'}
                </div>
                <div className="text">Optional</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">email</div>
                <div className="text">{user.email}</div>
                <div className="text">Required</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">family_name</div>
                <div className="text">{user.lastName}</div>
                <div className="text">Optional</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">given_name</div>
                <div className="text">{user.firstName}</div>
                <div className="text">Optional</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">name</div>
                <div className="text">
                  {user.firstName} {user.lastName}
                </div>
                <div className="text">Required</div>
              </div>
              <div className="attribute-item-desktop">
                <div className="text">sub</div>
                <div className="text">{user.id}</div>
                <div className="text">Required</div>
              </div>
            </>
          )}
        </div>
      )}
      {state.tab === ADMIN_DASHBOARD_USER_INFO_TABS.USER_GROUP_MEMBERSHIP && (
        <div className="user-groups-tab-content">
          {!user.cognitoGroups.length && (
            <div className="no-results" style={{ marginTop: '14px' }}>
              No groups assigned.
            </div>
          )}
          {state.layout === 'mobile' && !!user.cognitoGroups.length && (
            <>
              {user.cognitoGroups.map((groupName) => {
                const group = ADMIN_DASHBOARD_USER_GROUPS_ARRAY_ALL.find(
                  (g) => g.name === groupName
                );
                return (
                  <div key={groupName} className="group-item-mobile">
                    <div className="row">
                      <div className="attribute">Group name</div>
                      <div className="attribute value">{group.name}</div>
                    </div>
                    <div className="row">
                      <div className="attribute bold">Description</div>
                      <div className="attribute value">{group.description}</div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {state.layout === 'desktop' && !!user.cognitoGroups.length && (
            <>
              <div className="group-table-heading-wrap-desktop">
                <div className="title">Group name</div>
                <div className="title long">Description</div>
              </div>
              {user.cognitoGroups.map((groupName) => {
                const group = ADMIN_DASHBOARD_USER_GROUPS_ARRAY_ALL.find(
                  (g) => g.name === groupName
                );
                return (
                  <div key={groupName} className="group-item-desktop">
                    <div className="text">{group.name}</div>
                    <div className="text long">{group.description}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
});

export default UserInfo;
