import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import SharedProjectEntry from './SharedProjectEntry';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import { useStore } from '../../../store/store';

const SharedProjectsList = observer(({ projectList, params }) => {
  const { utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    }
  }));

  if (state.layout === 'mobile') {
    if (!projectList.length) {
      return <div className="no-requests-text-mobile">No requests of this type</div>;
    } else {
      return projectList.map((project) => (
        <SharedProjectEntry key={project.id} project={project} params={params} />
      ));
    }
  }

  return (
    <div className="table-responsive">
      {projectList.length === 0 ? (
        <div className="no-data">No requests of this type</div>
      ) : (
        <>
          <div className="header">
            <div className="item">Sender name</div>
            <div className="item">Project name</div>
            <div className="item">
              {params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
                ? 'Date received'
                : 'Date resolved'}
            </div>
            <div className="item">Results</div>
            <div className="item">Search type</div>
          </div>
          {projectList.map((project) => (
            <SharedProjectEntry key={project.id} project={project} params={params} />
          ))}
        </>
      )}
    </div>
  );
});

export default SharedProjectsList;
