import { Link } from 'react-router-dom';
import { paths } from '../../../../utils/constants/routes';

const useMobileSettingsTab = () => {
  const view = (
    <div className="mobile-settings-container" key="tab-mobile-settings">
      <div className="item">
        <Link to={paths.USER_PROFILE_SETTINGS_CHANGE_PASSWORD}>Change password</Link>
      </div>
      <div className="item">
        <Link to={paths.USER_PROFILE_SETTINGS_DEACTIVATE_ACCOUNT}>Deactivate account</Link>
      </div>
    </div>
  );
  return { view, isActionsHidden: true };
};

export default useMobileSettingsTab;
