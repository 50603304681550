import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import {
  encodeProjectsParams,
  SELECTED_PROJECT_TABS,
  selectedProjectTabPageSize
} from '../../Projects/constants';
import useHistory from '../../../hooks/useHistory';
import {
  getErrorFields,
  PROVIDE_FEEDBACK_FIELDS,
  SEARCH_FIELDS
} from '../../../utils/constants/fields';
import { SEARCH_CATEGORIES } from '../../../utils/constants/searchCategories';
import {
  formatNumber,
  formatNumberRange,
  formatPercent,
  mapFieldsToState
} from '../../../utils/utils';
import { useStore } from '../../../store/store';
import GeneralError from '../../../components/errors/GeneralError';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import FeedbackSearchResultsTabView from './FeedbackSearchResultsTabView';
import { API_ENDPOINTS } from '../../../api/endpoints';
import TextArea from '../../../components/inputs/TextArea';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import { runInAction } from 'mobx';

const FeedbackRequestView = observer(({ project, params }) => {
  const store = useStore();
  const { utilsStore, authStore, projectStore } = store;
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isRendered: true,
    unrender: () => (state.isRendered = false),
    isFieldsSectionCollapsed: true,
    toggleFieldsSection: () => {
      state.isFieldsSectionCollapsed = !state.isFieldsSectionCollapsed;
      if (!state.isFieldsSectionCollapsed) {
        setTimeout(() => {
          if (!state.isFieldsSectionCollapsed) {
            const mandatoryContainerDOM = document.getElementById('frv-mandatory-container');
            if (mandatoryContainerDOM) {
              window.scrollTo({
                top: mandatoryContainerDOM.offsetTop - (state.layout === 'mobile' ? 52 : 80),
                behavior: 'smooth'
              });
            }
          }
        }, 10);
      }
    },
    isLoading: false,
    loadError: false,
    results: [],
    feedbacks: [],
    loadResults: (projectId = null) => {
      if (state.isLoading || !projectId) {
        return;
      }

      state.loadError = false;
      state.isLoading = true;
      store.makeRequest({
        endpoint: API_ENDPOINTS.GET_PROJECT_RESULTS,
        body: { projectId },
        onSuccess: (response) => {
          if (!state.isRendered) {
            return;
          }
          state.results = response.results;
        },
        onError: (errorMessage) => {
          if (!state.isRendered) {
            return;
          }

          state.loadError = errorMessage || 'Failed to obtain project results.';
        },
        onFinally: () => {
          if (!state.isRendered) {
            return;
          }

          state.isLoading = false;
        }
      });
    },
    fields: mapFieldsToState(PROVIDE_FEEDBACK_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    get validationFields() {
      return getErrorFields(Object.values(PROVIDE_FEEDBACK_FIELDS), state.fields);
    },
    onChangeFeedback: (resultId, feedback) => {
      const index = state.feedbacks.findIndex((fb) => fb.resultId === resultId);
      if (index !== -1) {
        const updatedFeedbacks = [...state.feedbacks];
        updatedFeedbacks[index] = { ...updatedFeedbacks[index], feedback };
        state.feedbacks = updatedFeedbacks;
      } else {
        state.feedbacks = [...state.feedbacks, { resultId, feedback }];
      }
    },
    get layout() {
      return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
    }
  }));

  useEffect(() => {
    return () => state.unrender();
  }, [state]);

  useEffect(() => {
    state.loadResults(project.id);
  }, [project]);

  useEffect(() => {
    runInAction(() => {
      state.feedbacks = project.feedbacks.filter(
        (feedback) => feedback.userId === authStore.currentUserId
      );
    });
  }, [project]);

  useEffect(() => {
    if (state.isLoading) {
      return;
    }

    let shouldNavigate = false;
    const newParams = {};

    if (!Object.values(SELECTED_PROJECT_TABS).includes(params.selectedProjectTab)) {
      newParams.selectedProjectTab = SELECTED_PROJECT_TABS.RESULTS;
      shouldNavigate = true;
    }

    const list =
      params.selectedProjectTab === SELECTED_PROJECT_TABS.RESULTS
        ? state.results.filter((r) => !r.isExcluded)
        : params.selectedProjectTab === SELECTED_PROJECT_TABS.EXCLUDED
        ? state.results.filter((r) => r.isExcluded)
        : project.teamComments;
    let listPages = Math.ceil(list.length / selectedProjectTabPageSize);
    if (listPages === 0) {
      listPages = 1;
    }

    if (
      isNaN(params.selectedProjectTabPage) ||
      params.selectedProjectTabPage > listPages ||
      params.selectedProjectTabPage < 1
    ) {
      if (isNaN(params.selectedProjectTabPage) || params.selectedProjectTabPage < 1) {
        newParams.selectedProjectTabPage = 1;
      } else if (params.selectedProjectTabPage > listPages) {
        newParams.selectedProjectTabPage = listPages;
      }
      shouldNavigate = true;
    }

    if (shouldNavigate) {
      navigate(paths.TEAM + '?params=' + encodeProjectsParams({ ...params, ...newParams }));
    }
  }, [params, state.results, state.results.filter((r) => r.isExcluded).length]);

  const onBackClick = (ev) => {
    ev?.preventDefault?.();
    ev?.nativeEvent?.preventDefault?.();
    ev?.nativeEvent?.stopPropagation?.();
    const newParams = { ...params };
    newParams.selectedProjectId = null;
    newParams.selectedProjectTab = null;
    newParams.selectedProjectTabPage = null;
    navigate(paths.TEAM + '?params=' + encodeProjectsParams(newParams));
  };

  const scrollOnAction = () => {
    setTimeout(() => {
      const el = document.getElementById('team-project-view-tab-1');
      const header = document.querySelector('header');
      if (el) {
        const headerHeight = header?.clientHeight || 0;
        window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  const selectedProjectTabs = [
    {
      name: 'Search results (' + state.results.filter((r) => !r.isExcluded).length + ')',
      onClick: () => {
        navigate(
          paths.TEAM +
            '?params=' +
            encodeProjectsParams({
              ...params,
              selectedProjectTab: SELECTED_PROJECT_TABS.RESULTS,
              selectedProjectTabPage: 1
            })
        );
        scrollOnAction();
      },
      isActive: params.selectedProjectTab === SELECTED_PROJECT_TABS.RESULTS
    },
    {
      name: 'Excluded results (' + state.results.filter((r) => r.isExcluded).length + ')',
      onClick: () => {
        navigate(
          paths.TEAM +
            '?params=' +
            encodeProjectsParams({
              ...params,
              selectedProjectTab: SELECTED_PROJECT_TABS.EXCLUDED,
              selectedProjectTabPage: 1
            })
        );
        scrollOnAction();
      },
      isActive: params.selectedProjectTab === SELECTED_PROJECT_TABS.EXCLUDED
    }
  ];
  const foundSharedUserInfo = useMemo(() => {
    return project.sharedUsers.find((user) => user.id === authStore.currentUserId);
  }, [project.id, authStore.currentUserId]);

  const onResolveFeedback = useCallback(() => {
    projectStore.resolveProjectFeedback(
      project.id,
      state.fields[PROVIDE_FEEDBACK_FIELDS.COMMENT.NAME],
      state.feedbacks,
      onBackClick
    );
  }, [
    project.id,
    state.feedbacks,
    state.fields[PROVIDE_FEEDBACK_FIELDS.COMMENT.NAME],
    onBackClick
  ]);

  const isFeedbackButtonDisabled =
    (state.feedbacks.length === 0 && state.fields[PROVIDE_FEEDBACK_FIELDS.COMMENT.NAME] === '') ||
    state.validationFields.invalidFields.length;

  const readOnly = params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED;
  const showMobileFeedbackBtn = state.layout === 'mobile' && !readOnly;
  const foundComment =
    readOnly &&
    project.teamComments.find((comment) => comment.userId === authStore.currentUserId)?.commentText;

  return (
    <div className="feedback-details" style={showMobileFeedbackBtn ? { marginBottom: '36px' } : {}}>
      <Link to={paths.TEAM} className="backlink" onClick={onBackClick}>
        Back to shared projects
      </Link>

      <div className="feedback-view-header">
        <div className="love">
          <div className="title">{project.name}</div>
          {state.layout === 'desktop' && (
            <div className="actions">
              {!readOnly && (
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  innerText="Send feedback"
                  onClick={onResolveFeedback}
                  filled
                  disabled={isFeedbackButtonDisabled}
                />
              )}
            </div>
          )}
        </div>

        {foundSharedUserInfo?.note && <div className="note">Note: {foundSharedUserInfo?.note}</div>}
        <TextArea
          field={PROVIDE_FEEDBACK_FIELDS.COMMENT}
          value={foundComment || state.fields[PROVIDE_FEEDBACK_FIELDS.COMMENT.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!!projectStore.isRequestingProjectFeedback || readOnly}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={true}
          inputWrap={{ enable: true, className: 'comment' }}
          adjustHeight={{
            enable: true,
            minRows: state.layout === 'mobile' ? 4 : 2,
            maxRows: state.layout === 'mobile' ? 6 : 4
          }}
        />
      </div>

      <section>
        <div className="mandatory-container" id="frv-mandatory-container">
          <div className="mandatory-header">
            <div className="section-title">Search criteria</div>
            <IconButton
              type={ICON_BUTTON_TYPES.DEFAULT}
              icon={
                state.isFieldsSectionCollapsed
                  ? ICON_BUTTON_ICONS.CHEVRON_DOWN
                  : ICON_BUTTON_ICONS.CHEVRON_UP
              }
              innerText={state.isFieldsSectionCollapsed ? 'Expand' : 'Collapse'}
              onClick={state.toggleFieldsSection}
              iconOnRight
              withBorder={false}
            />
          </div>
          <div
            style={{ display: state.isFieldsSectionCollapsed ? 'none' : 'block' }}
            className="fields">
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.SEARCH_TYPE.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.SEARCH_TYPE)}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.GENERAL}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.INDUSTRY.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.INDUSTRY).join(', ')}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.LABEL}</div>
                <div className="value">
                  {utilsStore
                    .getOptionName(project.fields, SEARCH_FIELDS.GEOGRAPHICAL_FOCUS)
                    .join(', ')}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.HEADQUARTER.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.HEADQUARTER)}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.FINANCIALS}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.REVENUE.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumber(project.fields[SEARCH_FIELDS.REVENUE.NAME])}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EBITDA.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumber(project.fields[SEARCH_FIELDS.EBITDA.NAME])}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EBITDA_MARGIN.LABEL_COMPACT}</div>
                <div className="value">
                  {formatPercent(project.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME])}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.TRANSACTION}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EQUITY_STAKE.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.EQUITY_STAKE).join(', ')}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.SITUATION.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.SITUATION).join(', ')}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.VALUATION}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.ENTERPRISE_MIN.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumberRange(
                    project.fields[SEARCH_FIELDS.ENTERPRISE_MIN.NAME],
                    project.fields[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]
                  )}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EQUITY_MIN.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumberRange(
                    project.fields[SEARCH_FIELDS.EQUITY_MIN.NAME],
                    project.fields[SEARCH_FIELDS.EQUITY_MIN.NAME]
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lists">
        <div className="tabs request-view">
          {selectedProjectTabs.map((tab, idx) => {
            return (
              <div
                key={tab.name}
                onClick={tab.onClick}
                className={`tab ${tab.isActive ? 'active' : ''}`}
                {...(idx === 0 ? { id: 'team-project-view-tab-1' } : {})}>
                {tab.name}
              </div>
            );
          })}
        </div>

        {state.loadError && (
          <GeneralError
            errorMessage={state.loadError}
            actionMessage="You may want to:"
            actionButtontext="Try again"
            onActionButtonClick={() => state.loadResults(project.id)}
          />
        )}

        {state.isLoading && <GeneralLoader />}

        <section className="lists">
          {!state.isLoading && (
            <FeedbackSearchResultsTabView
              key={project.id}
              project={project}
              results={state.results}
              feedbacks={state.feedbacks}
              forTab={params.selectedProjectTab}
              params={params}
              excludeResult={state.excludeResult}
              isExcludingResult={state.isExcludingResult}
              displayMessagePopup={state.displayMessagePopup}
              onChangeFeedback={state.onChangeFeedback}
            />
          )}
          {params.selectedProjectTab === SELECTED_PROJECT_TABS.COMMENTS && null}
        </section>
      </section>

      {showMobileFeedbackBtn && (
        <div className="actions-bottom">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            innerText="Send feedback"
            onClick={onResolveFeedback}
            filled
            disabled={isFeedbackButtonDisabled}
            wrapClassName="send-feedback-btn"
          />
        </div>
      )}
    </div>
  );
});

export default FeedbackRequestView;
