import { observer, useLocalObservable } from 'mobx-react';
import {
  OUTREACH_FIELDS,
  getErrorFields,
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  trimStateFields,
  INVEST_PROFILE_FIELDS
} from '../../../utils/constants/fields';
import Input from '../../../components/inputs/Input';
import TextArea from '../../../components/inputs/TextArea';
import { mapFieldsToState } from '../../../utils/utils';
import { useEffect } from 'react';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { useStore } from '../../../store/store';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { eventOutreachSearch } from '../../../ga4/ga4';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { REQ_METHODS } from '../../../api/methods';
import { runInAction } from 'mobx';
import { CONTENT_TYPES } from '../../../api/contentTypes';
import PDFList from '../../../components/shared/PDFList';

const ProjectMessagePopup = observer(
  ({ messagePopupDisplayed, displayMessagePopup, submitMessage, forSearchType }) => {
    const { utilsStore, makeRequest, outreachStore } = useStore();
    const state = useLocalObservable(() => {
      const hasContact = !!messagePopupDisplayed.contactInfo?.firstName;
      return {
        isRendered: false,
        setIsRendered: (value = false) => (state.isRendered = value),
        hasContact: hasContact,
        contactName: hasContact
          ? `${messagePopupDisplayed.contactInfo?.firstName || ''} ${
              messagePopupDisplayed.contactInfo?.lastName || ''
            }`
          : 'Hadi Manesh, transact.digital',
        get isFund() {
          return forSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
        },
        get isPortfolio() {
          return forSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'];
        },
        get isProfile() {
          // return forSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates;
          return !state.isFund && !state.isPortfolio;
        },
        get entryName() {
          if (state.isFund) {
            return messagePopupDisplayed[FUND_FIELDS.NAME.NAME];
          } else if (state.isPortfolio) {
            return messagePopupDisplayed[PORTFOLIO_FIELDS.NAME.NAME];
          } else if (state.isProfile) {
            return (
              (messagePopupDisplayed.companyName ? `${messagePopupDisplayed.companyName} ` : '') +
              messagePopupDisplayed[INVEST_PROFILE_FIELDS.NAME.NAME]
            );
          } else {
            return messagePopupDisplayed.name;
          }
        },
        fields: mapFieldsToState(OUTREACH_FIELDS),
        setFieldValue: (field = {}, value) => {
          state.fields[field.NAME] = value;
        },
        documents: [],
        onSubmitErrorState: false,
        setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
        get validationFields() {
          return getErrorFields(Object.values(OUTREACH_FIELDS), state.fields);
        },
        get isSendDisabled() {
          return (
            outreachStore.isSendingOutreach ||
            !!state.documents.find((d) => d.isUploading) ||
            state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
            (state.onSubmitErrorState && state.validationFields.invalidFields.length)
          );
        },
        isMessageSent: false,
        messageError: null,
        sendMessage: () => {
          trimStateFields(state.fields);
          if (state.validationFields.invalidFields.length) {
            if (!state.onSubmitErrorState) {
              state.setOnSubmitErrorState(true);
            }
            return;
          }

          if (outreachStore.isSendingOutreach) {
            return;
          }

          state.messageError = null;
          submitMessage(
            messagePopupDisplayed.id,
            state.fields[OUTREACH_FIELDS.SUBJECT.NAME],
            state.fields[OUTREACH_FIELDS.MESSAGE.NAME],
            state.documents.map((d) => d.key),
            () => {
              if (!state.isRendered) {
                utilsStore.setHeaderMessage(
                  state.hasContact
                    ? `Outreach to ${state.contactName}, ${state.entryName} initiated.`
                    : `Message was sent to ${state.contactName} for ${state.entryName}`
                );
                return;
              }
              state.isMessageSent = true;
              eventOutreachSearch();
            },
            (errorMessage) => {
              if (!state.isRendered) {
                utilsStore.setHeaderMessage(
                  state.hasContact
                    ? `Failed to send message to investor ${state.contactName} regarding ${state.entryName}!`
                    : `Failed to send message to ${state.contactName} for ${state.entryName}`,
                  HEADER_MESSAGE_TYPES.ERROR
                );
                return;
              }
              state.messageError = errorMessage || 'Failed to send message to investor';
            }
          );
        }
      };
    });

    useEffect(() => {
      state.setIsRendered(true);
      return () => {
        state.setIsRendered(false);
      };
    }, [state]);

    const isLoading = outreachStore.isSendingOutreach;
    const headerInfoType = state.isFund
      ? 'fund'
      : state.isPortfolio
      ? 'portfolio co.'
      : 'invest profile';

    return (
      <div className="overlay">
        <div className="container popup-message">
          {isLoading ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <GeneralLoader />
                </div>
              </div>
            </>
          ) : state.isMessageSent ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <img src="/icons/check-circle-blue-40.svg" alt="Success" />
                  {state.hasContact ? (
                    <div className="text">
                      Outreach to <span className="names"> {state.contactName}</span>,{' '}
                      {state.entryName} initiated.
                    </div>
                  ) : (
                    <div className="text">
                      Message was sent to <span className="names"> {state.contactName}</span> for{' '}
                      {state.entryName}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="header message">
                {!state.hasContact ? (
                  <div>
                    <div className="header-info">
                      Please note: This {headerInfoType} is not onboarded yet, outreach will be
                      performed by transact.digital
                    </div>
                    <div className="text">
                      <label>Contact</label>
                      <div className="value">
                        {state.contactName}, {state.entryName || ''}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text">
                    <label>Contact</label>
                    <div className="value">
                      {state.contactName}, {state.entryName || ''}
                    </div>
                  </div>
                )}

                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="separator"></div>
              <div className="content">
                <Input
                  field={OUTREACH_FIELDS.SUBJECT}
                  value={state.fields[OUTREACH_FIELDS.SUBJECT.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field' }}
                />
                <TextArea
                  field={OUTREACH_FIELDS.MESSAGE}
                  value={state.fields[OUTREACH_FIELDS.MESSAGE.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field no-margin-bottom' }}
                />
                <PDFList documents={state.documents} />
                <div className="info">Outreach implies adherence to transact.digital terms.</div>
                <div className="actions outreach">
                  <div className="pdf-action">
                    <IconButton
                      type={ICON_BUTTON_TYPES.BLUE}
                      icon={ICON_BUTTON_ICONS.PAPERCLIP}
                      isPDF
                      onPDF={(file) => {
                        const size = file.size;
                        const sizeInMB = '(' + (size / (1024 * 1024)).toFixed(2) + 'mb)';
                        let key = (Math.random() + 1).toString(36).substring(7);
                        runInAction(() => {
                          state.documents.push({
                            key,
                            isUploading: true,
                            name: file.name,
                            size: sizeInMB
                          });
                        });

                        makeRequest({
                          endpoint: API_ENDPOINTS.DOCUMENT_PRESIGN,
                          body: { filename: file.name, size },
                          onSuccess: (response) => {
                            if (!state.isRendered) {
                              return;
                            }

                            const foundIndex = state.documents.findIndex((d) => d.key === key);
                            if (foundIndex !== -1) {
                              state.documents.splice(foundIndex, 1);
                            }

                            const { presignedUrl } = response;
                            key = response.key;

                            state.documents.splice(foundIndex, 0, {
                              key,
                              isUploading: true,
                              name: file.name,
                              size: sizeInMB
                            });

                            (async () => {
                              try {
                                await fetch(presignedUrl, {
                                  method: REQ_METHODS.PUT,
                                  headers: {
                                    'Content-Type': CONTENT_TYPES.TEXT_PLAIN
                                  },
                                  body: file
                                });

                                if (!state.isRendered) {
                                  return;
                                }

                                const foundIndex = state.documents.findIndex((d) => d.key === key);
                                if (foundIndex !== -1) {
                                  runInAction(() => {
                                    state.documents[foundIndex].isUploading = false;
                                  });
                                }
                              } catch (error) {
                                if (!state.isRendered) {
                                  return;
                                }

                                const foundIndex = state.documents.findIndex((d) => d.key === key);
                                if (foundIndex !== -1) {
                                  runInAction(() => {
                                    state.documents.splice(foundIndex, 1);
                                  });
                                }

                                console.log('Error from ', presignedUrl);
                                console.log('error', error);
                              }
                            })();
                          },
                          onError: (errorMessage) => {
                            const foundIndex = state.documents.findIndex((d) => d.key === key);
                            if (foundIndex !== -1) {
                              state.documents.splice(foundIndex, 1);
                            }
                            console.log('Error from /documents-presign-url');
                            console.log('errorMessage', errorMessage);
                          }
                        });
                      }}
                    />
                    <div className="pdf-action-info">pdf format only</div>
                  </div>
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    innerText="Send message"
                    disabled={state.isSendDisabled}
                    onClick={state.sendMessage}
                    filled
                  />
                </div>
                {!!state.messageError && <div className="errors">{state.messageError}</div>}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default ProjectMessagePopup;
