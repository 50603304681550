import { UI_OPTION_KEYS } from './uiOptionKeys';

export const UI_OPTIONS = {
  [UI_OPTION_KEYS.SEARCH_TYPE]: {
    Funds: 'funds',
    'Portfolio Co.': 'portfolios',
    ...(['dev', 'stg'].includes(process.env.REACT_APP_ENVIRONMENT)
      ? { Corporates: 'investprofiles' }
      : {})
  }
};

export const UI_OPTIONS_UTILS = Object.fromEntries(
  Object.entries(UI_OPTIONS).map(([key, values]) => [
    key,
    Object.entries(values).map(([name, value]) => ({ name, value }))
  ])
);
