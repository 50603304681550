import { useEffect, Fragment, useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { ACCOUNT_TYPES } from '../../../utils/constants/auth';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import ForwardOutreachPopup from './ForwardOutreachPopup';
import RequestFeedbackOutreachPopup from './RequestFeedbackOutreachPopup';
import { stringToColour, mapFieldsToState, checkBrowser } from '../../../utils/utils';
import FundDetailsPopup from '../../../components/shared/FundDetailsPopup';
import PortfolioCoDetailsPopup from '../../../components/shared/PortfolioCoDetailsPopup';
import InvestProfileDetailsPopup from '../../../components/shared/InvestProfileDetailsPopup';
import { mapData } from '../../../api/dataMappers';
import {
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  INVEST_PROFILE_FIELDS,
  COMPANY_PROFILE_FIELDS,
  OUTREACH_SEND_MESSAGE_FIELDS,
  getErrorFields,
  trimStateFields,
  FIELDS_TYPES
} from '../../../utils/constants/fields';
import ChatMembersPopup from './ChatMembersPopup';
import { runInAction, observable } from 'mobx';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import TextArea from '../../../components/inputs/TextArea';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { REQ_METHODS } from '../../../api/methods';
import { CONTENT_TYPES } from '../../../api/contentTypes';
import PDFList from '../../../components/shared/PDFList';
import pubSub from '../../../utils/pubsub';
import { getOutreachMessageDisplayTime, encodeOutreachParams } from './constants';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import Radio from '../../../components/inputs/Radio';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';

const globalState = observable({
  data: {},
  getData: (outreachId) => {
    if (!globalState.data[outreachId]) {
      globalState.generateData(outreachId);
    }

    return globalState.data[outreachId];
  },
  generateData: (outreachId) => {
    globalState.data[outreachId] = {
      sendMessageFields: mapFieldsToState(OUTREACH_SEND_MESSAGE_FIELDS),
      setSendMessageFieldValue: (field = {}, value) => {
        globalState.data[outreachId].sendMessageFields[field.NAME] = value;
      },
      sendMessageDocuments: [],
      sendMessageOnSubmitErrorState: false,
      setSendMessageOnSubmitErrorState: (value = false) =>
        (globalState.data[outreachId].sendMessageOnSubmitErrorState = value),
      rating: [''],
      ratingNumber: 0,
      toggledMobileRating: false
    };
  }
});

pubSub.subscribe((payload) => {
  if (payload?.event === 'logout') {
    runInAction(() => {
      globalState.data = {};
    });
  }
});

const displayObjectTypes = {
  funds: 'Fund',
  portfolios: 'Portfolio company',
  investprofiles: 'Invest profile'
};

const Chat = observer(({ outreachId, params }) => {
  const { makeRequest, authStore, outreachStore, utilsStore } = useStore();
  const { navigate } = useHistory();
  const chatScrollRef = useRef(null);
  const state = useLocalObservable(() => ({
    isRendered: true,
    headMenuOpened: false,
    toggleHeadMenu: () => (state.headMenuOpened = !state.headMenuOpened),
    membersPopupOpened: false,
    toggleMembersPopup: () => (state.membersPopupOpened = !state.membersPopupOpened),
    forwardPopupOpened: false,
    toggleForwardPopup: () => (state.forwardPopupOpened = !state.forwardPopupOpened),
    feedbackPopupOpened: false,
    toggleFeedbackPopup: () => (state.feedbackPopupOpened = !state.feedbackPopupOpened),
    infoPopupOpen: false,
    toggleInfoPopup: () => (state.infoPopupOpen = !state.infoPopupOpen),
    toggleMobileRating: () =>
      (state.chatState.toggledMobileRating = !state.chatState.toggledMobileRating),
    get chatState() {
      return globalState.getData(outreachId);
    },
    get isSendMessageDisabled() {
      return (
        outreachStore.isSendingMessage ||
        !!state.chatState.sendMessageDocuments.find((d) => d.isUploading) ||
        state.sendMessageValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.chatState.sendMessageOnSubmitErrorState &&
          state.sendMessageValidationFields.invalidFields.length)
      );
    },
    get sendMessageValidationFields() {
      return getErrorFields(
        Object.values(OUTREACH_SEND_MESSAGE_FIELDS),
        state.chatState.sendMessageFields
      );
    },
    sendMessage: () => {
      trimStateFields(state.chatState.sendMessageFields);
      if (state.sendMessageValidationFields.invalidFields.length) {
        if (!state.chatState.sendMessageOnSubmitErrorState) {
          state.chatState.setSendMessageOnSubmitErrorState(true);
        }
        return;
      }

      outreachStore.sendMessage(
        outreachId,
        state.chatState.sendMessageFields[OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.NAME],
        state.chatState.sendMessageDocuments.map((d) => d.key),
        () => {
          state.chatState.sendMessageFields[OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.NAME] = '';
          state.chatState.sendMessageDocuments = [];
          setTimeout(() => {
            if (state.layout === 'mobile') {
              return;
            }
            if (state.isRendered) {
              const messageTextAreaDOM = document.getElementById(
                OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.ID
              );
              if (messageTextAreaDOM) {
                messageTextAreaDOM.focus();
              }
            }
          }, 10);
        },
        (errorMessage) => {
          utilsStore.setHeaderMessage(
            errorMessage || 'Failed to send message.',
            HEADER_MESSAGE_TYPES.ERROR
          );
        }
      );
    },
    get messages() {
      return outreachStore.messages[outreachId];
    },
    // Fancy style but can disrupt some user actions like input in the middle of the sentence or marking of text for copying
    // get textMessages() {
    //   return outreachStore.messages[outreachId].filter((m) => m.messageType === 'text');
    // },
    now: Date.now(),
    setDateNow: (ms) => (state.now = ms),
    prevChatScrollHeight: null,
    setPrevChatScrollHeight: (height) => (state.prevChatScrollHeight = height),
    hoveredRatingNumber: 0,
    setHoveredRatingNumber: (number = 0) => (state.hoveredRatingNumber = number),
    rateOutreach: () => {
      outreachStore.rateOutreach(
        outreachId,
        state.chatState.rating[0],
        state.chatState.ratingNumber,
        () => {},
        (errorMessage) => {
          utilsStore.setHeaderMessage(
            errorMessage || 'Failed to rate outreach',
            HEADER_MESSAGE_TYPES.ERROR
          );
        }
      );
    },
    // Fancy style but can disrupt some user actions like input in the middle of the sentence or marking of text for copying
    // focusedElPreMessageRender: null,
    // onFirstMessageRender: (focusNode = null) => {
    //   runInAction(() => {
    //     state.focusedElPreMessageRender = focusNode;
    //   });

    //   if (state.focusedElPreMessageRender) {
    //     const outreach_send_message = state.focusedElPreMessageRender.firstChild;
    //     if (
    //       outreach_send_message &&
    //       outreach_send_message.id === OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.ID
    //     ) {
    //       outreach_send_message.className = 'forceFocusStyle';
    //       outreach_send_message?.blur?.();
    //     } else {
    //       state.focusedElPreMessageRender?.blur?.();
    //     }
    //   }
    // },
    // onLastMessageRender: () => {
    //   if (state.focusedElPreMessageRender) {
    //     const outreach_send_message = state.focusedElPreMessageRender.firstChild;
    //     if (
    //       outreach_send_message &&
    //       outreach_send_message.id === OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.ID
    //     ) {
    //       setTimeout(() => {
    //         document.getElementById(OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.ID)?.focus?.();
    //         outreach_send_message.className = '';
    //       }, 0);
    //     } else {
    //       setTimeout(() => state.focusedElPreMessageRender?.focus?.(), 0);
    //     }
    //   }
    // }
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    get detailedLayout() {
      return utilsStore.windowWidth < 481
        ? 'mobile'
        : utilsStore.windowWidth < 1201
        ? 'mobile-1200'
        : 'desktop';
    }
    // mobileChromeHeight: window.visualViewport.height + 'px'
  }));

  useEffect(() => {
    const interval = setInterval(() => state.setDateNow(Date.now()), 60_000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);

  useEffect(() => {
    return () => {
      runInAction(() => (state.isRendered = false));
    };
  }, [state]);

  useEffect(() => {
    chatScrollRef.current?.lastElementChild?.scrollIntoView();
  }, []);

  // useEffect(() => {
  //   const mobileResizeListener = () => {
  //     runInAction(() => {
  //       state.mobileChromeHeight = window.visualViewport.height + 'px';
  //     });
  //     setTimeout(() => {
  //       runInAction(() => {
  //         state.mobileChromeHeight = window.visualViewport.height + 'px';
  //       });
  //     }, 100);
  //   };
  //   window.addEventListener('resize', mobileResizeListener);
  //   return () => {
  //     window.removeEventListener('resize', mobileResizeListener);
  //   };
  // }, [state]);

  useEffect(() => {
    const subscription = (payload) => {
      if (payload.event === 'new-outreach-message' && payload.outreachId === outreachId) {
        if (payload.forceScroll) {
          chatScrollRef.current?.lastElementChild?.scrollIntoView();
        } else {
          const visibleHeight = chatScrollRef.current.clientHeight;
          const scrollHeight = chatScrollRef.current.scrollHeight;
          const scrollTop = chatScrollRef.current.scrollTop;
          const halfVisibleHeight = visibleHeight / 2;
          if (scrollHeight - (scrollTop + visibleHeight) < halfVisibleHeight) {
            chatScrollRef.current?.lastElementChild?.scrollIntoView();
          }
        }
      }
    };

    pubSub.subscribe(subscription);

    return () => {
      pubSub.unsubscribe(subscription);
    };
  }, [outreachId]);

  useEffect(() => {
    if (!chatScrollRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      const currentHeight = entry.contentRect.height;
      const prevHeight = state.prevChatScrollHeight;

      if (prevHeight === null) {
        state.setPrevChatScrollHeight(currentHeight);
        return;
      }

      if (currentHeight === prevHeight) {
        return;
      }

      if (prevHeight > currentHeight) {
        const diff = prevHeight - currentHeight;
        entry.target.scrollBy(0, diff);
      }

      state.setPrevChatScrollHeight(currentHeight);
    });

    resizeObserver.observe(chatScrollRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [state]);

  useEffect(() => {
    const subscription = (payload) => {
      if (
        payload.event === 'load-more-messages' &&
        payload.outreachId === outreachId &&
        payload.fromId
      ) {
        const fromMessageDOM = document.getElementById(`chat-${outreachId}-${payload.fromId}`);
        fromMessageDOM.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
      }
    };

    pubSub.subscribe(subscription);

    return () => {
      pubSub.unsubscribe(subscription);
    };
  }, [outreachId]);

  const outreach = outreachStore.outreaches.find((o) => o.id === outreachId);
  const currentUserInfo = outreach.participants.find((u) => u.id === authStore.currentUserId);
  const isForwarded = outreach.isForwarded;
  const isTeamFeedback = outreach.isProjectFeedback;
  const isProvideTeamFeedback =
    isTeamFeedback &&
    outreachStore.outreachesList.giveFeedback.findIndex((o) => o.id === outreachId) !== -1;
  const isAgent = currentUserInfo.role === ACCOUNT_TYPES.AGENT;
  const currentUserCompanyId = authStore.userProfileCompany.id;
  const displayCompany =
    outreach.companiesData.find((c) => c.id !== currentUserCompanyId) || outreach.companiesData[0];
  const displayUsers = outreach.participants.filter((u) => u.isActive);
  const displayUser =
    displayUsers.filter((u) => u.id !== authStore.currentUserId)[0] ||
    outreach.participants.find((u) => u.role !== authStore.userAccountType);
  const displayObjectType = displayObjectTypes[outreach.objectType];
  const canMarkAsUnread =
    (outreachStore.messages[outreachId][outreachStore.messages[outreachId].length - 1]?.date || 0) <
      currentUserInfo.lastCheckedMessage && !currentUserInfo.isMarkedAsUnread;
  const showArchiveButtons = !isTeamFeedback;
  const canArchive = !currentUserInfo.isArchived;
  const showForwardButton = !isAgent && !isTeamFeedback && !outreach.isMarkedForDeletion;
  const showFeedbackButton = !isAgent && !isTeamFeedback && !outreach.isMarkedForDeletion;
  const infoPopupUser = outreach.participants.find((u) =>
    isAgent ? u.role !== ACCOUNT_TYPES.AGENT : u.role !== ACCOUNT_TYPES.INVESTOR
  );
  const infoPopupCompany = outreach.companiesData.find((c) => c.id === infoPopupUser?.companyId);
  let contact = null;
  if (infoPopupUser && infoPopupCompany) {
    const companyData = mapData(infoPopupCompany, COMPANY_PROFILE_FIELDS);
    contact = {};
    contact.userName = infoPopupUser.firstName + ' ' + infoPopupUser.lastName;
    contact.companyName = companyData[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME];
    if (displayUser.location) {
      contact.location = displayUser.location;
    } else {
      contact.location =
        companyData[COMPANY_PROFILE_FIELDS.CITY.NAME] +
        ', ' +
        utilsStore.getOptionName(companyData, COMPANY_PROFILE_FIELDS.COUNTRY);
    }
    if (displayUser.phone) {
      contact.phone = displayUser.phone;
    } else {
      contact.phone = companyData[COMPANY_PROFILE_FIELDS.PHONE_NUMBER.NAME];
    }
    if (displayUser.email) {
      contact.email = displayUser.email;
    } else {
      contact.email = companyData[COMPANY_PROFILE_FIELDS.CONTACT_EMAIL.NAME];
    }
  }

  const onChatScroll = () => {
    if (
      chatScrollRef.current.scrollTop === 0 &&
      chatScrollRef.current.scrollHeight > chatScrollRef.current.clientHeight &&
      state.messages.length >= 20
    ) {
      outreachStore.loadMoreMessages(outreachId);
    }
  };

  const unreadMessages = state.messages.filter(
    (m) => m.date > currentUserInfo.lastCheckedMessage
  ).length;
  const onChatBodyClick = () => {
    const visibleHeight = chatScrollRef.current.clientHeight;
    const scrollHeight = chatScrollRef.current.scrollHeight;
    const scrollTop = chatScrollRef.current.scrollTop;
    const halfVisibleHeight = visibleHeight / 2;
    const shouldMarkAsRead = scrollHeight - (scrollTop + visibleHeight) < halfVisibleHeight;

    if (unreadMessages > 0 && shouldMarkAsRead) {
      outreachStore.markOutreachAsRead(outreachId);
    }

    if (currentUserInfo.isMarkedAsUnread && shouldMarkAsRead) {
      outreachStore.markOutreachAsUnread(outreachId, false);
    }
  };

  const showRating = !isTeamFeedback && !isForwarded && !currentUserInfo.isOutreachFeedbackGiven;

  const renderHeadMenu = () => {
    return (
      <>
        <div className="head-menu-closer" onClick={state.toggleHeadMenu} />
        <div className="head-menu-wrap">
          <IconButton
            icon={ICON_BUTTON_ICONS.ENVELOPE_DASH}
            hoverType={ICON_BUTTON_TYPES.BLUE}
            withBorder={false}
            innerText="Mark as unread"
            disabled={
              !canMarkAsUnread ||
              outreachStore.isUpdatingLastCheckedMessage ||
              outreachStore.isMarkingOutreachAsUnread
            }
            tooltipText={
              outreachStore.isUpdatingLastCheckedMessage || outreachStore.isMarkingOutreachAsUnread
                ? 'Updating'
                : canMarkAsUnread
                ? ''
                : currentUserInfo.isMarkedAsUnread
                ? 'Outreach marked as unread'
                : 'You have unread messages'
            }
            onClick={() => outreachStore.markOutreachAsUnread(outreachId) & state.toggleHeadMenu()}
          />
          {displayUsers.length > 2 && (
            <IconButton
              icon={ICON_BUTTON_ICONS.PEOPLE}
              hoverType={ICON_BUTTON_TYPES.BLUE}
              withBorder={false}
              innerText="Show members"
              onClick={() => state.toggleMembersPopup() & state.toggleHeadMenu()}
            />
          )}
          {showForwardButton && (
            <IconButton
              icon={ICON_BUTTON_ICONS.ARROW_RIGHT_SQUARE}
              hoverType={ICON_BUTTON_TYPES.BLUE}
              withBorder={false}
              innerText="Forward outreach"
              disabled={isForwarded || outreachStore.isForwardingOutreach}
              tooltipText={
                outreachStore.isForwardingOutreach
                  ? 'Updating'
                  : isForwarded
                  ? 'Already forwarded'
                  : ''
              }
              onClick={() => state.toggleForwardPopup() & state.toggleHeadMenu()}
            />
          )}
          {showFeedbackButton && (
            <IconButton
              icon={ICON_BUTTON_ICONS.CHAT_LEFT_QUOTE}
              hoverType={ICON_BUTTON_TYPES.BLUE}
              withBorder={false}
              innerText="Request feedback"
              disabled={outreachStore.isRequestingOutreachFeedback}
              tooltipText={outreachStore.isRequestingOutreachFeedback ? 'Updating' : ''}
              onClick={() => state.toggleFeedbackPopup() & state.toggleHeadMenu()}
            />
          )}
          {showArchiveButtons && (
            <IconButton
              icon={
                canArchive ? ICON_BUTTON_ICONS.ARCHIVE : ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE
              }
              hoverType={ICON_BUTTON_TYPES.BLUE}
              withBorder={false}
              innerText={canArchive ? 'Archive' : 'Unarchive'}
              disabled={outreachStore.isArchivingOutreach}
              tooltipText={outreachStore.isArchivingOutreach ? 'Updating' : ''}
              onClick={() => {
                if (canArchive) {
                  outreachStore.archiveOutreach(outreachId, () => {
                    if (state.layout === 'mobile') {
                      utilsStore.setHeaderMessage(
                        'Successfully archived outreach.',
                        HEADER_MESSAGE_TYPES.SUCCESS
                      );
                    }
                  });
                } else {
                  outreachStore.unarchiveOutreach(outreachId, () => {
                    if (state.layout === 'mobile') {
                      utilsStore.setHeaderMessage(
                        'Successfully unarchived outreach.',
                        HEADER_MESSAGE_TYPES.SUCCESS
                      );
                    }
                  });
                }
                state.toggleHeadMenu();
              }}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={`chat-wrap ${checkBrowser().safariAgent ? 'safariui' : ''}`}
      // style={
      //   state.layout === 'mobile' && checkBrowser().chromeAgent
      //     ? {
      //         height: `calc(${state.mobileChromeHeight} - ${
      //           state.detailedLayout === 'mobile' ? '52px' : '80px'
      //         })`
      //       }
      //     : {}
      // }
    >
      {state.forwardPopupOpened && (
        <ForwardOutreachPopup onClose={state.toggleForwardPopup} outreachId={outreachId} />
      )}
      {state.feedbackPopupOpened && (
        <RequestFeedbackOutreachPopup onClose={state.toggleFeedbackPopup} outreachId={outreachId} />
      )}
      {state.membersPopupOpened && (
        <ChatMembersPopup onClose={state.toggleMembersPopup} participants={displayUsers} />
      )}
      {state.infoPopupOpen &&
        (outreach.objectType === 'funds' ? (
          <FundDetailsPopup
            onClose={state.toggleInfoPopup}
            fund={mapData(outreach.objectData, FUND_FIELDS)}
            contact={!isTeamFeedback ? contact : null}
          />
        ) : outreach.objectType === 'portfolios' ? (
          <PortfolioCoDetailsPopup
            onClose={state.toggleInfoPopup}
            portfolio={mapData(outreach.objectData, PORTFOLIO_FIELDS)}
            contact={!isTeamFeedback ? contact : null}
          />
        ) : (
          <InvestProfileDetailsPopup
            onClose={state.toggleInfoPopup}
            profile={mapData(outreach.objectData, INVEST_PROFILE_FIELDS)}
            contact={!isTeamFeedback ? contact : null}
          />
        ))}
      {state.layout === 'mobile' && (
        <div className="chat-head-mobile">
          <IconButton
            id="mobile-chat-header-back-arrow"
            type={ICON_BUTTON_TYPES.DEFAULT}
            hoverType={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.ARROW_LEFT}
            withBorder={false}
            filled={false}
            onClick={() => {
              navigate(
                paths.OUTREACH + '?params=' + encodeOutreachParams({ ...params, outreachId: null })
              );
            }}
          />
          {displayUsers.length <= 2 && (
            <div
              className="avatar"
              style={{
                backgroundColor: stringToColour(displayUser.firstName + displayUser.lastName)
              }}>
              {displayUser.firstName.toUpperCase().charAt(0)}
            </div>
          )}
          {displayUsers.length > 2 && <div className="avatar">G</div>}
          <div className="mobile-chat-info">
            <div className="top-info">
              <IconButton
                id="mobile-chat-header-info-icon-button"
                icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                type={ICON_BUTTON_TYPES.BLUE}
                withBorder={false}
                withFill={false}
                onClick={state.toggleInfoPopup}
              />
              <div className="text">
                {displayUsers.length > 2 && (
                  <>
                    Group Chat • <font className="extra-info">{outreach.objectData.name}</font>
                  </>
                )}
                {isAgent && (
                  <>
                    {displayUser.firstName + ' ' + displayUser.lastName} •{' '}
                    <font className="extra-info">{`${outreach.objectData.name}${
                      displayObjectType ? ` • ${displayObjectType}` : ''
                    }`}</font>
                  </>
                )}
                {!isAgent && (
                  <>
                    {displayUser.firstName + ' ' + displayUser.lastName} •{' '}
                    <font className="extra-info">
                      {isTeamFeedback ? outreach.objectData.name : displayCompany.companyName}
                    </font>
                  </>
                )}
              </div>
            </div>
            {displayUsers.length > 2 ? (
              <div className="middle-info">{displayUsers.length} members</div>
            ) : (
              <div className="middle-separator" />
            )}
            <div className="bottom-info">{outreach.subject}</div>
          </div>
          <IconButton
            id="mobile-chat-header-dots"
            type={ICON_BUTTON_TYPES.DEFAULT}
            hoverType={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.THREE_DOTS}
            withBorder={false}
            filled={state.headMenuOpened}
            onClick={state.toggleHeadMenu}
          />
          {state.headMenuOpened && renderHeadMenu()}
        </div>
      )}
      {state.layout === 'desktop' && (
        <div className="chat-head">
          {isTeamFeedback ? (
            <div className="info-wrap-feedback">
              {displayUsers.length < 3 ? (
                <div className="top">
                  {displayUser.firstName} {displayUser.lastName} •&nbsp;
                  <font className="extra-info">{outreach.objectData.name}</font>{' '}
                  {/* If they want to be just for the providing section */}
                  {/* {isProvideTeamFeedback ? ` •` : ''}
                {isProvideTeamFeedback && <font>&nbsp;</font>}
                {isProvideTeamFeedback && (
                  <font className="extra-info">{outreach.objectData.name}</font>
                )}{' '} */}
                  <IconButton
                    icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                    type={ICON_BUTTON_TYPES.BLUE}
                    withBorder={false}
                    withFill={false}
                    onClick={state.toggleInfoPopup}
                  />
                </div>
              ) : (
                <div className="top-multiuser">
                  {displayUsers.slice(0, displayUsers.length > 3 ? 2 : 3).map((u, i, arr) => {
                    const style = {
                      backgroundColor: stringToColour(u.firstName + u.lastName),
                      ...(i > 0 ? { marginLeft: '-6px' } : {}),
                      zIndex: arr.length - i + 1
                    };
                    return (
                      <Fragment key={u.id}>
                        <div className="noselect user-icon" style={style}>
                          {u.firstName.charAt(0).toUpperCase()}
                        </div>
                      </Fragment>
                    );
                  })}
                  {displayUsers.length > 3 && (
                    <Fragment>
                      <div
                        className="noselect user-icon"
                        style={{
                          backgroundColor: '#FFF4D2',
                          marginLeft: '-6px',
                          zIndex: 1,
                          fontSize: '8px'
                        }}>
                        +{displayUsers.length - 2}
                      </div>
                    </Fragment>
                  )}
                  <div className="group-chat-title">
                    Group Chat • <font className="extra-info">{outreach.objectData.name}</font>{' '}
                    {/* If they want to be just for the providing section */}
                    {/* Group Chat{isProvideTeamFeedback ? ` • ` : ''}
                  {isProvideTeamFeedback && (
                    <font className="extra-info">{outreach.objectData.name}</font>
                  )}{' '} */}
                  </div>
                  <IconButton
                    icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                    type={ICON_BUTTON_TYPES.BLUE}
                    withBorder={false}
                    withFill={false}
                    onClick={state.toggleInfoPopup}
                  />
                </div>
              )}
              <div className="bottom">
                <div className="object-info">{outreach.subject}</div>
              </div>
            </div>
          ) : isAgent ? (
            <div className="info-wrap-agent">
              <div className="top">
                <div className="title">
                  {displayUser.firstName + ' ' + displayUser.lastName} •{' '}
                  <font className="extra-info">{`${outreach.objectData.name}${
                    displayObjectType ? ` • ${displayObjectType}` : ''
                  }`}</font>
                </div>
                <IconButton
                  icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                  type={ICON_BUTTON_TYPES.BLUE}
                  withBorder={false}
                  withFill={false}
                  onClick={state.toggleInfoPopup}
                />
              </div>
              <div className="bottom">{outreach.subject}</div>
            </div>
          ) : (
            <div className="info-wrap-investor">
              <div className="top">
                <div className="title">
                  {displayUser.firstName + ' ' + displayUser.lastName} •{' '}
                  <font className="extra-info">{displayCompany.companyName}</font>
                </div>
                <IconButton
                  icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                  type={ICON_BUTTON_TYPES.BLUE}
                  withBorder={false}
                  withFill={false}
                  onClick={state.toggleInfoPopup}
                />
              </div>
              <div className="bottom">{outreach.subject}</div>
            </div>
          )}
          <IconButton
            type={ICON_BUTTON_TYPES.DEFAULT}
            hoverType={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.THREE_DOTS}
            withBorder={false}
            filled={state.headMenuOpened}
            onClick={state.toggleHeadMenu}
          />
          {state.headMenuOpened && renderHeadMenu()}
        </div>
      )}
      {showRating &&
        (state.layout === 'desktop' ||
        (state.layout === 'mobile' && state.chatState.toggledMobileRating) ? (
          <div className="chat-rating-wrap">
            <div className="rating-wrap">
              <div className="title">
                {isAgent ? 'Sufficient investor feedback?' : 'Relevant investment opportunity?'}
              </div>
              <div className="rating-inputs-wrap">
                <Radio
                  field={{
                    NAME: 'chatRating',
                    ID: 'chat_rating',
                    REQUIRED: true,
                    TYPE: FIELDS_TYPES.TYPE_RADIO
                  }}
                  value={state.chatState.rating}
                  values={[
                    { name: 'Yes', value: 'yes' },
                    { name: 'No', value: 'no' }
                  ]}
                  setFieldValue={(_, value) => {
                    runInAction(() => {
                      state.chatState.rating = value;
                    });
                  }}
                />
              </div>
            </div>
            <div className="rating-number-wrap">
              <div className="title">
                {isAgent ? 'Investor sweet spot covered?' : 'Banker sweet spot covered?'}
              </div>
              <div
                className="rating-inputs-wrap"
                onMouseLeave={() => state.setHoveredRatingNumber()}>
                {['not relevant', 'less relevant', 'relevant', 'highly relevant'].map(
                  (text, idx) => {
                    const number = idx + 1;
                    const selectedNumber =
                      state.hoveredRatingNumber || state.chatState.ratingNumber;
                    const isFilled = number <= selectedNumber;
                    const isSelected =
                      !state.hoveredRatingNumber && number === state.chatState.ratingNumber;

                    return (
                      <IconButton
                        key={idx}
                        icon={isFilled ? ICON_BUTTON_ICONS.STAR_FILL : ICON_BUTTON_ICONS.STAR}
                        type={ICON_BUTTON_TYPES.BLUE}
                        withBorder={false}
                        outerText={text}
                        withFill={false}
                        onMouseEnter={() => state.setHoveredRatingNumber(number)}
                        onClick={() => runInAction(() => (state.chatState.ratingNumber = number))}
                        {...(isSelected ? { wrapClassName: 'selected-chat-rating-number' } : {})}
                      />
                    );
                  }
                )}
              </div>
            </div>
            <IconButton
              innerText="Rate"
              id="send-rate-button"
              disabled={
                outreachStore.isRatingOutreach ||
                !state.chatState.rating[0] ||
                !state.chatState.ratingNumber
              }
              type={ICON_BUTTON_TYPES.BLUE}
              onClick={state.rateOutreach}
              tooltipText={
                state.layout === 'mobile' &&
                (!state.chatState.rating[0] || !state.chatState.ratingNumber)
                  ? '   Please first provide feedback   '
                  : ''
              }
            />
            {state.layout === 'mobile' && (
              <div className="mobile-rating-toggle">
                <IconButton
                  type={ICON_BUTTON_TYPES.DEFAULT}
                  withBorder={false}
                  hoverType={ICON_BUTTON_TYPES.BLUE}
                  icon={ICON_BUTTON_ICONS.CHEVRON_UP}
                  onClick={state.toggleMobileRating}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="mobile-rating-wrap">
            <div className="text">Rate your interaction</div>
            <IconButton
              type={ICON_BUTTON_TYPES.DEFAULT}
              withBorder={false}
              hoverType={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.CHEVRON_DOWN}
              onClick={state.toggleMobileRating}
            />
          </div>
        ))}
      <div className="chat-scrollable-body-wrap">
        <div className={`fade-top  ${checkBrowser().safariAgent ? 'safariui' : ''}`} />
        <div
          className={`chat-scrollable-body  ${checkBrowser().safariAgent ? 'safariui' : ''}`}
          ref={chatScrollRef}
          onScroll={onChatScroll}
          onClick={onChatBodyClick}>
          {outreachStore.isLoadingMoreMessages[outreachId] && <GeneralLoader />}
          {outreachStore.fullyLoadedMessages[outreachId] && (
            <div className="system-message">Outreach history starts here.</div>
          )}
          {state.messages >= 20 &&
            !outreachStore.isLoadingMoreMessages[outreachId] &&
            !outreachStore.fullyLoadedMessages[outreachId] && (
              <div
                className="noselect load-more-btn-large-height-display-fix"
                onClick={() => outreachStore.loadMoreMessages(outreachId)}>
                Load more
              </div>
            )}
          {state.messages.map((m, idx, arr) => {
            const isNewMessage =
              currentUserInfo.lastCheckedMessage < m.date && m.userId !== authStore.currentUserId;

            if (m.messageType === 'system') {
              return (
                <div key={m.id} className="system-message" id={`chat-${outreachId}-${m.id}`}>
                  {isNewMessage && <div className="message-info-notif-dot" />}
                  {m.data}
                </div>
              );
            }

            const isPDF = m.messageType === 'PDF';
            const isMyMessage = m.userId === authStore.currentUserId;
            const isCopy = m.isForwardCopy || m.isFeedbackCopy;
            const participant = outreach.participants.find((u) => u.id === m.userId);
            const isMyCompany =
              outreach.participants.find((u) => u.id === authStore.currentUserId)?.companyId ===
              participant?.companyId;
            const isMyCompanyCopy = isCopy && isMyCompany;
            const displayMessageInfo = !isPDF || (isPDF && arr[idx - 1]?.userId !== m.userId);

            const classNames = ['chat-message'];

            if (isMyMessage || isMyCompanyCopy) {
              classNames.push('right');
            } else {
              classNames.push('left');
            }

            if (isPDF) {
              classNames.push('pdf-message');
              if (!displayMessageInfo) {
                classNames.push('pdf-no-message-info');
              }
            }

            const className = classNames.join(' ');

            const backgroundColor = isMyCompanyCopy
              ? '#B0D2FD'
              : isMyMessage
              ? '#348CF9'
              : isTeamFeedback && isMyCompany
              ? stringToColour(participant.firstName + participant.lastName)
              : '#F4F5F7';

            const color = isMyCompanyCopy
              ? '#000000'
              : isMyMessage
              ? '#FFFFFF'
              : isTeamFeedback && isMyCompany
              ? '#000000'
              : '#000000';

            const displayTime = getOutreachMessageDisplayTime(m.date, state.now);

            return (
              <div key={m.id} className={className} id={`chat-${outreachId}-${m.id}`}>
                {displayMessageInfo && (
                  <div className="message-info">
                    <div className="message-info-icon" style={{ backgroundColor, color }}>
                      {isMyMessage ? 'Y' : participant.firstName.charAt(0).toUpperCase()}
                    </div>
                    <div className="message-info-names">
                      {isMyMessage ? 'You' : `${participant.firstName} ${participant.lastName}`}
                    </div>
                    <div className="message-info-dot">•</div>
                    <div className="message-info-date">{displayTime}</div>
                    {isNewMessage && <div className="message-info-notif-dot" />}
                  </div>
                )}
                <div className={'message-text-wrap'} style={{ backgroundColor }}>
                  {isPDF ? (
                    <div className="doc-wrap">
                      <img className="pdf-icon" src={ICON_BUTTON_ICONS.FILE_EARNMARK_PDF} />
                      <div className="doc-info-wrap">
                        <div className="doc-info-top">
                          <div
                            className="doc-name"
                            onClick={() => outreachStore.downloadPDF(outreachId, m.id)}>
                            {m.data.filename}
                          </div>
                          <div className="doc-size">
                            {'(' + (m.data.size / (1024 * 1024)).toFixed(2) + 'mb)'}
                          </div>
                        </div>
                        <div className="doc-info-bottom">
                          <div className="doc-status">Uploaded</div>
                          {/* {m.canDelete && isMyMessage && (
                          <IconButton
                            icon={ICON_BUTTON_ICONS.X_LG}
                            withBorder={false}
                            onClick={() => {}}
                          />
                        )} */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="message-text"
                      style={{ color }}
                      ref={() => {
                        // Fancy style but can disrupt some user actions like input in the middle of the sentence or marking of text for copying
                        // const msgTextIndex = state.textMessages.findIndex((_m) => _m.id === m.id);
                        // if (msgTextIndex === 0) {
                        //   const prevFocusNode = window.getSelection().focusNode;
                        //   state.onFirstMessageRender(prevFocusNode);
                        // }
                        // const element = document.querySelector(
                        //   `#chat-${outreachId}-${m.id} .message-text`
                        // );
                        // window.getSelection().selectAllChildren(element);
                        // const rects = [...window.getSelection().getRangeAt(0).getClientRects()];
                        // const biggestWidthText = rects.slice().sort((a, b) => b.width - a.width)[0];
                        // if (biggestWidthText) {
                        //   element.style.maxWidth = biggestWidthText.width + 'px';
                        // }
                        // window.getSelection().removeAllRanges();
                        // if (msgTextIndex === state.textMessages.length - 1) {
                        //   state.onLastMessageRender();
                        // }
                      }}>
                      {m.data}
                    </div>
                  )}
                  {isPDF && isNewMessage && <div className="message-info-notif-dot" />}
                </div>
              </div>
            );
          })}
        </div>
        <div className={`fade-bottom  ${checkBrowser().safariAgent ? 'safariui' : ''}`} />
      </div>
      {!outreach.isMarkedForDeletion && (
        <div className="inputs-wrap">
          <TextArea
            field={OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE}
            value={state.chatState.sendMessageFields[OUTREACH_SEND_MESSAGE_FIELDS.MESSAGE.NAME]}
            setFieldValue={state.chatState.setSendMessageFieldValue}
            disabled={outreachStore.isSendingMessage}
            messages={state.sendMessageValidationFields.messages}
            showOnSubmitErrorState={true}
            adjustHeight={{
              enable: true,
              minRows: state.layout === 'mobile' ? 1 : 2,
              maxRows: 4,
              lineHeight: state.layout === 'mobile' ? 24 : 20
            }}
            onClick={onChatBodyClick}
            onKeyDown={(e) => {
              if (!e.shiftKey && e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent?.preventDefault?.();
                e.nativeEvent?.stopPropagation?.();
                state.sendMessage();
              }
            }}
          />
          <PDFList documents={state.chatState.sendMessageDocuments} />
          <div
            className="bottom-inputs-row"
            style={{
              marginTop: state.chatState.sendMessageDocuments.length ? '4px' : '12px'
            }}>
            <div className="pdf-action">
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                icon={ICON_BUTTON_ICONS.PAPERCLIP}
                isPDF
                onPDF={(file) => {
                  const size = file.size;
                  const sizeInMB = '(' + (size / (1024 * 1024)).toFixed(2) + 'mb)';
                  let key = (Math.random() + 1).toString(36).substring(7);
                  runInAction(() => {
                    state.chatState.sendMessageDocuments.push({
                      key,
                      isUploading: true,
                      name: file.name,
                      size: sizeInMB
                    });
                  });

                  makeRequest({
                    endpoint: API_ENDPOINTS.DOCUMENT_PRESIGN,
                    body: { filename: file.name, size },
                    onSuccess: (response) => {
                      const foundIndex = state.chatState.sendMessageDocuments.findIndex(
                        (d) => d.key === key
                      );
                      if (foundIndex !== -1) {
                        state.chatState.sendMessageDocuments.splice(foundIndex, 1);
                      }

                      const { presignedUrl } = response;
                      key = response.key;

                      state.chatState.sendMessageDocuments.splice(foundIndex, 0, {
                        key,
                        isUploading: true,
                        name: file.name,
                        size: sizeInMB
                      });

                      (async () => {
                        try {
                          await fetch(presignedUrl, {
                            method: REQ_METHODS.PUT,
                            headers: {
                              'Content-Type': CONTENT_TYPES.TEXT_PLAIN
                            },
                            body: file
                          });

                          const foundIndex = state.chatState.sendMessageDocuments.findIndex(
                            (d) => d.key === key
                          );
                          if (foundIndex !== -1) {
                            runInAction(() => {
                              state.chatState.sendMessageDocuments[foundIndex].isUploading = false;
                            });
                          }
                        } catch (error) {
                          const foundIndex = state.chatState.sendMessageDocuments.findIndex(
                            (d) => d.key === key
                          );
                          if (foundIndex !== -1) {
                            runInAction(() => {
                              state.chatState.sendMessageDocuments.splice(foundIndex, 1);
                            });
                          }

                          console.log('Error from ', presignedUrl);
                          console.log('error', error);
                        }
                      })();
                    },
                    onError: (errorMessage) => {
                      const foundIndex = state.chatState.sendMessageDocuments.findIndex(
                        (d) => d.key === key
                      );
                      if (foundIndex !== -1) {
                        state.chatState.sendMessageDocuments.splice(foundIndex, 1);
                      }
                      console.log('Error from /documents-presign-url');
                      console.log('errorMessage', errorMessage);
                    }
                  });
                }}
              />
              <div className="pdf-action-info">pdf format only</div>
            </div>
            <IconButton
              id="send_message_button"
              type={ICON_BUTTON_TYPES.BLUE}
              innerText={state.layout === 'desktop' ? 'Send' : ''}
              icon={state.layout === 'mobile' ? ICON_BUTTON_ICONS.SEND : null}
              disabled={state.isSendMessageDisabled}
              onClick={state.sendMessage}
              filled
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default Chat;
