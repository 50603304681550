import { observer } from 'mobx-react';
import { useStore } from '../../../../store/store';
import { FUND_FIELDS } from '../../../../utils/constants/fields';
import { formatNumber } from '../../../../utils/utils';
import { PUNCTUATION } from '../../../../utils/constants/notation';

const FundGeneralInformation = observer(({ entry }) => {
  const { utilsStore } = useStore();

  return (
    <div className="content">
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.NAME.LABEL}</div>
        <div className="value">{entry[FUND_FIELDS.NAME.NAME]}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.VINTAGE.LABEL}</div>
        <div className="value">{entry[FUND_FIELDS.VINTAGE.NAME] || PUNCTUATION.EMPTY_VALUE}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.VOLUME.LABEL} (€M)</div>
        <div className="value">{formatNumber(entry[FUND_FIELDS.VOLUME.NAME])}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.DESCRIPTION.LABEL}</div>
        <div className="value">{entry[FUND_FIELDS.DESCRIPTION.NAME]}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.INDUSTRIES.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, FUND_FIELDS.INDUSTRIES).join(', ')}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.GEOGRAPHIES.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, FUND_FIELDS.GEOGRAPHIES).join(', ')}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.SITUATION.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, FUND_FIELDS.SITUATION).join(', ')}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.EQUITY.LABEL}</div>
        <div className="value">
          {utilsStore.getOptionName(entry, FUND_FIELDS.EQUITY).join(', ')}
        </div>
      </div>
    </div>
  );
});

export default FundGeneralInformation;
