import { observer, useLocalObservable } from 'mobx-react';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import {
  getErrorFields,
  trimStateFields,
  PROFILER_SEARCH_FIELDS
} from '../../utils/constants/fields';
import { mapFieldsToState } from '../../utils/utils';
import { paths } from '../../utils/constants/routes';
import useHistory from '../../hooks/useHistory';
import { useStore } from '../../store/store';
import SearchFormDesktop from './components/SearchFormDesktop';

const Profiler = observer(() => {
  const { utilsStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    fields: {
      ...mapFieldsToState(PROFILER_SEARCH_FIELDS)
    },
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(PROFILER_SEARCH_FIELDS), state.fields);
    },
    get isSearchDisabled() {
      return (
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    onSearch: (e) => {
      e?.preventDefault?.();

      trimStateFields(state.fields);
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      const fields = Object.entries(state.fields)
        .filter((field) => field[1].length)
        .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
        .join('&');

      navigate(paths.PROFILER_SEARCH_RESULTS + `?page=1&${fields}`);
    },
    get layout() {
      return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
    }
  }));

  return (
    <StaticPageLayout
      page="search-form"
      component={state.layout === 'mobile' ? 'mobile-filter' : 'general'}>
      <div className="search-container">
        <h1>Identify investment opportunities</h1>
        <div className="search-fields-container">
          {state.layout === 'desktop' && (
            <SearchFormDesktop
              fields={state.fields}
              setFieldValue={state.setFieldValue}
              onSubmitErrorState={state.onSubmitErrorState}
              validationFields={state.validationFields}
              isSearchDisabled={state.isSearchDisabled}
              onSearch={state.onSearch}
            />
          )}
        </div>
      </div>
    </StaticPageLayout>
  );
});

export default Profiler;
