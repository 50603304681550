import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import { useEffect, useMemo } from 'react';
import { paths } from '../../utils/constants/routes';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import GeneralError from '../../components/errors/GeneralError';
import useHistory from '../../hooks/useHistory';
import {
  PROJECT_TYPE_TABS,
  encodeProjectsParams,
  decodeProjectsParams,
  SELECTED_PROJECT_TABS,
  PROJECT_LIST_SORT
} from './constants';
import ProfilerProjectsList from './components/ProfilerProjectsList';
import ProfilerProjectView from './components/ProfilerProjectView';

const projectsListingPageSize = 8;

const ProfilerProjects = observer(() => {
  const { location, navigate } = useHistory();
  const { profilerProjectStore } = useStore();

  const state = useLocalObservable(() => ({
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    }
  }));

  useEffect(() => {
    if (profilerProjectStore.isLoadingProjectsData) {
      return;
    }

    // params - projectTypeTab, projectTypePage, projectSort, selectedProjectId, selectedProjectTab, selectedProjectTabPage

    let [urlParams, params, shouldNavigate] = [null, {}, false];
    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeProjectsParams(urlParams.get('params'));
    } catch (ex) {
      params = { projectTypeTab: PROJECT_TYPE_TABS.SAVED, projectTypePage: 1 };
    }

    if (typeof params !== 'object' || params === null || params === undefined) {
      params = {};
    }

    if (!Object.values(PROJECT_TYPE_TABS).includes(params.projectTypeTab)) {
      params.projectTypeTab = PROJECT_TYPE_TABS.SAVED;
      shouldNavigate = true;
    }

    if (!Object.values(PROJECT_LIST_SORT).includes(params.projectSort)) {
      params.projectSort = PROJECT_LIST_SORT.DATE_DESC;
      shouldNavigate = true;
    }

    const projectsList =
      params.projectTypeTab === PROJECT_TYPE_TABS.SAVED
        ? profilerProjectStore.projects
        : profilerProjectStore.archivedProjects;
    let projectsListPages = Math.ceil(projectsList.length / projectsListingPageSize);
    if (projectsListPages === 0) {
      projectsListPages = 1;
    }

    if (
      isNaN(params.projectTypePage) ||
      params.projectTypePage > projectsListPages ||
      params.projectTypePage < 1
    ) {
      if (isNaN(params.projectTypePage) || params.projectTypePage < 1) {
        params.projectTypePage = 1;
      } else if (params.projectTypePage > projectsListPages) {
        params.projectTypePage = projectsListPages;
      }
      shouldNavigate = true;
    }

    if (params.selectedProjectId) {
      const foundProject = projectsList.find((p) => p.id === params.selectedProjectId);
      if (!foundProject) {
        params.selectedProjectId = null;
        params.selectedProjectTab = null;
        params.selectedProjectTabPage = null;
        shouldNavigate = true;
      } else {
        if (!Object.values(SELECTED_PROJECT_TABS).includes(params.selectedProjectTab)) {
          params.selectedProjectTab = SELECTED_PROJECT_TABS.RESULTS;
          shouldNavigate = true;
        }
      }
    }

    if (shouldNavigate) {
      navigate(paths.PROFILER_PROJECTS + '?params=' + encodeProjectsParams(params));
    }

    state.setParams(params);
  }, [
    state,
    profilerProjectStore,
    profilerProjectStore.allProjects,
    profilerProjectStore.isLoadingProjectsData,
    profilerProjectStore.projects.length,
    profilerProjectStore.archivedProjects.length,
    location.search
  ]);

  const selectedProject = useMemo(() => {
    const list =
      state.params.projectTypeTab === PROJECT_TYPE_TABS.SAVED
        ? profilerProjectStore.projects
        : state.params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED
        ? profilerProjectStore.archivedProjects
        : [];
    const foundEntry = list.find((s) => s.id == state.params.selectedProjectId);
    return foundEntry;
  }, [profilerProjectStore.projects, profilerProjectStore.archivedProjects, state.params]);

  if (
    profilerProjectStore.initialLoadProjects ||
    (profilerProjectStore.isLoadingProjects && !profilerProjectStore.allProjects.length)
  ) {
    return (
      <StaticPageLayout page="projects-listing" hideMobileFooter={true}>
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  const showError =
    profilerProjectStore.loadProjectsError && !profilerProjectStore.allProjects.length;
  const showActiveProjectsList =
    !showError &&
    state.params.projectTypeTab === PROJECT_TYPE_TABS.SAVED &&
    !state.params.selectedProjectId;
  const showArchivedProjectsList =
    !showError &&
    state.params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED &&
    !state.params.selectedProjectId;
  const showProjectsList = showActiveProjectsList || showArchivedProjectsList;
  const showProjectView = !showError && state.params.selectedProjectId && selectedProject;

  return (
    <StaticPageLayout page="projects-listing" hideMobileFooter={true}>
      {showError && (
        <GeneralError
          errorMessage={profilerProjectStore.loadProjectsError}
          actionMessage="You may want to:"
          withHomePageButton={true}
          actionButtontext="Try again"
          onActionButtonClick={profilerProjectStore.loadProjects}
          withLogoutButton
        />
      )}
      {showProjectsList && (
        <ProfilerProjectsList
          key={state.params.projectTypeTab}
          listType={state.params.projectTypeTab}
          params={state.params}
        />
      )}
      {showProjectView && (
        <ProfilerProjectView
          key={selectedProject.id}
          project={selectedProject}
          params={state.params}
        />
      )}
    </StaticPageLayout>
  );
});

export default ProfilerProjects;
