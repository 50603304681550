import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import InvestorObjectButton from './InvestorObjectButton';

const MyOutreachesSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isMyOutreachesExpanded, toggle_d_MyOutreaches, outreachesList } = outreachStore;

  return (
    <>
      <CollapsableSectionHead
        title="My outreaches"
        icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
        isCollapsed={!d_isMyOutreachesExpanded}
        toggleCollapse={toggle_d_MyOutreaches}
        count={outreachesList.activeNewMessagesCount}
        showEmptyDot={
          !outreachesList.activeNewMessagesCount &&
          Object.values(outreachesList.active).findIndex((obj) => obj.hasMarkedUnread) !== -1
        }
      />
      {d_isMyOutreachesExpanded &&
        Object.entries(outreachesList.active)
          .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
          .map(([objectId, object]) => {
            return (
              <InvestorObjectButton
                key={objectId}
                objectId={objectId}
                object={object}
                params={params}
                list={outreachesList.active}
                type="active"
              />
            );
          })}
      {d_isMyOutreachesExpanded && <div className="separator" />}
    </>
  );
});

export default MyOutreachesSection;
