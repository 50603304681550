export const ADMIN_DASHBOARD_TABS = {
  USER_MANAGEMENT: 'user-management',
  USER_ACTIVITIES: 'user-activities',
  INVEST_VEHICLES: 'invest-vehicles'
};

export const ADMIN_DASHBOARD_USER_INFO_TABS = {
  USER_INFORMATION: 'user-information',
  USER_ATTRIBUTES: 'user-attributes',
  USER_GROUP_MEMBERSHIP: 'user-group-membership'
};

export const ADMIN_DASHBOARD_USER_GROUPS = {
  admin: {
    name: 'admin',
    value: 'admin',
    description: 'Admin users',
    selectable: true
  },
  approved: {
    name: 'approved',
    value: 'approved',
    description: 'Users that were approved by admin',
    selectable: true
  },
  master: {
    name: 'master',
    value: 'master',
    description: 'Master users for companies',
    selectable: true
  },
  profiler: {
    name: 'profiler',
    value: 'profiler',
    description: 'Users allowed to use the company profiler',
    selectable: true
  },
  agent: {
    name: 'agent',
    value: 'agent',
    description: 'Users with Agent role'
  },
  investor: {
    name: 'investor',
    value: 'investor',
    description: 'Users with Investor role'
  }
};

export const ADMIN_DASHBOARD_USER_GROUPS_ARRAY_ALL = Object.values(ADMIN_DASHBOARD_USER_GROUPS);

export const ADMIN_DASHBOARD_USER_GROUPS_ARRAY_SELECTABLE = Object.values(
  ADMIN_DASHBOARD_USER_GROUPS
).filter((g) => g.selectable);
