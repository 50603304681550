import { observer, useLocalObservable } from 'mobx-react';
import * as Portal from '@radix-ui/react-portal';
import { useStore } from '../../../store/store';
import { useEffect } from 'react';

const ArchivePopup = observer(({ archivedProjects = {}, toggle = () => {} }) => {
  const { projectStore } = useStore();
  const state = useLocalObservable(() => ({
    selectedProjectsToDelete: [],
    isConfirmationChecked: false
  }));

  const onDeleteClick = () => {
    projectStore.deleteProjects(state.selectedProjectsToDelete, toggle, toggle);
  };

  const isCheckboxChecked = (checkboxId) => {
    return state.selectedProjectsToDelete.includes(checkboxId);
  };

  const onCheckCheckbox = (checkboxId) => {
    const findIndex = state.selectedProjectsToDelete.indexOf(checkboxId);
    if (findIndex > -1) {
      state.selectedProjectsToDelete.splice(findIndex, 1);
    } else {
      state.selectedProjectsToDelete.push(checkboxId);
    }
  };

  const onCheckConfirmation = () => {
    state.isConfirmationChecked = !state.isConfirmationChecked;
  };

  return (
    <Portal.Root>
      <div className="transparent-overlay">
        <div className="container">
          <div className="title">Select the project(s) you want to delete</div>
          <div className="delete-projects-list">
            <div className="content">
              {archivedProjects.map((project) => {
                return (
                  <label key={project.id}>
                    <input
                      type="checkbox"
                      checked={isCheckboxChecked(project.id)}
                      onChange={() => onCheckCheckbox(project.id)}
                    />
                    {project.name}
                  </label>
                );
              })}
            </div>
          </div>
          <label className="validation-content">
            <input
              type="checkbox"
              checked={state.isConfirmationChecked}
              onChange={onCheckConfirmation}
            />
            I understand and confirm that all project and related data (feedback & outreaches) will
            be deleted and cannot be restored.
          </label>
          <div className="actions">
            <button onClick={toggle} className="btn btn-outline">
              Cancel
            </button>
            <button
              onClick={onDeleteClick}
              disabled={
                projectStore.isDeletingProjects ||
                state.selectedProjectsToDelete.length === 0 ||
                !state.isConfirmationChecked
              }
              className="btn btn-danger">
              {`Delete (${state.selectedProjectsToDelete.length})`}
            </button>
          </div>
        </div>
      </div>
    </Portal.Root>
  );
});

export default ArchivePopup;
