import { isInDevelopment } from './constants';

/**
 * Logs an error in the console if the environment is equal to 'development'.
 * @param {*} error - Error string or Error object.
 */
export const logError = (error = '') => {
  if (isInDevelopment) {
    console.error(error);
  }
};

/**
 * Guards the Google Analytics functions from breaking the app after new changes
 * @param {Function} func - A GA4 function to be called.
 */
export const gaEventGuard = (func = () => {}) => {
  if (typeof func !== 'function') {
    logError('[gaEventGuard] func param is not a function!');
    return;
  }

  try {
    func();
  } catch (err) {
    logError(err);
  }
};
