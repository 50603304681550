import { observer } from 'mobx-react';
import { useMemo } from 'react';
import {
  selectedProjectTabPageSize,
  SELECTED_PROJECT_TABS,
  encodeProjectsParams
} from '../constants';
import SearchResultsTabEntry from './SearchResultsTabEntry';
import ReactPaginate from 'react-paginate';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';

const SearchResultsTabView = observer(
  ({
    project = {},
    results = [],
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    params = {},
    excludeResult = () => {},
    isExcludingResult = false,
    displayMessagePopup
  }) => {
    const { utilsStore } = useStore();
    const { navigate } = useHistory();
    const isFund = project.searchType === 'funds';
    const isPortfolio = project.searchType === 'portfolios';
    const isProfile = project.searchType === 'investprofiles';

    const list = useMemo(() => {
      return (
        forTab === SELECTED_PROJECT_TABS.RESULTS
          ? results.filter((r) => !r.isExcluded)
          : results.filter((r) => r.isExcluded)
      )
        .slice()
        .sort((a, b) => a.index - b.index);
    }, [results.filter((r) => !r.isExcluded), forTab]);

    const showPagination = list.length > selectedProjectTabPageSize;
    const pages = Math.ceil(list.length / selectedProjectTabPageSize) || 1;
    const page = (params.selectedProjectTabPage || 1) - 1;

    return (
      <div className="table-responsive">
        {list.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header">
              <div className="item">
                {isFund ? 'Fund name' : isPortfolio ? 'Portfolio name' : 'Profile name'}
              </div>
              <div className="item">Industry focus</div>
              <div className="item">Geo focus</div>
              <div className="item">Enterprise value (€M)</div>
              <div className="item">Equity value (€M)</div>
              <div className="item feedback-item">Feedback results</div>
              <div className="item actions" />
            </div>

            {list
              .slice(
                (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
                params.selectedProjectTabPage * selectedProjectTabPageSize
              )
              .map((entry, idx) => (
                <SearchResultsTabEntry
                  key={entry.id}
                  entry={entry}
                  project={project}
                  type={project.searchType}
                  forTab={forTab}
                  excludeResult={excludeResult}
                  isExcludingResult={isExcludingResult}
                  displayMessagePopup={displayMessagePopup}
                />
              ))}

            {showPagination && (
              <ReactPaginate
                onPageChange={({ selected }) => {
                  navigate(
                    paths.PROJECTS +
                      '?params=' +
                      encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                  );
                  setTimeout(() => {
                    const el = document.getElementById('project-view-tab-1');
                    const header = document.querySelector('header');
                    if (el) {
                      const headerHeight = header?.clientHeight || 0;
                      window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                    }
                  }, 100);
                }}
                forcePage={page}
                pageCount={pages}
                pageRangeDisplayed={utilsStore.windowWidth < 480 ? 1 : selectedProjectTabPageSize}
                marginPagesDisplayed={utilsStore.windowWidth < 480 ? 1 : selectedProjectTabPageSize}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Prev"
                containerClassName="pagination-container"
                pageClassName="page"
                pageLinkClassName="pageLink"
                nextClassName="next"
                nextLinkClassName="nextLink"
                previousClassName="prev"
                previousLinkClassName="prevLink"
                breakClassName="break"
                breakLinkClassName="breakLink"
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default SearchResultsTabView;
