import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { UI_OPTIONS_UTILS } from '../../utils/constants/uiOptions';
import { mapOptions } from '../../api/dataMappers';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';

class UtilsStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;

    window.addEventListener('resize', () => this.setWindowWidth(window.innerWidth));

    this.loadOptions();
  }

  isLoadingOptions = false;
  options = null;

  loadOptions = () => {
    if (this.isLoadingOptions) {
      return;
    }

    this.isLoadingOptions = true;
    const res = { success: false };
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_PUBLIC_OPTIONS,
      onSuccess: (response) => {
        this.options = { ...UI_OPTIONS_UTILS, ...mapOptions(response) };
        res.success = true;
      },
      onError: () => {
        this.isLoadingOptions = false;
        this.loadOptions(); // non stop requests untill we get the options
      },
      onFinally: () => {
        if (res.success) {
          this.isLoadingOptions = false;
        }
      }
    });
  };

  getOptionName = (data = {}, fieldConfig = {}, optionKey = '') => {
    const options = this.options?.[optionKey || fieldConfig.VALUES_KEY];
    const fieldData = data?.[fieldConfig.NAME] || data;
    if (Array.isArray(fieldData)) {
      return fieldData?.map?.((v) => options?.find?.((o) => o.value === v)?.name || v) || [];
    } else {
      return options?.find?.((o) => o.value === fieldData)?.name || fieldData;
    }
  };

  shortenList = (list = [], maxLength = 3) => {
    if (list.length <= maxLength) {
      return list;
    }

    return list.slice(0, maxLength).concat(['...']);
  };

  windowWidth = window.innerWidth;
  setWindowWidth = (width = 0) => (this.windowWidth = width);

  isSessionExpired = false;
  setIsSessionExpired = (value = false) => {
    if (value === this.isSessionExpired) {
      return;
    }

    runInAction(() => {
      this.isSessionExpired = value;
    });
  };

  isMobileHamburgerOpen = false;
  setIsMobileHamburgerOpen = (value = false) => {
    if (value !== this.isMobileHamburgerOpen) {
      runInAction(() => {
        this.isMobileHamburgerOpen = value;
      });

      if (this.isMobileHamburgerOpen) {
        this.lockScroll(true, true);
        this.isLockScroll = null;
        window.scrollTo({ top: 0 });
      } else {
        this.lockScroll(false);
      }
    }
  };

  isDesktopMenuOpen = false;
  setIsDesktopMenuOpen = (value = false) => {
    if (value !== this.isDesktopMenuOpen) {
      runInAction(() => {
        this.isDesktopMenuOpen = value;
      });
    }
  };

  headerPinnedMessage = { message: '', type: HEADER_MESSAGE_TYPES.SUCCESS, id: null };
  setHeaderPinnedMessage = (message = '', type = HEADER_MESSAGE_TYPES.WARNING, id = null) => {
    runInAction(() => {
      this.headerPinnedMessage = { message, type, id };
    });
  };

  headerMessageTimestamp = 0;
  headerMessage = { message: '', type: HEADER_MESSAGE_TYPES.SUCCESS, isFading: false };
  setHeaderMessage = (message = '', type = HEADER_MESSAGE_TYPES.SUCCESS) => {
    runInAction(() => {
      this.headerMessage = { message, type, isFading: false };
      this.headerMessageTimestamp = new Date().getTime();
    });

    setTimeout(() => {
      if (this.headerMessageTimestamp + 3_000 <= new Date().getTime()) {
        runInAction(() => {
          this.headerMessage = { ...this.headerMessage, isFading: true };
        });
      }
    }, 3_001);

    setTimeout(() => {
      if (this.headerMessageTimestamp + 4_000 <= new Date().getTime()) {
        runInAction(() => {
          this.headerMessage = { message: '', type };
        });
      }
    }, 4_001);
  };

  isMobileFooterHidden = false;
  isMobileFeeter1200Hidden = false;
  setIsMobileFooterHidden = (value = false) => {
    if (value !== this.isMobileFooterHidden) {
      runInAction(() => {
        this.isMobileFooterHidden = value;
      });
    }
  };
  setIsMobileFooter1200Hidden = (value = false) => {
    if (value !== this.isMobileFeeter1200Hidden) {
      runInAction(() => {
        this.isMobileFeeter1200Hidden = value;
      });
    }
  };

  isLockScroll = false;
  lockScrollY = null;
  saveScrollPosition = false;
  returnToPosition = false;
  lockScroll = (lock = true, savePosition = false) => {
    if (lock) {
      this.lockScrollY = window.scrollY;
      this.saveScrollPosition = savePosition;
      this.isLockScroll = true;
    } else {
      this.returnToPosition = this.saveScrollPosition ? this.lockScrollY : null;
      this.isLockScroll = false;
      this.lockScrollY = null;
      this.saveScrollPosition = false;
    }
  };

  isRefreshingApp = false;
  setIsRefreshingApp = (value = true) => {
    runInAction(() => {
      this.isRefreshingApp = value;
    });
  };

  defaultRefresh = async () => {
    setTimeout(() => window.location.reload(), 1000);
    return new Promise((res) => setTimeout(() => res(true), 2000));
  };

  onPageRefresh = null; // async promise if refreshing data from page

  setOnPageRefresh = (onRefresh = null) => {
    runInAction(() => {
      this.onPageRefresh = onRefresh;
    });
  };

  refreshPage = async () => {
    this.setIsRefreshingApp(true);
    if (typeof this.onPageRefresh === 'function') {
      return this.onPageRefresh();
    } else {
      return this.defaultRefresh();
    }
  };
}

export default UtilsStore;
