import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { useEffect } from 'react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
// import MobileFilters from './components/MobileFilters';
import DesktopFilters from './components/DesktopFilters';
import {
  PROFILER_SEARCH_FIELDS,
  getErrorFields,
  compareFields,
  trimStateFields,
  isEmptyValue
} from '../../../utils/constants/fields';
import { getProfilerFilledSearchCategories, mapFieldsToState } from '../../../utils/utils';
import { mapData } from '../../../api/dataMappers';
import CompanyResult from './components/CompanyResult';
import { PROFILER_SEARCH_CATEGORIES } from '../../../utils/constants/searchCategories';
import { runInAction, observable } from 'mobx';
import { getResultSearchFields } from './constants/resultSearchFields';
import SaveSearchPopup from './components/SaveSearchPopup';
import NoResults from './components/NoResults';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import useHistory from '../../../hooks/useHistory';
import ReactPaginate from 'react-paginate';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import CompanyDetails from './components/CompanyDetails';

const savedState = observable({
  savedScrollY: {},
  saveScrollY: (searchId = null) => {
    savedState.savedScrollY[searchId] = window.scrollY;
  }
});

const pageSize = 8;

const ProfilerResults = observer(() => {
  const { navigate, location } = useHistory();
  const { profilerProjectStore, utilsStore, makeRequest } = useStore();

  const state = useLocalObservable(() => {
    return {
      isRendered: false,
      setIsRendered: (value = false) => (state.isRendered = value),
      results: [],
      page: 1,
      pages: 1,
      searchId: null,
      paginatedSearchId: null,
      isLoading: false,
      isInitialLoading: true,
      isMobileFiltersDisplayed: false,
      toggleMobileFilters: () => {
        state.isMobileFiltersDisplayed = !state.isMobileFiltersDisplayed;
        if (state.isMobileFiltersDisplayed) {
          window.scrollTo({ top: 0 });
        }
      },
      detailsDisplayed: null,
      displayDetails: (entry) => {
        if (entry) {
          savedState.saveScrollY(state.searchId);
        } else {
          setTimeout(() => {
            const savedScrollY = savedState.savedScrollY[state.searchId] || 0;
            window.scrollTo({ top: savedScrollY });
            runInAction(() => delete savedState.savedScrollY[state.searchId]);
          }, 20);
        }
        state.detailsDisplayed = entry;
      },
      saveSearchPopupDisplayed: null,
      toggleSaveProjectPopup: (entry) => {
        state.saveSearchPopupDisplayed = entry;
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
      },
      resultsError: null,
      failedReqParams: null,
      getResults: (searchFields = {}, searchId = null) => {
        state.isLoading = true;
        state.resultsError = false;
        state.searchId = null;
        const body = { ...mapData(searchFields, PROFILER_SEARCH_FIELDS, true) };
        if (['number', 'string'].includes(typeof searchId)) {
          body.searchId = searchId;
        }

        makeRequest({
          endpoint: API_ENDPOINTS.GET_PROFILER_SEARCH_RESULTS,
          body,
          onSuccess: ({ results, searchId: newSearchId }) => {
            if (!state.isRendered) {
              return;
            }

            state.currentSearch = JSON.parse(JSON.stringify(searchFields));
            state.failedReqParams = null;
            state.results = results;
            state.searchId = newSearchId;
          },
          onError: (errorMessage) => {
            if (!state.isRendered) {
              return;
            }

            state.resultsError = errorMessage || 'Failed to obtain search results';
            state.failedReqParams = JSON.parse(JSON.stringify([searchFields, searchId]));
          },
          onFinally: () => {
            if (!state.isRendered) {
              return;
            }

            state.isLoading = false;
            if (state.isInitialLoading) {
              state.isInitialLoading = false;
            }
          }
        });
      },
      currentSearch: {},
      fields: mapFieldsToState(PROFILER_SEARCH_FIELDS),
      setFieldValue: (field = {}, value) => {
        state.fields[field.NAME] = value;
      },
      get fieldsCount() {
        return getProfilerFilledSearchCategories(state.fields);
      },
      searchSectionsToggle: Object.fromEntries(
        Object.values(PROFILER_SEARCH_CATEGORIES).map((section) => [section, false])
      ),
      toggleSearchSection: (section) => {
        state.searchSectionsToggle[section] = !state.searchSectionsToggle[section];
      },
      onSubmitErrorState: false,
      setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
      isSavingSearch: false,
      get projectId() {
        return profilerProjectStore.allProjects.find((p) => p.searchId === state.searchId)?.id;
      },
      get validationFields() {
        return getErrorFields(Object.values(PROFILER_SEARCH_FIELDS), state.fields);
      },
      get isSearchDisabled() {
        return (
          state.isLoading ||
          state.isInitialLoading ||
          state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
          (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
          !compareFields(state.fields, state.currentSearch)
        );
      },
      onSearch: (e) => {
        e?.preventDefault?.();

        trimStateFields(state.fields);
        if (state.validationFields.invalidFields.length) {
          if (!state.onSubmitErrorState) {
            state.setOnSubmitErrorState(true);
          }
          return;
        }

        if (state.isMobileFiltersDisplayed) {
          state.toggleMobileFilters();
        }

        const fieldsParams = Object.entries(state.fields)
          .filter((field) => field[1].length)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');
        navigate(paths.PROFILER_SEARCH_RESULTS + `?page=1&${fieldsParams}`);
        document.getElementsByClassName('page-search-listing')[0].scrollTo({ top: 0 });
      },
      onSaveSearch: (searchName, onSuccess, onError, onFinally) => {
        state.isSavingSearch = true;
        profilerProjectStore.saveProject(state.searchId, searchName, onSuccess, onError, () => {
          state.isSavingSearch = false;
          onFinally();
        });
      },
      onExcludeResult: (entry, exclude = true) => {
        makeRequest({
          endpoint: API_ENDPOINTS.EXCLUDE_PROFILER_PROJECT_RESULT,
          body: {
            ...(state.projectId ? { projectId: state.projectId } : { searchId: state.searchId }),
            companyId: entry.id,
            exclude
          },
          onSuccess: () => {
            entry.isExcluded = exclude;
          },
          onError: (errorMessage) => {
            utilsStore.setHeaderMessage(
              errorMessage || `Failed to exclude result with name ${entry.name}.`,
              HEADER_MESSAGE_TYPES.ERROR
            );
          }
        });
      },
      isExcludedExpanded: false,
      toggleExcludedExpanded: () => (state.isExcludedExpanded = !state.isExcludedExpanded)
    };
  });

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
      runInAction(() => delete savedState.savedScrollY[state.searchId]);
    };
  }, [state]);

  useEffect(() => {
    return () => {
      // close all the popups when exiting the page so the lock scroll can be released
      if (state.detailsDisplayed) {
        state.displayDetails(null);
      }

      if (state.saveSearchPopupDisplayed) {
        state.toggleSaveProjectPopup(null);
      }
    };
  }, [state]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let hasInvalidParam = false;
    let searchFields = null;
    try {
      searchFields = Object.values(PROFILER_SEARCH_FIELDS).reduce((fields, f) => {
        fields[f.NAME] = JSON.parse(decodeURIComponent(urlParams.get(f.NAME)));
        if (f.REQUIRED && isEmptyValue(fields[f.NAME])) {
          hasInvalidParam = true;
        }
        return fields;
      }, {});
    } catch (err) {
      hasInvalidParam = true;
    }

    if (hasInvalidParam) {
      runInAction(() => {
        state.resultsError = 'Invalid search params were found in the URL.';
        if (state.isInitialLoading) {
          state.isInitialLoading = false;
        }
      });
      return;
    } else {
      runInAction(() => {
        const initial = mapFieldsToState(PROFILER_SEARCH_FIELDS);
        Object.entries(searchFields).forEach(([fieldName, fieldValue]) => {
          state.fields[fieldName] = fieldValue || initial[fieldName];
        });
      });

      const initial = mapFieldsToState(PROFILER_SEARCH_FIELDS);
      const queryFields = Object.fromEntries(
        Object.entries(searchFields).map(([fieldName, fieldValue]) => [
          fieldName,
          fieldValue || initial[fieldName]
        ])
      );

      if (compareFields(queryFields, state.currentSearch)) {
        const foundSearchId = urlParams.get('searchId');
        state.getResults(searchFields, foundSearchId);
      }
    }
  }, [state, location.search]);

  useEffect(() => {
    if (state.isLoading || state.isInitialLoading || !Object.values(state.currentSearch).length) {
      return;
    }

    const fieldsParams = Object.entries(state.currentSearch)
      .filter((field) => field[1].length)
      .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
      .join('&');

    let pages = Math.ceil(state.results.filter((r) => !r.isExcluded).length / pageSize) || 1;
    const urlParams = new URLSearchParams(location.search);
    const foundPage = urlParams.get('page');
    let page = foundPage ? +foundPage : 1;
    if (isNaN(page) || page < 1 || page > pages) {
      if (isNaN(page) || page < 1) {
        page = 1;
        navigate(
          paths.PROFILER_SEARCH_RESULTS +
            `?page=1&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
        );
      } else if (page > pages) {
        page = pages || 1;
        navigate(
          paths.PROFILER_SEARCH_RESULTS +
            `?page=${pages}&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
        );
      }
    } else {
      if (state.page !== page || state.pages !== pages) {
        runInAction(() => {
          state.page = page || 1;
          state.pages = pages;
        });
      }
    }

    if (state.paginatedSearchId !== state.searchId) {
      runInAction(() => (state.paginatedSearchId = state.searchId));
      navigate(
        paths.PROFILER_SEARCH_RESULTS +
          `?page=${page}&${state.searchId ? `searchId=${state.searchId}&` : ''}${fieldsParams}`
      );
    }
  }, [state, location.search, state.searchId, state.results.filter((r) => !r.isExcluded).length]);

  const fields = getResultSearchFields(state);

  const isLoading = state.isLoading || state.isInitialLoading;
  const hasResults = !!state.results.length;
  const hasError = !!state.resultsError;
  const showDetailsPopup = !!state.detailsDisplayed;
  const showSaveSearchPopup = !!state.saveSearchPopupDisplayed;
  const showNoResults = !isLoading && !hasError && !hasResults;
  const showResults = !isLoading && !hasError && hasResults;
  const showPaging = showResults && state.results.length > pageSize;
  const canDownload = ['number', 'string'].includes(typeof state.searchId) && hasResults;
  const disableSaveSearch = !canDownload || profilerProjectStore.isLoadingProjects;
  const canSaveSearch = canDownload && !state.projectId;
  const fillSaveSearchIcon = !!state.projectId;
  const excludedResults = state.results.filter((entry) => entry.isExcluded);

  return (
    <StaticPageLayout page="search-listing" hideMobileFooter={true}>
      {showDetailsPopup && (
        <CompanyDetails company={state.detailsDisplayed} displayDetails={state.displayDetails} />
      )}
      {showSaveSearchPopup && (
        <SaveSearchPopup
          toggleSaveProjectPopup={state.toggleSaveProjectPopup}
          onSaveSearch={state.onSaveSearch}
        />
      )}
      {/* {state.isMobileFiltersDisplayed && !showDetailsPopup && (
        <MobileFilters
          onToggle={state.toggleMobileFilters}
          fields={fields}
          isSearchDisabled={state.isSearchDisabled}
          onSearch={state.onSearch}
        />
      )} */}
      {!state.isMobileFiltersDisplayed && !showDetailsPopup && (
        <div className="listing-layout">
          <DesktopFilters
            fields={fields}
            fieldsCount={state.fieldsCount}
            searchSectionsToggle={state.searchSectionsToggle}
            toggleSearchSection={state.toggleSearchSection}
            onSearch={state.onSearch}
            isSearchDisabled={state.isSearchDisabled}
          />
          <div className="results">
            <div className="mobile-filters">
              <div className="total-number">
                {!state.isInitialLoading && !state.isLoading
                  ? `${state.results.length} results`
                  : ' '}
              </div>
              <div className="filters-download-container">
                <div className="filters-btn" onClick={state.toggleMobileFilters}>
                  Filters
                </div>
                <div className="actions">
                  <IconButton
                    icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled={fillSaveSearchIcon}
                    onClick={() => {
                      if (canSaveSearch && !state.isSavingSearch) {
                        state.toggleSaveProjectPopup(true);
                      }
                    }}
                    disabled={disableSaveSearch || state.isSavingSearch}
                    tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                  />
                  <IconButton
                    icon={ICON_BUTTON_ICONS.DOWNLOAD}
                    type={ICON_BUTTON_TYPES.BLUE}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_API_BASEURL}/public/downloadSearch?searchId=${state.searchId}`,
                        '_blank',
                        'noopener, noreferrer'
                      );
                    }}
                    disabled={!canDownload}
                    tooltipText="Download search"
                  />
                </div>
              </div>
            </div>

            <div className="results-header">
              <div className="cols">
                <div className="col">Company name</div>
                <div className="col">Company HQ</div>
                <div className="col">Industry</div>
                <div className="col">Value chain</div>
                <div className="col">Products</div>
                <div className="col right-align">Revenue (€M)</div>
                <div className="col right-align">Employees</div>
              </div>
              <div className="actions">
                <div className="total-num">
                  {!state.isInitialLoading && !state.isLoading
                    ? `${state.results.length} results`
                    : ' '}
                </div>
                <div className="buttons">
                  <IconButton
                    icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled={fillSaveSearchIcon}
                    onClick={() => {
                      if (canSaveSearch && !state.isSavingSearch) {
                        state.toggleSaveProjectPopup(true);
                      }
                    }}
                    disabled={disableSaveSearch || state.isSavingSearch}
                    tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                  />
                  <IconButton
                    icon={ICON_BUTTON_ICONS.DOWNLOAD}
                    type={ICON_BUTTON_TYPES.BLUE}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_API_BASEURL}/public/downloadProfilerSearch?searchId=${state.searchId}`,
                        '_blank',
                        'noopener, noreferrer'
                      );
                    }}
                    disabled={!canDownload}
                    tooltipText="Download search"
                  />
                </div>
              </div>
            </div>

            {isLoading && <GeneralLoader />}

            {hasError && (
              <GeneralError
                errorMessage={state.resultsError}
                actionMessage="You may want to:"
                {...(state.failedReqParams
                  ? {
                      actionButtontext: 'Try again',
                      onActionButtonClick: () => state.getResults(...state.failedReqParams)
                    }
                  : { withHomePageButton: true })}
                withLogoutButton
              />
            )}

            {showNoResults && <NoResults />}

            {showResults &&
              state.results
                .filter((entry) => !entry.isExcluded)
                .slice((state.page - 1) * pageSize, state.page * pageSize)
                .map((company) => (
                  <CompanyResult
                    key={company.id}
                    company={company}
                    displayDetails={state.displayDetails}
                    excludeResult={state.onExcludeResult}
                  />
                ))}

            {showResults && !!excludedResults.length && (
              <div className="excluded-results-wrap">
                <div className="excluded-results-box">
                  <div
                    className={`excluded-results-header${
                      state.isExcludedExpanded ? ' expanded' : ''
                    }`}>
                    <div className="excluded-results-header-text">
                      Excluded results ({excludedResults.length})
                    </div>
                    <IconButton
                      type={ICON_BUTTON_TYPES.DEFAULT}
                      icon={
                        state.isExcludedExpanded
                          ? ICON_BUTTON_ICONS.CHEVRON_UP
                          : ICON_BUTTON_ICONS.CHEVRON_DOWN
                      }
                      innerText={state.isExcludedExpanded ? 'Collapse' : 'Expand  '}
                      iconOnRight
                      withBorder={false}
                      onClick={state.toggleExcludedExpanded}
                      hoverType={ICON_BUTTON_TYPES.BLUE}
                    />
                  </div>
                  {state.isExcludedExpanded && (
                    <div>
                      {excludedResults.map((company) => (
                        <CompanyResult
                          key={company.id}
                          company={company}
                          displayDetails={state.displayDetails}
                          excludeResult={state.onExcludeResult}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {showPaging && (
              <ReactPaginate
                onPageChange={({ selected }) => {
                  const fieldsParams = Object.entries(state.currentSearch)
                    .filter((field) => field[1].length)
                    .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
                    .join('&');
                  navigate(
                    paths.PROFILER_SEARCH_RESULTS +
                      `?page=${selected + 1}&${
                        state.searchId ? `searchId=${state.searchId}&` : ''
                      }${fieldsParams}`
                  );
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                forcePage={state.page - 1}
                pageRangeDisplayed={utilsStore.windowWidth < 480 ? 1 : pageSize}
                marginPagesDisplayed={utilsStore.windowWidth < 480 ? 1 : pageSize}
                pageCount={state.pages}
                renderOnZeroPageCount={null}
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Prev"
                containerClassName="pagination-container"
                pageClassName="page"
                pageLinkClassName="pageLink"
                nextClassName="next"
                nextLinkClassName="nextLink"
                previousClassName="prev"
                previousLinkClassName="prevLink"
                breakClassName="break"
                breakLinkClassName="breakLink"
              />
            )}
          </div>
        </div>
      )}
    </StaticPageLayout>
  );
});

export default ProfilerResults;
