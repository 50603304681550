import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import InvestorObjectButton from './InvestorObjectButton';

const ArchivedOutreachesSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isArchivedOutreachesExpanded, toggle_d_ArchivedOutreaches, outreachesList } =
    outreachStore;

  return (
    <>
      <CollapsableSectionHead
        title="Archived outreaches"
        icon={ICON_BUTTON_ICONS.ARCHIVE}
        isCollapsed={!d_isArchivedOutreachesExpanded}
        toggleCollapse={toggle_d_ArchivedOutreaches}
        count={outreachesList.archivedNewMessagesCount}
        showEmptyDot={
          !outreachesList.activeNewMessagesCount &&
          Object.values(outreachesList.archived).findIndex((obj) => obj.hasMarkedUnread) !== -1
        }
      />
      {d_isArchivedOutreachesExpanded &&
        Object.entries(outreachesList.archived)
          .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
          .map(([objectId, object]) => {
            return (
              <InvestorObjectButton
                key={objectId}
                objectId={objectId}
                object={object}
                params={params}
                list={outreachesList.archived}
                type="archived"
              />
            );
          })}
      {d_isArchivedOutreachesExpanded && <div className="separator" />}
    </>
  );
});

export default ArchivedOutreachesSection;
