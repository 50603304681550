import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import useHistory from '../../hooks/useHistory';
import {
  decodeAllInvestmentsParams,
  INV_TYPE_TABS,
  invTypePageSize,
  encodeAllInvestmentsParams
} from './constants';
import { paths } from '../../utils/constants/routes';
import { useEffect, useMemo } from 'react';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import GeneralError from '../../components/errors/GeneralError';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import AllInvestmentsList from './components/AllInvestmentsList';

const AllInvestments = observer(() => {
  const { location, navigate } = useHistory();
  const { fundsStore, authStore } = useStore();

  const state = useLocalObservable(() => ({
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    }
  }));

  useEffect(() => {
    if (fundsStore.isLoadingData) {
      return;
    }

    // params - tab, page

    let [urlParams, params, shouldNavigate] = [null, {}, false];
    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeAllInvestmentsParams(urlParams.get('params'));
    } catch (ex) {
      params = { tab: INV_TYPE_TABS.FUNDS, page: 1 };
    }

    if (typeof params !== 'object' || params === null || params === undefined) {
      params = {};
    }

    if (authStore.isCoraporatesInvestor && params.tab !== INV_TYPE_TABS.INVEST_PROFILES) {
      params.tab = INV_TYPE_TABS.INVEST_PROFILES;
      shouldNavigate = true;
    }

    if (
      !authStore.isCoraporatesInvestor &&
      ![INV_TYPE_TABS.FUNDS, INV_TYPE_TABS.PORTFOLIOS].includes(params.tab)
    ) {
      params.tab = INV_TYPE_TABS.FUNDS;
      shouldNavigate = true;
    }

    const list =
      params.tab === INV_TYPE_TABS.FUNDS
        ? fundsStore.allFunds
        : params.tab === INV_TYPE_TABS.PORTFOLIOS
        ? fundsStore.allPortfolios
        : fundsStore.allInvestProfiles;
    let pages = Math.ceil(list.length / invTypePageSize);
    if (pages === 0) {
      pages = 1;
    }

    if (isNaN(params.page) || params.page > pages || params.page < 1) {
      if (isNaN(params.page) || params.page < 1) {
        params.page = 1;
      } else if (params.page > pages) {
        params.page = pages;
      }
      shouldNavigate = true;
    }

    if (shouldNavigate) {
      navigate(paths.ALL_INVESTMENTS + '?params=' + encodeAllInvestmentsParams(params));
    }

    state.setParams(params);
  }, [
    state,
    fundsStore,
    fundsStore.allFunds,
    fundsStore.allInvestProfiles,
    fundsStore.allPortfolios,
    fundsStore.isLoadingData,
    location.search
  ]);

  const list = useMemo(() => {
    let selectedList = [];
    if (state.params.tab === INV_TYPE_TABS.FUNDS) {
      selectedList = fundsStore.allFunds;
    } else if (state.params.tab === INV_TYPE_TABS.PORTFOLIOS) {
      selectedList = fundsStore.allPortfolios;
    } else if (state.params.tab === INV_TYPE_TABS.INVEST_PROFILES) {
      selectedList = fundsStore.allInvestProfiles;
    }
    return selectedList;
  }, [fundsStore, state.params]);

  if (fundsStore.initialLoad || (fundsStore.isLoading && !list.length)) {
    return <GeneralLoader />;
  }

  const showError = fundsStore.loadDataError && !list.length;
  const showList = !showError;

  return (
    <StaticPageLayout page="all-investments-listing" hideMobileFooter={true}>
      {showError && (
        <GeneralError
          errorMessage={fundsStore.loadDataError}
          actionMessage="You may want to:"
          withHomePageButton={true}
          actionButtontext="Try again"
          onActionButtonClick={fundsStore.loadFunds}
        />
      )}
      {showList && <AllInvestmentsList key={state.params.tab} list={list} params={state.params} />}
    </StaticPageLayout>
  );
});

export default AllInvestments;
