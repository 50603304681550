import { observer } from 'mobx-react';
import * as Portal from '@radix-ui/react-portal';
import { useStore } from '../../../store/store';

const ProfilerArchivePopup = observer(({ project = {}, toggle = () => {} }) => {
  const { profilerProjectStore } = useStore();

  const isArchive = project.status === 'ACTIVE';

  const onArchiveSuccess = () => {
    project.status = isArchive ? 'ARCHIVED' : 'ACTIVE';
  };
  const onArchiveClick = () => {
    profilerProjectStore.archiveProject(project.id, isArchive, onArchiveSuccess);
    toggle();
  };

  return (
    <Portal.Root>
      <div className="transparent-overlay">
        <div className="container">
          <div className="title">{`Are you sure you want to ${
            isArchive ? 'archive' : 'unarchive'
          } ${project.name}?`}</div>
          <div className="actions">
            <button onClick={toggle} className="btn btn-outline">
              Cancel
            </button>
            <button
              onClick={onArchiveClick}
              disabled={profilerProjectStore.isArchivingProject}
              className="btn btn-primary">
              {isArchive ? 'Archive' : 'Unarchive'}
            </button>
          </div>
        </div>
      </div>
    </Portal.Root>
  );
});

export default ProfilerArchivePopup;
