import * as Portal from '@radix-ui/react-portal';
import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import { useEffect } from 'react';
import { mapFieldsToState } from '../../../utils/utils';
import { REQUEST_FEEDBACK_OUTREACH_FIELDS, getErrorFields } from '../../../utils/constants/fields';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { runInAction } from 'mobx';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import TextArea from '../../../components/inputs/TextArea';

const RequestFeedbackOutreachPopup = observer(({ onClose, outreachId }) => {
  const { outreachStore, authStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    isRendered: false,
    setIsRendered: (value = false) => (state.isRendered = value),
    fields: mapFieldsToState(REQUEST_FEEDBACK_OUTREACH_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(REQUEST_FEEDBACK_OUTREACH_FIELDS), state.fields);
    },
    get isRequestDisabled() {
      return (
        outreachStore.isRequestingOutreachFeedback ||
        authStore.isLoadingCompanyUsers ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    get isFieldsDisabled() {
      return outreachStore.isRequestingOutreachFeedback || authStore.isLoadingCompanyUsers;
    },
    get usersList() {
      const users = authStore.companyUsers
        .filter((u) => u.id !== authStore.currentUserId)
        .map((u) => ({
          name: u.firstName + ' ' + u.lastName + ' • ' + u.jobPosition,
          value: u.id
        }));

      for (let i = 0; i < users.length; i++) {
        for (let j = i + 1; j < users.length; j++) {
          if (users[j].name === users[i].name) {
            users[j].name += ' ';
          }
        }
      }

      return users;
    },
    isRequestSent: false,
    requestError: null,
    individualFeedback: false,
    requestOutreachFeedback: (requestSeparateFeedbacks = false) => {
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      state.requestError = null;
      outreachStore.requestOutreachFeedback(
        outreachId,
        state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS.NAME],
        state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.MESSAGE.NAME].trim(),
        requestSeparateFeedbacks,
        () => {
          if (state.isRendered) {
            state.isRequestSent = true;
            state.individualFeedback = requestSeparateFeedbacks;
          } else {
            utilsStore.setHeaderMessage(state.successText + ' ' + state.displayUsers);
          }

          // const outreach = outreachStore.outreaches.find((o) => o.id === outreachId);
        },
        (errorMessage) => {
          if (state.isRendered) {
            state.requestError = errorMessage || 'Failed to request outreach feedback.';
          } else {
            utilsStore.setHeaderMessage(
              errorMessage || 'Failed to request outreach feedback.',
              HEADER_MESSAGE_TYPES.ERROR
            );
          }
        }
      );
    },
    get canSendIndividually() {
      return state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS.NAME].length > 1;
    },
    get wasIndividualFeedback() {
      return (
        state.individualFeedback ||
        state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS.NAME].length < 2
      );
    },
    get successText() {
      return state.wasIndividualFeedback
        ? 'You have successfully requested individual feedback from:'
        : 'You have successfully requested a group feedback from:';
    },
    get displayUsers() {
      const selectedUsersIds = state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS.NAME];
      return selectedUsersIds
        .map((uId) => {
          const userInfo = authStore.companyUsers.find((u) => u.id === uId);
          return userInfo.firstName + ' ' + userInfo.lastName + ' • ' + userInfo.jobPosition;
        })
        .join(', ');
    }
  }));

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, [state]);

  useEffect(() => {
    authStore.loadCompanyUsers();
  }, [authStore]);

  useEffect(() => {
    if (!authStore.isLoadingCompanyUsers && state.usersList.length === 1) {
      runInAction(() => {
        state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS.NAME] = [state.usersList[0].value];
      });
    }
  }, [authStore.isLoadingCompanyUsers, state.usersList]);

  return (
    <Portal.Root>
      <div className="desktop-forward-outreach-popup-closer" onClick={onClose} />
      {outreachStore.isRequestingOutreachFeedback ? (
        <div className="desktop-forward-outreach-popup">
          <div className="dfop-head">
            <div className="dfop-head-text"></div>
            <IconButton
              innerText="Close"
              icon={ICON_BUTTON_ICONS.X_LG}
              iconOnRight
              withBorder={false}
              withFill={false}
              onClick={onClose}
            />
          </div>
          <GeneralLoader />
        </div>
      ) : state.isRequestSent ? (
        <div className="desktop-forward-outreach-popup">
          <div className="dfop-head">
            <div className="dfop-head-text"></div>
            <IconButton
              innerText="Close"
              icon={ICON_BUTTON_ICONS.X_LG}
              iconOnRight
              withBorder={false}
              withFill={false}
              onClick={onClose}
            />
          </div>
          <div className="dfop-success-wrap">
            <img className="dfop-success-icon" src={ICON_BUTTON_ICONS.CHECK_CIRCLE} />
            <div className="dfop-success-text">
              {state.successText}
              <div className="subtext">{state.displayUsers}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="desktop-forward-outreach-popup">
          <div className="dfop-head">
            <div className="dfop-head-text">Select colleague(s) to request feedback from.</div>
            <IconButton
              innerText="Close"
              icon={ICON_BUTTON_ICONS.X_LG}
              iconOnRight
              withBorder={false}
              withFill={false}
              onClick={onClose}
            />
          </div>
          <div className="dfop-separator" />
          <MultiSelect
            field={REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS}
            value={state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.USERS.NAME]}
            values={state.usersList}
            setFieldValue={state.setFieldValue}
            disabled={state.isFieldsDisabled}
            inputWrap={{ enable: true, className: 'field' }}
          />
          <TextArea
            field={REQUEST_FEEDBACK_OUTREACH_FIELDS.MESSAGE}
            value={state.fields[REQUEST_FEEDBACK_OUTREACH_FIELDS.MESSAGE.NAME]}
            setFieldValue={state.setFieldValue}
            disabled={state.isFieldsDisabled}
            messages={state.validationFields.messages}
            showOnSubmitErrorState={true}
            inputWrap={{ enable: true, className: 'field' }}
          />
          {!!state.requestError && <div className="error">{state.requestError}</div>}
          <div className="forward-button-wrap">
            <div className="buttons-right-wrap">
              {state.canSendIndividually && (
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  withBorder={true}
                  withFill={false}
                  innerText={'Send individually'}
                  disabled={state.isRequestDisabled}
                  onClick={() => state.requestOutreachFeedback(true)}
                  id="request-feedback-button"
                />
              )}
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                filled
                innerText={state.canSendIndividually ? 'Send to group' : 'Send'}
                disabled={state.isRequestDisabled}
                onClick={() => state.requestOutreachFeedback(false)}
              />
            </div>
          </div>
        </div>
      )}
    </Portal.Root>
  );
});

export default RequestFeedbackOutreachPopup;
