import { useStore } from '../store/store';
import { useEffect } from 'react';

const useAppScrollEffect = () => {
  const { utilsStore } = useStore();

  useEffect(() => {
    if (utilsStore.isLockScroll) {
      const handleScrollbar = () => {
        window.scrollTo({ top: utilsStore.lockScrollY });
      };

      window.addEventListener('scroll', handleScrollbar, { capture: true });

      return () => {
        window.removeEventListener('scroll', handleScrollbar, { capture: true });
      };
    } else if (
      typeof utilsStore.returnToPosition === 'number' &&
      utilsStore.isLockScroll !== null
    ) {
      window.scrollTo({ top: utilsStore.returnToPosition });
    }
  }, [utilsStore.isLockScroll, utilsStore.returnToPosition]);
};

export default useAppScrollEffect;
