import { observer } from 'mobx-react';
import { Fragment } from 'react';

const SearchFormMobileSection = observer(({ section = null, onBack = null, fields = {} }) => {
  return (
    <div>
      <button className="backlink" onClick={() => onBack()}>
        Search
      </button>
      <div className="mobile-fields">
        {Object.values(fields[section]).map((field, idx) => (
          <Fragment key={idx}>{field}</Fragment>
        ))}
      </div>
      <div className="mobile-fields-mandatory-row">*mandatory fields</div>
    </div>
  );
});

export default SearchFormMobileSection;
