import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import InvestorObjectButton from './InvestorObjectButton';

const ForwardedOutreachesSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isForwardedOutreachesExpanded, toggle_d_ForwardedOutreaches, outreachesList } =
    outreachStore;

  return (
    <>
      <CollapsableSectionHead
        title="Forwarded outreaches"
        icon={ICON_BUTTON_ICONS.ARROW_RIGHT_SQUARE}
        isCollapsed={!d_isForwardedOutreachesExpanded}
        toggleCollapse={toggle_d_ForwardedOutreaches}
        count={outreachesList.forwardedNewMessagesCount}
        showEmptyDot={
          !outreachesList.activeNewMessagesCount &&
          Object.values(outreachesList.forwarded).findIndex((obj) => obj.hasMarkedUnread) !== -1
        }
      />
      {d_isForwardedOutreachesExpanded &&
        Object.entries(outreachesList.forwarded)
          .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
          .map(([objectId, object]) => {
            return (
              <InvestorObjectButton
                key={objectId}
                objectId={objectId}
                object={object}
                params={params}
                list={outreachesList.forwarded}
                type="forwarded"
              />
            );
          })}
      {d_isForwardedOutreachesExpanded && <div className="separator" />}
    </>
  );
});

export default ForwardedOutreachesSection;
