import StaticPageLayout from '../../../components/layouts/StaticPageLayout';

const CookiePolicy = () => {
  return (
    <StaticPageLayout page="tos">
      <h1>Cookie Policy</h1>

      <div className="content-header">Last Updated: June 26, 2023</div>
      <div className="content-body">
        Welcome to Transact Digital! This Cookie Policy explains how Transact Digital
        (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) uses cookies and similar technologies
        on our online platform to connect investors with businesses looking for funding. By
        accessing or using our platform, you agree to the use of cookies as described in this
        policy.
      </div>
      <div className="content-body">
        <div className="content-large">What are cookies?</div>
        Cookies are small text files that are placed on your device when you visit a website or use
        an online service. They are widely used to make websites work more efficiently, as well as
        to provide information to the website owners.
      </div>
      <div className="content-body">
        <div className="content-large">How do we use cookies?</div>
        We use cookies and similar technologies for various purposes, including:
        <ol>
          <li>
            Authentication and Security: Cookies help us identify and authenticate users, and
            enhance the security of our platform. They enable you to log in to your account securely
            and help protect your personal information from unauthorized access.
          </li>
          <li>
            Preferences and Settings: Cookies remember your preferences and settings, such as
            language preferences and display settings, to provide you with a personalized experience
            on our platform.
          </li>
          <li>
            Analytics and Performance: We use cookies to collect information about how you interact
            with our platform, including the pages you visit and any errors you may encounter. These
            cookies help us analyze and improve the performance and usability of our platform.
          </li>
          <li>
            Advertising and Marketing: We may use cookies to deliver personalized advertisements to
            you based on your interests and browsing behavior. These cookies help us show relevant
            ads to the right audience and measure the effectiveness of our advertising campaigns.
          </li>
          <li>
            Third-Party Cookies: We may allow third-party service providers, such as analytics and
            advertising partners, to use cookies on our platform. These cookies are subject to the
            respective privacy policies of these third parties.
          </li>
        </ol>
      </div>
      <div className="content-body">
        <div className="content-large">Your Cookie Choices</div>
        You have the option to manage or disable cookies on your device. Most web browsers allow you
        to control cookies through their settings. However, please note that disabling certain
        cookies may affect the functionality and user experience of our platform.
      </div>
      <div className="content-body">
        If you do not wish to accept cookies, you can usually modify your browser settings to
        decline cookies or alert you when a cookie is being sent. Please refer to your
        browser&apos;s help documentation for more information on how to manage cookies.
      </div>
      <div className="content-body">
        <div className="content-large">
          Please note that our platform may not function properly if you disable cookies.
        </div>
      </div>
      <div className="content-body">
        <div className="content-large">Updates to this Cookie Policy</div>
        We may update this Cookie Policy from time to time to reflect changes in our practices or
        for legal or regulatory reasons. We encourage you to review this policy periodically for any
        updates.
      </div>
      <div className="content-body">
        <div className="content-large">Contact Us</div>
      </div>
      <div className="content-body">
        <div className="content-large">
          If you have any questions or concerns about our Cookie Policy, please contact us at [email
          protected]
        </div>
      </div>
      <div className="content-body">
        <div className="content-large">
          By using Transact Digital, you consent to the use of cookies and similar technologies as
          described in this Cookie Policy.
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default CookiePolicy;
