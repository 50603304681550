export const paths = {
  HOME: '/',
  HOME_FULL: '/en/home',
  FORGOTTEN_PASSWORD: '/en/forgotten',
  SIGNUP: '/en/registration',
  LOGIN: '/en/login',
  USER_VERIF_PROGRESS: '/en/verification-progress',
  TERMS_N_CONDITIONS: '/en/terms-and-conditions',
  COOKIE_POLICY: '/en/cookie-policy',
  PRIVACY_POLICY: '/en/privacy-policy',
  IMPRINT: '/en/imprint',
  USER_PROFILE: '/en/profile',
  USER_PROFILE_SETTINGS: '/en/profile/settings',
  USER_PROFILE_SETTINGS_CHANGE_PASSWORD: '/en/profile/settings/change-password',
  USER_PROFILE_SETTINGS_DEACTIVATE_ACCOUNT: '/en/profile/settings/deactivate-account',
  SEARCH_RESULTS: '/en/search-results',
  COMPANY_PROFILE: '/en/company-profile',
  CREATE_COMPANY_PROFILE: '/en/company-profile/create',
  FUNDS: '/en/funds',
  FUNDS_CREATE: '/en/funds/create',
  FUNDS_EDIT: '/en/funds/edit',
  PORTOFLIO_COMPANIES: '/en/portfolio-companies',
  PORTOFLIO_COMPANIES_CREATE: '/en/portfolio-companies/create',
  PORTOFLIO_COMPANIES_EDIT: '/en/portfolio-companies/edit',
  INVEST_PROFILES: '/en/invest-profiles',
  INVEST_PROFILES_CREATE: '/en/invest-profiles/create',
  INVEST_PROFILES_EDIT: '/en/invest-profiles/edit',
  ALL_INVESTMENTS: '/en/all-investments',
  PROJECTS: '/en/projects',
  TEAM: '/en/team',
  OUTREACH: '/en/outreach',
  ADMIN_DASHBOARD: '/en/admin-dashboard',
  PROFILER: '/en/profiler',
  PROFILER_SEARCH_RESULTS: '/en/profiler-search-results',
  PROFILER_PROJECTS: '/en/profiler-projects',
  PROFILER_TEAM: '/en/profiler-team',
  PROFILER_WATCH_LIST: '/en/profiler-watch-list'
};

export const routes = {
  USER_PROFILE_PATH: '/en/profile/:section?/:subsection?',
  FUNDS_PATH: '/en/funds/:component?',
  PORTFOLIO_COMPANIES_PATH: '/en/portfolio-companies/:component?',
  INVEST_PROFILES_PATH: '/en/invest-profiles/:component?'
};
