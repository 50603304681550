import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import { paths } from '../../../utils/constants/routes';
import { encodeProjectsParams } from '../../Projects/constants';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import useHistory from '../../../hooks/useHistory';
import { useStore } from '../../../store/store';

const icons = {
  pending: ICON_BUTTON_ICONS.HOURGLASS,
  resolved: ICON_BUTTON_ICONS.SQUARE_CHECK
};
const RequestTypeButton = observer(({ type, params }) => {
  const { projectStore } = useStore();
  const { navigate, goBack } = useHistory();

  const currentButtonConfig = useMemo(() => {
    const configuration = {
      [SHARED_PROJECT_TYPE_TABS.PENDING]: {
        icon: icons[type],
        name: `Open requests (${projectStore.pendingSharedProjects.length})`,
        onClick: () => {
          navigate(
            paths.TEAM +
              '?params=' +
              encodeProjectsParams({ projectTypeTab: SHARED_PROJECT_TYPE_TABS.PENDING })
          );
        },
        isActive: params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
      },
      [SHARED_PROJECT_TYPE_TABS.RESOLVED]: {
        icon: icons[type],
        name: `Resolved requests (${projectStore.resolvedSharedProjects.length})`,
        onClick: () => {
          navigate(
            paths.TEAM +
              '?params=' +
              encodeProjectsParams({ projectTypeTab: SHARED_PROJECT_TYPE_TABS.RESOLVED })
          );
        },
        isActive: params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED
      }
    };
    return configuration[type];
  }, [
    type,
    params.projectTypeTab,
    projectStore.pendingSharedProjects.length,
    projectStore.resolvedSharedProjects.length
  ]);

  return (
    <div
      className={`noselect shared-project-button ${!!currentButtonConfig.isActive && 'selected'}`}
      onClick={currentButtonConfig.onClick}>
      <img className="icon-title" src={currentButtonConfig.icon} />
      <div className="head-title">{currentButtonConfig.name}</div>
    </div>
  );
});

export default RequestTypeButton;
