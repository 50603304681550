import { observer } from 'mobx-react';
import { FUND_FIELDS } from '../../../../utils/constants/fields';
import { formatPercent, formatNumberRange } from '../../../../utils/utils';

const FundInformation = observer(({ entry }) => {
  return (
    <div className="content">
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.REVENUE_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[FUND_FIELDS.REVENUE_RANGE_MIN.NAME],
            entry[FUND_FIELDS.REVENUE_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.EBITDA_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[FUND_FIELDS.EBITDA_RANGE_MIN.NAME],
            entry[FUND_FIELDS.EBITDA_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.EBITDA_MARGIN.LABEL}</div>
        <div className="value">{formatPercent(entry[FUND_FIELDS.EBITDA_MARGIN.NAME])}</div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.ENTERPRISE_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
            entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
          )}
        </div>
      </div>
      <div className="row">
        <div className="bullet"></div>
        <div className="label">{FUND_FIELDS.EQUITY_RANGE_MIN.LABEL} (€M)</div>
        <div className="value">
          {formatNumberRange(
            entry[FUND_FIELDS.EQUITY_RANGE_MIN.NAME],
            entry[FUND_FIELDS.EQUITY_RANGE_MAX.NAME]
          )}
        </div>
      </div>
    </div>
  );
});

export default FundInformation;
