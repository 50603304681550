import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { useEffect } from 'react';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import { formatDateDDMMYYYY } from '../../../utils/utils';
import ReactPaginate from 'react-paginate';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { FIELDS_TYPES } from '../../../utils/constants/fields';
import { runInAction } from 'mobx';
import { OPTION_KEYS } from '../../../utils/constants/optionKeys';

const UsersActivities = observer(() => {
  const { adminDashboardStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    filtersToggled: false,
    toggleFilters: (clearChanges = false) => {
      state.filtersToggled = !state.filtersToggled;
      utilsStore.lockScroll(state.filtersToggled);
      if (clearChanges) {
        adminDashboardStore.filtersDisplayUsersActivities = JSON.parse(
          JSON.stringify(adminDashboardStore.filtersUsersActivities)
        );
      }
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    get filterCompanyTypeValues() {
      const companyTypes = utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES];
      return [{ name: 'All', value: 'all' }, ...(companyTypes || [])];
    },
    getFiltersCount: (filters = {}) => {
      let count = 0;
      if (filters.filterCompanyType && filters.filterCompanyType !== 'all') {
        count++;
      }
      if (filters.filterOnlyMasterGroup) {
        count++;
      }
      return count;
    },
    get displayFiltersCount() {
      return state.getFiltersCount(adminDashboardStore.filtersDisplayUsersActivities);
    },
    get appliedFiltersCount() {
      return state.getFiltersCount(adminDashboardStore.filtersUsersActivities);
    },
    get disableFiltersInput() {
      return adminDashboardStore.isLoadingUsersActivities;
    },
    get disableApplyFiltersBtn() {
      return (
        adminDashboardStore.isLoadingUsersActivities ||
        JSON.stringify(adminDashboardStore.filtersDisplayUsersActivities) ===
          JSON.stringify(adminDashboardStore.filtersUsersActivities)
      );
    },
    get disableClearAllFiltersBtn() {
      return (
        adminDashboardStore.isLoadingUsersActivities ||
        (state.displayFiltersCount === 0 && state.appliedFiltersCount === 0)
      );
    }
  }));

  useEffect(() => {
    return () => {
      if (state.filtersToggled) {
        state.toggleFilters();
      }
    };
  }, [state]);

  useEffect(() => {
    if (
      !adminDashboardStore.isLoadingUsersActivities &&
      !adminDashboardStore.usersActivitiesList.length &&
      !adminDashboardStore.loadUsersActivitiesError
    ) {
      adminDashboardStore.loadUsersActivitiesList(adminDashboardStore.usersActivitiesPage);
    }
  }, [adminDashboardStore]);

  const showLoader = adminDashboardStore.isLoadingUsersActivities;
  const showError = !!adminDashboardStore.loadUsersActivitiesError;
  const showResults = !!adminDashboardStore.usersActivitiesList.length;
  const showPaging = adminDashboardStore.usersActivitiesMaxPage > 1;
  const showNoResults =
    !adminDashboardStore.isLoadingUsersActivities &&
    !adminDashboardStore.loadUsersActivitiesError &&
    !adminDashboardStore.usersActivitiesList.length;

  return (
    <>
      <div className="filters-wrap">
        <IconButton
          type={ICON_BUTTON_TYPES.DEFAULT}
          hoverType={ICON_BUTTON_TYPES.GREEN}
          icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
          withBorder={false}
          innerText="Refresh"
          onClick={() => {
            adminDashboardStore.loadUsersActivitiesList(adminDashboardStore.usersActivitiesPage);
          }}
          disabled={state.disableFiltersInput}
        />
        <IconButton
          type={ICON_BUTTON_TYPES.DEFAULT}
          hoverType={ICON_BUTTON_TYPES.BLUE}
          withBorder={false}
          filled={state.filtersToggled}
          icon={ICON_BUTTON_ICONS.FILTER}
          innerText={
            state.displayFiltersCount ? `Filters (${state.displayFiltersCount})` : 'Filters'
          }
          onClick={state.toggleFilters}
        />
        {state.filtersToggled && (
          <>
            <div className="filters-closer" onClick={() => state.toggleFilters(true)} />
            <div className="filters-container">
              <div className="filters-title">Filter by:</div>
              <div className="filters-body">
                <MultiSelect
                  field={{
                    NAME: 'filterCompanyType',
                    ID: 'users_activities_filter_company_type',
                    LABEL: 'Company type',
                    PLACEHOLDER: 'Select',
                    TYPE: FIELDS_TYPES.TYPE_SELECT
                  }}
                  values={state.filterCompanyTypeValues}
                  value={[adminDashboardStore.filtersDisplayUsersActivities.filterCompanyType]}
                  setFieldValue={(_, value) => {
                    runInAction(() => {
                      adminDashboardStore.filtersDisplayUsersActivities.filterCompanyType =
                        value[0];
                    });
                  }}
                  disabled={state.disableFiltersInput}
                  inputWrap={{ enable: true, className: 'filter-wrap' }}
                />
                <div className="filter-wrap">
                  <div className="checkbox-wrap">
                    <div className="label">Only user special rights</div>
                    <div className="noselect options-layout">
                      <label>
                        <input
                          type={FIELDS_TYPES.TYPE_CHECKBOX}
                          checked={
                            adminDashboardStore.filtersDisplayUsersActivities.filterOnlyMasterGroup
                          }
                          onChange={() => {
                            runInAction(() => {
                              adminDashboardStore.filtersDisplayUsersActivities.filterOnlyMasterGroup =
                                !adminDashboardStore.filtersDisplayUsersActivities
                                  .filterOnlyMasterGroup;
                            });
                          }}
                          disabled={state.disableFiltersInput}
                        />
                        Master
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filters-actions">
                <IconButton
                  innerText="Clear all filters"
                  type={ICON_BUTTON_TYPES.RED}
                  disabled={state.disableClearAllFiltersBtn}
                  onClick={() => {
                    state.toggleFilters();
                    adminDashboardStore.clearUsersActivitiesFilters();
                  }}
                  wrapClassName={'clear-btn-override'}
                />
                <IconButton innerText="Cancel" onClick={() => state.toggleFilters(true)} />
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  filled
                  innerText="Apply filters"
                  disabled={state.disableApplyFiltersBtn}
                  onClick={() => {
                    state.toggleFilters();
                    adminDashboardStore.loadUsersActivitiesList();
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {showLoader && <GeneralLoader />}
      {showError && (
        <GeneralError
          errorMessage={adminDashboardStore.loadUsersActivitiesError}
          actionMessage="You may want to:"
          actionButtontext="Try again"
          onActionButtonClick={() =>
            adminDashboardStore.loadUsersActivitiesList(adminDashboardStore.usersActivitiesPage)
          }
        />
      )}
      {showResults && (
        <div className="results-table-activities">
          <div className="table-head desktop-only">
            <div className="head-item">Name</div>
            <div className="head-item">Company name</div>
            <div className="head-item">Company type</div>
            <div className="head-item">User created date</div>
            <div className="head-item">Last user login</div>
            <div className="head-item">Co. profile created</div>
            <div className="head-item">First fund created</div>
            <div className="head-item">First portfolio company created</div>
            <div className="head-item">First invest profile created</div>
            <div className="head-item">Last search executed</div>
          </div>
          {adminDashboardStore.usersActivitiesList.map((user, idx) => {
            if (state.layout === 'mobile') {
              return (
                <div key={user.id} className="user-item-mobile">
                  <div className="row">
                    <div className="field">Name</div>
                    <div className="value text">
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Company name</div>
                    <div className="value text">{user.companyProfileName}</div>
                  </div>
                  <div className="row">
                    <div className="field">Company type</div>
                    <div className="value text">
                      {user.companyType
                        ? utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES]?.find?.(
                            (c) => c.value === user.companyType
                          )?.name || user.companyType
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">User created date</div>
                    <div className="value text">{formatDateDDMMYYYY(user.createdAt)}</div>
                  </div>
                  <div className="row">
                    <div className="field">Last user login</div>
                    <div className="value text">
                      {user.lastLoginTs ? formatDateDDMMYYYY(user.lastLoginTs) : 'N/A'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Co. profile created</div>
                    <div className="value text">
                      {user.companyProfileCreatedAt
                        ? formatDateDDMMYYYY(user.companyProfileCreatedAt)
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">First fund created</div>
                    <div className="value text">
                      {user.fundCreatedAt ? formatDateDDMMYYYY(user.fundCreatedAt) : 'N/A'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">First portfolio company created</div>
                    <div className="value text">
                      {user.portfolioCompanyCreatedAt
                        ? formatDateDDMMYYYY(user.portfolioCompanyCreatedAt)
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">First invest profile created</div>
                    <div className="value text">
                      {user.investProfileCreatedAt
                        ? formatDateDDMMYYYY(user.investProfileCreatedAt)
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="row last">
                    <div className="field">Last search executed</div>
                    <div className="value text">
                      {user.lastSearchTimestamp
                        ? formatDateDDMMYYYY(user.lastSearchTimestamp)
                        : 'N/A'}
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div key={user.id} className="user-item">
                <div className="cell text">
                  {user.firstName} {user.lastName}
                </div>
                <div className="cell text">{user.companyProfileName}</div>
                <div className="cell text">
                  {user.companyType
                    ? utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES]?.find?.(
                        (c) => c.value === user.companyType
                      )?.name || user.companyType
                    : 'N/A'}
                </div>
                <div className="cell text">{formatDateDDMMYYYY(user.createdAt)}</div>
                <div className="cell text">
                  {user.lastLoginTs ? formatDateDDMMYYYY(user.lastLoginTs) : 'N/A'}
                </div>
                <div className="cell text">
                  {user.companyProfileCreatedAt
                    ? formatDateDDMMYYYY(user.companyProfileCreatedAt)
                    : 'N/A'}
                </div>
                <div className="cell text">
                  {user.fundCreatedAt ? formatDateDDMMYYYY(user.fundCreatedAt) : 'N/A'}
                </div>
                <div className="cell text">
                  {user.portfolioCompanyCreatedAt
                    ? formatDateDDMMYYYY(user.portfolioCompanyCreatedAt)
                    : 'N/A'}
                </div>
                <div className="cell text">
                  {user.investProfileCreatedAt
                    ? formatDateDDMMYYYY(user.investProfileCreatedAt)
                    : 'N/A'}
                </div>
                <div className="cell text">
                  {user.lastSearchTimestamp ? formatDateDDMMYYYY(user.lastSearchTimestamp) : 'N/A'}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {showNoResults && <div className="no-results">No results found.</div>}
      {showPaging && (
        <ReactPaginate
          onPageChange={({ selected }) => {
            adminDashboardStore.loadUsersActivitiesList(selected + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          forcePage={adminDashboardStore.usersActivitiesPage - 1}
          pageRangeDisplayed={state.layout === 'mobile' ? 1 : adminDashboardStore.pageSize}
          marginPagesDisplayed={state.layout === 'mobile' ? 1 : adminDashboardStore.pageSize}
          pageCount={adminDashboardStore.usersActivitiesMaxPage}
          renderOnZeroPageCount={null}
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Prev"
          containerClassName="pagination-container"
          pageClassName="page"
          pageLinkClassName="pageLink"
          nextClassName="next"
          nextLinkClassName="nextLink"
          previousClassName="prev"
          previousLinkClassName="prevLink"
          breakClassName="break"
          breakLinkClassName="breakLink"
        />
      )}
    </>
  );
});

export default UsersActivities;
