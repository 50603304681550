import * as Portal from '@radix-ui/react-portal';
import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import { useEffect } from 'react';
import { mapFieldsToState } from '../../../utils/utils';
import { FORWARD_OUTREACH_FIELDS, getErrorFields } from '../../../utils/constants/fields';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { runInAction } from 'mobx';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';

const displayTypes = {
  funds: 'Fund',
  portfolios: 'Portfolio company'
};

const ForwardOutreachPopup = observer(({ onClose, outreachId }) => {
  const { outreachStore, authStore, fundsStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    isRendered: false,
    setIsRendered: (value = false) => (state.isRendered = value),
    fields: mapFieldsToState(FORWARD_OUTREACH_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(FORWARD_OUTREACH_FIELDS), state.fields);
    },
    get isForwardDisabled() {
      return (
        outreachStore.isForwardingOutreach ||
        authStore.isLoadingCompanyUsers ||
        fundsStore.isLoadingCompanyFunds ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    get isFieldsDisabled() {
      return (
        outreachStore.isForwardingOutreach ||
        authStore.isLoadingCompanyUsers ||
        fundsStore.isLoadingCompanyFunds
      );
    },
    get usersList() {
      return authStore.companyUsers
        .filter((u) => u.id !== authStore.currentUserId)
        .map((u) => ({
          name: u.firstName + ' ' + u.lastName + ' • ' + u.jobPosition,
          value: u.id
        }));
    },
    get _vehicles() {
      return authStore.isCoraporatesInvestor
        ? fundsStore.companyInvestProfiles.map((ip) => ({ ...ip, type: 'investprofiles' }))
        : fundsStore.companyFunds
            .map((f) => ({ ...f, type: 'funds' }))
            .concat(fundsStore.companyPortfolios.map((p) => ({ ...p, type: 'portfolios' })));
    },
    get vehicleList() {
      return state._vehicles
        .filter((ntry) => ntry.ownerId === state.fields[FORWARD_OUTREACH_FIELDS.USER.NAME][0])
        .map((ntry) => ({
          name: ntry.name + (displayTypes[ntry.type] ? ' • ' + displayTypes[ntry.type] : ''),
          value: ntry.id
        }));
    },
    isForwardSent: false,
    forwardError: null,
    forwardOutreach: () => {
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      state.forwardError = null;
      const objectId = state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME][0];
      const objectType = state._vehicles.find((ntry) => ntry.id === objectId).type;
      outreachStore.forwardOutreach(
        outreachId,
        objectType,
        objectId,
        () => {
          if (state.isRendered) {
            state.isForwardSent = true;
          } else {
            utilsStore.setHeaderMessage(
              `You have successfully forwarded outreach to ${state.displayForwardedUserName}, ${state.displayForwardedObjectInfo}`
            );
          }

          const outreach = outreachStore.outreaches.find((o) => o.id === outreachId);
          outreach.isForwarded = true;
          outreach.forwardedFromUserId = authStore.currentUserId;
        },
        (errorMessage) => {
          if (state.isRendered) {
            state.forwardError = errorMessage || 'Failed to forward outreach.';
          } else {
            utilsStore.setHeaderMessage(
              errorMessage || 'Failed to forward outreach.',
              HEADER_MESSAGE_TYPES.ERROR
            );
          }
        }
      );
    },
    get forwardedUser() {
      return authStore.companyUsers.find(
        (u) => u.id === state.fields[FORWARD_OUTREACH_FIELDS.USER.NAME][0]
      );
    },
    get displayForwardedUserName() {
      return state.forwardedUser?.firstName + ' ' + state.forwardedUser?.lastName;
    },
    get forwardedObject() {
      return state._vehicles.find(
        (ntry) => ntry.id === state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME][0]
      );
    },
    get displayForwardedObjectInfo() {
      return state.forwardedObject?.name;
    }
  }));

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, [state]);

  useEffect(() => {
    authStore.loadCompanyUsers();
    fundsStore.loadCompanyFunds();
  }, [authStore, fundsStore]);

  useEffect(() => {
    if (!authStore.isLoadingCompanyUsers && state.usersList.length === 1) {
      runInAction(() => {
        state.fields[FORWARD_OUTREACH_FIELDS.USER.NAME] = [state.usersList[0].value];
      });
    }
  }, [authStore.isLoadingCompanyUsers, state.usersList]);

  useEffect(() => {
    if (!authStore.isLoadingCompanyUsers && !fundsStore.isLoadingCompanyFunds) {
      const selectedUserId = state.fields[FORWARD_OUTREACH_FIELDS.USER.NAME][0];
      runInAction(() => {
        if (selectedUserId) {
          if (state.vehicleList.length > 0) {
            if (state.vehicleList.length === 1) {
              state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME] = [state.vehicleList[0].value];
            } else {
              state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME] = [];
            }
          } else {
            state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME] = [];
          }
        } else {
          state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME] = [];
        }
      });
    }
  }, [
    state,
    state.vehicleList,
    authStore.isLoadingCompanyUsers,
    fundsStore.isLoadingCompanyFunds,
    state.fields[FORWARD_OUTREACH_FIELDS.USER.NAME][0]
  ]);

  return (
    <Portal.Root>
      <div className="desktop-forward-outreach-popup-closer" onClick={onClose} />
      {outreachStore.isForwardingOutreach ? (
        <div className="desktop-forward-outreach-popup">
          <div className="dfop-head">
            <div className="dfop-head-text"></div>
            <IconButton
              innerText="Close"
              icon={ICON_BUTTON_ICONS.X_LG}
              iconOnRight
              withBorder={false}
              withFill={false}
              onClick={onClose}
            />
          </div>
          <GeneralLoader />
        </div>
      ) : state.isForwardSent ? (
        <div className="desktop-forward-outreach-popup">
          <div className="dfop-head">
            <div className="dfop-head-text"></div>
            <IconButton
              innerText="Close"
              icon={ICON_BUTTON_ICONS.X_LG}
              iconOnRight
              withBorder={false}
              withFill={false}
              onClick={onClose}
            />
          </div>
          <div className="dfop-success-wrap">
            <img className="dfop-success-icon" src={ICON_BUTTON_ICONS.CHECK_CIRCLE} />
            <div className="dfop-success-text">
              You have successfully forwarded this outreach to {state.displayForwardedUserName},{' '}
              {state.displayForwardedObjectInfo}
            </div>
          </div>
        </div>
      ) : (
        <div className="desktop-forward-outreach-popup">
          <div className="dfop-head">
            <div className="dfop-head-text">Select a colleague to forward the outreach to.</div>
            <IconButton
              innerText="Close"
              icon={ICON_BUTTON_ICONS.X_LG}
              iconOnRight
              withBorder={false}
              withFill={false}
              onClick={onClose}
            />
          </div>
          <div className="dfop-head-info">
            By forwarding an outreach, the current information will be moved to &quot;forwarded
            outreaches&quot; section.
          </div>
          <div className="dfop-separator" />
          <MultiSelect
            field={FORWARD_OUTREACH_FIELDS.USER}
            value={state.fields[FORWARD_OUTREACH_FIELDS.USER.NAME]}
            values={state.usersList}
            setFieldValue={state.setFieldValue}
            disabled={state.isFieldsDisabled}
            inputWrap={{ enable: true, className: 'field' }}
          />
          <MultiSelect
            field={FORWARD_OUTREACH_FIELDS.ENTRY}
            value={state.fields[FORWARD_OUTREACH_FIELDS.ENTRY.NAME]}
            values={state.vehicleList}
            setFieldValue={state.setFieldValue}
            disabled={state.isFieldsDisabled}
            inputWrap={{ enable: true, className: 'field' }}
          />
          {!!state.forwardError && <div className="error">{state.forwardError}</div>}
          <div className="forward-button-wrap">
            <IconButton
              type={ICON_BUTTON_TYPES.BLUE}
              filled
              innerText="Forward"
              disabled={state.isForwardDisabled}
              onClick={state.forwardOutreach}
            />
          </div>
        </div>
      )}
    </Portal.Root>
  );
});

export default ForwardOutreachPopup;
