import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import {
  encodeProjectsParams,
  SELECTED_PROJECT_TABS,
  selectedProjectTabPageSize
} from '../constants';
import { SEARCH_FIELDS } from '../../../utils/constants/fields';
import { useEffect } from 'react';
import { API_ENDPOINTS } from '../../../api/endpoints';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { SEARCH_CATEGORIES } from '../../../utils/constants/searchCategories';
import { formatPercent, formatNumber, formatNumberRange } from '../../../utils/utils';
import GeneralError from '../../../components/errors/GeneralError';
import SearchResultsTabView from './SearchResultsTabView';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { Link } from 'react-router-dom';
import MassOutreachPopup from '../../search/SearchResults/components/MassOutreachPopup';
import ProjectMessagePopup from './ProjectMessagePopup';
import FeedbackPopup from './FeedbackPopup';
import CommentsTabView from './CommentsTabView';
import pubSub from '../../../utils/pubsub';

const ProjectView = observer(({ project = {}, params = {} }) => {
  const store = useStore();
  const { projectStore, utilsStore, authStore, outreachStore } = store;
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isRendered: true,
    unrender: () => (state.isRendered = false),
    isLoading: false,
    loadError: false,
    results: [],
    loadResults: (projectId = null) => {
      if (state.isLoading || !projectId) {
        return;
      }

      state.loadError = false;
      state.isLoading = true;
      store.makeRequest({
        endpoint: API_ENDPOINTS.GET_PROJECT_RESULTS,
        body: { projectId },
        onSuccess: (response) => {
          if (!state.isRendered) {
            return;
          }

          // state.searchType = searchFields[SEARCH_FIELDS.SEARCH_TYPE.NAME],
          state.results = response.results;
        },
        onError: (errorMessage) => {
          if (!state.isRendered) {
            return;
          }

          state.loadError = errorMessage || 'Failed to obtain project results.';
        },
        onFinally: () => {
          if (!state.isRendered) {
            return;
          }
          state.isLoading = false;
        }
      });
    },
    isExcludingResult: false,
    excludeResult: (projectId = '', resultId = '', exclude = true) => {
      if (state.isExcludingResult || !projectId || !resultId) {
        return;
      }

      state.isExcludingResult = true;
      store.makeRequest({
        endpoint: API_ENDPOINTS.EXCLUDE_PROJECT_RESULT,
        body: { projectId, resultId, exclude: !!exclude },
        onSuccess: () => {
          if (!state.isRendered) {
            return;
          }
          const foundResult = state.results.find((r) => r.id === resultId);

          if (foundResult) {
            foundResult.isExcluded = !!exclude;
            utilsStore.setHeaderMessage(
              `Result successfully moved to ${!exclude ? ' saved ' : ' excluded '} results.`
            );
          }
        },
        onError: (errorMessage) => {
          if (!state.isRendered) {
            return;
          }

          utilsStore.setHeaderMessage(errorMessage || 'Failed to exclude result.');
        },
        onFinally: () => {
          if (!state.isRendered) {
            return;
          }
          state.isExcludingResult = false;
        }
      });
    },
    messagePopupDisplayed: null,
    displayMessagePopup: (entry) => {
      if (entry) {
        utilsStore.lockScroll(true);
      } else {
        utilsStore.lockScroll(false);
      }
      state.messagePopupDisplayed = entry;
    },
    onSubmitMessage: (id, subject, message, documents, onSuccess, onError) => {
      outreachStore.sendOutreach(
        params.selectedProjectId,
        [id],
        subject,
        message,
        documents,
        onSuccess,
        onError
      );
    },
    massOutreachPopupDisplayed: false,
    toggleMassOutreachPopup: (toggle = false) => {
      state.massOutreachPopupDisplayed = toggle;
      if (toggle) {
        utilsStore.lockScroll(true);
      } else {
        utilsStore.lockScroll(false);
      }
    },
    onMassOutreach: (ids = [], subject, message, documents, onSuccess, onError) => {
      outreachStore.sendOutreach(
        params.selectedProjectId,
        ids,
        subject,
        message,
        documents,
        onSuccess,
        onError
      );
    },
    isRequestFeedbackPopupOpen: false,
    toggleRequestFeedbackPopup: () => {
      state.isRequestFeedbackPopupOpen = !state.isRequestFeedbackPopupOpen;
      if (state.isRequestFeedbackPopupOpen) {
        utilsStore.lockScroll(true);
      } else {
        utilsStore.lockScroll(false);
      }
    },
    isFieldsSectionCollapsed: true,
    toggleFieldsSection: () => {
      state.isFieldsSectionCollapsed = !state.isFieldsSectionCollapsed;
      if (!state.isFieldsSectionCollapsed) {
        setTimeout(() => {
          if (!state.isFieldsSectionCollapsed) {
            const mandatoryContainerDOM = document.getElementById('pv-mandatory-container');
            if (mandatoryContainerDOM) {
              window.scrollTo({
                top: mandatoryContainerDOM.offsetTop - (state.layout === 'mobile' ? 52 : 80),
                behavior: 'smooth'
              });
            }
          }
        }, 10);
      }
    },
    get layout() {
      return utilsStore.windowWidth < 481 ? 'mobile' : 'desktop';
    }
  }));

  useEffect(() => {
    projectStore.updateProjectLastCheckedDate(project.id);

    const subscription = (payload) => {
      if (
        payload?.event === 'update-project-last-checked-date' &&
        payload?.projectId === project.id
      ) {
        setTimeout(() => {
          projectStore.updateProjectLastCheckedDate(project.id);
        }, 0);
      }
    };

    pubSub.subscribe(subscription);

    return () => {
      pubSub.unsubscribe(subscription);
    };
  }, [project.id, projectStore]);

  const scrollOnAction = (tabId = '') => {
    setTimeout(() => {
      const el = document.getElementById('project-view-tab-1');
      const header = document.querySelector('header');
      if (el) {
        const headerHeight = header?.clientHeight || 0;
        window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
      }
      setTimeout(() => {
        const tabDOM = document.getElementById(tabId);
        if (tabDOM) {
          tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
      }, 500);
    }, 100);
  };

  const selectedProjectTabs = [
    {
      id: 'project-view-tab-1',
      name: 'Search results (' + state.results.filter((r) => !r.isExcluded).length + ')',
      onClick: () => {
        navigate(
          paths.PROJECTS +
            '?params=' +
            encodeProjectsParams({
              ...params,
              selectedProjectTab: SELECTED_PROJECT_TABS.RESULTS,
              selectedProjectTabPage: 1
            })
        );
        scrollOnAction('project-view-tab-1');
      },
      isActive: params.selectedProjectTab === SELECTED_PROJECT_TABS.RESULTS
    },
    {
      id: 'project-view-tab-2',
      name: 'Excluded results (' + state.results.filter((r) => r.isExcluded).length + ')',
      onClick: () => {
        navigate(
          paths.PROJECTS +
            '?params=' +
            encodeProjectsParams({
              ...params,
              selectedProjectTab: SELECTED_PROJECT_TABS.EXCLUDED,
              selectedProjectTabPage: 1
            })
        );
        scrollOnAction('project-view-tab-2');
      },
      isActive: params.selectedProjectTab === SELECTED_PROJECT_TABS.EXCLUDED
    },
    {
      id: 'project-view-tab-3',
      name: 'Team comments (' + project.teamComments.length + ')',
      onClick: () => {
        navigate(
          paths.PROJECTS +
            '?params=' +
            encodeProjectsParams({
              ...params,
              selectedProjectTab: SELECTED_PROJECT_TABS.COMMENTS,
              selectedProjectTabPage: 1
            })
        );
        scrollOnAction('project-view-tab-3');
      },
      isActive: params.selectedProjectTab === SELECTED_PROJECT_TABS.COMMENTS
    }
  ];

  useEffect(() => {
    return () => state.unrender();
  }, [state]);

  useEffect(() => {
    state.loadResults(project.id);
    authStore.loadCompanyUsers();
  }, [authStore, project.id]);

  useEffect(() => {
    if (state.isLoading) {
      return;
    }

    let shouldNavigate = false;
    const newParams = {};

    if (!Object.values(SELECTED_PROJECT_TABS).includes(params.selectedProjectTab)) {
      newParams.selectedProjectTab = SELECTED_PROJECT_TABS.RESULTS;
      shouldNavigate = true;
    }

    const list =
      params.selectedProjectTab === SELECTED_PROJECT_TABS.RESULTS
        ? state.results.filter((r) => !r.isExcluded)
        : params.selectedProjectTab === SELECTED_PROJECT_TABS.EXCLUDED
        ? state.results.filter((r) => r.isExcluded)
        : project.teamComments;
    let listPages = Math.ceil(list.length / selectedProjectTabPageSize);
    if (listPages === 0) {
      listPages = 1;
    }

    if (
      isNaN(params.selectedProjectTabPage) ||
      params.selectedProjectTabPage > listPages ||
      params.selectedProjectTabPage < 1
    ) {
      if (isNaN(params.selectedProjectTabPage) || params.selectedProjectTabPage < 1) {
        newParams.selectedProjectTabPage = 1;
      } else if (params.selectedProjectTabPage > listPages) {
        newParams.selectedProjectTabPage = listPages;
      }
      shouldNavigate = true;
    }

    if (shouldNavigate) {
      navigate(paths.PROJECTS + '?params=' + encodeProjectsParams({ ...params, ...newParams }));
    }
  }, [params, state.results, state.results.filter((r) => r.isExcluded).length]);

  const onBackClick = (ev) => {
    ev?.preventDefault?.();
    ev?.nativeEvent?.preventDefault?.();
    ev?.nativeEvent?.stopPropagation?.();
    const newParams = { ...params };
    newParams.selectedProjectId = null;
    newParams.selectedProjectTab = null;
    newParams.selectedProjectTabPage = null;
    navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(newParams));
  };

  const onDownloadClick = () => {
    window.open(
      `${process.env.REACT_APP_API_BASEURL}/public/downloadSearch?searchId=${project.searchId}`,
      '_blank',
      'noopener, noreferrer'
    );
  };

  const showMessagePopup = !!state.messagePopupDisplayed;
  const showMassOutreachPopup = !!state.massOutreachPopupDisplayed;
  const showFeedbackPopup = !!state.isRequestFeedbackPopupOpen;
  const disableMassOutreach = projectStore.isLoadingProjects || outreachStore.isSendingOutreach;

  return (
    <div className="project-details">
      <Link
        to={
          paths.PROJECTS + '?params=' + encodeProjectsParams({ ...params, selectedProjectId: null })
        }
        className="backlink"
        onClick={onBackClick}>
        Back to projects
      </Link>

      <div className="project-view-header">
        <div className="title">{project.name}</div>
        <div className="actions desktop-only">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.CHAT_RIGHT_DOTS}
            onClick={() => state.toggleMassOutreachPopup(true)}
            disabled={disableMassOutreach}
          />
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.DOWNLOAD}
            onClick={onDownloadClick}
          />
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            filled
            innerText="Request feedback"
            onClick={state.toggleRequestFeedbackPopup}
          />
        </div>
      </div>

      <section>
        <div className="mandatory-container" id="pv-mandatory-container">
          <div className="mandatory-header">
            <div className="section-title">Search criteria</div>
            <IconButton
              type={ICON_BUTTON_TYPES.DEFAULT}
              icon={
                state.isFieldsSectionCollapsed
                  ? ICON_BUTTON_ICONS.CHEVRON_DOWN
                  : ICON_BUTTON_ICONS.CHEVRON_UP
              }
              innerText={state.isFieldsSectionCollapsed ? 'Expand' : 'Collapse'}
              onClick={state.toggleFieldsSection}
              iconOnRight
              withBorder={false}
            />
          </div>
          <div
            style={{ display: state.isFieldsSectionCollapsed ? 'none' : 'block' }}
            className="fields">
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.SEARCH_TYPE.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.SEARCH_TYPE)}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.GENERAL}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.INDUSTRY.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.INDUSTRY).join(', ')}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.LABEL}</div>
                <div className="value">
                  {utilsStore
                    .getOptionName(project.fields, SEARCH_FIELDS.GEOGRAPHICAL_FOCUS)
                    .join(', ')}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.HEADQUARTER.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.HEADQUARTER)}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.FINANCIALS}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.REVENUE.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumber(project.fields[SEARCH_FIELDS.REVENUE.NAME])}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EBITDA.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumber(project.fields[SEARCH_FIELDS.EBITDA.NAME])}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EBITDA_MARGIN.LABEL_COMPACT}</div>
                <div className="value">
                  {formatPercent(project.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME])}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.TRANSACTION}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EQUITY_STAKE.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.EQUITY_STAKE).join(', ')}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.SITUATION.LABEL}</div>
                <div className="value">
                  {utilsStore.getOptionName(project.fields, SEARCH_FIELDS.SITUATION).join(', ')}
                </div>
              </div>
            </div>

            <div className="group-title">{SEARCH_CATEGORIES.VALUATION}</div>
            <div className="group">
              <div className="field">
                <div className="label">{SEARCH_FIELDS.ENTERPRISE_MIN.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumberRange(
                    project.fields[SEARCH_FIELDS.ENTERPRISE_MIN.NAME],
                    project.fields[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]
                  )}
                </div>
              </div>
              <div className="field">
                <div className="label">{SEARCH_FIELDS.EQUITY_MIN.LABEL_COMPACT} (€M)</div>
                <div className="value">
                  {formatNumberRange(
                    project.fields[SEARCH_FIELDS.EQUITY_MIN.NAME],
                    project.fields[SEARCH_FIELDS.EQUITY_MIN.NAME]
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lists">
        <div className="tabs">
          {selectedProjectTabs.map((tab, idx) => {
            return (
              <div
                id={tab.id}
                key={tab.name}
                onClick={tab.onClick}
                className={`tab ${tab.isActive ? 'active' : ''}`}>
                {tab.name}
              </div>
            );
          })}
        </div>

        {state.loadError && params.selectedProjectTab !== SELECTED_PROJECT_TABS.COMMENTS && (
          <GeneralError
            errorMessage={state.loadError}
            actionMessage="You may want to:"
            actionButtontext="Try again"
            onActionButtonClick={() => state.loadResults(project.id)}
          />
        )}

        {state.isLoading && params.selectedProjectTab !== SELECTED_PROJECT_TABS.COMMENTS && (
          <GeneralLoader />
        )}

        {!state.isLoading &&
          [SELECTED_PROJECT_TABS.RESULTS, SELECTED_PROJECT_TABS.EXCLUDED].includes(
            params.selectedProjectTab
          ) && (
            <SearchResultsTabView
              key={project.id}
              project={project}
              results={state.results}
              forTab={params.selectedProjectTab}
              params={params}
              excludeResult={state.excludeResult}
              isExcludingResult={state.isExcludingResult}
              displayMessagePopup={state.displayMessagePopup}
            />
          )}

        {params.selectedProjectTab === SELECTED_PROJECT_TABS.COMMENTS && (
          <CommentsTabView params={params} project={project} />
        )}
      </section>
      <div className="actions-bottom mobile-only">
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.DOWNLOAD}
          onClick={onDownloadClick}
        />
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.CHAT_RIGHT_DOTS}
          onClick={() => state.toggleMassOutreachPopup(true)}
          disabled={disableMassOutreach}
        />
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          filled
          innerText="Request feedback"
          onClick={state.toggleRequestFeedbackPopup}
        />
      </div>
      {showMessagePopup && (
        <ProjectMessagePopup
          messagePopupDisplayed={state.messagePopupDisplayed}
          displayMessagePopup={state.displayMessagePopup}
          submitMessage={state.onSubmitMessage}
          forSearchType={project.searchType}
        />
      )}
      {showMassOutreachPopup && (
        <MassOutreachPopup
          toggleMassOutreachPopup={state.toggleMassOutreachPopup}
          submitMessage={state.onMassOutreach}
          projectId={params.selectedProjectId}
          entries={state.results.filter((r) => !r.isExcluded)}
        />
      )}
      {showFeedbackPopup && (
        <FeedbackPopup
          toggle={state.toggleRequestFeedbackPopup}
          companyUsers={authStore.companyUsers}
          projectId={params.selectedProjectId}
        />
      )}
    </div>
  );
});

export default ProjectView;
