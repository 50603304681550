import { observer, useLocalObservable } from 'mobx-react';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import { useStore } from '../../store/store';
import { ADMIN_DASHBOARD_TABS } from './constants';
import { runInAction } from 'mobx';
import UsersManagement from './components/UsersManagement';
import UsersActivities from './components/UsersActivities';
import InvestVehicles from './components/InvestVehicles';

const AdminDashboard = observer(() => {
  const { adminDashboardStore } = useStore();

  const scrollTabIntoView = (tabId = '') => {
    const tabDOM = document.getElementById(tabId);
    if (tabDOM) {
      setTimeout(() => {
        tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }, 0);
    }
  };

  const tabs = [
    {
      id: 'admin_dashboard_user_mgmt_tab',
      name: adminDashboardStore.usersManagementTotalCount
        ? 'User Mgmt. (' + adminDashboardStore.usersManagementTotalCount + ')'
        : 'User Mgmt.',
      isActive: adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.USER_MANAGEMENT,
      onClick: () => {
        if (adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.USER_MANAGEMENT) {
          return;
        }
        runInAction(() => {
          adminDashboardStore.tab = ADMIN_DASHBOARD_TABS.USER_MANAGEMENT;
        });
        scrollTabIntoView('admin_dashboard_user_mgmt_tab');
      }
    },
    {
      id: 'admin_dashboard_user_activities_tab',
      name: 'Activities',
      isActive: adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.USER_ACTIVITIES,
      onClick: () => {
        if (adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.USER_ACTIVITIES) {
          return;
        }
        runInAction(() => {
          adminDashboardStore.tab = ADMIN_DASHBOARD_TABS.USER_ACTIVITIES;
        });
        scrollTabIntoView('admin_dashboard_user_activities_tab');
      }
    },
    {
      id: 'admin_dashboard_invest_vehicles_tab',
      name: adminDashboardStore.investVehiclesTotalCount
        ? 'Invest profiles (' + adminDashboardStore.investVehiclesTotalCount + ')'
        : 'Invest profiles',
      isActive: adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.INVEST_VEHICLES,
      onClick: () => {
        if (adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.INVEST_VEHICLES) {
          return;
        }
        runInAction(() => {
          adminDashboardStore.tab = ADMIN_DASHBOARD_TABS.INVEST_VEHICLES;
        });
        scrollTabIntoView('admin_dashboard_invest_vehicles_tab');
      }
    }
  ];

  return (
    <StaticPageLayout page="admin-dashboard" hideMobileFooter={true} hideMobileFooter1200={true}>
      <div className="tabs-scrollable-wrap">
        <div className="tabs-wrap">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.id}
                id={tab.id}
                className={`noselect tab ${tab.isActive ? 'active' : ''}`}
                onClick={tab.onClick}>
                {tab.name}
              </div>
            );
          })}
        </div>
      </div>
      {adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.USER_MANAGEMENT && <UsersManagement />}
      {adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.USER_ACTIVITIES && <UsersActivities />}
      {adminDashboardStore.tab === ADMIN_DASHBOARD_TABS.INVEST_VEHICLES && <InvestVehicles />}
    </StaticPageLayout>
  );
});

export default AdminDashboard;
