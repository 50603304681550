import { observer } from 'mobx-react';
import { useStore } from '../../../../store/store';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../../components/buttons/IconButton';

const CompanyResult = observer(({ company, displayDetails, excludeResult }) => {
  const { utilsStore } = useStore();

  let badgeClass = 'success';
  if (company.match === 'Average match') {
    badgeClass = 'info';
  }
  if (company.match === 'Good match') {
    badgeClass = 'warning';
  }

  return (
    <div key={company.id} className={`item${company.isExcluded ? ' no-shadow' : ''}`}>
      <div className="cols">
        {/* <div className="col no-desktop mobile-badge no-border">
          <div className={`badge ${badgeClass}`}>{company.match}</div>
        </div> */}
        <div className="col">
          <div className="label">Company name</div>
          <div className="value">{company.name || 'N/A'}</div>
        </div>
        <div className="col">
          <div className="label">Company HQ</div>
          <div className="value">{company.headquarters || 'N/A'}</div>
        </div>
        <div className="col">
          <div className="label">Industry</div>
          <div className="value">
            {company.industries && company.industries.length
              ? `${utilsStore.shortenList(company.industries, 3).join(', ')}${
                  company.industries.length > 3 ? ' ...' : ''
                }`
              : 'N/A'}
          </div>
        </div>
        <div className="col">
          <div className="label">Value chain</div>
          <div className="value">{company.valueChain || 'N/A'}</div>
        </div>
        <div className="col">
          <div className="label">Products</div>
          <div className="value">
            {company.products && company.products.length
              ? `${utilsStore.shortenList(company.products, 3).join(', ')}${
                  company.products.length > 3 ? ' ...' : ''
                }`
              : 'N/A'}
          </div>
        </div>
        <div className="col">
          <div className="label">Revenue (€M)</div>
          <div className="value right-align-desktop">
            {company.financials?.revenues?.years && !!company.financials.revenues.years.length ? (
              <>
                {
                  company.financials.revenues.years[company.financials.revenues.years.length - 1]
                    .values.value
                }
                <span className="year">
                  &lsquo;
                  {company.financials.revenues.years[
                    company.financials.revenues.years.length - 1
                  ].year
                    .toString()
                    .slice(-2)}
                </span>
              </>
            ) : (
              'N/A'
            )}
          </div>
        </div>
        <div className="col no-border">
          <div className="label">Employees</div>
          <div className="value right-align-desktop">
            {company.employees ? (
              <>
                {company.employees}
                <span className="year">
                  &lsquo;
                  {new Date(company.employeesNumberUpdatedDate || Date.now())
                    .getFullYear()
                    .toString()
                    .slice(-2)}
                </span>
              </>
            ) : (
              'N/A'
            )}
          </div>
        </div>
        {/* <div className="col no-mobile">
        <div className="label">Match</div>
        <div className={`value badge ${badgeClass}`}>{company.match}</div>
      </div> */}
      </div>
      <div className="actions">
        {company.isExcluded ? (
          <div className="col no-border restore-btn">
            <IconButton
              type={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
              innerText="Restore"
              onClick={() => excludeResult(company, false)}
              iconOnRight
              filled
            />
          </div>
        ) : (
          <>
            {' '}
            <div className="col no-border view-details-btn desktop-only">
              <IconButton
                filled
                type={ICON_BUTTON_TYPES.BLUE}
                onClick={() => displayDetails(company)}
                innerText="Details"
              />
            </div>
            <div className="col no-border view-details-btn mobile-only">
              <IconButton
                filled
                type={ICON_BUTTON_TYPES.BLUE}
                onClick={() => displayDetails(company)}
                innerText="View details"
              />
            </div>
            <div className="col no-border">
              <IconButton
                type={ICON_BUTTON_TYPES.RED}
                icon={ICON_BUTTON_ICONS.X_LG}
                tooltipText="Exclude result"
                onClick={() => excludeResult(company, true)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default CompanyResult;
