export const SEARCH_CATEGORIES = {
  GENERAL: 'General',
  FINANCIALS: 'Financials',
  TRANSACTION: 'Transaction',
  VALUATION: 'Valuation'
};

export const PROFILER_SEARCH_CATEGORIES = {
  INDUSTRY: 'Industry',
  FINANCIALS: 'Financials'
};
