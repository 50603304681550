import { GA_EVENTS } from '../../ga4/constants';

const FUNDS_PATH_COMPONENTS = {
  CREATE: 'create',
  EDIT: 'edit'
};

const FUNDS_VIEW_MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE'
};

const FUNDS_VIEW_TABS = {
  INFORMATION: 'INFORMATION',
  GEO_INDUSTRIES: 'GEO_INDUSTRIES',
  FINANCIAL_METRICS: 'FINANCIAL_METRICS',
  TRANSACTION_TYPES: 'TRANSACTION_TYPES',
  VALUATION: 'VALUATION'
};

const PORTFOLIOS_VIEW_TABS = {
  INFORMATION: 'INFORMATION',
  GEO_INDUSTRIES: 'GEO_INDUSTRIES',
  FINANCIAL_METRICS: 'FINANCIAL_METRICS',
  TRANSACTION_TYPES: 'TRANSACTION_TYPES',
  VALUATION: 'VALUATION'
};

// TODO: Check if they are correct and change them if needed
const INVEST_PROFILES_VIEW_TABS = {
  INFORMATION: 'INFORMATION',
  GEO_INDUSTRIES: 'GEO_INDUSTRIES',
  FINANCIAL_METRICS: 'FINANCIAL_METRICS',
  TRANSACTION_TYPES: 'TRANSACTION_TYPES',
  VALUATION: 'VALUATION'
};

const FUNDS_VIEW_ANALYTICS_TABS_EVENTS = {
  [FUNDS_VIEW_TABS.INFORMATION]: GA_EVENTS.fund_create_Information_complete,
  [FUNDS_VIEW_TABS.GEO_INDUSTRIES]: GA_EVENTS.fund_create_GeoIndustries_complete,
  [FUNDS_VIEW_TABS.FINANCIAL_METRICS]: GA_EVENTS.fund_create_financials_complete,
  [FUNDS_VIEW_TABS.TRANSACTION_TYPES]: GA_EVENTS.fund_create_transaction_complete,
  [FUNDS_VIEW_TABS.VALUATION]: GA_EVENTS.fund_create_valuation_complete
};

const PORTFOLIOS_VIEW_ANALYTICS_TABS_EVENTS = {
  [PORTFOLIOS_VIEW_TABS.INFORMATION]: GA_EVENTS.portfolio_co_information_complete,
  [PORTFOLIOS_VIEW_TABS.GEO_INDUSTRIES]: GA_EVENTS.portfolio_co_geoindustries_complete,
  [PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS]: GA_EVENTS.portfolio_co_financials_complete,
  [PORTFOLIOS_VIEW_TABS.TRANSACTION_TYPES]: GA_EVENTS.portfolio_co_transaction_complete,
  [PORTFOLIOS_VIEW_TABS.VALUATION]: GA_EVENTS.portfolio_co_valuation_complete
};

// TODO: Edit them when google analytics will be implemented
const INVEST_PROFILES_VIEW_ANALYTICS_TABS_EVENTS = {
  [PORTFOLIOS_VIEW_TABS.INFORMATION]: GA_EVENTS.portfolio_co_information_complete,
  [PORTFOLIOS_VIEW_TABS.GEO_INDUSTRIES]: GA_EVENTS.portfolio_co_geoindustries_complete,
  [PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS]: GA_EVENTS.portfolio_co_financials_complete,
  [PORTFOLIOS_VIEW_TABS.TRANSACTION_TYPES]: GA_EVENTS.portfolio_co_transaction_complete,
  [PORTFOLIOS_VIEW_TABS.VALUATION]: GA_EVENTS.portfolio_co_valuation_complete
};

export {
  FUNDS_PATH_COMPONENTS,
  FUNDS_VIEW_MODE,
  FUNDS_VIEW_TABS,
  PORTFOLIOS_VIEW_TABS,
  INVEST_PROFILES_VIEW_TABS,
  FUNDS_VIEW_ANALYTICS_TABS_EVENTS,
  PORTFOLIOS_VIEW_ANALYTICS_TABS_EVENTS,
  INVEST_PROFILES_VIEW_ANALYTICS_TABS_EVENTS
};
