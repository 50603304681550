import { observer, useLocalObservable } from 'mobx-react';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { encodeOutreachParams, getOutreachMessageDisplayTime } from './constants';
import { useStore } from '../../../store/store';
import { stringToColour } from '../../../utils/utils';
import { useEffect } from 'react';

const RecentOutreachButton = observer(({ outreachId = '', outreach = {}, params = {} }) => {
  const { authStore, outreachStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    now: Date.now(),
    setDateNow: (ms) => (state.now = ms)
  }));

  useEffect(() => {
    const interval = setInterval(() => state.setDateNow(Date.now()), 60_000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);

  const lastMessage =
    outreachStore.messages[outreachId][outreachStore.messages[outreachId].length - 1];

  const displayTime = getOutreachMessageDisplayTime(lastMessage?.date, state.now);

  const isSelected = params.selected === 'recent' && params.outreachId === outreachId;

  const onSelect = () => {
    if (isSelected) {
      return;
    }

    navigate(
      paths.OUTREACH +
        '?params=' +
        encodeOutreachParams({ selected: 'recent', type: null, outreachId })
    );
  };

  const displayUser = outreach.participants.find(
    (p) => p.id !== authStore.currentUserId && p.isActive
  );

  return (
    <div
      className={`noselect recent-outreach-button${isSelected ? ' selected' : ''}`}
      onClick={onSelect}>
      <div
        className="icon-user"
        style={{ backgroundColor: stringToColour(displayUser.firstName + displayUser.lastName) }}>
        {displayUser.firstName.charAt(0).toUpperCase()}
      </div>
      <div className="name-user">{`${displayUser.firstName} ${displayUser.lastName}`}</div>
      {outreach.newMessagesCount > 0 ? (
        <div className="msg-count">{outreach.newMessagesCount}</div>
      ) : outreach.hasMarkedUnread ? (
        <div className="msg-count" />
      ) : (
        <div className="date">{displayTime}</div>
      )}
    </div>
  );
});

export default RecentOutreachButton;
