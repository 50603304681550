import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { ACCOUNT_TYPES, ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';
import pubSub from '../../utils/pubsub';

class OutreachStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  reset = () => {
    this.wssQuery = [];
    this.outreaches = [];
    this.messages = {};
    this.loadOutreachesError = null;
    this.isLoadingOutreaches = false;
    this.initialLoadOutreaches = true;
    this.isLoadingMoreMessages = {};
    this.fullyLoadedMessages = {};
    this.isSendingOutreach = false;
    this.isSendingMessage = false;
    this.isUpdatingLastCheckedMessage = false;
    this.isMarkingOutreachAsUnread = false;
    this.isArchivingOutreach = false;
    this.isForwardingOutreach = false;
    this.isRequestingOutreachFeedback = false;
    this.isDownloadingPDFMessages = {};
    this.isRatingOutreach = false;
  };

  wssQuery = [];
  outreaches = [];
  messages = {};
  loadOutreachesError = null;
  isLoadingOutreaches = false;
  initialLoadOutreaches = true;
  isLoadingMoreMessages = {};
  fullyLoadedMessages = {};
  isSendingOutreach = false;
  isSendingMessage = false;
  isUpdatingLastCheckedMessage = false;
  isMarkingOutreachAsUnread = false;
  isArchivingOutreach = false;
  isForwardingOutreach = false;
  isRequestingOutreachFeedback = false;
  isDownloadingPDFMessages = {};
  isRatingOutreach = false;

  sentOutreachesEntryIds = {};

  // d_ stands for desktop
  d_isSavedProjectsExpanded = true;
  d_isRecentOutreachesExpanded = true;
  d_isArchivedProjectsExpanded = true;
  d_isMyOutreachesExpanded = true;
  d_isForwardedOutreachesExpanded = true;
  d_isArchivedOutreachesExpanded = true;
  d_isProvideFeedbackExpanded = true;
  d_isReceiveFeedbackExpanded = true;

  _wss_checkQuery = () => {
    const queriesCount = this.wssQuery.length;
    let count = 0;
    while (count < queriesCount) {
      count++;
      const jsData = this.wssQuery.splice(0, 1)[0];
      this._wss_onData(jsData);
    }
  };

  _wss_onData = (jsData = {}) => {
    if (this.isLoadingOutreaches || this.initialLoadOutreaches || this.loadOutreachesError) {
      runInAction(() => {
        this.wssQuery.push(jsData);
      });
      return;
    } else {
      runInAction(() => {
        if (jsData.for === 'outreach') {
          if (jsData?.action === 'create') {
            this._wss_onCreateOutreach(jsData);
          } else if (jsData?.action === 'update') {
            this._wss_onUpdateOutreach(jsData);
          } else if (jsData?.action === 'delete') {
            this._wss_onDeleteOutreach(jsData);
          }
        } else if (jsData.for === 'chat') {
          if (jsData?.action === 'create') {
            this._wss_onCreateMessage(jsData);
          } else if (jsData?.action === 'update') {
            this._wss_onUpdateMessage(jsData);
          }
        } else if (jsData.for === 'chat-lcm-update') {
          this._wss_onLastCheckedMessageUpdate(jsData);
        } else if (jsData.for === 'chat-archive-update') {
          this._wss_onOutreachArchiveUpdate(jsData);
        } else if (jsData.for === 'chat-unread-update') {
          this._wss_onOutreachUnreadMarkUpdate(jsData);
        }
      });
    }
  };

  _wss_onCreateOutreach = (jsData) => {
    const outreach = jsData.data;
    const foundOutreach = this.outreaches.find((e) => e.id === outreach.id);
    if (foundOutreach) {
      Object.entries(outreach).forEach(([prop, value]) => {
        foundOutreach[prop] = value;
      });
    } else {
      if (!this.messages[outreach.id] && !this.messages[outreach.id]) {
        this.messages[outreach.id] = [];
      }
      this.outreaches.push(outreach);
    }
  };

  _wss_onUpdateOutreach = (jsData) => {
    const outreach = jsData.data;
    const foundOutreach = this.outreaches.find((e) => e.id === outreach.id);
    if (foundOutreach) {
      Object.entries(outreach).forEach(([prop, value]) => {
        foundOutreach[prop] = value;
      });
    }
  };

  _wss_onDeleteOutreach = (jsData) => {
    const outreachId = jsData.data?.id;

    const foundOutreachIndex = this.outreaches.findIndex((e) => e.id === outreachId);
    if (foundOutreachIndex !== -1) {
      this.outreaches.splice(foundOutreachIndex, 1);
    }

    delete this.messages[outreachId];
  };

  receiveTimeout = {};
  receiveScrollTimeout = {};
  _wss_onCreateMessage = (jsData) => {
    const message = jsData.data;

    if (!this.messages[message.outreachId]) {
      this.messages[message.outreachId] = [];
    }

    const foundMessage = this.messages[message.outreachId].find((m) => m.id === message.id);
    if (foundMessage) {
      Object.entries(message).forEach(([prop, value]) => {
        foundMessage[prop] = value;
      });
    } else {
      this.messages[message.outreachId].push(message);
    }

    const isMyMessage = message.userId === this.root.authStore.currentUserId;
    if (isMyMessage && !message.keepUnread) {
      clearTimeout(this.receiveTimeout[message.outreachId]);
      this.receiveTimeout[message.outreachId] = setTimeout(() => {
        this.markOutreachAsRead(message.outreachId, message.id);
      }, 10);
    }

    clearTimeout(this.receiveScrollTimeout[message.outreachId]);
    this.receiveScrollTimeout[message.outreachId] = setTimeout(() => {
      pubSub.publish({
        event: 'new-outreach-message',
        outreachId: message.outreachId
        // forceScroll: isMyMessage // not sure if needed
      });
    }, 10);
  };

  _wss_onUpdateMessage = (jsData) => {
    const message = jsData.data;

    if (!this.messages[message.outreachId]) {
      this.messages[message.outreachId] = [];
    }

    const foundMessage = this.messages[message.outreachId].find((m) => m.id === message.id);
    if (foundMessage) {
      Object.entries(message).forEach(([prop, value]) => {
        foundMessage[prop] = value;
      });
    } else {
      // message is not on the screen which means it isn't loaded and might be from 10 days ago, shouldn't be pushed in the array
      // this.messages[message.outreachId].push(message);
    }
  };

  _wss_onLastCheckedMessageUpdate = (jsData) => {
    const { outreachId, lastCheckedMessage } = jsData.data;
    const foundOutreach = this.outreaches.find((e) => e.id === outreachId);
    if (foundOutreach) {
      const foundParticipant = foundOutreach.participants?.find?.(
        (u) => u.id === this.root.authStore.currentUserId
      );
      if (foundParticipant) {
        foundParticipant.lastCheckedMessage = lastCheckedMessage;
      }
    }
  };

  _wss_onOutreachUnreadMarkUpdate = (jsData) => {
    const { outreachId, isMarkedAsUnread } = jsData.data;
    const foundOutreach = this.outreaches.find((o) => o.id === outreachId);
    if (foundOutreach) {
      const foundParticipant = foundOutreach.participants?.find?.(
        (u) => u.id === this.root.authStore.currentUserId
      );
      if (foundParticipant) {
        foundParticipant.isMarkedAsUnread = isMarkedAsUnread;
      }
    }
  };

  _wss_onOutreachArchiveUpdate = (jsData) => {
    const { outreachId, isArchived } = jsData.data;
    const foundOutreach = this.outreaches.find((o) => o.id === outreachId);
    if (foundOutreach) {
      const foundParticipant = foundOutreach.participants?.find?.(
        (u) => u.id === this.root.authStore.currentUserId
      );
      if (foundParticipant) {
        foundParticipant.isArchived = isArchived;
      }
    }
  };

  get projectsList() {
    if (this.root.authStore.userAccountType !== ACCOUNT_TYPES.AGENT) {
      return { saved: {}, archived: {}, savedNewMessagesCount: 0, archivedNewMessagesCount: 0 };
    }

    let [saved, archived, savedNewMessagesCount, archivedNewMessagesCount] = [{}, {}, 0, 0];
    this.outreaches.forEach((o) => {
      const userOutreachInfo = o.participants.find(
        (u) => u.id === this.root.authStore.currentUserId
      );
      const map = userOutreachInfo.isArchived ? archived : saved;

      const newMsgsCount = this.messages[o.id].filter(
        (m) =>
          m.date > userOutreachInfo.lastCheckedMessage &&
          m.userId !== this.root.authStore.currentUserId
      ).length;
      const lastMessageDate = this.messages[o.id][this.messages[o.id].length - 1]?.date || 0;

      if (userOutreachInfo.isArchived) {
        archivedNewMessagesCount += newMsgsCount;
      } else {
        savedNewMessagesCount += newMsgsCount;
      }

      const hasMarkedUnread = !!userOutreachInfo.isMarkedAsUnread;

      if (!map[o.projectId]) {
        const project = this.root.projectStore.allProjects.find((p) => p.id === o.projectId);
        const projectName = project?.name || o.projectName || 'Loading';
        const searchType = project?.searchType || o.objectType || 'loading';
        map[o.projectId] = {
          projectName,
          outreaches: [o],
          newMessagesCount: newMsgsCount,
          searchType,
          lastMessageDate,
          hasMarkedUnread
        };
      } else {
        map[o.projectId].outreaches.push(o);
        map[o.projectId].newMessagesCount += newMsgsCount;
        if (hasMarkedUnread) {
          map[o.projectId].hasMarkedUnread = true;
        }
      }
    });

    return { saved, archived, savedNewMessagesCount, archivedNewMessagesCount };
  }

  get recentOutreaches() {
    const sortedList = this.outreaches
      .filter((o) => !o.isProjectFeedback)
      .sort(
        (a, b) =>
          (this.messages[b.id][this.messages[b.id].length - 1]?.date || 0) -
          (this.messages[a.id][this.messages[a.id].length - 1]?.date || 0)
      );

    let outreaches = sortedList;
    if (outreaches.length > 5) {
      outreaches = outreaches.slice(0, 5);
    }

    return outreaches.map((o) => {
      const userOutreachInfo = o.participants.find(
        (u) => u.id === this.root.authStore.currentUserId
      );
      const newMessagesCount = this.messages[o.id].filter(
        (m) =>
          m.date > userOutreachInfo.lastCheckedMessage &&
          m.userId !== this.root.authStore.currentUserId
      ).length;
      return { ...o, newMessagesCount, hasMarkedUnread: userOutreachInfo.isMarkedAsUnread };
    });
  }

  get outreachesList() {
    if (this.root.authStore.userAccountType !== ACCOUNT_TYPES.INVESTOR) {
      return {
        active: {},
        archived: {},
        forwarded: {},
        teamFeedbackNewMessagesCount: 0,
        teamFeedbackHasMarkedUnread: false,
        giveFeedbackHasMarkedUnread: false,
        getFeedbackHasMarkedUnread: false,
        giveFeedbackNewMessagesCount: 0,
        getFeedbackNewMessagesCount: 0,
        activeNewMessagesCount: 0,
        archivedNewMessagesCount: 0,
        forwardedNewMessagesCount: 0,
        giveFeedback: [],
        getFeedback: []
      };
    }

    let [
      active,
      archived,
      forwarded,
      teamFeedbackNewMessagesCount,
      teamFeedbackHasMarkedUnread,
      giveFeedbackHasMarkedUnread,
      getFeedbackHasMarkedUnread,
      giveFeedbackNewMessagesCount,
      getFeedbackNewMessagesCount,
      activeNewMessagesCount,
      archivedNewMessagesCount,
      forwardedNewMessagesCount,
      giveFeedback,
      getFeedback
    ] = [{}, {}, {}, 0, false, false, false, 0, 0, 0, 0, 0, [], []];

    this.outreaches.forEach((o) => {
      const userOutreachInfo = o.participants.find(
        (u) => u.id === this.root.authStore.currentUserId
      );

      const newMsgsCount = this.messages[o.id].filter(
        (m) =>
          m.date > userOutreachInfo.lastCheckedMessage &&
          m.userId !== this.root.authStore.currentUserId
      ).length;
      const lastMessageDate = this.messages[o.id][this.messages[o.id].length - 1]?.date || 0;

      const hasMarkedUnread = userOutreachInfo.isMarkedAsUnread;

      if (o.isProjectFeedback) {
        if (o.feedbackRequestorId === this.root.authStore.currentUserId) {
          getFeedback.push(o);
          getFeedbackNewMessagesCount += newMsgsCount;
          if (hasMarkedUnread) {
            getFeedbackHasMarkedUnread = true;
          }
        } else {
          giveFeedback.push(o);
          giveFeedbackNewMessagesCount += newMsgsCount;
          if (hasMarkedUnread) {
            giveFeedbackHasMarkedUnread = true;
          }
        }

        teamFeedbackNewMessagesCount += newMsgsCount;
        if (hasMarkedUnread) {
          teamFeedbackHasMarkedUnread = true;
        }
      } else {
        const map = userOutreachInfo.isArchived
          ? archived
          : o.forwardedFromUserId === this.root.authStore.currentUserId
          ? forwarded
          : active;

        if (userOutreachInfo.isArchived) {
          archivedNewMessagesCount += newMsgsCount;
        } else {
          if (o.forwardedFromUserId === this.root.authStore.currentUserId) {
            forwardedNewMessagesCount += newMsgsCount;
          } else {
            activeNewMessagesCount += newMsgsCount;
          }
        }

        if (!map[o.objectData.id]) {
          map[o.objectData.id] = {
            outreaches: [o],
            newMessagesCount: newMsgsCount,
            name: o.objectData.name,
            objectType: o.objectType,
            lastMessageDate,
            hasMarkedUnread
          };
        } else {
          map[o.objectData.id].outreaches.push(o);
          map[o.objectData.id].newMessagesCount += newMsgsCount;
          if (hasMarkedUnread) {
            map[o.objectData.id].hasMarkedUnread = true;
          }
        }
      }
    });

    return {
      active,
      archived,
      forwarded,
      teamFeedbackNewMessagesCount,
      teamFeedbackHasMarkedUnread,
      giveFeedbackHasMarkedUnread,
      getFeedbackHasMarkedUnread,
      giveFeedbackNewMessagesCount,
      getFeedbackNewMessagesCount,
      activeNewMessagesCount,
      archivedNewMessagesCount,
      forwardedNewMessagesCount,
      giveFeedback,
      getFeedback
    };
  }

  get totalOutreachMessagesCount() {
    return this.outreaches.reduce((count, o) => {
      const userOutreachInfo = o.participants.find(
        (u) => u.id === this.root.authStore.currentUserId
      );
      count += this.messages[o.id].filter(
        (m) => m.date > userOutreachInfo?.lastCheckedMessage || 0
      ).length;
      return count;
    }, 0);
  }

  loadOutreaches = () => {
    if (
      this.isLoadingOutreaches ||
      this.root.authStore.userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED
    ) {
      return;
    }

    const onFinally = () => {
      this.isLoadingOutreaches = false;
      if (this.initialLoadOutreaches) {
        this.initialLoadOutreaches = false;
      }
    };

    this.isLoadingOutreaches = true;
    this.loadOutreachesError = null;
    let outreaches = null;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_OUTREACHES,
      onSuccess: (response) => {
        outreaches = response;
      },
      onError: (errorMessage) => {
        this.loadOutreachesError = errorMessage || 'Failed to obtain outreaches.';
      },
      onFinally: () => {
        if (outreaches) {
          if (outreaches.length) {
            // Limitation break
            // const body = {
            //   outreaches: outreaches.map((o) => ({
            //     outreachId: o.id,
            //     date: 194000000000000,
            //     ...(this.messages[o.id]?.length
            //       ? { startDate: this.messages[o.id][this.messages[o.id].length - 1]?.date }
            //       : {})
            //   }))
            // };
            // in case the startDate is present the backend endpoint shouldn't limit the messages count to 20
            // but instead return all the messages where entry date > startDate from body and entry date < date from body
            this.root.makeRequest({
              endpoint: API_ENDPOINTS.GET_OUTREACH_MESSAGES,
              body: {
                outreaches: outreaches.map((o) => ({ outreachId: o.id, date: 194000000000000 }))
              },
              onSuccess: (response) => {
                this.outreaches = outreaches;
                // Limitation - if the user has loaded 30 messages for a single outreach
                // then the user has disconnected and the outreach had 30 more new messages while he was disconnected but still in the app
                // once he reconnects and loads the new response data he will have some missing messages
                // he will have only [message1, ..., message30, message40, ..., message60]
                // to break this limitation the endpoint API_ENDPOINTS.GET_OUTREACH_MESSAGES should receive the body is format:
                // outreaches: outreaches.map((o) => ({ outreachId: o.id, date: 194000000000000, startDate: date_of_last_message_for_the_outreach_ms }))
                Object.entries(response).forEach(([outreachId, messages]) => {
                  if (!this.messages[outreachId]?.length) {
                    this.messages[outreachId] = messages;
                  } else {
                    const oldMessages = this.messages[outreachId].filter(
                      (om) => messages.findIndex((nm) => nm.id === om.id) === -1
                    );
                    this.messages[outreachId] = [...oldMessages, ...messages];
                    this.messages[outreachId].sort((a, b) => a.date - b.date);
                  }
                });
                this._wss_checkQuery();
              },
              onError: (errorMessage) => {
                this.loadOutreachesError = errorMessage || 'Failed to obtain outreach messages.';
              },
              onFinally
            });
          } else {
            onFinally();
          }
        } else {
          onFinally();
        }
      }
    });
  };

  loadMoreMessages = (outreachId = '') => {
    if (this.isLoadingMoreMessages[outreachId] || this.fullyLoadedMessages[outreachId]) {
      return;
    }

    runInAction(() => {
      this.isLoadingMoreMessages[outreachId] = true;
    });

    const firstMessage = this.messages[outreachId][0];
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_OUTREACH_MESSAGES,
      body: { outreaches: [{ outreachId, date: firstMessage.date }] },
      onSuccess: (response) => {
        const messages = response[outreachId];
        this.messages[outreachId] = [...messages, ...this.messages[outreachId]];
        if (!messages.length) {
          this.fullyLoadedMessages[outreachId] = true;
        }
        setTimeout(() => {
          pubSub.publish({ event: 'load-more-messages', outreachId, fromId: firstMessage.id });
        }, 10);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to load outreach messages.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.isLoadingMoreMessages[outreachId] = false;
      }
    });
  };

  sendOutreach = (
    projectId = '',
    entriesIds = [],
    subject = '',
    message = '',
    documents = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isSendingOutreach) {
      return;
    }

    runInAction(() => {
      this.isSendingOutreach = true;

      entriesIds.forEach((entryId) => {
        this.sentOutreachesEntryIds[entryId] = [
          ...(this.sentOutreachesEntryIds[entryId] || []),
          projectId
        ];
      });
    });

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.POST_OUTREACH_MESSAGE,
      body: { projectId, entriesIds, subject, message, documents },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to send outreach.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
        entriesIds.forEach((entryId) => {
          this.sentOutreachesEntryIds[entryId] = this.sentOutreachesEntryIds[entryId].filter(
            (pId) => pId !== projectId
          );
        });
      },
      onFinally: () => {
        this.isSendingOutreach = false;
        onFinally();
      }
    });
  };

  sendMessage = (
    outreachId = '',
    message = '',
    documents = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isSendingMessage) {
      return;
    }

    this.isSendingMessage = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.SEND_OUTREACH_MESSAGE,
      body: { outreachId, message, documents },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to send message.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isSendingMessage = false;
        onFinally();
      }
    });
  };

  markOutreachAsRead = (outreachId = '', messageId = '') => {
    if (this.isUpdatingLastCheckedMessage) {
      return;
    }

    let foundDate = 0;
    if (messageId) {
      foundDate = this.messages[outreachId].find((m) => m.id === messageId)?.date;
    } else {
      foundDate = this.messages[outreachId][this.messages[outreachId].length - 1]?.date;
    }

    const outreach = this.outreaches.find((o) => o.id === outreachId);
    const participant = outreach?.participants?.find?.(
      (u) => u.id === this.root.authStore.currentUserId
    );
    if (outreach && participant) {
      if (participant?.lastCheckedMessage >= foundDate) {
        return;
      }

      // runInAction(() => {
      //   participant.lastCheckedMessage = foundDate;
      // });
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.UPDATE_OUTREACH_LAST_CHECKED_MESSAGE,
      body: {
        outreachId,
        date: foundDate ? foundDate + 1 : new Date().getTime()
      }
    });
  };

  markOutreachAsUnread = (outreachId = '', isUnread = true) => {
    if (this.isMarkingOutreachAsUnread) {
      return;
    }

    runInAction(() => {
      this.isMarkingOutreachAsUnread = true;
    });

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.MARK_OUTREACH_UNREAD,
      body: { outreachId, isUnread },
      onFinally: () => {
        this.isMarkingOutreachAsUnread = false;
      }
    });

    // const foundDate = this.messages[outreachId][this.messages[outreachId].length - 1]?.date;
    // this.root.makeRequest({
    //   endpoint: API_ENDPOINTS.UPDATE_OUTREACH_LAST_CHECKED_MESSAGE,
    //   body: {
    //     outreachId,
    //     date: 0
    //     // date: foundDate ? foundDate - 1 : new Date().getTime()
    //   }
    // });
  };

  archiveOutreach = (
    outreachId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isArchivingOutreach) {
      return;
    }

    this.isArchivingOutreach = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ARCHIVE_OUTREACH,
      body: { outreachId, archive: true },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to archive outreach.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isArchivingOutreach = false;
        onFinally();
      }
    });
  };

  unarchiveOutreach = (
    outreachId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isArchivingOutreach) {
      return;
    }

    this.isArchivingOutreach = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ARCHIVE_OUTREACH,
      body: { outreachId, archive: false },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to unarchive outreach.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isArchivingOutreach = false;
        onFinally();
      }
    });
  };

  forwardOutreach = (
    outreachId = '',
    objectType = '',
    objectId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isForwardingOutreach) {
      return;
    }

    this.isForwardingOutreach = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.FORWARD_OUTREACH,
      body: { outreachId, objectType, objectId },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        this.isForwardingOutreach = false;
        onFinally();
      }
    });
  };

  requestOutreachFeedback = (
    outreachId = '',
    askUserIds = [],
    message = '',
    requestSeparateFeedbacks = false,
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isRequestingOutreachFeedback) {
      return;
    }

    this.isRequestingOutreachFeedback = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.REQUEST_OUTREACH_FEEDBACK,
      body: { outreachId, askUserIds, message, requestSeparateFeedbacks },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        this.isRequestingOutreachFeedback = false;
        onFinally();
      }
    });
  };

  downloadPDF = (
    outreachId = '',
    messageId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isDownloadingPDFMessages[messageId]) {
      return;
    }

    runInAction(() => {
      this.isDownloadingPDFMessages[messageId] = true;
    });

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_DOCUMENT_DOWNLOAD_URL,
      body: { outreachId, messageId },
      onSuccess: (response) => {
        onSuccess(response);
        window.open(response.presignedUrl, '_blank', 'noopener, noreferrer');
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        this.isDownloadingPDFMessages[messageId] = false;
        onFinally();
      }
    });
  };

  rateOutreach = (
    outreachId = '',
    rating = '',
    ratingNumber = 0,
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isRatingOutreach) {
      return;
    }

    this.isRatingOutreach = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.RATE_OUTREACH,
      body: { outreachId, rating, ratingNumber },
      onSuccess: (response) => {
        const foundOutreach = this.outreaches.find((o) => o.id === outreachId);
        if (foundOutreach) {
          const participant = foundOutreach.participants.find(
            (u) => u.id === this.root.authStore.currentUserId
          );
          if (participant) {
            participant.isOutreachFeedbackGiven = true;
          }
        }
        this.root.utilsStore.setHeaderMessage('Rating submitted!');
        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        this.isRatingOutreach = false;
        onFinally();
      }
    });
  };

  getProjectIdForSearch = (searchId = '') => {
    return this.root.projectStore.allProjects.find((p) => p.searchId === searchId)?.id;
  };

  canSendOutreachToEntryId = (projectId = '', entryId = '') => {
    if (
      this.sentOutreachesEntryIds[entryId] &&
      this.sentOutreachesEntryIds[entryId].includes(projectId)
    ) {
      return false;
    }

    if (this.outreaches.find((o) => o.projectId === projectId && o.objectData.id === entryId)) {
      return false;
    }

    return true;
  };

  toggle_d_SavedProjects = () => {
    this.d_isSavedProjectsExpanded = !this.d_isSavedProjectsExpanded;
  };
  toggle_d_RecentOutreaches = () => {
    this.d_isRecentOutreachesExpanded = !this.d_isRecentOutreachesExpanded;
  };
  toggle_d_ArchivedProjects = () => {
    this.d_isArchivedProjectsExpanded = !this.d_isArchivedProjectsExpanded;
  };
  toggle_d_MyOutreaches = () => {
    this.d_isMyOutreachesExpanded = !this.d_isMyOutreachesExpanded;
  };
  toggle_d_ForwardedOutreaches = () => {
    this.d_isForwardedOutreachesExpanded = !this.d_isForwardedOutreachesExpanded;
  };
  toggle_d_ArchivedOutreaches = () => {
    this.d_isArchivedOutreachesExpanded = !this.d_isArchivedOutreachesExpanded;
  };
  toggle_d_ProvideFeedback = () => {
    this.d_isProvideFeedbackExpanded = !this.d_isProvideFeedbackExpanded;
  };
  toggle_d_ReceiveFeedback = () => {
    this.d_isReceiveFeedbackExpanded = !this.d_isReceiveFeedbackExpanded;
  };
}

export default OutreachStore;
