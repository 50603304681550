import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import Reroute from '../../components/navigation/Reroute';
import { paths, routes } from '../../utils/constants/routes';
import { useMemo } from 'react';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import FundsList from './components/FundsList';
import PortfoliosList from './components/PortfoliosList';
import InvestProfilesList from './components/InvestProfilesList';
import { FUNDS_PATH_COMPONENTS, FUNDS_VIEW_MODE } from './constants';
import FundView from './components/FundView';
import PortfolioView from './components/PortfolioView';
import InvestProfileView from './components/InvestProfileView';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import GeneralError from '../../components/errors/GeneralError';
import useHistory from '../../hooks/useHistory';
import { matchPath } from '../../utils/utils';

const Funds = observer(() => {
  const { fundsStore, authStore } = useStore();
  const { location, params, goBack } = useHistory();

  const [fundId, editFundEntry, portfolioId, editPortfolioEntry, profileId, editProfileEntry] =
    useMemo(() => {
      const urlParams = new URLSearchParams(location.search);
      const [fundId, portfolioId, profileId] = [
        urlParams.get('fundId'),
        urlParams.get('portfolioId'),
        urlParams.get('profileId')
      ];
      const [editFundEntry, editPortfolioEntry, editProfileEntry] = [
        fundsStore.funds.find((f) => f.id == fundId),
        fundsStore.portfolios.find((f) => f.id == portfolioId),
        fundsStore.investProfiles.find((f) => f.id === profileId)
      ];
      return [fundId, editFundEntry, portfolioId, editPortfolioEntry, profileId, editProfileEntry];
    }, [fundsStore, location.search, fundsStore.isLoading]);

  const isFundPath = matchPath([routes.FUNDS_PATH], location);
  const isPortfolioCoPath = matchPath([routes.PORTFOLIO_COMPANIES_PATH], location);
  const isInvestProfilePath = matchPath([routes.INVEST_PROFILES_PATH], location);
  const component = params.component;
  const showFundsList = !component && isFundPath;
  const showPortfoliosList = !component && isPortfolioCoPath;
  const showProfilesList = !component && isInvestProfilePath;
  const showFundView =
    isFundPath && [FUNDS_PATH_COMPONENTS.CREATE, FUNDS_PATH_COMPONENTS.EDIT].includes(component);
  const showPortfolioView =
    isPortfolioCoPath &&
    [FUNDS_PATH_COMPONENTS.CREATE, FUNDS_PATH_COMPONENTS.EDIT].includes(component);
  const showProfileView =
    isInvestProfilePath &&
    [FUNDS_PATH_COMPONENTS.CREATE, FUNDS_PATH_COMPONENTS.EDIT].includes(component);
  const hideMobileFooter = !(showFundsList || showPortfoliosList || showProfilesList);

  const isCorporate = authStore.isCoraporatesInvestor;

  if (
    fundsStore.initialLoad ||
    (fundsStore.isLoading &&
      ((isCorporate && !fundsStore.allInvestProfiles.length) ||
        (!isCorporate && !fundsStore.allFunds.length && !fundsStore.allPortfolios.length)))
  ) {
    return <GeneralLoader />;
  }

  if (
    fundsStore.loadDataError &&
    ((isCorporate && !fundsStore.allInvestProfiles.length) ||
      (!isCorporate && !fundsStore.allFunds.length && !fundsStore.allPortfolios.length))
  ) {
    return (
      <StaticPageLayout
        page="investor-funds-listing"
        hideMobileFooter={hideMobileFooter}
        component={component || 'default'}>
        <GeneralError
          errorMessage={fundsStore.loadDataError}
          actionMessage="You may want to:"
          withHomePageButton={true}
          actionButtontext={'Try again'}
          onActionButtonClick={fundsStore.loadFunds}
        />
      </StaticPageLayout>
    );
  }

  if (isCorporate && !isInvestProfilePath) {
    return <Reroute path={paths.INVEST_PROFILES} />;
  }

  if (!isCorporate && isInvestProfilePath) {
    return <Reroute path={paths.FUNDS} />;
  }

  if (
    showFundView &&
    component === FUNDS_PATH_COMPONENTS.EDIT &&
    (!fundId || !editFundEntry) &&
    !fundsStore.isLoadingData &&
    !fundsStore.loadDataError
  ) {
    return <Reroute path={paths.FUNDS} />;
  }

  if (
    showPortfolioView &&
    component === FUNDS_PATH_COMPONENTS.EDIT &&
    (!portfolioId || !editPortfolioEntry) &&
    !fundsStore.isLoadingData &&
    !fundsStore.loadDataError
  ) {
    return <Reroute path={paths.PORTOFLIO_COMPANIES} />;
  }

  if (
    showProfileView &&
    component === FUNDS_PATH_COMPONENTS.EDIT &&
    (!profileId || !editProfileEntry) &&
    !fundsStore.isLoadingData &&
    !fundsStore.loadDataError
  ) {
    return <Reroute path={paths.INVEST_PROFILES} />;
  }

  return (
    <StaticPageLayout
      page="investor-funds-listing"
      hideMobileFooter={hideMobileFooter}
      component={component || 'default'}>
      {(showFundsList || showPortfoliosList || showProfilesList) && (
        <>
          <div className="gray-title-panel">
            <div className="title-section">
              <h4>
                {isCorporate
                  ? 'My invest profiles'
                  : isFundPath
                  ? 'My funds'
                  : 'My portfolio companies'}
              </h4>
              <h6>
                {isCorporate
                  ? 'Create and manage your invest profiles'
                  : isFundPath
                  ? 'Create and manage your fund profiles'
                  : 'Create and manage your portfolio company profiles'}
              </h6>
            </div>
          </div>
          <div className="tabs">
            {isCorporate ? (
              <div
                className={'tab no-cursor investo-profile'}
                onClick={() => goBack(paths.INVEST_PROFILES)}>
                Invest profiles
              </div>
            ) : (
              <>
                <div
                  className={`tab ${showFundsList ? 'active' : ''}`}
                  onClick={() => goBack(paths.FUNDS)}>
                  Funds
                </div>
                <div
                  className={`tab ${showPortfoliosList ? 'active' : ''}`}
                  onClick={() => goBack(paths.PORTOFLIO_COMPANIES)}>
                  <div className="desktop-text">Portfolio companies</div>
                  <div className="mobile-text">Portfolio co.</div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {showFundsList && <FundsList />}
      {showPortfoliosList && <PortfoliosList />}
      {showProfilesList && <InvestProfilesList />}
      {showFundView && (
        <FundView
          fund={editFundEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE === component ? 'c-f' : 'e-f') + (editFundEntry?.id || '')
          }
        />
      )}
      {showPortfolioView && (
        <PortfolioView
          portfolio={editPortfolioEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE === component ? 'c-p' : 'e-p') +
            (editPortfolioEntry?.id || '')
          }
        />
      )}
      {showProfileView && (
        <InvestProfileView
          profile={editProfileEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE === component ? 'c-p' : 'e-p') +
            (editProfileEntry?.id || '')
          }
        />
      )}
    </StaticPageLayout>
  );
});

export default Funds;
