import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { mapData } from '../../api/dataMappers';
import { FUND_FIELDS, PORTFOLIO_FIELDS, INVEST_PROFILE_FIELDS } from '../../utils/constants/fields';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';
import { ACCOUNT_VERIF_TYPES, ACCOUNT_TYPES } from '../../utils/constants/auth';

class FundsStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  reset = () => {
    this.wssQuery = [];
    this.allFunds = [];
    this.allPortfolios = [];
    this.allInvestProfiles = [];
    this.loadDataError = null;
    this.isLoading = false;
    this.initialLoad = true;
    this.fundsInAction = {};
    this.portfoliosInAction = {};
    this.investProfilesInAction = {};
    this.isCreatingFund = false;
    this.isCreatingPortfolio = false;
    this.isCreatingInvestProfie = false;
    this.isLoadingCompanyFunds = false;
    this.companyFunds = [];
    this.companyPortfolios = [];
    this.companyInvestProfiles = [];
  };

  wssQuery = [];
  allFunds = [];
  allPortfolios = [];
  allInvestProfiles = [];
  loadDataError = null;
  isLoading = false;
  initialLoad = true;
  fundsInAction = {};
  portfoliosInAction = {};
  investProfilesInAction = {};
  isCreatingFund = false;
  isCreatingPortfolio = false;
  isCreatingInvestProfie = false;
  isLoadingCompanyFunds = false;
  companyFunds = [];
  companyPortfolios = [];
  companyInvestProfiles = [];

  _wss_checkQuery = () => {
    const queriesCount = this.wssQuery.length;
    let count = 0;
    while (count < queriesCount) {
      count++;
      const jsData = this.wssQuery.splice(0, 1)[0];
      this._wss_onData(jsData);
    }
  };

  _wss_onData = (jsData = {}) => {
    if (this.isLoading || this.initialLoad || this.loadDataError) {
      runInAction(() => {
        this.wssQuery.push(jsData);
      });
      return;
    } else {
      if (['create', 'update'].includes(jsData?.action)) {
        const entryFields = {
          fund: FUND_FIELDS,
          portfolio: PORTFOLIO_FIELDS,
          'invest-profile': INVEST_PROFILE_FIELDS
        }[jsData.for];

        jsData.data = {
          ...jsData.data,
          ...mapData(jsData.data, entryFields)
        };
      }

      runInAction(() => {
        if (jsData?.action === 'create') {
          this._wss_onCreate(jsData);
        } else if (jsData?.action === 'update') {
          this._wss_onUpdate(jsData);
        } else if (jsData?.action === 'delete') {
          this._wss_onDelete(jsData);
        }
      });
    }
  };

  _wss_onCreate = (jsData) => {
    const entryParentProperty = {
      fund: 'allFunds',
      portfolio: 'allPortfolios',
      'invest-profile': 'allInvestProfiles'
    }[jsData.for];
    const entry = jsData.data;

    const foundEntry = this[entryParentProperty].find((e) => e.id === entry.id);
    if (foundEntry) {
      Object.entries(entry).forEach(([prop, value]) => {
        foundEntry[prop] = value;
      });
    } else {
      this[entryParentProperty].push(entry);
    }
  };

  _wss_onUpdate = (jsData) => {
    const entryParentProperty = {
      fund: 'allFunds',
      portfolio: 'allPortfolios',
      'invest-profile': 'allInvestProfiles'
    }[jsData.for];
    const entry = jsData.data;

    const foundEntry = this[entryParentProperty].find((e) => e.id === entry.id);
    if (foundEntry) {
      Object.entries(entry).forEach(([prop, value]) => {
        foundEntry[prop] = value;
      });
    }
  };

  _wss_onDelete = (jsData) => {
    const entryParentProperty = {
      fund: 'allFunds',
      portfolio: 'allPortfolios',
      'invest-profile': 'allInvestProfiles'
    }[jsData.for];
    const entryId = jsData.data?.id;

    const foundEntryIndex = this[entryParentProperty].findIndex((e) => e.id === entryId);
    if (foundEntryIndex !== -1) {
      this[entryParentProperty].splice(foundEntryIndex, 1);
    }
  };

  loadCompanyFunds = () => {
    if (
      this.isLoadingCompanyFunds ||
      this.root.authStore.userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED ||
      this.root.authStore.userAccountType !== ACCOUNT_TYPES.INVESTOR
    ) {
      return;
    }

    this.isLoadingCompanyFunds = true;

    if (this.root.authStore.isCoraporatesInvestor) {
      this.root.makeRequest({
        endpoint: API_ENDPOINTS.GET_SELF_COMPANY_INVEST_PROFILES,
        onSuccess: (response) => {
          this.companyInvestProfiles = response;
        },
        onError: (/*errorMessage*/) => {
          console.error('Failed to load company invest profiles!');
        },
        onFinally: () => {
          this.isLoadingCompanyFunds = false;
        }
      });
    } else {
      this.root.makeRequests({
        requests: [
          { endpoint: API_ENDPOINTS.GET_SELF_COMPANY_FUNDS },
          { endpoint: API_ENDPOINTS.GET_SELF_COMPANY_PORTFOLIOS }
        ],
        onSuccess: (response) => {
          this.companyFunds = response[0];
          this.companyPortfolios = response[1];
        },
        onError: (/*errorMessage*/) => {
          console.error('Failed to load company funds and portfolios!');
        },
        onFinally: () => {
          this.isLoadingCompanyFunds = false;
        }
      });
    }
  };

  get isLoadingData() {
    return this.isLoading || this.initialLoad;
  }

  get funds() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allFunds.filter((f) => f.ownerId === currentUserId);
  }

  get portfolios() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allPortfolios.filter((p) => p.ownerId === currentUserId);
  }

  get investProfiles() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allInvestProfiles.filter((i) => i.ownerId === currentUserId);
  }

  loadFunds = () => {
    if (
      this.isLoading ||
      this.root.authStore.userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED ||
      this.root.authStore.userAccountType !== ACCOUNT_TYPES.INVESTOR
    ) {
      return;
    }

    this.loadCompanyFunds();
    this.isLoading = true;
    this.loadDataError = null;

    if (this.root.authStore.isCoraporatesInvestor) {
      this.root.makeRequest({
        endpoint: API_ENDPOINTS.GET_INVEST_PROFILES,
        onSuccess: (response) => {
          this.allInvestProfiles = mapData(response, INVEST_PROFILE_FIELDS);
          this._wss_checkQuery();
        },
        onError: (errorMessage) => {
          this.loadDataError = errorMessage || 'Failed to obtain invest profiles';
        },
        onFinally: () => {
          this.isLoading = false;
          if (this.initialLoad) {
            this.initialLoad = false;
          }
        }
      });
    } else {
      this.root.makeRequests({
        requests: [
          { endpoint: API_ENDPOINTS.GET_FUNDS },
          { endpoint: API_ENDPOINTS.GET_PORTFOLIO_COMPANIES }
        ],
        onSuccess: (response) => {
          this.allFunds = mapData(response[0], FUND_FIELDS);
          this.allPortfolios = mapData(response[1], PORTFOLIO_FIELDS);
          this._wss_checkQuery();
        },
        onError: (errorMessage) => {
          this.loadDataError = errorMessage || 'Failed to obtain funds and portoflio companies';
        },
        onFinally: () => {
          this.isLoading = false;
          if (this.initialLoad) {
            this.initialLoad = false;
          }
        }
      });
    }
  };

  editFund = (entry = {}, updatedValues = {}, callback = () => {}) => {
    if (this.fundsInAction[entry.id]) {
      return;
    }

    this.fundsInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.EDIT_FUND,
      body: { ...entry, ...mapData(updatedValues, FUND_FIELDS, true) },
      onSuccess: (response) => {
        this._wss_onData({ for: 'fund', action: 'update', data: response }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Successfully edited fund.');
        callback();
        this.loadCompanyFunds();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to edit fund.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.fundsInAction[entry.id] = false;
      }
    });
  };

  createFund = (fundData = {}, callback = () => {}) => {
    if (this.isCreatingFund) {
      return;
    }

    this.isCreatingFund = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.CREATE_FUND,
      body: { ...mapData(fundData, FUND_FIELDS, true) },
      onSuccess: (response) => {
        this._wss_onData({ for: 'fund', action: 'create', data: response }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Fund successfully created.');
        callback();
        this.loadCompanyFunds();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to create fund.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.isCreatingFund = false;
      }
    });
  };

  deleteFund = (entry = {}, callback = () => {}) => {
    if (this.fundsInAction[entry.id]) {
      return;
    }

    this.fundsInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_FUND,
      body: { id: entry.id },
      onSuccess: () => {
        this._wss_onData({ for: 'fund', action: 'delete', data: { id: entry.id } }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Successfully deleted fund.');
        callback();
        this.loadCompanyFunds();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to delete fund.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.fundsInAction[entry.id] = false;
      }
    });
  };

  editPortfolio = (entry = {}, updatedValues = {}, callback = () => {}) => {
    if (this.portfoliosInAction[entry.id]) {
      return;
    }

    this.portfoliosInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.EDIT_PORTFOLIO_COMPANY,
      body: { ...entry, ...mapData(updatedValues, PORTFOLIO_FIELDS, true) },
      onSuccess: (response) => {
        this._wss_onData({ for: 'portfolio', action: 'update', data: response }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Successfully edited portfolio company.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to edit portfolio company.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.portfoliosInAction[entry.id] = false;
      }
    });
  };

  createPortfolio = (portfolioData = {}, callback = () => {}) => {
    this.isCreatingPortfolio = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.CREATE_PORTFOLIO_COMPANY,
      body: { ...mapData(portfolioData, PORTFOLIO_FIELDS, true) },
      onSuccess: (response) => {
        this._wss_onData({ for: 'portfolio', action: 'create', data: response }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Portfolio company successfully created.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to create portoflio company.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.isCreatingPortfolio = false;
      }
    });
  };

  deletePortfolio = (entry = {}, callback = () => {}) => {
    if (this.portfoliosInAction[entry.id]) {
      return;
    }

    this.portfoliosInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_PORTFOLIO_COMPANY,
      body: { id: entry.id },
      onSuccess: () => {
        this._wss_onData({ for: 'portfolio', action: 'delete', data: { id: entry.id } }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Successfully deleted portfolio company.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to delete portfolio company.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.portfoliosInAction[entry.id] = false;
      }
    });
  };

  editInvestProfile = (entry = {}, updatedValues = {}, callback = () => {}) => {
    if (this.investProfilesInAction[entry.id]) {
      return;
    }

    this.investProfilesInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.EDIT_INVEST_PROFILE,
      body: { ...entry, ...mapData(updatedValues, INVEST_PROFILE_FIELDS, true) },
      onSuccess: (response) => {
        this._wss_onData({ for: 'invest-profile', action: 'update', data: response }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Successfully edited invest profile.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to edit invest profile.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.investProfilesInAction[entry.id] = false;
      }
    });
  };

  createInvestProfile = (investProfileData = {}, callback = () => {}) => {
    this.isCreatingInvestProfie = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.CREATE_INVEST_PROFILE,
      body: { ...mapData(investProfileData, INVEST_PROFILE_FIELDS, true) },
      onSuccess: (response) => {
        this._wss_onData({ for: 'invest-profile', action: 'create', data: response }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Invest profile successfully created.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to create invest profile.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.isCreatingInvestProfie = false;
      }
    });
  };

  deleteInvestProfile = (entry = {}, callback = () => {}) => {
    if (this.investProfilesInAction[entry.id]) {
      return;
    }

    this.investProfilesInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_INVEST_PROFILE,
      body: { id: entry.id },
      onSuccess: () => {
        this._wss_onData({ for: 'invest-profile', action: 'delete', data: { id: entry.id } }); // TODO: Remove when WSS functionality is done
        this.root.utilsStore.setHeaderMessage('Successfully deleted invest profile.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to delete invest profile.',
          HEADER_MESSAGE_TYPES.ERROR
        );
      },
      onFinally: () => {
        this.investProfilesInAction[entry.id] = false;
      }
    });
  };
}

export default FundsStore;
