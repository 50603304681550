import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';

class WebSocketStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
    this.pingSocket();
    this.checkSocketConnection();
  }

  reset = () => {
    this.onSocketClose();
  };

  pingSocket = () => {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ ping: 'pong' }));
    }
    setTimeout(this.pingSocket, 120_000);
  };

  checkSocketConnection = () => {
    if (!this.connected) {
      this.init();
    }

    setTimeout(this.checkSocketConnection, 5000);
  };

  ws = null;
  connected = false;
  isConnecting = false;

  loadReconectedData = () => {
    // user has been disconnected before that
    this.root.authStore.loadCompanyUsers();
    this.root.fundsStore.loadCompanyFunds();
    this.root.fundsStore.loadFunds();
    this.root.projectStore.loadProjects();
    this.root.profilerProjectStore.loadProjects();
    this.root.profilerProjectStore.loadWatchlist();
    this.root.outreachStore.loadOutreaches();
  };

  _initSocket = (wssToken) => {
    const userId = this.root.authStore.user.idToken.payload['cognito:username'];

    const ws = new WebSocket(
      `${process.env.REACT_APP_WSS_URL}?userId=${userId}&wssToken=${wssToken}`
    );

    ws.onopen = () => {
      if (!this.connected) {
        this.loadReconectedData();
      }
      this.connected = true;
      this.isConnecting = false;
    };

    ws.onmessage = (event) => {
      try {
        const jsData = JSON.parse(event.data);
        if (jsData?.message !== 'default') {
          console.log('ws event.data = ', jsData);
        }

        if (Array.isArray(jsData)) {
          jsData.forEach((dataEntry) => {
            const dataType = dataEntry?.for;
            if (['fund', 'portfolio', 'invest-profile'].includes(dataType)) {
              this.root.fundsStore._wss_onData(dataEntry);
            } else if (['project', 'project-olcd-update'].includes(dataType)) {
              this.root.projectStore._wss_onData(dataEntry);
            } else if (
              [
                'profiler-project',
                'profiler-project-olcd-update',
                'company-watchlist-news-update'
              ].includes(dataType)
            ) {
              this.root.profilerProjectStore._wss_onData(dataEntry);
            } else if (
              [
                'outreach',
                'chat',
                'chat-lcm-update',
                'chat-archive-update',
                'chat-unread-update'
              ].includes(dataType)
            ) {
              this.root.outreachStore._wss_onData(dataEntry);
            }
          });
        }
      } catch (ex) {
        console.error(ex);
      }
    };

    ws.onclose = this.onSocketClose;

    ws.onerror = this.onSocketClose;

    this.ws = ws;
  };

  init = (wssToken = '') => {
    if (
      !this.root.authStore.user ||
      !this.root.authStore.userInfo ||
      this.isConnecting ||
      this.connected
    ) {
      return;
    }

    this.isConnecting = true;
    if (!wssToken) {
      this.root.makeRequest({
        endpoint: API_ENDPOINTS.GET_WEBSOCKET_TOKEN,
        onSuccess: ({ token: wssToken }) => {
          this._initSocket(wssToken);
        },
        onError: () => {
          this.onSocketClose();
        }
      });
    } else {
      this._initSocket(wssToken);
    }
  };

  onSocketClose = () => {
    if (this.ws) {
      this.ws.onopen = null;
      this.ws.onmessage = null;
      this.ws.onclose = null;
      this.ws.onerror = null;
      this.ws.close();
    }
    this.connected = false;
    this.isConnecting = false;
  };
}

export default WebSocketStore;
