import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import { ACCOUNT_TYPES } from '../../utils/constants/auth';
import Signup from '../auth/Signup';
import Login from '../auth/Login';
import InvestorView from './components/InvestorView';
import AgentView from './components/AgentView';
import useHistory from '../../hooks/useHistory';
import { matchPath } from '../../utils/utils';
import { paths } from '../../utils/constants/routes';
import Reroute from '../../components/navigation/Reroute';

const Home = observer(() => {
  const { authStore, isWaitlistActivated } = useStore();
  const { location } = useHistory();

  if (!authStore.user) {
    if (matchPath([paths.HOME_FULL], location)) {
      return <Reroute path={paths.HOME} />;
    }
    return isWaitlistActivated ? <Signup /> : <Login />;
  } else if (!isWaitlistActivated && matchPath([paths.HOME], location)) {
    return <Reroute path={paths.HOME_FULL} />;
  }

  if (authStore.userAccountType === ACCOUNT_TYPES.INVESTOR) {
    return <InvestorView />;
  } else if (authStore.userAccountType === ACCOUNT_TYPES.AGENT) {
    return <AgentView />;
  }
});

export default Home;
