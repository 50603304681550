import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import { FUND_FIELDS } from '../../utils/constants/fields';
import { PUNCTUATION } from '../../utils/constants/notation';
import { formatPercent, formatNumber, formatNumberRange } from '../../utils/utils';
import * as Portal from '@radix-ui/react-portal';
import DetailsContactTab from './details-components/DetailsContactTab';

const FundDetailsPopup = observer(({ fund = {}, onClose = () => {}, contact = null }) => {
  const { utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    tab: contact ? 'contact' : 'general',
    setTab: (tab = 'general') => (state.tab = tab)
  }));

  const tabs = [
    ...(contact
      ? [
          {
            name: 'Contact',
            onClick: () => state.setTab('contact'),
            isActive: state.tab === 'contact'
          }
        ]
      : []),
    {
      name: 'General information',
      onClick: () => state.setTab('general'),
      isActive: state.tab === 'general'
    },
    {
      name: 'Fund information',
      onClick: () => state.setTab('information'),
      isActive: state.tab === 'information'
    }
  ];

  return (
    <Portal.Root>
      <div className="shared-popup">
        <div className="shared-fund-details-popup-body">
          <div className="header">
            <div className="tabs">
              {tabs.map((tab) => {
                return (
                  <div
                    key={tab.name}
                    className={`tab${tab.isActive ? ' active' : ''}`}
                    onClick={tab.onClick}>
                    {tab.name}
                  </div>
                );
              })}
            </div>
            <div className="close" onClick={onClose}>
              Close
            </div>
          </div>
          <div className="content">
            {state.tab === 'contact' && <DetailsContactTab contact={contact} />}
            {state.tab === 'general' && (
              <>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.NAME.LABEL}</div>
                  <div className="value">{fund[FUND_FIELDS.NAME.NAME]}</div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.VINTAGE.LABEL}</div>
                  <div className="value">
                    {fund[FUND_FIELDS.VINTAGE.NAME] || PUNCTUATION.EMPTY_VALUE}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.VOLUME.LABEL} (€M)</div>
                  <div className="value">{formatNumber(fund[FUND_FIELDS.VOLUME.NAME])}</div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.DESCRIPTION.LABEL}</div>
                  <div className="value">{fund[FUND_FIELDS.DESCRIPTION.NAME]}</div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.INDUSTRIES.LABEL}</div>
                  <div className="value">
                    {utilsStore.getOptionName(fund, FUND_FIELDS.INDUSTRIES).join(', ')}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.GEOGRAPHIES.LABEL}</div>
                  <div className="value">
                    {utilsStore.getOptionName(fund, FUND_FIELDS.GEOGRAPHIES).join(', ')}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.SITUATION.LABEL}</div>
                  <div className="value">
                    {utilsStore.getOptionName(fund, FUND_FIELDS.SITUATION).join(', ')}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.EQUITY.LABEL}</div>
                  <div className="value">
                    {utilsStore.getOptionName(fund, FUND_FIELDS.EQUITY).join(', ')}
                  </div>
                </div>
              </>
            )}
            {state.tab === 'information' && (
              <>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.REVENUE_RANGE_MIN.LABEL} (€M)</div>
                  <div className="value">
                    {formatNumberRange(
                      fund[FUND_FIELDS.REVENUE_RANGE_MIN.NAME],
                      fund[FUND_FIELDS.REVENUE_RANGE_MAX.NAME]
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.EBITDA_RANGE_MIN.LABEL} (€M)</div>
                  <div className="value">
                    {formatNumberRange(
                      fund[FUND_FIELDS.EBITDA_RANGE_MIN.NAME],
                      fund[FUND_FIELDS.EBITDA_RANGE_MAX.NAME]
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.EBITDA_MARGIN.LABEL}</div>
                  <div className="value">{formatPercent(fund[FUND_FIELDS.EBITDA_MARGIN.NAME])}</div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.ENTERPRISE_RANGE_MIN.LABEL} (€M)</div>
                  <div className="value">
                    {formatNumberRange(
                      fund[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                      fund[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="bullet"></div>
                  <div className="label">{FUND_FIELDS.EQUITY_RANGE_MIN.LABEL} (€M)</div>
                  <div className="value">
                    {formatNumberRange(
                      fund[FUND_FIELDS.EQUITY_RANGE_MIN.NAME],
                      fund[FUND_FIELDS.EQUITY_RANGE_MAX.NAME]
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Portal.Root>
  );
});

export default FundDetailsPopup;
