import { observer } from 'mobx-react';
import { useMemo } from 'react';
import InputWrapper from './components/InputWrapper';

const CheckBox = observer(
  ({
    field,
    value = false,
    setFieldValue,
    children,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    hideErrorMessages = false,
    hideRequiredLabelSymbol = false,
    inputWrap = { enable: false, className: '' },
    wrapClassname = ''
  }) => {
    const props = useMemo(() => {
      const [inputProps, labelProps] = [{}, {}];

      inputProps.className = field.INPUT_CLASSNAME || 'form-control';

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      return { inputProps, labelProps };
    }, [field]);

    const { onSubmitErrorStateClassName, onSubmitErrorMessages } = useMemo(() => {
      let onSubmitErrorStateClassName = field.ON_SUBMIT_ERROR_STATE_WRAP_CLASSNAME || '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => m.field === field.NAME && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        onSubmitErrorStateClassName += ` ${
          field.ON_SUBMIT_ERROR_STATE_WRAP_INVALID_CLASSNAME || 'invalid-checkbox'
        }`;
      }

      return { onSubmitErrorStateClassName, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    const withWrap = inputWrap.enable;

    const view = (
      <div className={wrapClassname || field.WRAP_CLASSNAME || 'form-group'}>
        <div className={onSubmitErrorStateClassName}>
          <label {...props.labelProps}>
            <input
              type={field.TYPE}
              checked={value}
              onChange={() => setFieldValue(field, !value)}
              disabled={disabled}
              {...props.inputProps}
            />
            {field.LABEL || children}{' '}
            {field.LABEL &&
              field.REQUIRED &&
              !hideRequiredLabelSymbol &&
              !field.HIDE_REQUIRED_LABEL_SYMBOL &&
              '*'}
          </label>
        </div>
        {!hideErrorMessages &&
          onSubmitErrorMessages.map(({ msg }, idx) => (
            <div
              key={idx}
              className={
                field.ON_SUBMIT_ERROR_STATE_ERROR_MESSAGE_CLASSNAME || 'error-msg-checkbox'
              }>
              {msg}
            </div>
          ))}
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default CheckBox;
