import { observer, useLocalObservable } from 'mobx-react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../../components/buttons/IconButton';
import { DETAILS_POUP_TABS } from '../constants/tabs';
import { runInAction } from 'mobx';
import { useEffect, useRef } from 'react';
import Tree from 'react-d3-tree';
import ReactPaginate from 'react-paginate';
import { useStore } from '../../../../store/store';
import { getCompanyNewsDisplayTime } from '../../../Outreach/desktop/constants';
import { API_ENDPOINTS } from '../../../../api/endpoints';
import useHistory from '../../../../hooks/useHistory';

const performanceIcons = {
  group1: {
    up: '/icons/performance/group1-green-up.svg',
    down: '/icons/performance/group1-red-down.svg',
    right: '/icons/performance/group1-yellow-right.svg'
  },
  group2: {
    up: '/icons/performance/group2-black-up.svg',
    down: '/icons/performance/group2-black-down.svg',
    right: '/icons/performance/group2-black-right.svg'
  },
  group3: {
    up: '/icons/performance/group3-red-up.svg',
    down: '/icons/performance/group3-green-down.svg',
    right: '/icons/performance/group3-black-right.svg'
  }
};

const legendsIcons = {
  target: '/icons/clean/crosshair.svg',
  organization: '/icons/clean/bank.svg',
  individual: '/icons/clean/person.svg',
  entity: '/icons/clean/people.svg',
  share: '/icons/clean/pie-chart.svg',
  beneficial: '/icons/clean/percent.svg'
};

const newsPageSize = 8;

const CompanyDetails = observer(
  ({ company = {}, displayDetails = () => {}, isFromWatchList = false }) => {
    const treeMapRef = useRef();
    const { makeRequest, utilsStore, profilerProjectStore } = useStore();
    const { navigate, location } = useHistory();
    const state = useLocalObservable(() => ({
      rendered: true,
      unrender: () => (state.rendered = false),
      observableCompany: company,
      setObservableCompany: (company = {}) => {
        runInAction(() => {
          state.observableCompany = company;
        });
      },
      // tab: isFromWatchList ? DETAILS_POUP_TABS.UPDATES : DETAILS_POUP_TABS.GENERAL,
      tab: DETAILS_POUP_TABS.GENERAL,
      setTab: (tab = DETAILS_POUP_TABS.GENERAL) => {
        if (state.tab !== tab) {
          state.tab = tab;
          return true;
        }
      },
      scrollTabIntoView: (tabId = '') => {
        const tabDOM = document.getElementById(tabId);
        if (tabDOM) {
          setTimeout(() => {
            tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          }, 0);
        }
      },
      translatePoint: { x: 0, y: 0 },
      setTranslatePoint: (translateX) => {
        runInAction(() => {
          state.translatePoint = { x: translateX, y: 0 };
        });
      },
      newsPage: 1,
      setNewsPage: (page = 1) => {
        state.newsPage = page;
      },
      get newsPages() {
        return Math.ceil((state.observableCompany.news || []).length / newsPageSize);
      },
      get showNewsPages() {
        return state.newsPages > 1;
      },
      now: Date.now(),
      setDateNow: (ms) => (state.now = ms),
      updateTabVisited: false,
      flagUpdateTabVisited: () => {
        state.updateTabVisited = true;
      },
      isAddRemoveWatchlistPopupOpen: false,
      setIsAddRemoveWatchlistPopupOpen: (open = false) => {
        state.isAddRemoveWatchlistPopupOpen = open;
        utilsStore.lockScroll(open);
      },
      companyLogo: '/images/company-logo-placeholder.svg',
      loadCompanyLogo: () => {
        makeRequest({
          endpoint: API_ENDPOINTS.GET_COMPANY_LOGO,
          body: { companyDomain: state.observableCompany.website },
          onSuccess: (response) => {
            state.companyLogo = response?.src || '/images/company-logo-placeholder.svg';
          }
        });
      },
      newsLogos: {},
      loadNewsLogos: () => {
        if (!state.observableCompany.news?.length) {
          return;
        }

        makeRequest({
          endpoint: API_ENDPOINTS.GET_COMPANY_LOGO,
          body: {
            companyDomains: [...new Set(state.observableCompany.news.map((obj) => obj.domain))]
          },
          onSuccess: (response) => {
            state.newsLogos = Object.fromEntries(
              (response || []).map((obj) => [obj.domain, obj.src])
            );
          }
        });
      }
    }));

    useEffect(() => {
      const closeDetails = () => {
        displayDetails(null);
      };
      navigate(location.pathname + location.search);
      addEventListener('popstate', closeDetails);
      return () => {
        removeEventListener('popstate', closeDetails);
      };
    }, []);

    useEffect(() => {
      const interval = setInterval(() => state.setDateNow(Date.now()), 60_000);
      return () => {
        state.unrender();
        clearInterval(interval);
        if (state.isAddRemoveWatchlistPopupOpen) {
          state.setIsAddRemoveWatchlistPopupOpen();
        }
      };
    }, [state]);

    useEffect(() => {
      return () => {
        if (state.updateTabVisited) {
          profilerProjectStore.updateWatchlistCompanyNewsSeenDate(company.id);
        }
      };
    }, [state, profilerProjectStore, company.id]);

    useEffect(() => {
      if (state.tab === DETAILS_POUP_TABS.UPDATES) {
        state.flagUpdateTabVisited(true);
      }
    }, [state, state.tab]);

    useEffect(() => {
      state.setObservableCompany(company);
      state.loadCompanyLogo();
    }, [state, company]);

    useEffect(() => {
      state.loadNewsLogos();
    }, [state.observableCompany.news]);

    useEffect(() => {
      window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
      if (!treeMapRef.current) {
        return;
      }

      setTimeout(() => {
        const treeWrapperDOM = document.querySelector('.treeWrapper');
        if (!treeWrapperDOM) {
          return;
        }

        // const dragWidth = treeWrapperDOM.clientWidth / 2;
        const dragWidth = (treeWrapperDOM.clientWidth * 1.55) / 2;
        state.setTranslatePoint(dragWidth);
      }, 10);
    }, [state, state.tab, treeMapRef, treeMapRef.current]);

    const tabs = [
      {
        id: 'profiler_search_results_details_tab',
        name: 'General information',
        isActive: state.tab === DETAILS_POUP_TABS.GENERAL,
        onClick: () => {
          if (state.setTab(DETAILS_POUP_TABS.GENERAL)) {
            state.scrollTabIntoView('profiler_search_results_details_tab');
          }
        }
      },
      {
        id: 'profiler_search_results_product_tab',
        name: 'Product portfolio',
        isActive: state.tab === DETAILS_POUP_TABS.PRODUCT_PORTFOLIO,
        onClick: () => {
          if (state.setTab(DETAILS_POUP_TABS.PRODUCT_PORTFOLIO)) {
            state.scrollTabIntoView('profiler_search_results_product_tab');
          }
        }
      },
      {
        id: 'profiler_search_results_financials_tab',
        name: 'Financials',
        isActive: state.tab === DETAILS_POUP_TABS.FINANCIALS,
        onClick: () => {
          if (state.setTab(DETAILS_POUP_TABS.FINANCIALS)) {
            state.scrollTabIntoView('profiler_search_results_financials_tab');
          }
        }
      },
      {
        id: 'profiler_search_results_shareholder_tab',
        name: 'Shareholder structure',
        isActive: state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE,
        onClick: () => {
          if (state.setTab(DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE)) {
            state.scrollTabIntoView('profiler_search_results_shareholder_tab');
          }
        }
      }
    ];

    // if (isFromWatchList) {
    //   tabs.push({
    //     id: 'profiler_search_results_updates_tab',
    //     name: 'Updates',
    //     isActive: state.tab === DETAILS_POUP_TABS.UPDATES,
    //     onClick: () => {
    //       if (state.setTab(DETAILS_POUP_TABS.UPDATES)) {
    //         state.scrollTabIntoView('profiler_search_results_updates_tab');
    //       }
    //     }
    //   });
    // }

    const renderTreeCompanyDetails = (entry = {}) => {
      const name = entry.nodeDatum.name;
      const { type, age, beneficialOwnership, share } = entry.nodeDatum.attributes;
      return (
        <g>
          <foreignObject width={1} height={1} style={{ overflow: 'visible' }}>
            <div className="company-details-node-wrap ">
              <div className="name">{name}</div>
              <div className="icon-wrap">
                <img src={legendsIcons[type]} />
                {!!beneficialOwnership && (
                  <div className="beneficial-count-wrap">
                    <div className="beneficial-count">{beneficialOwnership}</div>
                  </div>
                )}
              </div>
              {!!share && (
                <div className="share-wrap">
                  <img src={legendsIcons.share} />
                  <div className="ownership">{share}%</div>
                </div>
              )}
            </div>
          </foreignObject>
        </g>
      );
    };

    const _allFinancialYears = Object.values(company.financials).reduce((acc, curr) => {
      return acc.concat(curr.years.map((obj) => obj.year));
    }, []);
    const minFinancialYear = Math.min(..._allFinancialYears);
    const maxFinancialYear = Math.max(..._allFinancialYears);
    const financialYears = [];
    for (let y = minFinancialYear; y <= maxFinancialYear; y++) {
      financialYears.push(y);
    }

    const isInWatchlist =
      profilerProjectStore.watchlist.findIndex((c) => c.id === company.id) !== -1;

    return (
      <>
        {state.isAddRemoveWatchlistPopupOpen && (
          <>
            <div
              className="add-remove-watchlist-popup-closer"
              onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
            />
            <div className="add-remove-watchlist-popup">
              <div className="title">{`Are you sure you want to remove ${company.name} from watchlist?`}</div>
              <div className="buttons-container">
                <IconButton
                  innerText="Cancel"
                  onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
                />
                <IconButton
                  innerText="Remove from watchlist"
                  type={ICON_BUTTON_TYPES.RED}
                  filled
                  onClick={() => {
                    state.setIsAddRemoveWatchlistPopupOpen();
                    profilerProjectStore.addToWatchlist(company.id, company.name, () => {
                      if (state.rendered && isFromWatchList) {
                        displayDetails(null);
                      }
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="company-details">
          <button onClick={() => navigate(-1)} className="backlink">
            {isFromWatchList ? 'Back to watchlist' : 'Back to search results'}
          </button>

          <div className="company-details-header">
            <div className="info-wrap">
              <img className="logo" src={state.companyLogo} />
              <div className="info-col">
                <div className="bold-text">{company.name}</div>
                <div className="normal-text">{company.headquarters}</div>
              </div>
              <div className="info-col">
                <div className="bold-text">Industry</div>
                <div className="normal-text">{company.industries.join(', ')}</div>
              </div>
              <div className="info-col">
                {!!company.website && (
                  <div
                    className="info-link"
                    onClick={() => window.open('https://' + company.website, '_blank')}>
                    {company.website}
                  </div>
                )}
                <div
                  className="info-link"
                  onClick={() =>
                    window.open(
                      `https://www.linkedin.com/search/results/companies/?keywords=${encodeURIComponent(
                        company.name
                      )}`,
                      '_blank'
                    )
                  }>
                  LinkedIn
                </div>
              </div>
            </div>
            <IconButton
              id="psrd-btn"
              type={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.WATCHLIST}
              disabled={profilerProjectStore.isAddingToProfilerWatchlist}
              tooltipText={
                profilerProjectStore.isAddingToProfilerWatchlist
                  ? ''
                  : isInWatchlist
                  ? 'Remove from watchlist'
                  : 'Add to watchlist'
              }
              filled={isInWatchlist}
              onClick={() => {
                if (isInWatchlist) {
                  state.setIsAddRemoveWatchlistPopupOpen(true);
                } else {
                  profilerProjectStore.addToWatchlist(company.id, company.name);
                }
              }}
            />
          </div>

          <div className="tabs-scrollable-wrap">
            <div className="tabs-wrap">
              {tabs.map((tab) => {
                return (
                  <div
                    key={tab.id}
                    id={tab.id}
                    className={`noselect tab ${tab.isActive ? 'active' : ''}`}
                    onClick={tab.onClick}>
                    {tab.name}
                  </div>
                );
              })}
            </div>
          </div>

          {state.tab === DETAILS_POUP_TABS.GENERAL && (
            <div className="general-tab-cards">
              <div className="card">
                <div className="title">Summary</div>
                <div className="card-row description">
                  <div className="property">Description</div>
                  <div className="value description">{company.description}</div>
                </div>
                <div className="card-row">
                  <div className="property">Value chain</div>
                  <div className="value">{company.valueChain}</div>
                </div>
                <div className="card-row">
                  <div className="property">HQ location</div>
                  <div className="value">{company.headquarters}</div>
                </div>
                <div className="card-row">
                  <div className="property">Goe focus</div>
                  <div className="value">{(company.geographies || []).join(', ')}</div>
                </div>
                <div className="card-row">
                  <div className="property">Number of employees</div>
                  <div className="value">{company.employees}</div>
                </div>
                <div className="card-row last">
                  <div className="property">Managing director</div>
                  <div className="value">{company.managingDirector}</div>
                </div>
              </div>
              <div className="card">
                <div className="title">Financials</div>
                <div className="general-financials-layout">
                  <div className="general-financials-labels">
                    <div className="labels head-labels">
                      <div className="label head-label">Figures in €M</div>
                    </div>
                    <div className="labels">
                      <div className="label">Revenues</div>
                      <div className="sub-label">Growth in %</div>
                    </div>
                    <div className="labels">
                      <div className="label">Gross profit</div>
                      <div className="sub-label">in % of revenues</div>
                    </div>
                    <div className="labels">
                      <div className="label">EBITDA</div>
                      <div className="sub-label">in % of revenues</div>
                    </div>
                    <div className="labels">
                      <div className="label">EBIT</div>
                      <div className="sub-label">in % of revenues</div>
                    </div>
                    <div className="labels">
                      <div className="label">Net income</div>
                      <div className="sub-label">in % of revenues</div>
                    </div>
                    <div className="labels last">
                      <div className="label">Net debt</div>
                      <div className="sub-label">Equity ratio</div>
                    </div>
                  </div>

                  <div className="general-financials-values">
                    <div className="labels-wrap head-labels-wrap">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels head-labels" key={year + 'hl'}>
                            <div className="label head-label">{year}</div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="labels-wrap">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels" key={year + 'lw-revenues'}>
                            <div className="label">
                              {(company.financials?.revenues?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.value || '-'}
                            </div>
                            <div className="sub-label">
                              {(company.financials?.revenues?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.growthInPercent || '-'}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="labels-wrap">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels" key={year + 'lw-gross-profit'}>
                            <div className="label">
                              {(company.financials?.grossProfit?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.value || '-'}
                            </div>
                            <div className="sub-label">
                              {(company.financials?.grossProfit?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.inPercentOfRevenues || '-'}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="labels-wrap">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels" key={year + 'lw-ebitda'}>
                            <div className="label">
                              {(company.financials?.ebitda?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.value || '-'}
                            </div>
                            <div className="sub-label">
                              {(company.financials?.ebitda?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.inPercentOfRevenues || '-'}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="labels-wrap">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels" key={year + 'lw-ebit'}>
                            <div className="label">
                              {(company.financials?.ebit?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.value || '-'}
                            </div>
                            <div className="sub-label">
                              {(company.financials?.ebit?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.inPercentOfRevenues || '-'}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="labels-wrap">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels" key={year + 'lw-net-income'}>
                            <div className="label">
                              {(company.financials?.netIncome?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.value || '-'}
                            </div>
                            <div className="sub-label">
                              {(company.financials?.netIncome?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.inPercentOfRevenues || '-'}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="labels-wrap last">
                      {financialYears.map((year) => {
                        return (
                          <div className="labels" key={year + 'lw-net-debt'}>
                            <div className="label">
                              {(company.financials?.netDebt?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.value || '-'}
                            </div>
                            <div className="sub-label">
                              {(company.financials?.netDebt?.years || []).find(
                                (obj) => obj.year === year
                              )?.values?.equityRatio || '-'}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="title">Product portfolio</div>
                <div className="product-portfolio-value">{company.productPortfolio}</div>
              </div>
              <div className="card">
                <div className="title">Shareholder structure </div>
                <div className="sub-title">Ultimate owner of {company.name}</div>
                <div className="shareholder-flat-structure-wrap">
                  {(company.ultimateShareholders || []).map((entry, idx) => {
                    return (
                      <div className="entry" key={entry.name + idx}>
                        <div className="column-entry">{entry.beneficialOwnership} %</div>
                        <div className="column-entry">{entry.name}</div>
                        <div className="column-entry">{entry.age}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {state.tab === DETAILS_POUP_TABS.PRODUCT_PORTFOLIO && (
            <div className="product-portfolio-tab-cards">
              <div className="card">
                <div className="property">Business verticals</div>
                <div className="value">{company.businessVerticals || 'N/A'}</div>
              </div>
              <div className="card">
                <div className="property">Overview of key products</div>
                <div className="value">{company.overviewOfKeyProducts || 'N/A'}</div>
              </div>
              <div className="card">
                <div className="property">Key related brands</div>
                <div className="value">{company.keyRelatedBrands || 'N/A'}</div>
              </div>
              <div className="card">
                <div className="property">Keywords</div>
                <div className="value">
                  {company.keywords?.length ? company.keywords.join(', ') : 'N/A'}
                </div>
              </div>
            </div>
          )}

          {state.tab === DETAILS_POUP_TABS.FINANCIALS && (
            <div className="financials-tab-content">
              <div className="financials-table">
                <div className="left-content">
                  {/*  */}
                  <div className="row">
                    <div className="row-cell property">
                      <div className="label">Figures in €M</div>
                    </div>
                    <div className="row-cell performance">
                      <div className="label">Performance</div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">Revenues</div>
                      <div className="label sub-label">Growth in %</div>
                    </div>
                    <div className="row-cell performance">
                      <img
                        src={performanceIcons.group1[company.financials?.revenues?.performance]}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">Gross profit</div>
                      <div className="label sub-label">in % of revenues</div>
                    </div>
                    <div className="row-cell performance">
                      <img
                        src={performanceIcons.group1[company.financials?.grossProfit?.performance]}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">EBITDA</div>
                      <div className="label sub-label">in % of revenues</div>
                    </div>
                    <div className="row-cell performance">
                      <img src={performanceIcons.group1[company.financials?.ebitda?.performance]} />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">EBIT</div>
                      <div className="label sub-label">in % of revenues</div>
                    </div>
                    <div className="row-cell performance">
                      <img src={performanceIcons.group1[company.financials?.ebit?.performance]} />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">Net income</div>
                      <div className="label sub-label">in % of revenues</div>
                    </div>
                    <div className="row-cell performance">
                      <img
                        src={performanceIcons.group1[company.financials?.netIncome?.performance]}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row large-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">CAPEX</div>
                    </div>
                    <div className="row-cell performance">
                      <img src={performanceIcons.group2[company.financials?.CAPEX?.performance]} />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">Total assets</div>
                    </div>
                    <div className="row-cell performance">
                      <img
                        src={performanceIcons.group2[company.financials?.totalAssets?.performance]}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">Net debt</div>
                      <div className="label sub-label">Interest-bearing debt</div>
                      <div className="label sub-label">Cash & cash equivalents</div>
                    </div>
                    <div className="row-cell performance">
                      <img
                        src={performanceIcons.group3[company.financials?.netDebt?.performance]}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row large-bottom-border">
                    <div className="row-cell property">
                      <div className="label bold-label">Trade working capital</div>
                      <div className="label sub-label">Inventory</div>
                      <div className="label sub-label">Receivables</div>
                      <div className="label sub-label">Payables</div>
                    </div>
                    <div className="row-cell performance">
                      <img
                        src={
                          performanceIcons.group3[
                            company.financials?.tradeWorkingCapital?.performance
                          ]
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="row">
                    <div className="row-cell property">
                      <div className="label bold-label">FTEs</div>
                      <div className="label sub-label">Growth</div>
                    </div>
                    <div className="row-cell performance">
                      <img src={performanceIcons.group2[company.financials?.FTE?.performance]} />
                    </div>
                  </div>
                  {/*  */}
                </div>

                <div className="right-content">
                  <div className="row">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + '' + idx}>
                          <div className="label">{year}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.revenues?.years?.find?.((obj) => obj.year === year)
                              ?.values?.value || '-'}
                          </div>
                          <div className="label sub-label">
                            {company.financials?.revenues?.years?.find?.((obj) => obj.year === year)
                              ?.values?.growthInPercent || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.grossProfit?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.value || '-'}
                          </div>
                          <div className="label sub-label">
                            {company.financials?.grossProfit?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.inPercentOfRevenues || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.ebitda?.years?.find?.((obj) => obj.year === year)
                              ?.values?.value || '-'}
                          </div>
                          <div className="label sub-label">
                            {company.financials?.ebitda?.years?.find?.((obj) => obj.year === year)
                              ?.values?.inPercentOfRevenues || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.ebit?.years?.find?.((obj) => obj.year === year)
                              ?.values?.value || '-'}
                          </div>
                          <div className="label sub-label">
                            {company.financials?.ebit?.years?.find?.((obj) => obj.year === year)
                              ?.values?.inPercentOfRevenues || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.netIncome?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.value || '-'}
                          </div>
                          <div className="label sub-label">
                            {company.financials?.netIncome?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.inPercentOfRevenues || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row large-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.CAPEX?.years?.find?.((obj) => obj.year === year)
                              ?.values?.value || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.totalAssets?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.value || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row normal-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.netDebt?.years?.find?.((obj) => obj.year === year)
                              ?.values?.value || '-'}
                          </div>
                          <div className="sub-label">
                            {company.financials?.netDebt?.years?.find?.((obj) => obj.year === year)
                              ?.values?.interestBearingDebt || '-'}
                          </div>
                          <div className="sub-label">
                            {company.financials?.netDebt?.years?.find?.((obj) => obj.year === year)
                              ?.values?.cashEquivalents || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row large-bottom-border">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.tradeWorkingCapital?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.value || '-'}
                          </div>
                          <div className="sub-label">
                            {company.financials?.tradeWorkingCapital?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.inventory || '-'}
                          </div>
                          <div className="sub-label">
                            {company.financials?.tradeWorkingCapital?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.receivables || '-'}
                          </div>
                          <div className="sub-label">
                            {company.financials?.tradeWorkingCapital?.years?.find?.(
                              (obj) => obj.year === year
                            )?.values?.payables || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                  <div className="row">
                    {financialYears.map((year, idx) => {
                      return (
                        <div className="row-cell" key={year + 'rv' + idx}>
                          <div className="label bold-label">
                            {company.financials?.FTE?.years?.find?.((obj) => obj.year === year)
                              ?.values?.value || '-'}
                          </div>
                          <div className="sub-label">
                            {company.financials?.FTE?.years?.find?.((obj) => obj.year === year)
                              ?.values?.growthInPercent || '-'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          )}

          {state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE && (
            <div className="shareholder-structure-tab-cards">
              <div className="card">
                <div className="heading">Ultimate shareholders</div>
                <div className="shareholder-flat-structure-wrap">
                  <div className="entry entry-heading">
                    <div className="column-entry">Share (%)</div>
                    <div className="column-entry">Name</div>
                    <div className="column-entry">Age</div>
                  </div>
                  {(company.ultimateShareholders || []).map((entry, idx) => {
                    return (
                      <div className="entry" key={entry.name + idx}>
                        <div className="column-entry">{entry.beneficialOwnership} %</div>
                        <div className="column-entry">{entry.name}</div>
                        <div className="column-entry">{entry.age}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="card">
                <div className="heading">Shareholders structure</div>
                <div className="shareholders-structure-wrap">
                  <div className="legend">
                    <div className="legend-row">
                      <div className="icon-wrap">
                        <img src={legendsIcons.target} />
                      </div>
                      <div>Target</div>
                    </div>
                    <div className="legend-row">
                      <div className="icon-wrap">
                        <img src={legendsIcons.organization} />
                      </div>
                      <div>Organization</div>
                    </div>
                    <div className="legend-row">
                      <div className="icon-wrap">
                        <img src={legendsIcons.individual} />
                      </div>
                      <div>Individual</div>
                    </div>
                    <div className="legend-row">
                      <div className="icon-wrap">
                        <img src={legendsIcons.entity} />
                      </div>
                      <div>Entity</div>
                    </div>
                    <div className="legend-row">
                      <div className="icon-wrap">
                        <img src={legendsIcons.share} />
                      </div>
                      <div>Share % of target</div>
                    </div>
                    <div className="legend-row">
                      <div className="icon-wrap beneficial">
                        <img src={legendsIcons.beneficial} />
                      </div>
                      <div>
                        Beneficial ownership
                        <br />% of target
                      </div>
                    </div>
                  </div>
                  <div className="treeWrapper">
                    <Tree
                      data={company.shareholdersStructure}
                      collapsible={true}
                      orientation="vertical"
                      pathFunc="straight"
                      renderCustomNodeElement={renderTreeCompanyDetails}
                      ref={treeMapRef}
                      translate={state.translatePoint}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {state.tab === DETAILS_POUP_TABS.UPDATES && (
            <div className="product-portfolio-updates-wrap">
              <div className="news-container">
                <div className="news-main-title">Last updates</div>
                {(company.news || [])
                  .slice((state.newsPage - 1) * newsPageSize, state.newsPage * newsPageSize)
                  .map((entry, idx) => {
                    const displayTime = getCompanyNewsDisplayTime(entry.date, state.now);
                    return (
                      <div
                        className="news-card"
                        key={idx}
                        onClick={() =>
                          window.open(
                            company.website.startsWith('http') ? entry.url : 'https://' + entry.url,
                            '_blank'
                          )
                        }>
                        <div className="news-info">
                          <div className="domain-wrap">
                            <img
                              className="domain-logo"
                              src={
                                state.newsLogos[entry.domain] ||
                                '/images/company-logo-placeholder.svg'
                              }
                            />
                            <div className="domain-text">{entry.domain}</div>
                          </div>
                          <div className="news-title">{entry.title}</div>
                          <div className="news-date">{displayTime}</div>
                        </div>
                        <div className="image-wrap">
                          <img src={entry.imageUrl} />
                        </div>
                      </div>
                    );
                  })}
              </div>
              {state.showNewsPages && (
                <ReactPaginate
                  onPageChange={({ selected }) => {
                    state.setNewsPage(selected + 1);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  forcePage={state.newsPage - 1}
                  pageRangeDisplayed={utilsStore.windowWidth < 480 ? 1 : newsPageSize}
                  marginPagesDisplayed={utilsStore.windowWidth < 480 ? 1 : newsPageSize}
                  pageCount={state.newsPages}
                  renderOnZeroPageCount={null}
                  breakLabel="..."
                  nextLabel="Next"
                  previousLabel="Prev"
                  containerClassName="pagination-container"
                  pageClassName="page"
                  pageLinkClassName="pageLink"
                  nextClassName="next"
                  nextLinkClassName="nextLink"
                  previousClassName="prev"
                  previousLinkClassName="prevLink"
                  breakClassName="break"
                  breakLinkClassName="breakLink"
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
);

export default CompanyDetails;
