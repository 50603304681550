import { observer } from 'mobx-react';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';

const icons = {
  funds: ICON_BUTTON_ICONS.CASH_STACK,
  portfolios: ICON_BUTTON_ICONS.PORTFOLIO_COMPANY,
  investprofiles: ICON_BUTTON_ICONS.INVEST_PROFILE,
  teamfeedback: ICON_BUTTON_ICONS.CHAT_LEFT_QUOTE
};

const HeaderSubtab = observer(
  ({
    type = '',
    text = '',
    active = false,
    count = 0,
    showEmptyDot = false,
    onClick = () => {},
    wrapClassName = ''
  }) => {
    const tabClassNames = ['noselect', 'subtab'];
    if (wrapClassName) {
      tabClassNames.push(wrapClassName);
    }
    if (active) {
      tabClassNames.push('active');
    }

    return (
      <div
        className={tabClassNames.join(' ')}
        onClick={() => {
          if (!active) {
            onClick();
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 10);
          }
        }}>
        <div className="text">{text}</div>
        <div className="img-wrap">
          <img src={icons[type]} />
        </div>
        {!active && !!count && <div className="count">{count}</div>}
        {!active && !count && showEmptyDot && <div className="count" />}
      </div>
    );
  }
);

export default HeaderSubtab;
