import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { paths } from '../../utils/constants/routes';
import { useStore } from '../../store/store';
import StaticPageLayout from '../layouts/StaticPageLayout';
import Header from './Header';
import Footer from './Footer';

const Reroute = ({ path = '' }) => {
  const {
    authStore: { user }
  } = useStore();
  return (
    <div className="app-wrapper">
      <Header />
      <StaticPageLayout>
        <Navigate to={path || (user ? paths.HOME_FULL : paths.HOME)} />
      </StaticPageLayout>
      <Footer />
    </div>
  );
};

Reroute.propTypes = {
  path: PropTypes.string.isRequired
};

export default Reroute;
