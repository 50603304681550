import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import AgentProjectButton from './AgentProjectButton';
import RecentOutreachButton from './RecentOutreachButton';

const RecentOutreachesSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isRecentOutreachesExpanded, toggle_d_RecentOutreaches, recentOutreaches } =
    outreachStore;

  const count = recentOutreaches.reduce((c, o) => c + o.newMessagesCount, 0);

  return (
    <>
      <CollapsableSectionHead
        title="Recent outreaches"
        icon={ICON_BUTTON_ICONS.CHAT_SQUARE_TEXT}
        isCollapsed={!d_isRecentOutreachesExpanded}
        toggleCollapse={toggle_d_RecentOutreaches}
        count={count}
        showEmptyDot={!count && recentOutreaches.findIndex((o) => o.hasMarkedUnread) !== -1}
      />
      {d_isRecentOutreachesExpanded &&
        recentOutreaches.map((outreach) => {
          return (
            <RecentOutreachButton
              key={outreach.id}
              outreachId={outreach.id}
              outreach={outreach}
              params={params}
            />
          );
        })}
      {d_isRecentOutreachesExpanded && <div className="separator" />}
    </>
  );
});

export default RecentOutreachesSection;
