import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import AgentProjectButton from './AgentProjectButton';

const SavedProjectsSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isSavedProjectsExpanded, toggle_d_SavedProjects, projectsList } = outreachStore;

  return (
    <>
      <CollapsableSectionHead
        title="Saved projects"
        icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
        isCollapsed={!d_isSavedProjectsExpanded}
        toggleCollapse={toggle_d_SavedProjects}
        count={projectsList.savedNewMessagesCount}
        showEmptyDot={
          !projectsList.savedNewMessagesCount &&
          Object.values(projectsList.saved).findIndex((p) => p.hasMarkedUnread) !== -1
        }
      />
      {d_isSavedProjectsExpanded &&
        Object.entries(projectsList.saved)
          .sort((a, b) => b[1].lastMessageDate - a[1].lastMessageDate)
          .map(([projectId, project]) => {
            return (
              <AgentProjectButton
                key={projectId}
                projectId={projectId}
                project={project}
                params={params}
                list={projectsList.saved}
                type="active"
              />
            );
          })}
      {d_isSavedProjectsExpanded && <div className="separator" />}
    </>
  );
});

export default SavedProjectsSection;
