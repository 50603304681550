import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import OutreachBox from './OutreachBox';

const ProvideFeedbackSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isProvideFeedbackExpanded, toggle_d_ProvideFeedback, outreachesList } = outreachStore;
  const { giveFeedback, giveFeedbackNewMessagesCount, giveFeedbackHasMarkedUnread } =
    outreachesList;

  return (
    <>
      <CollapsableSectionHead
        title="Provide feedback"
        isCollapsed={!d_isProvideFeedbackExpanded}
        toggleCollapse={toggle_d_ProvideFeedback}
        count={giveFeedbackNewMessagesCount}
        showEmptyDot={!giveFeedbackNewMessagesCount && giveFeedbackHasMarkedUnread}
      />
      {d_isProvideFeedbackExpanded &&
        giveFeedback
          .slice()
          .sort(
            (a, b) =>
              (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]?.date || 0) -
              (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]?.date || 0)
          )
          .map((o) => {
            return <OutreachBox key={'p-f-s' + o.id} outreach={o} params={params} />;
          })}
      {d_isProvideFeedbackExpanded && <div className="separator" />}
    </>
  );
});

export default ProvideFeedbackSection;
