import { useStore } from '../../../store/store';
import { useLocalObservable, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { LOGIN_FIELDS, getErrorFields, trimStateFields } from '../../../utils/constants/fields';
import { useEffect } from 'react';
import SignLayout from '../../../components/layouts/SignLayout';
import Input from '../../../components/inputs/Input';
import { mapFieldsToState } from '../../../utils/utils';
import Signup from '../Signup';

const Login = observer(() => {
  const { authStore } = useStore();

  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(LOGIN_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(LOGIN_FIELDS), state.fields);
    }
  }));

  useEffect(() => {
    return () => {
      authStore.cleanUpAuthPage();
    };
  }, [authStore]);

  const onSubmit = (e) => {
    e.preventDefault();

    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    authStore.login(fields[LOGIN_FIELDS.EMAIL.NAME], fields[LOGIN_FIELDS.PASSWORD.NAME]);
  };

  // const linkedinQueryParams = {
  //   identity_provider: 'linkedin',
  //   redirect_uri: window.location.origin + '?authenticate',
  //   response_type: 'CODE',
  //   client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  //   scope: 'aws.cognito.signin.user.admin email openid phone profile'
  // };
  // const linkedinLoginUrl =
  //   process.env.REACT_APP_COGNITO_DOMAIN +
  //   '/oauth2/authorize?' +
  //   new URLSearchParams(linkedinQueryParams);

  const { setFieldValue, fields } = state;

  if (authStore.showLoginVerifyScreen) {
    return <Signup />;
  }

  return (
    <SignLayout>
      <div className="login-wrapper">
        <form onSubmit={onSubmit}>
          <Input
            {...{
              field: LOGIN_FIELDS.EMAIL,
              value: fields[LOGIN_FIELDS.EMAIL.NAME],
              setFieldValue
            }}
          />
          <Input
            {...{
              field: LOGIN_FIELDS.PASSWORD,
              value: fields[LOGIN_FIELDS.PASSWORD.NAME],
              setFieldValue
            }}
          />
          <Link className="link" to={paths.FORGOTTEN_PASSWORD}>
            Forgot Password?
          </Link>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              authStore.isAuthenticating ||
              state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length
            }>
            {authStore.isAuthenticating ? 'Loading...' : 'Login'}
          </button>
          {authStore.authError && <div className="error">{authStore.authError}</div>}
          {!!state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length &&
            state.validationFields.messages
              .filter((m) => !m.isOnSubmit)
              .map((m, idx) => (
                <div className={m.isValid ? 'success' : 'error'} key={idx}>
                  {m.msg}
                </div>
              ))}
          {authStore.authErrors.messages.map((m, idx) => (
            <div className={m.isValid ? 'success' : 'error'} key={idx}>
              {m.msg}
            </div>
          ))}

          <div className="text-divider">or</div>

          {/* no LI login for now */}
          {/* <div className="linkedin-wrapper">
            <a href={linkedinLoginUrl}>Login with LinkedIn</a>
          </div> */}
          <Link to={paths.SIGNUP} className="btn btn-outline btn-block">
            New here? Sign up now
          </Link>
        </form>
      </div>
    </SignLayout>
  );
});

export default Login;
