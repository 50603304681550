export const SHARED_PROJECT_TYPE_TABS = { PENDING: 'pending', RESOLVED: 'resolved' };

export const SELECTED_PROJECT_TABS = {
  RESULTS: 'results',
  EXCLUDED: 'excluded'
};

export const selectedProjectTabPageSize = 8;

export const decodeSharedProjectsParams = (paramsString = '') =>
  JSON.parse(decodeURIComponent(paramsString));
export const encodeProjectsParams = (params = {}) => encodeURIComponent(JSON.stringify(params));
