import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import CollapsableSectionHead from './CollapsableSectionHead';
import OutreachBox from './OutreachBox';

const ReceiveFeedbackSection = observer(({ params = {} }) => {
  const { outreachStore } = useStore();
  const { d_isReceiveFeedbackExpanded, toggle_d_ReceiveFeedback, outreachesList } = outreachStore;
  const { getFeedback, getFeedbackNewMessagesCount, getFeedbackHasMarkedUnread } = outreachesList;

  return (
    <>
      <CollapsableSectionHead
        title="Receive feedback"
        isCollapsed={!d_isReceiveFeedbackExpanded}
        toggleCollapse={toggle_d_ReceiveFeedback}
        count={getFeedbackNewMessagesCount}
        showEmptyDot={!getFeedbackNewMessagesCount && getFeedbackHasMarkedUnread}
      />
      {d_isReceiveFeedbackExpanded &&
        getFeedback
          .slice()
          .sort(
            (a, b) =>
              (outreachStore.messages[b.id][outreachStore.messages[b.id].length - 1]?.date || 0) -
              (outreachStore.messages[a.id][outreachStore.messages[a.id].length - 1]?.date || 0)
          )
          .map((o) => {
            return <OutreachBox key={'r-f-s' + o.id} outreach={o} params={params} />;
          })}
      {d_isReceiveFeedbackExpanded && <div className="separator" />}
    </>
  );
});

export default ReceiveFeedbackSection;
