import { observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';

const SearchFormDesktop = observer(({ fields, isSearchDisabled, onSearch }) => {
  return (
    <form onSubmit={onSearch} className="desktop">
      <div className="fields-area">
        <div className="fields-area-title">Search by using your key project data</div>
        <div className="search-type-selector">{fields[SEARCH_FIELDS.SEARCH_TYPE.ID]}</div>
        <div className="row">
          {fields[SEARCH_CATEGORIES.GENERAL][SEARCH_FIELDS.INDUSTRY.ID]}
          {fields[SEARCH_CATEGORIES.GENERAL][SEARCH_FIELDS.HEADQUARTER.ID]}
          {fields[SEARCH_CATEGORIES.GENERAL][SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.ID]}
        </div>
        <div className="row">
          {fields[SEARCH_CATEGORIES.TRANSACTION][SEARCH_FIELDS.SITUATION.ID]}
          {fields[SEARCH_CATEGORIES.TRANSACTION][SEARCH_FIELDS.EQUITY_STAKE.ID]}
          <div className="col" />
        </div>
        <div className="row">
          {fields[SEARCH_CATEGORIES.FINANCIALS][SEARCH_FIELDS.REVENUE.ID]}
          {fields[SEARCH_CATEGORIES.FINANCIALS][SEARCH_FIELDS.EBITDA.ID]}
          {fields[SEARCH_CATEGORIES.FINANCIALS][SEARCH_FIELDS.EBITDA_MARGIN.ID]}
        </div>
        <div className="row">
          {
            fields[SEARCH_CATEGORIES.VALUATION][
              SEARCH_FIELDS.ENTERPRISE_MIN.ID + SEARCH_FIELDS.ENTERPRISE_MAX.ID
            ]
          }
          {
            fields[SEARCH_CATEGORIES.VALUATION][
              SEARCH_FIELDS.EQUITY_MIN.ID + SEARCH_FIELDS.EQUITY_MAX.ID
            ]
          }
          <div className="col" />
        </div>
        <div className="fields-area-mandatory-row">*mandatory fields</div>
      </div>
      <div className="separator"></div>

      <div className="submit-area">
        <button
          type="submit"
          className="btn btn-primary disable-fade-primary"
          disabled={isSearchDisabled}>
          Search
        </button>
      </div>
    </form>
  );
});

export default SearchFormDesktop;
