import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { Link } from 'react-router-dom';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import { useEffect } from 'react';
import useUserTab from './components/TabUser';
import useMobileSettingsTab from './components/TabMobileSettings';
import useChangePasswordTab from './components/TabChangePassword';
import useDeactivateAccountTab from './components/TabDeactivateAccount';
import useHistory from '../../../hooks/useHistory';

const tabConfig = [
  { path: paths.USER_PROFILE, desktopLabel: 'User', iconCls: 'user' },
  {
    path: paths.USER_PROFILE_SETTINGS_CHANGE_PASSWORD,
    desktopLabel: 'Change password',
    iconCls: 'change-password',
    onlyDesktop: true
  },
  {
    path: paths.USER_PROFILE_SETTINGS_DEACTIVATE_ACCOUNT,
    desktopLabel: 'Deactivate account',
    iconCls: 'deactivate-account',
    onlyDesktop: true
  },
  {
    path: paths.USER_PROFILE_SETTINGS,
    desktopLabel: 'Settings',
    iconCls: 'settings',
    onlyMobile: true
  }
];

const Profile = observer(() => {
  const { authStore } = useStore();
  const { location } = useHistory();

  const state = useLocalObservable(() => ({
    tab: paths.USER_PROFILE,
    setTab: (value = '') => (state.tab = value)
  }));

  useEffect(() => {
    if (state.tab !== location.pathname) {
      state.setTab(location.pathname);
    }
  }, [state, location.pathname]);

  useEffect(() => {
    return () => {
      authStore.cleanUpAuthPage();
    };
  }, [authStore]);

  const hookViews = {
    [tabConfig[0].path]: useUserTab(),
    [tabConfig[1].path]: useChangePasswordTab(),
    [tabConfig[2].path]: useDeactivateAccountTab(),
    [tabConfig[3].path]: useMobileSettingsTab()
  };

  let hookView = hookViews[location.pathname];
  const { view, onSave, onRestore, isSaveDisabled, isRestoreDisabled, isActionsHidden } = hookView;

  return (
    <StaticPageLayout page="user-profile" hideMobileFooter={true}>
      <div className="title-container">
        <div className="gray-title-panel">
          <div className="title-section">
            <h4>My account</h4>
            <h6>Manage your user data</h6>
          </div>
          {!isActionsHidden && (
            <div className="actions">
              <button
                className="btn btn-transparent btn-short disable-fade-transparent"
                disabled={isRestoreDisabled}
                onClick={onRestore}>
                Restore
              </button>
              <button
                className="btn btn-primary btn-short disable-fade-primary"
                disabled={isSaveDisabled}
                onClick={onSave}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="tabs">
        {/* we have 1 common tab between Deskto and Mobile - is there even a point in having a tabConfig ? */}
        {tabConfig.map((tab) => {
          const className = ['tab'];

          // mobile Settings actually covers multiple "tabs" that should be re-considered for displaying it "active"
          let isInMobileSettingsTab = false;
          if (state.tab.startsWith(paths.USER_PROFILE_SETTINGS)) {
            if (state.tab.startsWith(tab.path)) {
              if (tab.path.startsWith(paths.USER_PROFILE_SETTINGS)) {
                isInMobileSettingsTab = true;
              }
            }
          }
          if (tab.path === state.tab || isInMobileSettingsTab) {
            className.push('active');
          }
          if (tab.onlyDesktop) {
            className.push('only-desktop');
          }
          if (tab.onlyMobile) {
            className.push('only-mobile');
          }

          const iconClassName = ['icon'];
          if (tab.path === state.tab || isInMobileSettingsTab) {
            iconClassName.push(`active`);
          }
          iconClassName.push(`icon-${tab.iconCls}`);

          return (
            <Link to={tab.path} key={tab.path} className={className.join(' ')}>
              <div className={iconClassName.join(' ')}></div>
              <div className="desktop-text">{tab.desktopLabel}</div>
              <div className="mobile-text">{tab?.mobileLabel || tab.desktopLabel}</div>
            </Link>
          );
        })}
      </div>
      <form>{view}</form>
    </StaticPageLayout>
  );
});

export default Profile;
