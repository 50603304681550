import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import { ColorRing } from 'react-loader-spinner';

const MobileAppRefresher = observer(({ wrapClassName = '', isFromMobileNav = false }) => {
  const { utilsStore, authStore } = useStore();

  const classNames = ['mobile-app-refresher'];
  if (authStore.isLoadingAccountDetails || authStore.accountInfoError) {
    classNames.push('no-header');
  }

  if (wrapClassName) {
    classNames.push(wrapClassName);
  }

  return (
    <div className={classNames.join(' ')}>
      {/* {utilsStore.isRefreshingApp && ( */}
      {(!utilsStore.isMobileHamburgerOpen ||
        (utilsStore.isMobileHamburgerOpen && isFromMobileNav)) && (
        <ColorRing
          visible={true}
          height="48"
          width="48"
          ariaLabel="page-refresh-animation-icon"
          colors={['#348cf9', '#348cf9', '#348cf9', '#348cf9', '#348cf9']}
        />
      )}

      {/* )} */}
      {/* {!utilsStore.isRefreshingApp && <span>Refresh</span>} */}
    </div>
  );
});

export default MobileAppRefresher;
