import { useLocation, useNavigate, useParams } from 'react-router';
import history from '../utils/history';
import { useMemo } from 'react';

const useHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const goBack = useMemo(() => {
    return (targetPrevRoute = null, targetPrevPath = null) => {
      if (history._locationStack.length === 1 && !targetPrevRoute) {
        return;
      }

      if (targetPrevRoute) {
        if (history.matchesPrevPath(targetPrevRoute)) {
          navigate(-1);
        } else {
          navigate(targetPrevPath || targetPrevRoute);
        }
      } else {
        navigate(-1);
      }
    };
  }, [history, navigate]);

  return {
    location,
    navigate,
    params,
    goBack,
    matchesPrevPath: history.matchesPrevPath,
    lastHref: history.lastHref,
    lastLocation: history.lastLocation,
    prevHref: history.prevHref,
    prevLocation: history.prevLocation
  };
};

export default useHistory;
